<template>
  <v-container class="d-flex flex-column align-center justify-center" style="min-height: 100vh">
    <v-row class="d-flex flex-column align-center justify-center pa-5">
      <v-col cols="12" class="d-flex flex-column align-center justify-center">
        <div>
          <v-img src="/images/dip-logo.svg" contain aspect-ratio="1.6" width="115" max-width="100%" class="ma-5" />
        </div>
        <v-card class="d-flex" flat>
          <v-row>
            <v-col cols="12" md="6" class="d-none d-md-flex">
              <v-img src="/images/img-login.png" position="right bottom" height="100%" />
            </v-col>
            <v-col cols="12" md="6" class="d-flex flex-column align-center justify-space-between">
              <div>
                <v-container pa-6 pt-8 class="d-flex flex-column align-center">
                  <h1 class="text-center text-primary mb-2">Tweefactorauthenticatie</h1>
                  <v-col cols="12" class="pa-0 mb-2 text-center">
                    <p>Voer de verificatiecode uit de authenticatie-app in. Heb je vragen? Neem contact op met <a
                        href="mailto:support@dip.nl">support@dip.nl</a> en we helpen je graag verder.</p>
                    <h4 class="pt-16">Verificatiecode</h4>
                  </v-col>
                  <div style="max-width: 300px">
                    <v-otp-input v-model="otp" :disabled="loading" @finish="onFinish" />
                    <v-overlay :value="loading" absolute>
                      <v-progress-circular indeterminate color="primary" />
                    </v-overlay>
                  </div>
                  <v-alert text variant="outlined" type="error" v-if="authenticationError">
                    {{ authenticationError }}
                  </v-alert>
                </v-container>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'two-factor-auth',
  data() {
    return {
      loading: false,
      otp: '',
    };
  },
  computed: {
    ...mapGetters('auth', ['authenticating', 'authenticationError', 'authenticationErrorCode']),
  },
  methods: {
    ...mapActions('auth', ['twoFactorAuth']),
    onFinish() {
      this.loading = true;
      this.twoFactorAuth({ code: this.otp });
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>