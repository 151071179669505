<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="1123"></breadcrumbs-with-help>
        </v-row>

        <v-row flex>
          <v-col
            cols="12"
            sm="12"
            md="4"
            class="pa-0 ma-0 d-block d-sm-flex d-md-block"
          >
            <monitor-synchronization-widget
              :cols-desktop="12"
              :cols-tablet="6"
              :cols-mobile="12"
              :help-id="1139"
            ></monitor-synchronization-widget>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

// widgets
import MonitorSynchronizationWidget from '@/components/widgets/MonitorSynchronization.vue';

export default {
  name: 'MonitorSynchronization',
  components: {
    BreadcrumbsWithHelp,
    MonitorSynchronizationWidget,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'monitor',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'synchronisatie',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>
