<template>
  <v-col
    :cols="colsMobile"
    :sm="colsTablet"
    :md="colsDesktop"
    class="d-flex fill-height"
  >
    <v-card
      class="px-4 py-0 mb-1"
      flat
      width="100%"
    >
      <v-container>
        <v-row class="block-head">
          <v-col
            cols="12"
            class="d-flex align-center justify-space-between"
          >
            <p class="text-primary font-weight-medium mb-0">verkoop per gemeente</p>
            <help
              v-if="helpId != ''"
              bg-color=""
              :help-id="helpId"
            ></help>
          </v-col>
        </v-row>
        <v-row
          flex
          align="start"
          justify="center"
          class="py-2"
          style="overflow: hidden; position: relative"
        >
          <div id="municipalities"></div>
          <div class="legend">
            <h3>Aantal kaartkopers per gemeente</h3>
            <div class="d-flex align-center">
              <div class="rect ninety"></div>
              > 90
            </div>
            <div class="d-flex align-center">
              <div class="rect ninety-less"></div>
              70-90
            </div>
            <div class="d-flex align-center">
              <div class="rect fifty-seventy"></div>
              50-70
            </div>
            <div class="d-flex align-center">
              <div class="rect thirty-fifty"></div>
              30-50
            </div>
            <div class="d-flex align-center">
              <div class="rect ten-thirty"></div>
              10-30
            </div>
            <div class="d-flex align-center">
              <div class="rect ten-less"></div>
              &lt; 10
            </div>
          </div>
          <div class="map-buttons">
            <v-btn
              color="primary"
              elevation="0"
              id="zoom_in"
              class="mr-1 font-weight-bold pa-0"
              >+</v-btn
            >
            <v-btn
              color="primary"
              elevation="0"
              id="zoom_out"
              class="mr-1 font-weight-bold pa-0"
              >-</v-btn
            >
          </div>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import * as d3 from 'd3';
import * as topojson from 'topojson';
import Help from '../../Help.vue';

export default {
  props: ['helpId', 'colsMobile', 'cols-Tablet', 'colsDesktop', 'widgetData', 'mapData'],
  components: {
    Help,
  },
  name: 'MapMuniCipalities',
  computed: {
    isDataLoaded() {
      return null;
    },
  },
  methods: {
    drawMap() {
      this.clear();
      this.drawMapChart();
      this.initResizer();
    },
    drawMapChart() {
      let data = this.mapData;

      let NETHERLANDS_FILE_MUNICIPALITIES = 'https://cartomap.github.io/nl/wgs84/gemeente_2023.topojson';

      var windowWidth = (window.innerWidth - 350) / 2;

      const width = windowWidth;
      const height = width;

      const projection = d3
        .geoMercator()
        .scale(width * 11)
        .center([5.5, 52.2]) // strange magic
        .translate([width / 2, height / 2])
        .precision(0.1);

      const path = d3.geoPath().projection(projection);

      const map = d3
        .select('#municipalities')
        .append('svg')
        .attr('id', 'mapMunicipalties')
        .attr('width', width)
        .attr('height', height)
        .attr('viewbox', `0 0 ${width} ${height}`)
        .append('g');

      const zoom = d3.zoom().scaleExtent([1, 5]).on('zoom', zoomed);
      const mapInnerGroup = d3.select('svg > g');

      d3.select('#zoom_in').on('click', function () {
        zoom.scaleBy(mapInnerGroup.transition().duration(750), 1.2);
      });
      d3.select('#zoom_out').on('click', function () {
        zoom.scaleBy(mapInnerGroup.transition().duration(750), 0.8);
      });

      function zoomed(event) {
        mapInnerGroup.attr('transform', event.transform);
      }

      // run zoom function
      mapInnerGroup.call(zoom);

      const colorDomain = [10, 30, 50, 70, 90];
      const colorRange = ['#adfcad', '#ffcb40', '#ffba00', '#ff7d73', '#ff4e40', '#ff1300'];
      // const extColorDomain = [0, 10, 30, 50, 70, 90];
      const color = d3.scaleThreshold().domain(colorDomain).range(colorRange);

      const tooltip = d3.select('body').append('div').attr('class', 'tooltip').style('opacity', 0);

      (async function getChart() {
        try {
          const topology = await d3.json(NETHERLANDS_FILE_MUNICIPALITIES);

          const geojson = topojson.feature(topology, topology.objects.gemeente_2023);

          if (data != null) {
            var gemeentes = [];
            geojson.features.forEach(function (gemeente) {
              if (gemeente != undefined) {
                gemeentes.push(gemeente.properties.statnaam);
              }
            });

            const objectArray = gemeentes.map((string) => ({
              name: string,
              purchases: 0,
            }));

            var selected = data.values;
            var original = objectArray;

            selected.forEach((obj) => {
              var exists = original.find((x) => x.name === obj.name);
              if (exists) {
                exists.purchases += obj.purchases;
              } else {
                original.push(obj);
              }
            });

            data = original;
          }

          map
            .selectAll('path')
            .data(geojson.features)
            .enter()
            .append('path')
            .attr('d', path)
            .style('stroke', 'white')
            .style('fill', (d) => {
              const { id } = d.properties;
              let value;

              value = data[id - 1]['purchases'];

              if (value) {
                return color(value);
              }

              return '#ccc';
            })
            .on('mouseover', function (event, d) {
              const { id, statnaam } = d.properties;
              const value = data[id - 1]['purchases'];

              // Get the original color
              const originalColor = d3.color(d3.select(this).style('fill'));

              // Modify the color brightness or saturation
              const modifiedColor = originalColor.darker(0.4); // Modify the brightness

              d3.select(this).style('fill', modifiedColor);

              tooltip
                .html(`<b>${statnaam}</b></br>${value}`)
                .style('display', 'block')
                .style('opacity', 1)
                .style('left', `${event.pageX - 30}px`)
                .style('top', `${event.pageY - 40}px`);
            })
            .on('mouseout', function () {
              const originalColor = d3.select(this).datum().originalColor;
              d3.select(this).style('fill', originalColor);

              tooltip.style('opacity', 0);
            })
            .each(function () {
              // Store the original color as d3.color object
              d3.select(this).datum().originalColor = d3.color(d3.select(this).style('fill'));
            });
        } catch (e) {
          console.error(e);
        }
      })();
    },
    clear() {
      d3.selectAll('svg#mapMunicipalties').remove();
    },
    initResizer() {
      let resizeTimer;

      window.onresize = () => {
        clearTimeout(resizeTimer);

        resizeTimer = setTimeout(() => {
          this.clear();
          this.drawMapChart();
        }, 100);
      };
    },
  },
  watch: {
    mapData: {
      handler: function () {
        if (this.mapData !== null) {
          this.drawMap();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
#map {
  margin: 0 auto;
  display: block;
}

.title {
  text-align: center;
  font-size: 2.5em;
  margin: 1.5em 0;
}

.tooltip {
  font-family: 'Axiforma', Avenir, Helvetica, Arial, sans-serif;
  box-shadow: none;
  pointer-events: none;
  z-index: 9999;
}

.fill-height {
  height: 100%;
}

.map-buttons {
  position: absolute;
  top: 3rem;
  right: 5rem;
}

#municipalities {
  order: 1;
}

.legend {
  h3 {
    margin-bottom: 1rem;
    font-weight: 500;
  }
}

.rect {
  opacity: 0.8;
  width: 20px;
  height: 20px;
  margin-right: 10px;

  &.ninety {
    background-color: rgb(255, 19, 0);
  }
  &.ninety-less {
    background-color: rgb(255, 78, 64);
  }
  &.fifty-seventy {
    background-color: rgb(255, 125, 115);
  }
  &.thirty-fifty {
    background-color: rgb(255, 186, 0);
  }
  &.ten-thirty {
    background-color: rgb(255, 203, 64);
  }
  &.ten-less {
    background-color: rgb(173, 252, 173);
  }
}

button[id^='zoom'] {
  width: 2.6rem !important;
  min-width: unset !important;
  height: 2.6rem !important;
}
</style>
