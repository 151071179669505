<template>
    <v-dialog max-width="500">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn v-if="buttonText === ''" v-bind="activatorProps" rounded="circle" elevation="0" size="small"
                :icon="icon" :color="type" class="ml-1">
                <v-icon color="#fff">{{ icon }}</v-icon></v-btn>
            <v-btn v-else v-bind="activatorProps" size="large" :color="type" class="text-lowercase align-self-start mr-5" rounded variant="elevated"
                :append-icon="icon">{{ buttonText }}</v-btn>
        </template>
        <template v-slot:default="{ isActive }">
            <v-card :prepend-icon="icon" :text="text" :title="title">
                <template v-slot:actions>
                    <v-btn color="error" variant="elevated" @click="isActive.value = false">Nee</v-btn>
                    <v-btn color="primary" variant="elevated" @click="onConfirm">Ja</v-btn>
                </template>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
export default {
    name: 'DeleteDialog',
    props: {
        title: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        buttonText: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: 'mdi-delete',
        },
        type: {
            type: String,
            default: 'error',
        }
    },
    data() {
        return {
            isActive: false,
        };
    },
    methods: {
        onConfirm() {
            this.$emit('update:visible', false);
            this.$emit('confirm');
        },
    },
};
</script>