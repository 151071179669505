import UserService from '../services/user.service';
import { TokenService } from '../services/storage.service';
import router from '../router/router';

const auth = {
  namespaced: true,
  state() {
    return {
      authenticating: false,
      loggedIn: TokenService.getToken() !== '',
      accessToken: TokenService.getToken(),
      authenticationErrorCode: 0,
      authenticationError: '',
      navigation: [],
      user: {},
      navigationStatus: 'test',
      helperFunctions: []
    };
  },
  getters: {
    authenticationErrorCode(state) {
      return state.authenticationErrorCode;
    },
    authenticationError(state) {
      return state.authenticationError;
    },
    authenticating(state) {
      return state.authenticating;
    },
    getComponentRights(state) {
      return (componentName) => {
        let result = {
          edit: false,
          approve: false,
        };

        function checkRights(navigationComponent) {
          navigationComponent.forEach(item => {
            if (item.router.component === componentName) {
              result = item.rights;
            } else if (item.children) {
              checkRights(item.children);
            }
          });
        }
        checkRights(state.navigation);

        return result;
      };
    },
    getNavigationStatus(state) {
      return state.navigationStatus;
    },
    getHelperFunctions(state) {
      return state.helperFunctions;
    }
  },
  actions: {
    async login({ commit, state }, { email, password }) {
      commit('loginRequest');

      try {
        const token = await UserService.login(email, password);
        if (token.errorCode != null) {
          commit('loginError', token);
        } else {
          commit('loginSuccess', token);
          let redirect = setInterval(() => {
            if (state.user.twoFactorEnabled !== undefined) {
              clearInterval(redirect);
              if (state.user.twoFactorEnabled) {
                router.push(router.currentRoute.value.query.redirect || '/twofactorauth');
              }
            }
            if (state.user.isSuperUser !== undefined) {
              clearInterval(redirect);
              commit('loginTrue');
              if (state.user.isSuperUser) {
                router.push(router.currentRoute.value.query.redirect || '/');
              } else if (state.user.agreedToTerms) {
                router.push(router.currentRoute.value.query.redirect || '/dashboard');
              }
            }
          }, 100);
        }
        return true;
      } catch (e) {
        return false;
      }
    },
    logout({ commit }) {
      if (UserService.logout()) {
        commit('logoutSuccess');
      }
    },
    async twoFactorAuth({ commit, state }, { code }) {
      commit('loginRequest');

      try {
        const token = await UserService.twoFactorAuth(code);
        if (token.errorCode != null) {
          commit('loginError', token);
        } else {
          commit('loginSuccess', token);
          let redirect = setInterval(() => {
            if (state.user.isSuperUser !== undefined) {
              clearInterval(redirect);
              commit('loginTrue');
              if (state.user.isSuperUser) {
                router.push(router.currentRoute.value.query.redirect || '/');
              } else if (state.user.agreedToTerms) {
                router.push(router.currentRoute.value.query.redirect || '/dashboard');
              }
            }
          }, 100);
        }
        return true;
      } catch (e) {
        return false;
      }
    },
    switchRole({ commit }, { userId, userRoleId, organisationId, organisationTypeId }) {
      commit('switchRole');
      UserService.switchRole(userId, userRoleId, organisationId, organisationTypeId);

      commit('storage/clearStoredSalesMonitor', null, { root: true });

      if (router.currentRoute.value.path !== '/') {
        router.push('/');
      }
    },
    fetchNavigation({ commit }, navigation) {
      commit('setNavigation', navigation);
    },
    fetchNavigationStatus({ commit }) {
      const currentLocation = window.location.host;
      let status = null;

      if (currentLocation.includes('localhost')) {
        status = 4;
      } else if (
        currentLocation.includes('dip-dashboard-tst.tc8l.dev') ||
        currentLocation.includes('dip-test.innovatievooronderwijs.nl') ||
        currentLocation.includes('tst-dashboard.dip.nl') ||
        currentLocation.includes('api-mr-') ||
        currentLocation.includes('ui-mr-')
      ) {
        status = 3;
      } else if (currentLocation.includes('acc-dashboard.dip.nl')) {
        status = 2;
      } else if (currentLocation.includes('dashboard.dip.nl') || currentLocation.includes('snd-dashboard.dip.nl')) {
        status = 1;
      }

      commit('setNavigationStatus', status);
    },
    fetchHelperFunctions({ commit }, functions) {
      commit('setHelperFunctions', functions);
    },
    fetchUser({ commit }, user) {
      window.userGuiding.identify(user.userId, {
        organisationTypeId: user.organisationTypeId,
        role: user.roleName,
      });

      commit('setUser', user);
    },
    fetchAuthenticating({ commit }) {
      commit('loginTrue');
    },
  },
  mutations: {
    loginRequest(state) {
      state.authenticating = true;
      state.authenticationError = '';
      state.authenticationErrorCode = 0;
    },
    loginSuccess(state, accessToken) {
      state.accessToken = accessToken;
    },
    loginTrue(state) {
      state.loggedIn = true;
      state.authenticating = false;
    },
    loginError(state, { errorCode, errorMessage }) {
      state.authenticating = false;
      state.authenticationErrorCode = errorCode;
      state.authenticationError = errorMessage;
    },
    logoutSuccess(state) {
      state.loggedIn = false;
      state.authenticating = false;
      state.accessToken = '';
      state.navigation = [];
      state.user = {};
      state.accessToken = [];
      router.push('/login');
      setTimeout(() => {
        TokenService.changeCanSetCookie(true);
      }, 1000);
    },
    switchRole(state) {
      state.authenticating = true;
      state.navigation = [];
      state.user = {};
    },
    setNavigation(state, navigation) {
      state.navigation = navigation;
    },
    setNavigationStatus(state, status) {
      state.navigationStatus = status;
    },
    setHelperFunctions(state, functions) {
      state.helperFunctions = functions;
    },
    setUser(state, user) {
      state.user = user;
    },
  },
};

export default auth;