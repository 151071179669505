<template>
  <v-menu
    offset-y
    :close-on-click="false"
    :close-on-content-click="false"
    v-model="filterOpen"
    :left="opensLeft ? true : false"
  >
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn
        class="font-weight-bold text-lowercase ma-1"
        color="primary"
        height="40"
        variant="outlined"
        depressed
        v-bind="activatorProps"
        >{{ selectedOptionText }}
        <v-icon
          small
          right
          >mdi-calendar-blank</v-icon
        ></v-btn
      >
    </template>
    <v-card>
      <v-card-text>
        <v-form
          @submit.prevent
          v-model="formValid"
          class="d-flex flex-column"
          ref="form"
        >
          <v-row>
            <v-col
              cols="6"
              class="pb-0"
            >
              <date-picker
                :passed-date="filterDateFromView"
                @formatedDate = "this.filterDateFromView = $event"
                rules="dateRequired"
                label="datum vanaf"
              ></date-picker>
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <date-picker
                :passed-date="filterDateToView"
                @formatedDate = "this.filterDateToView = $event"
                rules="dateRequired"
                label="datum tot"
              ></date-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <v-btn
                rounded
                large
                elevation="0"
                @click="filterSubmit"
                color="secondary"
                class="text-lowercase align-self-start"
                >Filter</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex';
import functions from '../services/functions.service';
import DatePicker from '../components/DatePicker.vue';

export default {
  props: ['defaultFrom', 'defaultTo', 'opensLeft'],
  components: {
    DatePicker,
  },
  data() {
    return {
      filterOpen: false,
      formValid: false,
      dateNow: new Date(),
      selectedOptionText: '',
      filterDateFrom: null,
      filterDateTo: null,
      filterDateFromView: null,
      filterDateToView: null,
      formValidationRules: {},
    };
  },
  created() {
    this.formValidationRules = functions.getFormValidationRules();
    this.init();
  },
  methods: {
    init: function () {
      this.filterDateFromView = this.defaultFrom;
      this.filterDateToView = this.defaultTo;
      this.filterDateFrom = functions.getDateDatabase(this.filterDateFromView);
      this.filterDateTo = functions.getDateDatabase(this.filterDateToView);
      this.selectedOptionText = this.filterDateFromView + ' / ' + this.filterDateToView;
      this.$emit('changePeriod', {
        from: this.filterDateFrom,
        to: this.filterDateTo,
      });
    },
    filterSubmit: function () {
      if (this.formValid) {
        this.filterDateFrom = functions.getDateDatabase(this.filterDateFromView);
        this.filterDateTo = functions.getDateDatabase(this.filterDateToView);
        this.selectedOptionText = this.filterDateFromView + ' / ' + this.filterDateToView;
        this.$emit('changePeriod', {
          from: this.filterDateFrom,
          to: this.filterDateTo,
        });
        this.filterOpen = false;
      } else {
        this.$refs.form.validate();
      }
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
};
</script>

<style>
/* scoped */
.v-select.v-select--is-menu-active.icon-not-rotate .v-input__icon--append .v-icon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
</style>