<template>
  <v-col
    :cols="colsMobile"
    :sm="colsTablet"
    :md="colsDesktop"
    class="d-flex fill-height"
  >
    <v-card
      class="px-4 py-0 mb-1"
      flat
      width="100%"
    >
      <v-container>
        <v-row class="block-head">
          <v-col
            cols="12"
            class="py-2 d-flex align-center justify-space-between"
          >
            <p class="text-primary font-weight-medium mb-0">kaarten per klant</p>
            <help
              v-if="helpId != ''"
              bg-color=""
              :help-id="helpId"
            ></help>
          </v-col>
        </v-row>

        <v-row
          flex
          align="center"
          justify="center"
          class="py-2 flex-column flex-grow-1"
        >
          <template v-if="chart.loaded">
            <v-col
              cols="12"
              class="d-flex flex-column"
            >
              <bar
                v-if="chart.loaded"
                :chart-data="chart.data"
                :chart-options="chart.options"
              ></bar>

              <div class="widget-average d-flex flex-column align-center justify-center my-4">
                <span>eigen theater: gemiddeld {{ `${Math.round(widgetData.average)}` }} kaarten</span>
                <span v-if="widgetData.averageComparison != null">
                  {{ `theaters selectie: gemiddeld ${Math.round(widgetData.averageComparison)} kaarten` }}
                </span>
              </div>
            </v-col>
          </template>

          <template v-else>
            <p class="text-primary font-weight-medium mb-0">geen data beschikbaar</p>
          </template>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import Help from '../../Help.vue';
import { Bar } from 'vue-chartjs';

export default {
  props: ['helpId', 'colsMobile', 'cols-Tablet', 'colsDesktop', 'widgetData'],
  components: {
    Help,
    Bar,
  },
  data() {
    return {
      chart: {
        loaded: false,
        data: {},
        options: {},
      },
    };
  },
  methods: {
    getData() {
      this.chart.loaded = false;

      const data = {
        ticketsPerCustomer: this.widgetData,
      };

      if (!data.ticketsPerCustomer.values.length > 0) {
        return;
      }

      const getTickets = (data) => data.map((e) => e.tickets);

      let { values, comparison } = data.ticketsPerCustomer;
      let first = getTickets(values);
      let second = getTickets(comparison);

      let combined = [...first, ...second];

      const removeDuplicates = (data) => [...new Set(data)];

      let combinedAndConcatted = removeDuplicates(combined);

      combinedAndConcatted = combinedAndConcatted.sort(function (a, b) {
        return a - b;
      });

      var labels = combinedAndConcatted;

      var dataTickets = data.ticketsPerCustomer.values.map(function (e) {
        return e.rate;
      });

      var dataPurchases = data.ticketsPerCustomer.values.map(function (e) {
        return e.frequency;
      });

      if (data.ticketsPerCustomer.comparison != null) {
        var dataTicketsCompared = data.ticketsPerCustomer.comparison.map(function (e) {
          return e.rate;
        });

        var dataPurchasesCompared = data.ticketsPerCustomer.comparison.map(function (e) {
          return e.frequency;
        });
      }

      const backgroundColorBundle = [
        '#fff453',
        '#0abfb8',
        '#021e73',
        '#D92853',
        '#782562',
        '#FF8F2E',
        '#D64933',
        '#BA7BA1',
        '#D62246',
        '#151E3F',
        '#ACE4AA',
        '#9CFFFA',
        '#FAA300',
        '#85BAA1',
        '#9F9F92',
        '#C9D5B5',
        '#B1740F',
        '#1789FC',
        '#2A6041',
        '#5B6057',
      ];
      var colorBundle = [
        '#021e73',
        '#fff',
        '#fff',
        '#fff',
        '#fff',
        '#fff',
        '#fff',
        '#fff',
        '#fff',
        '#fff',
        '#fff',
        '#021e73',
        '#021e73',
        '#fff',
        '#fff',
        '#fff',
        '#fff',
      ];

      // Set chart options
      this.chart.options = {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            textAlign: 'left',
            title: {
              display: true,
              color: '#021e72',
              font: {
                size: 14,
              },
              padding: 10,
            },
          },
          datalabels: {
            formatter: function (value) {
              if (value > 5) {
                return Math.ceil(value);
              } else {
                return '';
              }
            },
          },
        },
        scales: {
          y: {
            stacked: true,
            title: {
              display: true,
              text: 'frequentie',
            },
          },
          x: {
            stacked: true,
            max: 100,
            title: {
              display: true,
            },
          },
        },
        textOnTopOfBar: false,
      };

      var datasets = labels.map(function (label, index) {
        
        return {
          label: label === 999 ? '>8' : label,
          backgroundColor: backgroundColorBundle[index],
          data: [dataTickets[index], dataTicketsCompared != null ? dataTicketsCompared[index] : null],
          dataPurchases: [dataPurchases[index], dataPurchasesCompared != null ? dataPurchasesCompared[index] : null],
          stack: 'Stack 1',
          datalabels: {
            color: colorBundle[index],
          },
          barThickness: 60,
          tooltip: {
            enabled: true,
            intersect: true,
            mode: 'index',
            position: 'nearest',
            callbacks: {
              label: function (tooltipItem) {
                var dataPercentage = parseFloat(tooltipItem.dataset.data[tooltipItem.dataIndex]).toFixed(2);
                var label = `${tooltipItem.dataset.label}: ${dataPercentage}%`;
                return label;
              },
            },
          },
        };
      });

      // Set chart data
      this.chart.data = {
        labels: ['eigen theater', 'vergelijking'],
        datasets: datasets,
      };

      // Load chart
      this.chart.loaded = true;
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    widgetData() {
      this.getData();
    },
  },
};
</script>