<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="text-primary">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="text-primary"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="41"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <v-row
                class="d-flex flex-column align-center col col-12"
                no-gutters
              >
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <!-- Form -->
                  <v-form
                    @submit.prevent
                    class="d-flex flex-column"
                    ref="form"
                  >
                    <h1 class="text-primary mb-5">{{ this.userRightsName }}</h1>
                    <h4 class="text-primary mb-2 font-weight-regular">gegevens rechten</h4>

                    <v-card
                      class="pa-6 mb-10"
                      flat
                    >
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <!-- Name -->
                          <v-text-field
                            class="text-primary"
                            label="naam"
                            v-model="userRights.name"
                            :rules="formValidationRules.required"
                            required
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <p class="text-primary mb-0">
                            <small>type organisatie:</small> <br />
                            <strong>{{ userRights.organisationTypeName }}</strong>
                          </p>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-form>
                </v-col>
              </v-row>

              <!-- Rechten overview -->
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                >
                  <v-row class="pa-0 ma-0 mb-2 d-flex flex-row align-center justify-space-between">
                    <h4 class="text-primary font-weight-regular">rechten</h4>
                  </v-row>

                  <!-- Table -->
                  <v-data-table
                    :headers="tableRightsHeaders"
                    :items="userRights.sitemap"
                    :mobile-breakpoint="0"
                    :loading="tableRightsLoading"
                    :items-per-page="-1"
                    hide-default-footer
                  >
                    <template v-slot:[`item.rights.view`]="{ item }">
                      <v-checkbox
                        v-model="item.rights.view"
                        v-if="item.rights.view != null"
                        :input-value="item.rights.view"
                        v-on:change="changeRight(item, item.rights.view, 'view')"
                      ></v-checkbox>
                      <div v-else>-</div>
                    </template>
                    <template v-slot:[`item.rights.edit`]="{ item }">
                      <v-checkbox
                        v-model="item.rights.edit"
                        v-if="item.rights.edit != null"
                        :input-value="item.rights.edit"
                        v-on:change="changeRight(item, item.rights.edit, 'edit')"
                      ></v-checkbox>
                      <div v-else>-</div>
                    </template>
                    <template v-slot:[`item.rights.approve`]="{ item }">
                      <v-checkbox
                        v-model="item.rights.approve"
                        v-if="item.rights.approve != null"
                        :input-value="item.rights.approve"
                        v-on:change="changeRight(item, item.rights.approve, 'approve')"
                      ></v-checkbox>
                      <div v-else>-</div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>

              <v-row class="d-flex flex-column flex-start col col-12">
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                >
                  <v-btn
                    rounded
                    large
                    elevation="0"
                    @click="submit"
                    :loading="formLoading"
                    color="secondary"
                    class="text-lowercase mb-5 align-self-start"
                    >Opslaan
                    <v-icon
                      small
                      right
                      >mdi-content-save</v-icon
                    ></v-btn
                  >
                </v-col>
              </v-row>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';

import Help from '../../../components/Help.vue';

export default {
  name: 'AdminRightsEdit',
  props: ['userRoleId'],
  components: {
    Help,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'rechten',
          disabled: false,
          href: '/admin/rechten',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      isEditing: false,
      formError: '',
      formLoading: false,
      formValidationRules: {},
      userRights: {},
      userRightsName: '',
      tableRightsLoading: true,

      tableRightsHeaders: [
        { title: 'module', key: 'name', sortable: false },
        { title: 'inzage', key: 'rights.view' },
        { title: 'beheer', key: 'rights.edit' },
        { title: 'accorderen', key: 'rights.approve' },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    const usersCanEdit = [
      1043, // Marlène Colbers (DIP)
      877, // Roel Cuppen (Cre8ion)
      878, // Sven Vermeulen (Cre8ion)
      879, // Sander van de Rijt (Cre8ion)
      880, // Joris van Loon (Cre8ion)
      1308, // Bas van Hout (Cre8ion)
    ];

    if (!usersCanEdit.includes(this.user.userId)) {
      router.back();
    }
    this.formValidationRules = functions.getFormValidationRules();
    this.getUserRights();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getUserRights: function () {
      const vm = this;

      ApiService.get('/Rights/Get', {
        userRoleId: this.userRoleId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          this.userRights = response.data.result;

          this.breadcrumbs[2].text = this.userRights.name;
          this.breadcrumbs[2].href = '/admin/rechten/' + this.userRights.id + '/edit';
          this.userRightsName = this.userRights.name;
          this.tableRightsLoading = false;

          setTimeout(function () {
            vm.isEditing = false;
          }, 200);
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    changeRight(module, value, type) {
      if (type == 'view') {
        module.rights.edit = module.rights.edit == null ? null : false;
        module.rights.approve = module.rights.approve == null ? null : false;
      } else if (type == 'edit') {
        if (value) {
          module.rights.view = module.rights.view == null ? null : true;
          module.rights.approve = module.rights.approve == null ? null : false;
        } else {
          module.rights.approve = module.rights.approve == null ? null : false;
        }
      } else if (type == 'approve' && value) {
        module.rights.view = module.rights.view == null ? null : true;
        module.rights.edit = module.rights.edit == null ? null : true;
      }
    },
    async submit() {
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        ApiService.put('/Rights/Update', this.userRights)
          .then((response) => {
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formLoading = false;
            }
          })
          .catch((error) => {
            this.formError = error;
            // eslint-disable-next-line
            console.log('error ' + error);
            this.errored = true;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    userRights: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}
</style>
