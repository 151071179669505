<template>
  <div>
    <!-- Agreement default -->
    <v-card
      class="py-6 px-3 pb-0 mb-4"
      flat
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <p class="text-primary font-weight-medium mb-0">kenmerk eigen systeem</p>
            <h3 class="text-primary border-bottom pb-4 mb-4">{{ contract.code != '' ? contract.code : '-' }}</h3>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <p class="text-primary font-weight-medium mb-0">type afspraak</p>
            <h3 class="text-primary border-bottom pb-4 mb-4">{{ contract.type }}</h3>
          </v-col>
        </v-row>

          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <p class="text-primary font-weight-medium mb-0">aanbieder</p>
              <h3 v-if="contract.agencyAsProvider" class="text-primary border-bottom pb-4 mb-4">{{ contract.agency.name }} namens {{ contract.producer.name }}</h3>
              <h3 v-else class="text-primary border-bottom pb-4 mb-4">{{ contract.producer.name }}</h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <p class="text-primary font-weight-medium mb-0">productie</p>
              <h3 class="text-primary border-bottom pb-4 mb-4">{{ contract.production.title }}</h3>
            </v-col>
          </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <p class="text-primary font-weight-medium mb-0">afnemer</p>
            <h3 class="text-primary border-bottom pb-4 mb-4">{{ contract.theater.name }}</h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="py-0 mb-8"
          >
            <p
              :class="contract.isStartTimeRejected ? 'error--text ' : 'text-primary '"
              class="font-weight-medium mb-0"
            >
              uitvoeringen
            </p>
            <h3
              :class="contract.isStartTimeRejected ? 'error--text ' : 'text-primary '"
              v-for="performance in contract.performances"
              :key="performance.id"
            >
              {{ performance.number }} / {{ getDateTimeView(performance.startDate) }} ({{ performance.area }}{{ performanceType(performance) }})
            </h3>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import functions from '../../../../services/functions.service';

export default {
  props: ['contract'],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    getDateTimeView(value) {
      return functions.getDateTimeView(value);
    },
    performanceType(performance) {
      if (performance.type === 'normal') return ''; 
      else return `, ${performance.type}`;
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {},
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid #e1e7f2;
}
</style>