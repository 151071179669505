<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row align="center" class="px-3 mb-4">
          <v-col class="py-3 add-row d-flex justify-sm-space-between align-center">
            <span class="d-none d-sm-flex">Een nieuwe externe partij aanmaken?</span>
            <v-btn color="primary" rounded depressed class="font-weight-bold text-lowercase"
              :to="{ name: 'AdminThirdPartiesAdd' }">
              Aanmaken nieuwe externe partij
            </v-btn>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row flex justify="center" align="center" class="pb-2" no-gutters>
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="1133"></breadcrumbs-with-help>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <v-text-field v-model="tableThirdPartiesFilter.name" label="op naam zoeken" hide-details
                variant="outlined" density="compact" bg-color="#fff" class="ma-1 input-sm"></v-text-field>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog v-model="tableThirdPartiesFilterDialog" width="500" fullscreen>
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn class="font-weight-bold text-lowercase" color="success" rounded dark
                    v-bind="activatorProps">filter overzicht
                    <v-icon small right>mdi-filter</v-icon></v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline dark">
                    <h5 class="text-primary">filter overzicht</h5>
                  </v-card-title>

                  <v-card-text>
                    <v-text-field v-model="tableThirdPartiesFilter.name" label="op naam zoeken" hide-details dense
                      outlined background-color="#fff" class="mb-3"></v-text-field>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn class="font-weight-bold text-lowercase mb-5" color="success" depressed
                      @click="tableThirdPartiesFilterDialog = false">filter overzicht
                      <v-icon small right>mdi-filter</v-icon></v-btn>
                    <v-btn class="font-weight-bold text-lowercase" color="error" outlined dark
                      @click="clearfilterThirdParties()">reset
                      filter
                      <v-icon small right>mdi-close</v-icon></v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table :headers="tableThirdPartiesHeaders" :items="tableThirdPartiesFiltered" :mobile-breakpoint="0"
          :loading="tableThirdPartiesLoading" :page="tableThirdPartiesPaging.page"
          :items-per-page="tableThirdPartiesPaging.itemsPerPage" hide-default-footer
          @update:current-items="tableThirdPartiesPaging.pageCount = Math.ceil(tableThirdPartiesFiltered.length / tableThirdPartiesPaging.itemsPerPage)"
          @update:items-per-page="tableThirdPartiesPaging.pageCount = Math.ceil(tableThirdPartiesFiltered.length / tableThirdPartiesPaging.itemsPerPage)"
          @click:row="(_, x) => goToDetail(x.item)" class="table-action row-is-clickable" fixed-header>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn rounded="circle" elevation="0" size="small" icon="mdi-eye" color="warning" class="ml-1"
              @click.stop="goToDetail(item)">
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
            <v-btn rounded="circle" elevation="0" size="small" icon="mdi-square-edit-outline" color="info" class="ml-1"
              @click.stop="goToEdit(item)">
              <v-icon color="primary">mdi-square-edit-outline</v-icon>
            </v-btn>
            <delete-dialog :title="deleteDialogTitle(item)"
                    :text="deleteDialogText(item)"
                    @confirm="(deleteItem = item), deleteThirdParty();" />
          </template>
        </v-data-table>

        <!-- Table settings -->
        <table-settings :items="tableThirdPartiesFiltered" :paging="tableThirdPartiesPaging"
          :export-columns="downloadExportColumns" :file-name="'dip-externe-partijen-export'"
          :sheet-name="'Externe partijen'" :show-excel-button="true"></table-settings>

      </v-col>
    </v-row>

    <!-- Snackbar -->
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ this.snackbarText }}
      <template v-slot:actions>
        <v-btn color="#fff" variant="text" @click="(snackbar = false), (snackbarText = '')">
          Sluiten
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';

import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import TableSettings from '@/components/TableSettings.vue';

export default {
  name: 'AdminThirdParties',
  components: {
    BreadcrumbsWithHelp,
    TableSettings,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'externe partijen',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      thirdParties: [],

      tableThirdPartiesFiltered: [],
      tableThirdPartiesLoading: true,
      tableThirdPartiesHeaders: [
        { title: 'naam', key: 'name' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
      tableThirdPartiesFilter: {
        name: '',
      },
      tableThirdPartiesPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      tableThirdPartiesFilterDialog: false,
      downloadExportColumns: [
        { label: 'id', field: 'id' },
        { label: 'naam', field: 'name' },
      ],
      deleteItem: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'secondary',
    };
  },
  mounted() {
    // Filter
    this.checkFilter({ viewName: this.$options.name, filterObject: this.tableThirdPartiesFilter });
    this.tableThirdPartiesFilter = this.getFilter(this.$options.name);

    this.getThirdParties();
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    goToDetail(row) {
      this.$router.push({
        name: 'AdminThirdPartiesDetail',
        params: { thirdPartyId: row.id },
      });
    },
    goToEdit(row) {
      this.$router.push({
        name: 'AdminThirdPartiesEdit',
        params: { thirdPartyId: row.id },
      });
    },
    getThirdParties: function () {
      ApiService.get('/ThirdPartyUsers/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.thirdParties = response.data.result;
          this.tableThirdPartiesFiltered = this.thirdParties;

          // Paging
          this.checkFilter({ viewName: this.$options.name + '-paging', filterObject: this.tableThirdPartiesPaging });
          this.tableThirdPartiesPaging = this.getFilter(this.$options.name + '-paging');

          this.filterThirdParties();

          this.tableThirdPartiesLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterThirdParties: function () {
      const vm = this;
      this.tableThirdPartiesFiltered = this.thirdParties.filter(function (thirdParty) {
        return cleanWords(thirdParty.name).indexOf(cleanWords(vm.tableThirdPartiesFilter.name)) !== -1;
      });
      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearfilterThirdParties: function () {
      this.tableThirdPartiesFilter.name = '';
      this.tableThirdPartiesFilterDialog = false;
    },
    deleteThirdParty: function () {
      ApiService.delete('/ThirdPartyUsers/Delete', {
        id: this.deleteItem.id,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableThirdPartiesLoading = true;
            this.getThirdParties();

            this.snackbarText = this.deleteItem.name + ' is verwijderd';
            this.deleteItem = '';
            this.snackbarColor = 'secondary';
            this.snackbar = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    deleteDialogTitle(value) {
      return `${value.name} verwijderen?`;
    },
    deleteDialogText(value) {
      return `Weet je zeker dat je ${value.name} wil verwijderen? Het is daarna niet meer terug te vinden.`;
    },
  },
  computed: {
    ...mapGetters('storage', ['getFilter']),
  },
  beforeUnmount() {
    // Filter
    this.updateFilter({ viewName: this.$options.name, filterObject: this.tableThirdPartiesFilter });
    // Paging
    this.updateFilter({ viewName: this.$options.name + '-paging', filterObject: this.tableThirdPartiesPaging });
  },
  watch: {
    tableThirdPartiesFilter: {
      handler: 'filterThirdParties',
      deep: true,
    },
  },
};
</script>
