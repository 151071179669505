<template>
  <v-col
    cols="12"
    sm="12"
    md="12"
  >
    <v-card
      class="py-5 px-3 pb-0 mb-18"
      flat
      height="100%"
    >
      <v-row>
        <v-col
          cols="12"
          md="2"
          class="py-2"
          v-if="this.production.productionNumber != null"
        >
          <p class="text-primary font-weight-medium mb-0">productienummer</p>
          <h3 class="text-primary border-bottom pb-0 mb-4">
            {{ this.production.productionNumber }}
          </h3>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="py-2"
        >
          <p class="text-primary font-weight-medium mb-0">titel</p>
          <h3 class="text-primary border-bottom pb-0 mb-4">
            {{ this.production.title }}
          </h3>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="py-2"
        >
          <p class="text-primary font-weight-medium mb-0">genre</p>
          <h3 class="text-primary border-bottom pb-0 mb-4">
            {{ this.production.genre }}
          </h3>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="py-2"
        >
          <p
            class="text-primary font-weight-medium mb-0"
            v-if="!this.production.playDay"
          >
            eerste speeldatum
          </p>
          <p
            class="text-primary font-weight-medium mb-0"
            v-else
          >
            speeldatum
          </p>
          <h3 class="text-primary border-bottom pb-0 mb-4">
            {{ getDateTimeView(this.production.firstStartDate) }}
          </h3>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="py-2"
        >
          <div v-if="!this.production.playDay">
            <p class="text-primary font-weight-medium mb-0">laatste speeldatum</p>
            <h3 class="text-primary border-bottom pb-0 mb-4">
              {{ getDateTimeView(this.production.lastStartDate) }}
            </h3>
          </div>
          <div v-else>
            <p class="text-primary font-weight-medium mb-0">speeldag</p>
            <h3 class="text-primary border-bottom pb-0 mb-4">
              {{ this.production.playDay }}
            </h3>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="py-2"
        >
          <p class="text-primary font-weight-medium mb-0">zaal</p>
          <h3 class="text-primary border-bottom pb-0 mb-4">
            {{ this.production.area }}
          </h3>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>
<script>
import functions from '../../../services/functions.service';

export default {
  props: {
    production: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    getDateTimeView(date) {
      return functions.getDateTimeView(date);
    },
  },
};
</script>
