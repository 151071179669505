<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row
          flex
          align="center"
          justify="space-between"
          class="px-3 mb-4"
        >
          <v-col class="py-3 add-row d-flex justify-sm-space-between align-center">
            <span class="d-none d-sm-flex">Meer cijfers?</span>
            <div>
              <v-btn
                v-if="this.user.organisationTypeId != 2"
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsSummary' }"
              >
                samenvatting
                <v-icon
                  small
                  right
                  >mdi-chart-bar-stacked</v-icon
                >
              </v-btn>
              <v-btn
                v-else
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsProductions' }"
              >
                producties
                <v-icon
                  small
                  right
                  >mdi-chart-bar-stacked</v-icon
                >
              </v-btn>
              <v-btn
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsPerformances' }"
              >
                voorstellingen
                <v-icon
                  small
                  right
                  >mdi-chart-bar-stacked</v-icon
                >
              </v-btn>
              <v-btn
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsProductions7days' }"
              >
                producties laatste 7 dagen
                <v-icon
                  small
                  right
                  >mdi-chart-bar-stacked</v-icon
                >
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="1129"></breadcrumbs-with-help>
        </v-row>

        <div v-show="!tableProductionsLoading">
          <!-- Table -->
          <v-data-table
            :headers="tableProductionsHeaders"
            :items="tableProductions"
            :mobile-breakpoint="0"
            :loading="tableProductionsLoading"
            :page="tableProductionsPaging.page"
            :items-per-page="tableProductionsPaging.itemsPerPage"
            @update:current-items="tableProductionsPaging.pageCount = Math.ceil(tableProductions.length / tableProductionsPaging.itemsPerPage)"
            @update:items-per-page="tableProductionsPaging.pageCount = Math.ceil(tableProductions.length / tableProductionsPaging.itemsPerPage)"
            class="table-expand"
            item-value="productionNumber"
            hide-default-footer
            single-expand
            show-expand
            fixed-header
            :height="tableProductions.length > 10 ? '55vh' : ''"
          >
            <template v-slot:[`item.firstPerformanceDate`]="{ item }">
              <span>{{ item.firstPerformanceDate_formatted }}</span>
            </template>

            <template v-slot:[`item.capacity`]="{ item }">
              <span>{{ formatThousandsWithDot(item.capacity) }}</span>
            </template>

            <template v-slot:expanded-row="{ columns, item }">
              <td
                :colspan="columns.length"
                class="pa-0"
              >
                <v-data-table
                  :headers="tableProductionsDetailsHeaders"
                  :items="item.details"
                  :mobile-breakpoint="0"
                  hide-default-footer
                  :items-per-page="-1"
                  class="table-action"
                >
                  <template v-slot:[`item.type`]="{ item }">
                    <strong>{{ item.type }}</strong>
                  </template>
                </v-data-table>
              </td>
            </template>


          </v-data-table>

          <!-- Table settings -->
          <table-settings :items="tableProductions" :paging="tableProductionsPaging"
            :export-columns="downloadExportColumns" :file-name="'dip-verkoopmutaties-producties-laatste-10-weken'"
            :sheet-name="'producties laatse 10 weken'" :show-excel-button="true">
          </table-settings>
        </div>
        <div v-show="tableProductionsLoading">
          <div class="text-center progress-circular">
            <v-progress-circular
              :size="100"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <h4 class="text-primary mt-5">Een moment geduld aub, je gegevens worden berekend. Dit kan even duren.</h4>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import TableSettings from '../../components/TableSettings';
import functions from '../../services/functions.service';

export default {
  name: 'SalesMutationsProductions10weeks',
  components: {
    BreadcrumbsWithHelp,
    TableSettings
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'verkoopmutaties',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producties laatste 10 weken',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      timeStamp: '',
      tableProductions: [],
      tableProductionsLoading: true,
      tableProductionsHeaders: [
        { title: 'productienummer', key: 'productionNumber' },
        { title: 'eerste speeldatum', key: 'firstPerformanceDate' },
        { title: 'productie', key: 'productionTitle' },
        { title: 'genre', key: 'genreName' },
        { title: 'capaciteit', key: 'capacity' },
        { title: '', key: 'data-table-expand', align: 'end' },
      ],
      tableProductionsPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
      },
      tableProductionsDetailsHeaders: [
        { title: '', key: 'type' },
        { title: 'voorverkoop', key: 'preSale' },
        { title: 'week', key: 'week1' },
        { title: 'week', key: 'week2' },
        { title: 'week', key: 'week3' },
        { title: 'week', key: 'week4' },
        { title: 'week', key: 'week5' },
        { title: 'week', key: 'week6' },
        { title: 'week', key: 'week7' },
        { title: 'week', key: 'week8' },
        { title: 'week', key: 'week9' },
        { title: 'week', key: 'week10' },
      ],
      exportProductions: [],
      exportProductionsColumns: [],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('SalesMutations');
    this.getProductions();
  },
  methods: {
    getEuroSign: function (value) {
      return functions.getEuroSignView(value);
    },
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getPercentageView: function (value) {
      return functions.getPercentageView(value);
    },
    calculateArraySum: function (items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    },
    getProductions: function () {
      const vmLegacy = { $set: (o, k, v) => o[k] = v };
      this.tableProductions = [];

      ApiService.get('/SalesMutations/GetProductionsForLastTenWeeks')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          this.tableProductions = response.data.result;

          for (var i = 0; i < this.tableProductions.length; i++) {
            vmLegacy.$set(
              this.tableProductions[i],
              'firstPerformanceDate_formatted',
              functions.getDateView(this.tableProductions[i].firstPerformanceDate)
            );
            vmLegacy.$set(this.tableProductions[i], 'key', i);

            var productionDetails = [
              {
                type: 'aantal',
                preSale: this.formatThousandsWithDot(this.tableProductions[i].presale.ticketCount),
              },
              {
                type: 'recette',
                preSale: functions.getEuroSignView(this.tableProductions[i].presale.revenueInVat),
              },
              {
                type: 'verschil',
              },
            ];
            for (var j = 0; j < this.tableProductions[i].weeks.length; j++) {
              productionDetails[0]['week' + (j + 1)] = this.formatThousandsWithDot(
                this.tableProductions[i].weeks[j].ticketCount
              );
              productionDetails[1]['week' + (j + 1)] = functions.getEuroSignView(
                this.tableProductions[i].weeks[j].revenueInVat
              );
              productionDetails[2]['week' + (j + 1)] = this.formatThousandsWithDot(
                this.tableProductions[i].weeks[j].mutation
              );
              this.tableProductionsDetailsHeaders[j + 2].text = 'week' + this.tableProductions[i].weeks[j].number;
            }
            this.tableProductions[i].details = productionDetails;
          }
          this.tableProductionsLoading = false;
          this.createExportData();
        })
        .catch((error) => {
          //eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    createExportData: function () {
      if (this.tableProductions.length > 0) {
        this.exportProductionsColumns = [];
        this.exportProductions = [];

        // create export columns
        for (var i = 1; i < 45; i++) {
          this.exportProductionsColumns.push({ label: '', field: 'column' + i });
        }

        // create export data
        let date = new Date();
        this.exportProductions.push({
          column1: this.user.organisationName,
          column3:
            'Gegenereerd op: ' +
            date.getDate() +
            '-' +
            (date.getMonth() + 1) +
            '-' +
            date.getFullYear() +
            ' ' +
            date.getHours() +
            ':' +
            (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()),
        });
        this.exportProductions.push({
          column6: 'voorverkoop t/m ' + functions.getDateView(this.tableProductions[0].presale.date),
          column7: 'voorverkoop t/m ' + functions.getDateView(this.tableProductions[0].presale.date),
        });

        this.exportProductions.push({
          column1: 'productienummer',
          column2: 'datum',
          column3: 'productie',
          column4: 'genre',
          column5: '# capaciteit',

          column6: 'aantal',
          column7: 'recette',
        });

        var weekColumsTotal = 10 * 3;
        var weekCount = 0;
        for (var y = 0; y < weekColumsTotal; y++) {
          var columnNummber = y + 8;
          var columnTitle = '';

          var weekPosition = (y + 1) % 3;
          if (weekPosition == 1) {
            columnTitle = 'aantal';
          } else if (weekPosition == 2) {
            columnTitle = 'recette';
          } else if (weekPosition == 0) {
            columnTitle = 'verschil';
          }
          this.exportProductions[1]['column' + columnNummber] =
            'week ' + this.tableProductions[0].weeks[weekCount].number;
          this.exportProductions[2]['column' + columnNummber] = columnTitle;
          if (weekPosition == 0) {
            weekCount++;
          }
        }

        this.tableProductions.forEach((item) => {
          this.exportProductions.push({
            column1: item.productionNumber,
            column2: item.firstPerformanceDate_formatted,
            column3: item.productionTitle,
            column4: item.genreName,
            column5: item.capacity,

            column6: item.presale.ticketCount,
            column7:
              item.presale.revenueInVat != null && item.presale.revenueInVat != ''
                ? parseFloat(item.presale.revenueInVat.toFixed(2))
                : '',

            column8: item.weeks[0].ticketCount,
            column9:
              item.weeks[0].revenueInVat != null && item.weeks[0].revenueInVat != ''
                ? parseFloat(item.weeks[0].revenueInVat.toFixed(2))
                : '',
            column10: item.weeks[0].mutation,

            column11: item.weeks[1].ticketCount,
            column12:
              item.weeks[1].revenueInVat != null && item.weeks[1].revenueInVat != ''
                ? parseFloat(item.weeks[1].revenueInVat.toFixed(2))
                : '',
            column13: item.weeks[1].mutation,

            column14: item.weeks[2].ticketCount,
            column15:
              item.weeks[2].revenueInVat != null && item.weeks[2].revenueInVat != ''
                ? parseFloat(item.weeks[2].revenueInVat.toFixed(2))
                : '',
            column16: item.weeks[2].mutation,

            column17: item.weeks[3].ticketCount,
            column18:
              item.weeks[3].revenueInVat != null && item.weeks[3].revenueInVat != ''
                ? parseFloat(item.weeks[3].revenueInVat.toFixed(2))
                : '',
            column19: item.weeks[3].mutation,

            column20: item.weeks[4].ticketCount,
            column21:
              item.weeks[4].revenueInVat != null && item.weeks[4].revenueInVat != ''
                ? parseFloat(item.weeks[4].revenueInVat.toFixed(2))
                : '',
            column22: item.weeks[4].mutation,

            column23: item.weeks[5].ticketCount,
            column24:
              item.weeks[5].revenueInVat != null && item.weeks[5].revenueInVat != ''
                ? parseFloat(item.weeks[5].revenueInVat.toFixed(2))
                : '',
            column25: item.weeks[5].mutation,

            column26: item.weeks[6].ticketCount,
            column27:
              item.weeks[6].revenueInVat != null && item.weeks[6].revenueInVat != ''
                ? parseFloat(item.weeks[6].revenueInVat.toFixed(2))
                : '',
            column28: item.weeks[6].mutation,

            column29: item.weeks[7].ticketCount,
            column30:
              item.weeks[7].revenueInVat != null && item.weeks[6].revenueInVat != ''
                ? parseFloat(item.weeks[6].revenueInVat.toFixed(2))
                : '',
            column31: item.weeks[7].mutation,

            column32: item.weeks[8].ticketCount,
            column33:
              item.weeks[8].revenueInVat != null && item.weeks[6].revenueInVat != ''
                ? parseFloat(item.weeks[6].revenueInVat.toFixed(2))
                : '',
            column34: item.weeks[8].mutation,

            column35: item.weeks[9].ticketCount,
            column36:
              item.weeks[9].revenueInVat != null && item.weeks[6].revenueInVat != ''
                ? parseFloat(item.weeks[6].revenueInVat.toFixed(2))
                : '',
            column37: item.weeks[9].mutation,
          });
        });
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
  },
};
</script>