<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="60"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-col cols="12">
            <v-card title="gegevens Zaalcapaciteit">
              <v-divider></v-divider>
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="12" class="py-0">
                    <p class="text-primary mb-0">
                      <small>zaal:</small> <br />
                      <strong>{{ areacapacitie.areaName }}</strong>
                    </p>
                  </v-col>
                </v-row>
                <hr class="accent-hr my-2" />
                <v-row no-gutters>
                  <v-col cols="12" class="py-0">
                    <p class="text-primary mb-0">
                      <small>naam:</small> <br />
                      <strong>{{ areacapacitie.name }}</strong>
                    </p>
                  </v-col>
                </v-row>
                <hr class="accent-hr my-2" />
                <v-row no-gutters>
                  <v-col cols="12" class="py-0">
                    <p class="text-primary mb-0">
                      <small>totale capaciteit:</small> <br />
                      <strong>{{ formatThousandsWithDot(areacapacitie.total) }}</strong>
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <div>
                  <p class="grey--text text--lighten-1 mb-2">
                    <small><i>{{ this.areacapacitie.lastEditedBy }}</i></small>
                  </p>

                  <!-- Bewerken -->
                  <v-btn-edit class="mr-5" v-if="rights.edit" :to="{ name: 'LibraryAreaCapacitiesEdit', params: { theaterAreaId } }">gegevens
                    Bewerken</v-btn-edit>

                  <!-- Verwijderen -->
                  <delete-dialog v-if="rights.edit" buttonText="verwijderen" :title="deleteDialogTitle"
                  :text="deleteDialogText" @confirm="deleteItemAreaCapacities()" />
                </div>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-col>
      </v-row>

      <!-- Snackbar -->
      <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ this.snackbarText }}
        <template v-slot:actions>
          <v-btn color="#fff" variant="text" @click="(snackbar = false), (snackbarText = '')">
            Sluiten
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';

import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'LibraryAreaCapacitiesDetail',
  props: ['theaterAreaId', 'AreaCapacityId'],
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      areacapacitie: {
        areaId: '',
        name: '',
        total: '0',
        lastEditedBy: '',
      },
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'zaalcapaciteit',
          disabled: false,
          href: '/beheer/zaalcapaciteit',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      deleteItemLinkDialog: false,
      deleteItemLink: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
    };
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryAreaCapacitiesDetail');
    this.getAreaCapacitie();
  },
  methods: {
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getAreaCapacitie: function () {
      ApiService.get('/AreaCapacities/Get', {
        AreaCapacityId: this.AreaCapacityId,
      })
        .then((response) => {
          if (response.data.success) {
            const areacapacitie = response.data.result;
            this.breadcrumbs[2].text = areacapacitie.name;
            this.areacapacitie.name = areacapacitie.name;
            this.areacapacitie.id = areacapacitie.id;
            this.areacapacitie.areaName = areacapacitie.areaName;
            this.areacapacitie.areaId = areacapacitie.areaId;
            this.areacapacitie.name = areacapacitie.name;
            this.areacapacitie.total = areacapacitie.total;
            this.areacapacitie.theaterId = areacapacitie.theaterId;
            this.areacapacitie.lastEditedBy = areacapacitie.lastEditedBy;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    deleteItemAreaCapacities: function () {
      ApiService.delete('/AreaCapacities/Delete', {
        AreaCapacityId: this.areacapacitie.id,
      })
        .then((response) => {
          if (response.data.success) {
            this.snackbarText = this.areacapacitie.name + ' is verwijderd';
            this.snackbarColor = 'secondary';
            this.snackbar = true;
            setTimeout(function () {
              router.push({ path: '/beheer/zaalcapaciteit' });
            }, 1500);
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    deleteDialogTitle() {
      return `${this.areacapacitie.name} verwijderen?`;
    },
    deleteDialogText() {
      return `Weet je zeker dat je ${this.areacapacitie.name} wil verwijderen? Het is daarna niet meer terug te vinden.`;
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
