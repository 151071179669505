<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row flex justify="center" align="center" class="pb-2" no-gutters>
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="26"></breadcrumbs-with-help>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <v-text-field v-model="tableGenresFilter.name" label="op naam zoeken" hide-details variant="outlined"
                density="compact" bg-color="#fff" class="ma-1 input-sm"></v-text-field>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog v-model="tableGenresFilterDialog" width="500" fullscreen>
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn class="font-weight-bold text-lowercase" color="success" rounded dark
                    v-bind="activatorProps">filter overzicht
                    <v-icon small right>mdi-filter</v-icon></v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline dark">
                    <h5 class="text-primary">filter overzicht</h5>
                  </v-card-title>

                  <v-card-text>
                    <v-text-field v-model="tableGenresFilter.name" label="op naam zoeken" hide-details dense outlined
                      background-color="#fff" class="mb-3"></v-text-field>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn class="font-weight-bold text-lowercase mb-5" color="success" depressed
                      @click="tableGenresFilterDialog = false">filter overzicht
                      <v-icon small right>mdi-filter</v-icon></v-btn>
                    <v-btn class="font-weight-bold text-lowercase" color="error" outlined dark
                      @click="clearFilterGenres()">reset filter
                      <v-icon small right>mdi-close</v-icon></v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table :headers="tableGenresHeaders" :items="tableGenresFiltered" :mobile-breakpoint="0"
          :loading="tableGenresLoading" :page="tableGenresPaging.page" :items-per-page="tableGenresPaging.itemsPerPage"
          hide-default-footer
          @update:current-items="tableGenresPaging.pageCount = Math.ceil(tableGenresFiltered.length / tableGenresPaging.itemsPerPage)"
          @update:items-per-page="tableGenresPaging.pageCount = Math.ceil(tableGenresFiltered.length / tableGenresPaging.itemsPerPage)"
          class="table-action" fixed-header :height="tableGenresFiltered.length > 10 ? '60vh' : ''">
        </v-data-table>

        <!-- Table settings -->
        <table-settings :items="tableGenresFiltered" :paging="tableGenresPaging" :export-columns="downloadExportColumns"
          :file-name="'dip-genres-export'" :sheet-name="'Genres'" :show-excel-button="true"></table-settings>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';

import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import TableSettings from '@/components/TableSettings.vue';

export default {
  name: 'AdminGenres',
  components: {
    BreadcrumbsWithHelp,
    TableSettings,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'genres',
          disabled: false,
          href: '/admin/genres',
          bold: true,
        },
      ],
      genres: [],

      tableGenresFiltered: [],
      tableGenresLoading: true,
      tableGenresHeaders: [
        { title: 'id', key: 'id' },
        { title: 'naam', key: 'name' },
      ],
      tableGenresFilter: {
        name: '',
      },
      tableGenresPaging: {
        page: 1,
        pageCount: 25,
        itemsPerPage: 25,
      },
      tableGenresFilterDialog: false,
      downloadExportColumns: [
        { label: 'ID', field: 'id' },
        { label: 'naam', field: 'name' },
      ],
      deleteItemDialog: false,
      deleteItem: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'secondary',
    };
  },
  mounted() {
    // Filter
    this.checkFilter({ viewName: this.$options.name, filterObject: this.tableGenresFilter });
    this.tableGenresFilter = this.getFilter(this.$options.name);

    this.getGenres();
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    getGenres: function () {
      ApiService.get('/Genres/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.genres = response.data.result;
          this.tableGenresFiltered = this.genres;

          // Paging
          this.checkFilter({ viewName: this.$options.name + '-paging', filterObject: this.tableGenresPaging });
          this.tableGenresPaging = this.getFilter(this.$options.name + '-paging');

          this.filterGenres();

          this.tableGenresLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterGenres: function () {
      const vm = this;
      this.tableGenresFiltered = this.genres.filter(function (genre) {
        return cleanWords(genre.name).indexOf(cleanWords(vm.tableGenresFilter.name)) !== -1;
      });
      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterGenres: function () {
      this.tableGenresFilter.name = '';
      this.tableGenresFilterDialog = false;
    },
  },
  computed: {
    ...mapGetters('storage', ['getFilter']),
  },
  beforeUnmount() {
    // Filter
    this.updateFilter({ viewName: this.$options.name, filterObject: this.tableGenresFilter });
    // Paging
    this.updateFilter({ viewName: this.$options.name + '-paging', filterObject: this.tableGenresPaging });
  },
  watch: {
    tableGenresFilter: {
      handler: 'filterGenres',
      deep: true,
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}
</style>
