<template>
  <div>
    <h2 class="text-primary mb-2">met wie maak je deze financiële afspraak?</h2>

    <!-- Form -->
    <v-form
      @submit.prevent
      v-model="formValid"
      class="d-flex flex-column"
      ref="form"
    >
      <v-card
        class="py-6 pb-0 px-3 mb-4"
        flat
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <v-text-field
                class="text-primary"
                label="afnemer"
                v-model="form.theater.name"
                outlined
                dense
                disabled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <v-autocomplete
                label="contactpersoon afnemer"
                v-model="theaterContactId"
                :items="theaterContactOptions"
                :rules="formValidationRules.required"
                required
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <v-text-field
                class="text-primary"
                label="e-mailadres afnemer"
                v-model="form.theaterContact.email"
                :rules="formValidationRules.emailRequired"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card class="py-6 pb-0 px-3 mb-4" flat v-if="this.user.organisationTypeId == 1">
        <v-container>
            <v-col cols="12" class="py-0">
                <v-radio-group v-model="form.agencyAsProvider" label="Wie is de contractpartij?" row class="input-radio-row-label my-0" required>
                  <v-radio label="producent" :value="false"></v-radio>
                  <v-radio label="impresariaat" :value="true"></v-radio>
                </v-radio-group>
            </v-col>
        </v-container>
      </v-card>
      
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ApiService from '../../../../services/api.service';
import { TokenService } from '../../../../services/storage.service';
import functions from '../../../../services/functions.service';

export default {
  props: ['form'],
  data() {
    return {
      formValid: false,
      formError: '',
      formValidationRules: {},
      theaters: [],
      theaterOptions: [],
      theaterContacts: [],
      theaterContactOptions: [],
    };
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.getData();
  },
  methods: {
    validateForm() {
      this.$refs.form.validate();
    },
    getData() {
      this.getTheaterContacts();

      if (this.form.theaterContact == null) {
        this.form.theaterContact = {
          id: 0,
          fullName: '',
          email: this.form.theaterContactEmail,
        };
      }

      if (this.form.providerContact == null) {
        this.form.providerContact = {
          id: this.user.userId,
          fullName: this.user.fullName,
          email: this.user.email,
        };
      }
    },
    getTheaterContacts() {
      this.theaterContacts = [];
      this.theaterContactOptions = [];

      ApiService.get('/Contracts/GetTheaterContacts', {
        TheaterId: this.form.theater.id,
      }).then((response) => {
        TokenService.saveToken(response.data.token);
        ApiService.setHeader();

        const data = response.data.result;

        this.theaterContacts = data;
        data.forEach((element) => {
          this.theaterContactOptions.push({ value: element.id, title: element.fullName });
        });
      });
    },
    changeTheaterContact() {
      const vm = this;
      this.form.theaterContact.email = '';

      if (this.form.theaterContact.id != '') {
        var theaterContact = this.theaterContacts.filter(function (option) {
          return option.id == vm.form.theaterContact.id;
        });
        this.form.theaterContact.fullName = theaterContact[0].fullName;
        this.form.theaterContact.email = theaterContact[0].email;
      }
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    theaterContactId: {
      get() {
        return this.form.theaterContact.id;
      },
      set(value) {
        this.form.theaterContact.id = value;
        this.changeTheaterContact();
      },
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit('form', this.form);
      },
    },
    formValid: function () {
      this.$emit('valid', this.formValid);
    },
  },
};
</script>

<style scoped></style>