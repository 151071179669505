import { createStore } from 'vuex';
import auth from './auth.module.js';
import storage from './storage.module.js';

const store = createStore({
  modules: {
    auth,
    storage,
  },
});

export default store;