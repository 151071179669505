<template>
  <v-col
    :cols="colsMobile"
    :sm="colsTablet"
    :md="colsDesktop"
    class="d-flex fill-height"
  >
    <v-card
      class="px-4 py-0 mb-1"
      flat
      width="100%"
    >
      <v-container>
        <v-row class="block-head">
          <v-col
            cols="12"
            class="d-flex align-center justify-space-between"
          >
            <p class="text-primary font-weight-medium mb-0">prijssoorten</p>
            <help
              v-if="helpId != ''"
              bg-color=""
              :help-id="helpId"
            ></help>
          </v-col>
        </v-row>

        <v-row
          flex
          align="center"
          justify="center"
          class="py-2 flex-column flex-grow-1"
        >
          <template v-if="chart.loaded">
            <v-col cols="12">
              <bar
                v-if="chart.loaded"
                :chart-data="chart.data"
                :chart-options="chart.options"
              ></bar>
            </v-col>
          </template>

          <template v-else>
            <p class="text-primary font-weight-medium mb-0">geen data beschikbaar</p>
          </template>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import Help from '../../Help.vue';
import getStackedBarChartData from '../../utils/getStackedBarChartData';
import { Bar } from 'vue-chartjs';

export default {
  props: ['helpId', 'colsMobile', 'cols-Tablet', 'colsDesktop', 'widgetData'],
  components: {
    Help,
    Bar,
  },
  data() {
    return {
      chart: {
        loaded: false,
        data: {},
        options: {},
      },
    };
  },
  mounted() {
    this.getStackedBarChartData(this.widgetData, 'type');
  },
  methods: {
    getStackedBarChartData,
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    widgetData() {
      this.getStackedBarChartData(this.widgetData, 'type');
    },
  },
};
</script>

<style scoped>
.fill-height {
  height: 100%;
}
</style>