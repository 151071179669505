<template>
  <div>
    <!-- Agreement Rent -->
    <v-card
      class="py-6 px-3 pb-0 mb-4"
      flat
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <p
              :class="contract.isTheaterSurchargeRejected ? 'error--text ' : 'text-primary '"
              class="font-weight-medium mb-0"
            >
              theatertoeslag
            </p>

            <div
              class="contract-diff mb-4"
              v-if="
                contract.previousApproved &&
                contract.previousApproved.theaterFee &&
                contract.previousApproved.theaterFee != null &&
                contract.previousApproved.theaterFee != contract.theaterFee
              "
            >
              <h3
                :class="
                  contract.isTheaterSurchargeRejected
                    ? 'error--text no-dash'
                    : 'text-primary text-decoration-line-through mb-0 pr-2'
                "
              >
                {{ getEuroSignView(contract.previousApproved.theaterFee) }}
              </h3>
              <h3 class="prev-approved--text pb-4 border-bottom">{{ getEuroSignView(contract.theaterFee) }}</h3>
            </div>
            <h3
              v-else
              :class="contract.isTheaterSurchargeRejected ? 'error--text ' : 'text-primary '"
              class="border-bottom pb-4 mb-4"
            >
              {{ getEuroSignView(contract.theaterFee) }}
            </h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <p
              :class="contract.isRentRejected ? 'error--text ' : 'text-primary '"
              class="font-weight-medium mb-0"
            >
              huur (excl BTW)
            </p>

            <div
              class="contract-diff mb-4"
              v-if="
                contract.previousApproved &&
                contract.previousApproved.rent &&
                contract.previousApproved.rent != null &&
                contract.previousApproved.rent != contract.rent
              "
            >
              <h3
                :class="
                  contract.isTheaterSurchargeRejected
                    ? 'error--text no-dash'
                    : 'text-primary text-decoration-line-through mb-0 pr-2'
                "
              >
                {{ getEuroSignView(contract.previousApproved.rent) }}
              </h3>
              <h3 class="prev-approved--text pb-4 border-bottom">{{ getEuroSignView(contract.rent) }}</h3>
            </div>
            <h3
              v-else
              :class="contract.isRentRejected ? 'error--text ' : 'text-primary '"
              class="border-bottom pb-4 mb-4"
            >
              {{ getEuroSignView(contract.rent) }}
            </h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0 mb-8"
          >
            <p
              :class="contract.isOtherCostsRejected ? 'error--text ' : 'text-primary '"
              class="font-weight-medium mb-0"
            >
              overige kosten
            </p>

            <div
              class="contract-diff mb-4"
              v-if="
                contract.previousApproved &&
                contract.previousApproved.otherCosts &&
                contract.previousApproved.otherCosts != null &&
                contract.previousApproved.otherCosts != contract.otherCosts
              "
            >
              <h3
                :class="
                  contract.isTheaterSurchargeRejected
                    ? 'error--text no-dash'
                    : 'text-primary text-decoration-line-through mb-0 pr-2'
                "
              >
                {{ getEuroSignView(contract.previousApproved.otherCosts) }}
              </h3>
              <h3 class="prev-approved--text pb-4 border-bottom">{{ getEuroSignView(contract.otherCosts) }}</h3>
            </div>
            <h3
              v-else
              :class="contract.isOtherCostsRejected ? 'error--text ' : 'text-primary '"
              class="border-bottom pb-4 mb-0"
            >
              {{ getEuroSignView(contract.otherCosts) }}
            </h3>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import functions from '../../../../services/functions.service';

export default {
  props: ['contract'],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    getEuroSignView(value) {
      return functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(value)));
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {},
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid #e1e7f2;
}
</style>
