<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="52"></breadcrumbs-with-help>
        </v-row>

        <!-- Table -->
        <v-data-table
          :headers="tableAreasHeaders"
          :items="tableAreas"
          :mobile-breakpoint="0"
          :loading="tableAreasLoading"
          :items-per-page="-1"
          hide-default-footer
          @click:row="(_, x) => goToDetail(x.item)"
          class="table-action row-is-clickable"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              rounded="circle" 
              elevation="0" 
              size="small" 
              icon="mdi-eye"
              color="warning"
              class="ml-1"
              @click.stop="goToDetail(item)"
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <!-- Tabel settings -->
        <table-settings
          :items="tableAreas"
          :paging="tableAreasPaging"
          :export-columns="downloadExportColumns"
          :file-name="'dip-zalen-export'"
          :sheet-name="'Zalen'"
          :show-excel-button="true"
        ></table-settings>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';

import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import TableSettings from '@/components/TableSettings.vue';

export default {
  name: 'LibraryAreas',
  components: {
    BreadcrumbsWithHelp,
    TableSettings,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      title: 'Zalen',
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'zalen',
          disabled: false,
          href: '/beheer/zalen',
          bold: true,
        },
      ],
      areas: [],
      tableAreas: [],
      tableAreasFiltered: [],
      tableAreasLoading: true,
      tableAreasHeaders: [
        { title: 'naam', key: 'name' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
      tableAreasPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
      },
      downloadExportColumns: [
        { label: 'zaalID', field: 'id' },
        { label: 'zaalnaam', field: 'name' },
        { label: 'intern', field: 'onPremise' },
        { label: 'straat', field: 'street' },
        { label: 'huisnummer', field: 'number' },
        { label: 'postcode', field: 'zipCode' },
        { label: 'plaats', field: 'city' },
        { label: 'provincie', field: 'provinceName' },
        { label: 'theater', field: 'theaterName' },
        { label: 'theaterID', field: 'theaterId' },
      ],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryAreas');
    this.getAreas();
  },
  methods: {
    goToDetail(row) {
      this.$router.push({
        name: 'LibraryAreasDetail',
        params: { theaterAreaId: row.id },
      });
    },
    getAreas: function () {
      ApiService.get('/TheaterAreas/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.areas = response.data.result.map(area => ({
            ...area,
            provinceName: area.province.name,
            theaterName: area.theater.name,
            onPremise: area.onPremise === true ? 'Ja' : 'Nee'
          }));

          this.tableAreas = this.areas;
          this.tableAreasLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
  },
  watch: {},
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}
</style>
