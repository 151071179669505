<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="7"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- Page info first row -->
          <v-row class="d-flex" justify="center" align="center" no-gutters>

            <v-col cols="12" class="py-0">
              <!-- Form -->
              <v-form @submit.prevent class="d-flex flex-column" ref="form">
                <v-card title="bewerken koppeling">
                  <v-divider></v-divider>
                  <v-card-text class="mt-5">
                    <v-row>
                      <!-- Type organisatie -->
                      <v-col cols="12" class="py-1">
                        <v-autocomplete id="type_organisatie" label="type organisatie" v-model="organisationType" 
                          :items="organisationTypeOptions" :rules="stringRules" disabled></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!-- Organisatie -->
                      <v-col cols="12" class="py-1">
                        <v-autocomplete id="organisatie" label="organisatie" v-model="editUserLinkForm.organisationId"
                          :items="organisationOptions" :rules="stringRules"></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!-- Userrol -->
                      <v-col cols="12" class="py-1">
                        <v-autocomplete id="gebruikersrol" label="gebruikersrol" v-model="editUserLinkForm.roleId" :items="userRoleOptions"
                          :rules="stringRules"></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-alert type="error" variant="tonal" v-if="formError != ''">{{ formError }}</v-alert>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <div>
                      <p class="grey--text text--lighten-1 mb-2">
                        <small><i>{{ this.lastEditedBy }}</i></small>
                      </p>
                      <v-btn-save @click="submit" :loading="formLoading">Opslaan</v-btn-save>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import { TokenService } from '../../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'AdminUsersLinkEdit',
  props: ['userId', 'linkId', 'organisationTypeId'],
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      user: {
        firstName: '',
        lastName: '',
      },
      isEditing: false,
      formError: '',
      formLoading: false,
      editUserLinkForm: {
        organisationType: '',
        organisationId: '',
        roleId: '',
      },
      lastEditedBy: '',
      organisationTypeOptions: [],
      organisationOptions: [],
      userRoleOptions: [],
      theaters: [],
      impresariaten: [],
      producenten: [],
      stringRules: [(v) => !!v || 'dit veld is verplicht'],
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'gebruikers',
          disabled: false,
          href: '/admin/gebruikers',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'bewerken koppeling',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.getUser();
    this.getOrganisations();
    this.getOrganisationTypes();
    this.getLink();
  },
  methods: {
    getUser: function () {
      ApiService.get('/Users/Get', {
        userId: this.userId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const user = response.data.result;

            this.user.firstName = user.firstName != null ? user.firstName : '';
            this.user.lastName = user.lastName != null ? user.lastName : '';

            this.breadcrumbs[2].text = this.user.firstName + ' ' + this.user.lastName;
            this.breadcrumbs[2].href = '/admin/gebruikers/' + this.userId + '/';
            this.breadcrumbs[3].href = '/admin/gebruikers/' + this.userId + '/link/add';
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getLink: function () {
      const vm = this;
      ApiService.get('/UserRoles/Get', {
        linkId: this.linkId,
        organisationTypeId: this.organisationTypeId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const link = response.data.result;
            this.editUserLinkForm.organisationType = link.organisationTypeId;
            this.changeOrganisationType();
            this.editUserLinkForm.organisationId = link.organisationId;
            this.editUserLinkForm.roleId = link.userRoleId;
            this.lastEditedBy = link.lastEditedBy;

            setTimeout(function () {
              vm.isEditing = false;
            }, 200);
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getOrganisations: function () {
      ApiService.get('/Theaters/GetAllDropDown')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.theaters.push({ value: element.id, title: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });

      ApiService.get('/Producers/GetAllDropDown')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.producenten.push({ value: element.id, title: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });

      ApiService.get('/Agencies/GetAllDropDown')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.impresariaten.push({ value: element.id, title: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getUserRoles: function () {
      ApiService.get('/UserRoles/GetAll', {
        organisationTypeId: this.editUserLinkForm.organisationType,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.userRoleOptions.push({ value: element.id, title: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getOrganisationTypes: function () {
      ApiService.get('/OrganisationTypes/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.organisationTypeOptions.push({
              value: element.organisationTypeId,
              title: element.organisationTypeName,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    changeOrganisationType: function () {
      this.organisationOptions = [];
      this.editUserLinkForm.organisationId = '';

      this.userRoleOptions = [];
      this.editUserLinkForm.roleId = '';
      this.getUserRoles();

      if (this.editUserLinkForm.organisationType == 1) {
        this.organisationOptions = this.impresariaten;
      } else if (this.editUserLinkForm.organisationType == 2) {
        this.organisationOptions = this.theaters;
      } else if (this.editUserLinkForm.organisationType == 3) {
        this.organisationOptions = this.producenten;
      }
    },
    async submit() {
      this.formError = '';
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const form = this.editUserLinkForm;

        ApiService.put('/UserRoles/Update', {
          linkId: parseInt(this.linkId),
          organisationTypeId: form.organisationType,
          organisationId: form.organisationId,
          roleId: form.roleId,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();

            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {
    organisationType: {
      get() {
        return this.editUserLinkForm.organisationType;
      },
      set(value) {
        this.editUserLinkForm.organisationType = value;
        this.changeOrganisationType();
      },
    },
  },
  watch: {
    editUserLinkForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
