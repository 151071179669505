<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="1115"></breadcrumbs-with-help>
        </v-row>

        <v-row v-if="performance && production">
          <v-col
            cols="12"
            md="6"
          >
            <div class="d-flex flex-column">
              <v-card
                class="py-5 px-3 pb-0 mb-4"
                flat
              >
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="4"
                      class="py-0"
                    >
                      <p class="text-primary font-weight-medium mb-0">productienummer</p>
                      <h3 class="text-primary border-bottom pb-0 mb-4">
                        {{ production.number }}
                      </h3>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      class="py-0"
                    >
                      <p class="text-primary font-weight-medium mb-0">voorstellingsnummer</p>
                      <h3 class="text-primary border-bottom pb-0 mb-4">
                        {{ performance.number }}
                      </h3>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <v-card
                class="py-0 pb-6 px-3 mb-4"
                flat
              >
                <v-container>
                  <v-row class="block-head">
                    <v-col
                      cols="12"
                      class="d-flex align-center justify-space-between"
                    >
                      <p class="text-primary font-weight-medium mb-0">kaartverkoop over tijd</p>
                    </v-col>
                  </v-row>
                  <v-row v-if="chart.data">
                    <v-col
                      cols="12"
                      md="12"
                      class="py-0"
                    >
                      <Line
                        :chart-data="chart.data"
                        :chart-options="chart.options"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-for="option in dateRangeOptions"
                      cols="12"
                      md="3"
                      class="py-0"
                      :key="option.text"
                    >
                      <v-btn
                        v-if="selectedDateRange == option.range"
                        @click="changeDateRange(option)"
                        class="font-weight-bold text-lowercase"
                        color="primary"
                        depressed
                        block
                        >{{ option.text }}</v-btn
                      >
                      <v-btn
                        v-else
                        @click="changeDateRange(option)"
                        class="font-weight-bold text-lowercase"
                        color="primary"
                        variant="outlined"
                        block
                        depressed
                        >{{ option.text }}</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <v-card
                class="py-0 pb-6 px-3 mb-4"
                flat
              >
                <v-container>
                  <v-row class="block-head">
                    <v-col
                      cols="12"
                      class="d-flex align-center justify-space-between"
                    >
                      <p class="text-primary font-weight-medium mb-0">kaartverkoop per maand</p>
                    </v-col>
                  </v-row>
                  <v-row v-if="chart.data">
                    <v-col
                      cols="12"
                      md="12"
                      class="py-0"
                    >
                      <Bar
                        :chart-data="barchart.data"
                        :chart-options="barchart.options"
                        :height="200"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </div>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <div class="d-flex flex-column">
              <v-card
                class="py-5 px-3 pb-0 mb-4"
                flat
              >
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                      class="py-0"
                    >
                      <p class="text-primary font-weight-medium mb-0">titel</p>
                      <h3 class="text-primary border-bottom pb-0 mb-4">
                        {{ production.title }}
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="8"
                      class="py-0"
                    >
                      <p class="text-primary font-weight-medium mb-0">producent</p>
                      <h3 class="text-primary border-bottom pb-0 mb-4">
                        {{ production.producer.name }}
                      </h3>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      class="py-0"
                    >
                      <p class="text-primary font-weight-medium mb-0">type productie</p>
                      <h3 class="text-primary border-bottom pb-0 mb-4">
                        {{ production.productionType === 'own_production' ? 'eigen productie' : 'co-productie' }}
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row v-if="production.coProducers.length > 0">
                    <v-col
                      cols="12"
                      md="8"
                      class="py-0"
                    >
                      <p class="text-primary font-weight-medium mb-0">co-producenten</p>
                      <h3 class="text-primary border-bottom pb-0 mb-4">
                        {{ production.coProducers.map((p) => p.name).join(', ') }}
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row v-if="production.agency">
                    <v-col
                      cols="12"
                      md="12"
                      class="py-0"
                    >
                      <p class="text-primary font-weight-medium mb-0">impresariaat</p>
                      <h3 class="text-primary border-bottom pb-0 mb-4">
                        {{ production.agency.name }}
                      </h3>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <v-card
                class="py-5 px-3 pb-0 mb-4"
                flat
              >
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                      class="py-0"
                    >
                      <p class="text-primary font-weight-medium mb-0">speeldatum</p>
                      <h3 class="text-primary border-bottom pb-0 mb-4">
                        {{ getDateTimeView(performance.startDate) }}
                      </h3>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      class="py-0"
                    >
                      <p class="text-primary font-weight-medium mb-0">capaciteit</p>
                      <h3 class="text-primary border-bottom pb-0 mb-4">
                        {{ formatThousandsWithDot(performance.capacity) }}
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                      class="py-0"
                    >
                      <p class="text-primary font-weight-medium mb-0">theater</p>
                      <h3 class="text-primary border-bottom pb-0 mb-4">
                        {{ performance.theater }}
                      </h3>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      class="py-0"
                    >
                      <p class="text-primary font-weight-medium mb-0">zaal</p>
                      <h3 class="text-primary border-bottom pb-0 mb-4">
                        {{ performance.area }}
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                      class="py-0"
                    >
                      <p class="text-primary font-weight-medium mb-0">type</p>
                      <h3 class="text-primary border-bottom pb-0 mb-4">
                        {{ performance.type }}
                      </h3>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      class="py-0"
                    >
                      <p class="text-primary font-weight-medium mb-0">status</p>
                      <h3 class="text-primary border-bottom pb-0 mb-4">
                        {{ performance.state }}
                      </h3>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>

              <!-- Summary -->
              <v-card
                class="py-5 px-3 pb-0 mb-4"
                flat
              >
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                      class="py-0 d-flex align-center justify-space-between mb-2"
                    >
                      <p class="text-primary font-weight-medium mb-0">gemiddelde ticketprijs</p>
                      <h3 class="text-primary border-bottom pb-0">
                        {{ getEuroSign(performanceSummary.localTicketPrice) }}
                      </h3>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                      class="py-0 d-flex align-center justify-space-between mb-2"
                    >
                      <p class="text-primary font-weight-medium mb-0">gemiddelde landelijke ticketprijs</p>
                      <h3 class="text-primary border-bottom pb-0">
                        {{ getEuroSign(performanceSummary.nationalTicketPrice) }}
                      </h3>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                      class="py-0 d-flex align-center justify-space-between mb-2"
                    >
                      <v-progress-linear
                        class="custom_progress"
                        bg-color='grey-darken-2'
                        height="35"
                        width="200"
                        rounded="true"
                        :model-value="performanceSummary.localOccupancyRate"
                        color="#0abfb8"
                      >
                        <div
                          class="target"
                          :style="{
                            left: performanceSummary.nationalOccupancyRate + '%',
                          }"
                          v-if="performanceSummary.nationalOccupancyRate > 0"
                        ></div>
                        </v-progress-linear>
                    </v-col>
                    <v-col class="py-0 text-primary">
                      <i>
                        bezettingspercentage
                        {{ getPercentageView(performanceSummary.localOccupancyRate) }}

                        (landelijk:
                        {{ getPercentageView(performanceSummary.nationalOccupancyRate) }})
                      </i>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                      class="py-0mb-2"
                      ><v-data-table
                        :headers="performanceSummaryHeaders"
                        :items="performanceSummaryItems"
                        :item-class="row_classes"
                        :items-per-page="-1"
                        :hide-default-footer="true"
                      >
                        <template v-slot:[`item.ticketCount`]="{ item }">
                          {{ formatThousandsWithDot(item.ticketCount) }}
                        </template>

                        <template v-slot:[`item.revenueInVat`]="{ item }">
                          {{ getEuroSign(item.revenueInVat) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ApiService from '../../services/api.service';
import BreadcrumbsWithHelp from '../../components/BreadcrumbsWithHelp.vue';
import router from '../../router/router.js';
import functions from '../../services/functions.service';
import { Line } from 'vue-chartjs';
import { Bar } from 'vue-chartjs';
import { DateTime } from 'luxon';

export default {
  name: 'SalesMonitorPerformancesDetail',
  props: ['productionNumber', 'performanceId'],
  components: {
    Bar,
    Line,
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      bufferValue: 20,
      rights: {
        edit: false,
        approve: false,
      },
      chart: {
        data: null,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              type: 'time',
              time: {
                tooltipFormat: 'dd MMM yyyy',
              },
              min: null,
            },
          },
          plugins: {
            datalabels: {
              display: false,
            },
          },
        },
      },
      barchart: {
        data: null,
        options: {
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
          plugins: {
            datalabels: {
              display: false,
            },
          },
        },
      },
      breadcrumbs: [
        {
          text: 'verkoopmonitor',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'voorstellingen',
          disabled: false,
          href: '/verkoopmonitor/voorstellingen',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      filterPeriod: {
        from: '',
        to: '',
      },
      performance: null,
      production: null,
      performanceSummary: [],
      performances: [],
      performanceSummaryHeaders: [
        {
          title: 'ticket- en plaatsstatus',
          key: 'salesType',
          sortable: false,
        },
        {
          title: 'aantal',
          key: 'ticketCount',
          align: 'right',
          sortable: false,
        },
        {
          title: 'waarde',
          key: 'revenueInVat',
          align: 'right',
          sortable: false,
        },
      ],
      performanceSummaryItems: [],
      maxDate: null,
      dateRangeOptions: [
        {
          text: '7d',
          range: { days: 7 },
        },
        {
          text: '1m',
          range: { month: 1 },
        },
        {
          text: '3m',
          range: { month: 3 },
        },
        {
          text: 'alle',
          range: null,
        },
      ],
      selectedDateRange: null,
    };
  },
  mounted() {
    this.rights = this.getComponentRights('SalesMonitor');
    this.getProduction();
    this.getPerformance();
    this.getPerformanceSalesHistory();
  },
  methods: {
    getEuroSign: function (value) {
      return functions.getEuroSignView(value);
    },
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getPercentageView: function (value) {
      return functions.getPercentageViewWithoutDecimals(value);
    },
    row_classes(item) {
      if (item.salesType == 'totaal') {
        return 'bold';
      }
      return '';
    },
    calculateArraySum: function (items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    },
    getDateTimeView(date) {
      return functions.getDateTimeView(date);
    },
    getPerformance: function () {
      ApiService.get('/Performances/Get', {
        performanceId: this.performanceId,
      })
        .then((response) => {
          if (response.data.success) {
            this.performance = response.data.result;
            this.performance.state = functions.changeState(this.performance.state);
            this.performance.type = functions.changeType(this.performance.type);
            this.breadcrumbs[3].text = this.performance.number;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getProduction: function () {
      ApiService.get('/Productions/Get', {
        productionNumber: this.productionNumber,
      })
        .then((response) => {
          if (response.data.success) {
            this.production = response.data.result;
            this.breadcrumbs[2].text = this.production.title;
            this.breadcrumbs[2].href = '/verkoopmonitor/producties/' + this.productionNumber + '/';
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getPerformanceSalesHistory: function () {
      this.performanceSummary = [];
      this.barchart.data = {};

      ApiService.get('/SalesMonitor/GetPerformance', {
        performanceId: this.performanceId,
      })
        .then((response) => {
          const resPerDay = response.data.result.perDay;
          const resPerMonth = response.data.result.perMonth;
          this.performanceSummary = response.data.result.summary;

          this.performanceSummaryItems = response.data.result.summary.salesTypes;

          // creating lineChart
          this.maxDate = DateTime.fromISO(resPerDay[resPerDay.length - 1].day);
          const types = [...new Set(resPerDay.map((r) => r.salesType))];

          this.chart.data = {
            datasets: types.map((type) => ({
              label: type,
              borderColor: functions.getTypeColor(type),
              data: resPerDay.filter((t) => t.salesType === type).map((t) => ({ x: t.day, y: t.ticketCount })),
            })),
          };

          // creating barChart
          this.barchart.data = {
            labels: resPerMonth.months,
            datasets: resPerMonth.salesTypes
              .map((type) => ({
                label: type.salesType,
                backgroundColor: functions.getTypeColor(type.salesType),
                data: type.ticketCounts,
              }))
              .filter((s) => s.data.filter((t) => t > 0).length > 0),
          };
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    changeDateRange: function (option) {
      this.selectedDateRange = option.range;
      if (option.range) {
        this.chart.options.scales.x.min = this.maxDate.minus(option.range).toISODate();
      } else {
        this.chart.options.scales.x.min = null;
      }
      this.chart.options.scales.x.time.unit = option.range?.days ? 'day' : false;
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
  },
  watch: {
    tableSalesMonitorFilter: {
      handler: 'filterProductions',
      deep: true,
    },
  },
};
</script>

<style>
tr.bold {
  font-weight: bold;
}
</style>