<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row flex align="center" justify="space-between" class="px-3 mb-4">
          <v-col class="py-3 add-row d-flex justify-sm-space-between align-center">
            <span class="d-none d-sm-flex">Meer cijfers?</span>
            <div>
              <v-btn color="primary" rounded depressed appendIcon="mdi-chart-bar-stacked"
                class="font-weight-bold text-lowercase ma-1" :to="{ name: 'SalesMutationsPerformances' }">
                voorstellingen
              </v-btn>
              <v-btn color="primary" rounded depressed appendIcon="mdi-chart-bar-stacked"
                class="font-weight-bold text-lowercase ma-1" :to="{ name: 'SalesMutationsProductions10weeks' }">
                producties laatste 10 weken
              </v-btn>
              <v-btn color="primary" rounded depressed appendIcon="mdi-chart-bar-stacked"
                class="font-weight-bold text-lowercase ma-1" :to="{ name: 'SalesMutationsProductions7days' }">
                producties laatste 7 dagen
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row flex justify="center" align="center" class="pb-2" no-gutters>
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="1127"></breadcrumbs-with-help>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-lg-flex">
              <filter-date-from-to :defaultFrom="this.filter.filterDateOne" :defaultTo="this.filter.filterDateTwo"
                @changePeriod="filter.filterPeriod = $event"></filter-date-from-to>
              <v-text-field v-model="tableProductionsFilter.productionTitle" label="productie zoeken" hide-details
                density="compact" bg-color="#fff" class="ma-1 input-sm"></v-text-field>
            </div>
            <!-- Filter dialog -->
            <div class="d-lg-none">
              <v-dialog v-model="tableSalesMutationsFilterDialog" width="500" fullscreen>
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn class="font-weight-bold text-lowercase" color="success" rounded dark
                    v-bind="activatorProps">filter overzicht
                    <v-icon small right>mdi-filter</v-icon></v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline dark">
                    <h5 class="text-primary">filter overzicht</h5>
                  </v-card-title>

                  <v-card-text>
                    <filter-date-from-to :defaultFrom="this.filter.filterDateOne" :defaultTo="this.filter.filterDateTwo"
                      @changePeriod="filter.filterPeriod = $event"></filter-date-from-to>
                    <v-text-field v-model="tableProductionsFilter.productionTitle" label="productie zoeken" hide-details
                      dense outlined background-color="#fff" class="ma-1 input-sm"></v-text-field>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn class="font-weight-bold text-lowercase" color="error" variant="outlined" dark
                      @click="clearFilterDateFromTo()">sluit filter
                      <v-icon small right>mdi-close</v-icon></v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <div class="tables-container" v-show="!this.tableGenresLoading && !this.tableProductionsLoading">
          <!-- Total Table -->
          <v-data-table :headers="tableProductionsHeaders" :items="tableProductionsFiltered"
            :v-model:sort-by.sync="sortBy" :v-model:sort-desc.sync="sortDesc" :mobile-breakpoint="0"
            :loading="tableProductionsLoading" :page.sync="tableProductionsPage"
            :items-per-page="tableProductionsItemsPerPage" hide-default-footer
            @update:current-items="tableProductionsPageCount = Math.ceil(tableProductionsFiltered.length / tableProductionsItemsPerPage)"
            @update:items-per-page="tableProductionsPageCount = Math.ceil(tableProductionsFiltered.length / tableProductionsItemsPerPage)"
            @click:row="(_, x) => goToDetail(x.item)" class="table-action row-is-clickable">
            <template v-slot:[`item.mutationNumber`]="{ item }">
              <v-chip v-if="item.mutationNumber > 0" color="success" small label><strong>{{ item.mutationNumber
                  }}</strong></v-chip>
              <v-chip v-else-if="item.mutationNumber < 0" color="error" small label><strong>{{ item.mutationNumber
                  }}</strong></v-chip>
              <template v-else-if="item.mutationNumber === 0">
                {{ item.mutationNumber }}
              </template>
            </template>

            <template v-slot:[`item.capacity`]="{ item }">
              <span>{{ formatThousandsWithDot(item.capacity) }}</span>
            </template>

            <template v-slot:[`item.weekOne`]="{ item }">
              <span>{{ formatThousandsWithDot(item.weekOne) }}</span>
            </template>

            <template v-slot:[`item.weekTwo`]="{ item }">
              <span>{{ formatThousandsWithDot(item.weekTwo) }}</span>
            </template>

            <template v-slot:[`item.mutationRate`]="{ item }">
              <span>{{ getPercentageView(item.mutationRate) }}</span>
            </template>

            <template v-slot:[`item.occupancyRate`]="{ item }">
              <span>{{ getPercentageView(item.occupancyRate) }}</span>
            </template>

            <template v-slot:[`item.ticketPrice`]="{ item }">
              <span>{{ getEuroSign(item.ticketPrice) }}</span>
            </template>

            <template v-slot:[`item.revenueInVat`]="{ item }">
              <span>{{ getEuroSign(item.revenueInVat) }}</span>
            </template>

            <template v-slot:[`body.append`]>
              <tr class="table-total">
                <th class="title">totaal</th>
                <th class=""></th>
                <th class=""></th>
                <th class="text">
                  {{ formatThousandsWithDot(this.tableProductionsTotal.performanceCount) }}
                </th>
                <th class="text">
                  {{ formatThousandsWithDot(this.tableProductionsTotal.capacity) }}
                </th>
                <th class="text">
                  {{ formatThousandsWithDot(this.tableProductionsTotal.theaters) }}
                </th>
                <th class="text">
                  {{ formatThousandsWithDot(this.tableProductionsTotal.weekOne) }}
                </th>
                <th class="text">
                  {{ formatThousandsWithDot(this.tableProductionsTotal.weekTwo) }}
                </th>
                <th class="text">
                  {{ this.tableProductionsTotal.mutationNumber }}
                </th>
                <th class="text">
                  {{ getPercentageView(this.tableProductionsTotal.mutationRate) }}
                </th>
                <th class="text">
                  {{ getPercentageView(this.tableProductionsTotal.occupancyRate) }}
                </th>
                <th class="text">
                  {{ getEuroSign(this.tableProductionsTotal.ticketPrice) }}
                </th>
                <th class="text">
                  {{ getEuroSign(this.tableProductionsTotal.revenueInVat) }}
                </th>
              </tr>
            </template>


          </v-data-table>

          <!-- Table settings -->
          <v-row class="text-center pt-2 mb-5" align="center">
            <!-- Table totaal -->
            <v-col cols="12">
              <p class="font-weight-medium text-left caption text-primary ma-0 pa-0">
                totaal: {{ this.tableProductionsFiltered.length }}
              </p>
            </v-col>

            <!-- Table buttons items per page -->
            <v-col cols="12" sm="4" class="d-flex align-center justify-center justify-sm-start">
              <v-btn v-if="tableProductionsFiltered.length > 25" @click="tableProductionsItemsPerPage = 25"
                :color="tableProductionsItemsPerPage == 25 ? 'primary' : '#fff'" height="45" width="45" elevation="0"
                size="small" class="pa-0 mr-1 font-weight-bold body-2">25</v-btn>
              <v-btn v-if="tableProductionsFiltered.length > 25" @click="tableProductionsItemsPerPage = 50"
                :color="tableProductionsItemsPerPage == 50 ? 'primary' : '#fff'" height="45" width="45" elevation="0"
                size="small" class="pa-0 mr-1 font-weight-bold body-2">50</v-btn>
              <v-btn v-if="tableProductionsFiltered.length > 50" @click="tableProductionsItemsPerPage = 100"
                :color="tableProductionsItemsPerPage == 100 ? 'primary' : '#fff'" height="45" width="45" elevation="0"
                size="small" class="pa-0 mr-1 font-weight-bold body-2">100</v-btn>
            </v-col>

            <!-- Table pagination -->
            <v-col cols="12" sm="4">
              <v-pagination v-if="tableProductionsPageCount > 1" v-model="tableProductionsPage"
                :length="tableProductionsPageCount" :total-visible="7" elevation="0" variant="elevated"
                active-color="primary" size="small"></v-pagination>
            </v-col>

            <!-- Table download -->
            <v-col cols="12" sm="4" class="d-flex justify-center justify-sm-end">
              <v-btn color="primary" rounded depressed class="font-weight-bold text-lowercase">
                <vue-excel-xlsx class="v-btn font-weight-bold text-lowercase" :data="exportData"
                  :columns="exportDataColumns" :filename="'dip-verkoopmutaties-samenvatting'"
                  :sheetname="'samenvatting'">exporteer <v-icon right>mdi-file-move</v-icon></vue-excel-xlsx>
              </v-btn>
            </v-col>
          </v-row>

          <div v-for="(item, i) in this.dataGenres" :key="i">
            <h4 class="text-primary mb-1">{{ item.genreName }}</h4>
            <v-data-table :headers="tableGenresHeaders" :items="item.details" :mobile-breakpoint="0"
              :loading="tableGenresLoading" :items-per-page="-1" hide-default-footer class="mb-5">
              <template v-slot:[`item.capacity`]="{ item }">
                <span>{{ formatThousandsWithDot(item.capacity) }}</span>
              </template>

              <template v-slot:[`item.week1`]="{ item }">
                <span>{{ formatThousandsWithDot(item.week1) }}</span>
              </template>

              <template v-slot:[`item.week2`]="{ item }">
                <span>{{ formatThousandsWithDot(item.week2) }}</span>
              </template>

              <template v-slot:[`item.averageTicketprice`]="{ item }">
                <span>{{ getEuroSign(item.averageTicketprice) }}</span>
              </template>

              <template v-slot:[`item.recette`]="{ item }">
                <span>{{ getEuroSign(item.recette) }}</span>
              </template>

              <template v-slot:[`body.append`] v-if="!tableGenresLoading">
                <tr class="table-total">
                  <th class="title">aandeel</th>
                  <th class="text">{{ item.total.performanceCountShare }}</th>
                  <th class="text">{{ item.total.capacityShare }}</th>
                  <th class="text">{{ item.total.theaterCountShare }}</th>
                  <th class="text">{{ item.total.weekOne }}</th>
                  <th class="text">{{ item.total.weekTwo }}</th>
                  <th class=""></th>
                  <th class=""></th>
                  <th class=""></th>
                  <th class=""></th>
                  <th class="text">{{ item.total.revenueInVatShare }}</th>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
        <div v-show="this.tableGenresLoading || this.tableProductionsLoading">
          <div class="text-center progress-circular">
            <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular>
            <h4 class="text-primary mt-5">Een moment geduld aub, je gegevens worden berekend. Dit kan even duren.</h4>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ApiService from '@/services/api.service';
import { TokenService } from '@/services/storage.service';
import BreadcrumbsWithHelp from '../../components/BreadcrumbsWithHelp.vue';
import functions from '@/services/functions.service';
import FilterDateFromTo from '@/components/FilterDateFromTo';

export default {
  name: 'SalesMutationsSummary',
  components: {
    BreadcrumbsWithHelp,
    FilterDateFromTo,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'verkoopmutaties',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'samenvatting',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      filter: {
        filterDateOne: '',
        filterDateTwo: '',
        filterPeriod: {
          from: '',
          to: '',
        },
      },
      timeStamp: '',
      tableSalesMutationsFilterDialog: false,
      tableProductionsFilter: {
        productionTitle: '',
      },
      productions: [],
      tableProductionsPage: 1,
      tableProductions: [],
      tableProductionsFiltered: [],
      tableProductionsTotal: {
        performanceCount: 0,
        capacity: 0,
        theatersAmount: 0,
        weekOne: 0,
        weekTwo: 0,
        mutation: 0,
        percentageMutation: 0,
        percentageBooked: 0,
        ticketPrice: 0,
        ticketPriceExport: 0,
        recette: 0,
        recetteExport: 0,
      },
      tableProductionsLoading: true,
      sortBy: 'mutationNumber',
      sortDesc: true,
      tableProductionsHeaders: [
        { title: 'prod nr', key: 'productionNumber' },
        { title: 'naam', key: 'productionTitle' },
        { title: 'genre', align: 'right', key: 'genreName' },
        { title: 'vrst', key: 'performanceCount' },
        { title: 'capaciteit', key: 'capacity' },
        { title: 'theaters', key: 'theaterCount' },
        { title: 'wk', key: 'weekOne' },
        { title: 'wk', key: 'weekTwo' },
        { title: '# mutatie', key: 'mutationNumber' },
        { title: '% mutatie', key: 'mutationRate' },
        { title: '% bezet', key: 'occupancyRate' },
        { title: '~kaartprijs', key: 'ticketPrice' },
        { title: 'recette', key: 'revenueInVat' },
      ],
      tableProductionsFilterActive: false,
      tableProductionsPageCount: 0,
      tableProductionsItemsPerPage: 25,
      dataGenres: [],
      tableGenresLoading: true,
      tableGenresHeaders: [
        { title: '', key: 'type' },
        { title: 'vrst', key: 'performanceCount' },
        { title: 'capaciteit', key: 'capacity' },
        { title: 'theaters', key: 'theaterCount' },
        { title: 'week1', key: 'week1' },
        { title: 'week2', key: 'week2' },
        { title: '# mutatie', key: 'mutationNumber' },
        { title: '% mutatie', key: 'mutationRate' },
        { title: '% bezet', key: 'occupancyRate' },
        { title: '~kaartprijs', key: 'ticketPrice' },
        { title: 'recette', key: 'revenueInVat' },
      ],
      exportData: [],
      exportDataColumns: [],
    };
  },
  beforeMount() {
    this.filterDefaultDate();
  },
  mounted() {
    this.rights = this.getComponentRights('SalesMutations');
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    goToDetail(row) {
      this.$router.push({
        name: 'SalesMonitorProductionsDetail',
        params: { productionNumber: row.productionNumber },
      });
    },
    getEuroSign: function (value) {
      return functions.getEuroSignView(value);
    },
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getPercentageView: function (value) {
      return functions.getPercentageView(value);
    },
    calculateArraySum: function (items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    },
    filterDefaultDate: function () {
      this.checkFilter({
        viewName: 'SalesMutationsOveral',
        filterObject: this.filter,
      });
      this.filter = {};
      this.filter = this.getFilter('SalesMutationsOveral');

      if (this.filter.filterDateOne == '') {
        var now = new Date();
        var previousWeek = new Date();
        previousWeek = new Date(previousWeek.setDate(previousWeek.getDate() - 7));
        this.filter.filterDateOne = functions.getDateView(functions.jsDateToStringDate(previousWeek));
        this.filter.filterDateTwo = functions.getDateView(functions.jsDateToStringDate(now));
      } else {
        this.filter.filterDateOne = functions.getDateView(
          functions.jsDateToStringDate(new Date(this.filter.filterPeriod.from))
        );
        this.filter.filterDateTwo = functions.getDateView(
          functions.jsDateToStringDate(new Date(this.filter.filterPeriod.to))
        );
      }
    },
    clearFilterDateFromTo: function () {
      this.tableSalesMutationsFilterDialog = false;
    },
    getData: function () {
      this.getDataGenres();
      this.getProductions();
      this.createExportData();
    },
    getDataGenres: function () {
      this.dataGenres = [];
      this.tableGenresLoading = true;

      ApiService.get('/SalesMutations/GetSummaryByGenre', {
        firstDate: this.filter.filterPeriod.from,
        lastDate: this.filter.filterPeriod.to,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.dataGenres = response.data.result;

          for (var i = 0; i < this.dataGenres.length; i++) {
            var genreDetails = [
              {
                type: this.dataGenres[i].organisation.name,
                performanceCount: this.dataGenres[i].organisation.performanceCount,
                capacity: this.dataGenres[i].organisation.capacity,
                theaterCount: this.dataGenres[i].organisation.theaterCount,
                mutationNumber: this.dataGenres[i].organisation.mutationNumber,
                mutationRate:
                  this.dataGenres[i].organisation.mutationRate != null
                    ? functions.getPercentageView(this.dataGenres[i].organisation.mutationRate)
                    : '',
                occupancyRate:
                  this.dataGenres[i].organisation.occupancyRate != null
                    ? functions.getPercentageView(this.dataGenres[i].organisation.occupancyRate)
                    : '',
                ticketPrice:
                  this.dataGenres[i].organisation.ticketPrice != null
                    ? functions.getEuroSignView(this.dataGenres[i].organisation.ticketPrice)
                    : '',
                revenueInVat: functions.getEuroSignView(this.dataGenres[i].organisation.revenueInVat),
              },
              {
                type: 'Landelijk',
                performanceCount: this.dataGenres[i].national.performanceCount,
                capacity: this.dataGenres[i].national.capacity,
                theaterCount: this.dataGenres[i].national.theaterCount,
                mutationNumber: this.dataGenres[i].national.mutationNumber,
                mutationRate:
                  this.dataGenres[i].national.mutationRate != null
                    ? functions.getPercentageView(this.dataGenres[i].national.mutationRate)
                    : '',
                occupancyRate:
                  this.dataGenres[i].national.occupancyRate != null
                    ? functions.getPercentageView(this.dataGenres[i].national.occupancyRate)
                    : '',
                ticketPrice:
                  this.dataGenres[i].national.ticketPrice != null
                    ? functions.getEuroSignView(this.dataGenres[i].national.ticketPrice)
                    : '',
                revenueInVat: functions.getEuroSignView(this.dataGenres[i].national.revenueInVat),
              },
            ];
            for (var j = 0; j < this.dataGenres[i].organisation.days.length; j++) {
              genreDetails[0]['week' + (j + 1)] = this.dataGenres[i].organisation.days[j].ticketCount;
              genreDetails[0]['week' + (j + 2)] = this.dataGenres[i].organisation.days[j].ticketCount;
            }
            for (var l = 0; l < this.dataGenres[i].national.days.length; l++) {
              genreDetails[1]['week' + (l + 1)] = this.dataGenres[i].national.days[l].ticketCount;
              genreDetails[1]['week' + (l + 2)] = this.dataGenres[i].national.days[l].ticketCount;
            }
            for (var k = 0; k < this.dataGenres[i].days.length; k++) {
              this.tableGenresHeaders[k + 4].text = functions.getDateView(this.dataGenres[i].days[k].date);
            }

            this.dataGenres[i].details = genreDetails;

            this.dataGenres[i].total = {
              performanceCountShare: functions.getPercentageView(this.dataGenres[i].performanceCountShare),
              capacityShare: functions.getPercentageView(this.dataGenres[i].capacityShare),
              theaterCountShare: functions.getPercentageView(this.dataGenres[i].theaterCountShare),
              weekOne: functions.getPercentageView(this.dataGenres[i].days[0].ticketCountShare),
              weekTwo: functions.getPercentageView(this.dataGenres[i].days[1].ticketCountShare),
              revenueInVatShare: functions.getPercentageView(this.dataGenres[i].revenueInVatShare),
            };
          }

          this.tableGenresLoading = false;
        })
        .catch((error) => {
          //eslint-disable-next-line
          console.log(error);
          this.errored = true;
          this.tableGenresLoading = false;
        });
    },
    getProductions: function () {
      this.tableProductions = [];
      this.tableProductionsLoading = true;

      ApiService.get('/SalesMutations/GetSummaryByProduction', {
        firstDate: this.filter.filterPeriod.from,
        lastDate: this.filter.filterPeriod.to,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.tableProductions = response.data.result.productions;
          this.tableProductionsTotals = response.data.result;

          for (let i = 0; i < this.tableProductions.length; i++) {
            this.tableProductions[i].occupancyRate = this.tableProductions[i].occupancyRate != null
              ? parseFloat(this.tableProductions[i].occupancyRate.toFixed(2))
              : '';
            this.tableProductions[i].mutationRate = this.tableProductions[i].mutationRate != null
              ? parseFloat(this.tableProductions[i].mutationRate.toFixed(2))
              : '';
            this.tableProductions[i].ticketPrice = this.tableProductions[i].ticketPrice != null
              ? parseFloat(this.tableProductions[i].ticketPrice.toFixed(2))
              : '';

            for (var j = 0; j < this.tableProductions[i].days.length; j++) {
              this.tableProductions[i].weekOne = this.tableProductions[i].days[0].ticketCount;
              this.tableProductions[i].weekTwo = this.tableProductions[i].days[1].ticketCount;
              this.tableProductionsHeaders[j + 6].text = functions.getDateView(this.tableProductions[i].days[j].date);
            }
          }

          this.tableProductionsTotal.performanceCount = this.tableProductionsTotals.performanceCount;
          this.tableProductionsTotal.capacity = this.tableProductionsTotals.capacity;
          this.tableProductionsTotal.theaters = this.tableProductionsTotals.theaterCount;
          if (this.tableProductionsTotals.days.length > 0) {
            this.tableProductionsTotal.weekOne = this.tableProductionsTotals.days[0].ticketCount;
            this.tableProductionsTotal.weekTwo = this.tableProductionsTotals.days[1].ticketCount;
          }
          this.tableProductionsTotal.mutationNumber = this.tableProductionsTotals.mutationNumber;
          this.tableProductionsTotal.mutationRate = this.tableProductionsTotals.mutationRate != null
            ? parseFloat(this.tableProductionsTotals.mutationRate.toFixed(2))
            : '';
          this.tableProductionsTotal.occupancyRate = this.tableProductionsTotals.occupancyRate != null
            ? parseFloat(this.tableProductionsTotals.occupancyRate.toFixed(2))
            : '';
          this.tableProductionsTotal.ticketPrice = this.tableProductionsTotals.ticketPrice != null
            ? parseFloat(this.tableProductionsTotals.ticketPrice.toFixed(2))
            : '';
          this.tableProductionsTotal.revenueInVat = this.tableProductionsTotals.revenueInVat != null
            ? parseFloat(this.tableProductionsTotals.revenueInVat.toFixed(2))
            : '';

          this.tableProductionsFiltered = this.tableProductions;

          this.filterProductions();
          this.tableProductionsLoading = false;
        })
        .catch((error) => {
          console.log('error ' + error);
          this.tableProductionsLoading = false;
          this.errored = true;
        });
    },
    filterProductions: function () {
      const vm = this;

      if (this.tableProductionsFilter.productionTitle == '') {
        this.tableProductionsFilterActive = false;
      } else {
        this.tableProductionsFilterActive = true;
      }

      this.tableProductionsFiltered = this.tableProductions.filter(function (production) {
        return (
          cleanWords(production.productionTitle).indexOf(cleanWords(vm.tableProductionsFilter.productionTitle)) !== -1
        );
      });

      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearfilterProductions: function () {
      this.tableProductionsFilter.productionTitle = '';
    },
    createExportData: function () {
      const vm = this;
      vm.exportData = [];
      vm.exportDataColumns = [];

      var intervalExport = setInterval(function () {
        if (!vm.tableGenresLoading && !vm.tableProductionsLoading) {
          clearInterval(intervalExport);

          if (vm.dataGenres.length > 0) {
            // create export columns
            for (var i = 1; i < 32; i++) {
              vm.exportDataColumns.push({ label: '', field: 'column' + i });
            }

            // create export data
            let date = new Date();
            vm.exportData.push({
              column1: vm.user.organisationName,
              column3:
                'Gegenereerd op: ' +
                date.getDate() +
                '-' +
                (date.getMonth() + 1) +
                '-' +
                date.getFullYear() +
                ' ' +
                date.getHours() +
                ':' +
                (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()),
            });
            vm.exportData.push({});

            // Genres
            vm.exportData.push({ column3: 'op genre' });
            vm.dataGenres.forEach((item) => {
              vm.exportData.push({
                column2: 'genre',
                column3: '# vrst',
                column4: '# capaciteit',
                column5: '# theaters',
                column6: functions.getDateView(item.days[0].date),
                column7: functions.getDateView(item.days[1].date),
                column8: '# mutatie',
                column9: '% mutatie',
                column10: '% bezet',
                column11: 'gemiddelde kaartprijs',
                column12: 'recette',
              });
              vm.exportData.push({
                column1: vm.user.organisationName,
                column2: item.genreName,
                column3: item.organisation.performanceCount,
                column4: item.organisation.capacity,
                column5: item.organisation.theaterCount,
                column6: item.organisation.days[0].ticketCount,
                column7: item.organisation.days[1].ticketCount,
                column8: item.organisation.mutationNumber,
                column9:
                  item.organisation.mutationRate != null && item.organisation.mutationRate != ''
                    ? parseFloat(item.organisation.mutationRate.toFixed(2))
                    : '',
                column10:
                  item.organisation.occupancyRate != null && item.organisation.occupancyRate != ''
                    ? parseFloat(item.organisation.occupancyRate.toFixed(2))
                    : '',
                column11:
                  item.organisation.ticketPrice != null && item.organisation.ticketPrice != ''
                    ? parseFloat(item.organisation.ticketPrice.toFixed(2))
                    : '',
                column12:
                  item.organisation.revenueInVat != null && item.organisation.revenueInVat != ''
                    ? parseFloat(item.organisation.revenueInVat.toFixed(2))
                    : '',
              });
              vm.exportData.push({
                column1: 'landelijk',
                column2: item.genreName,
                column3: item.national.performanceCount,
                column4: item.national.capacity,
                column5: item.national.theaterCount,
                column6: item.national.days[0].ticketCount,
                column7: item.national.days[1].ticketCount,
                column8: item.national.mutationNumber,
                column9:
                  item.national.mutationRate != null && item.national.mutationRate != ''
                    ? parseFloat(item.national.mutationRate.toFixed(2))
                    : '',
                column10:
                  item.national.occupancyRate != null && item.national.occupancyRate != ''
                    ? parseFloat(item.national.occupancyRate.toFixed(2))
                    : '',
                column11:
                  item.national.ticketPrice != null && item.national.ticketPrice != ''
                    ? parseFloat(item.national.ticketPrice.toFixed(2))
                    : '',
                column12:
                  item.national.revenueInVat != null && item.national.revenueInVat != ''
                    ? parseFloat(item.national.revenueInVat.toFixed(2))
                    : '',
              });
              vm.exportData.push({
                column1: 'aandeel %',

                column3:
                  item.performanceCountShare != null && item.performanceCountShare != ''
                    ? parseFloat(item.performanceCountShare.toFixed(2))
                    : '',
                column4:
                  item.capacityShare != null && item.capacityShare != ''
                    ? parseFloat(item.capacityShare.toFixed(2))
                    : '',
                column5:
                  item.theaterCountShare != null && item.theaterCountShare != ''
                    ? parseFloat(item.theaterCountShare.toFixed(2))
                    : '',
                column6:
                  item.days[0].ticketCountShare != null && item.days[0].ticketCountShare != ''
                    ? parseFloat(item.days[0].ticketCountShare.toFixed(2))
                    : '',
                column7:
                  item.days[1].ticketCountShare != null && item.days[1].ticketCountShare != ''
                    ? parseFloat(item.days[1].ticketCountShare.toFixed(2))
                    : '',

                column12:
                  item.revenueInVatShare != null && item.revenueInVatShare != ''
                    ? parseFloat(item.revenueInVatShare.toFixed(2))
                    : '',
              });
              vm.exportData.push({});
            });

            vm.exportData.push({});
          }

          // returns ARRAY --- console.log(vm.tableProductionsFiltered)

          if (vm.tableProductionsFiltered.length > 0) {
            // Productions
            vm.exportData.push({ column3: 'op productie' });
            vm.exportData.push({
              column1: 'Producties',
              column3: '# vrst',
              column4: '# capaciteit',
              column5: '# theaters',
              column6: functions.getDateView(vm.tableProductionsFiltered[0].days[0].date),
              column7: functions.getDateView(vm.tableProductionsFiltered[0].days[1].date),
              column8: '# mutatie',
              column9: '% mutatie',
              column10: '% bezet',
              column11: 'gemiddelde kaartprijs',
              column12: 'recette',
            });

            vm.tableProductionsFiltered.forEach((item) => {
              vm.exportData.push({
                column1: item.productionTitle,
                column2: item.genreName,
                column3: item.performanceCount,
                column4: item.capacity,
                column5: item.theaterCount,
                column6: item.days[0].ticketCount,
                column7: item.days[1].ticketCount,
                column8: item.mutationNumber,
                column9:
                  item.mutationRate != null && item.mutationRate != '' ? parseFloat(item.mutationRate.toFixed(2)) : '',
                column10:
                  item.occupancyRate != null && item.occupancyRate != ''
                    ? parseFloat(item.occupancyRate.toFixed(2))
                    : '',
                column11:
                  item.ticketPrice != null && item.ticketPrice != '' ? parseFloat(item.ticketPrice.toFixed(2)) : '',
                column12:
                  item.revenueInVat != null && item.revenueInVat != '' ? parseFloat(item.revenueInVat.toFixed(2)) : '',
              });
            });

            vm.exportData.push({});
            vm.exportData.push({
              column2: 'totaal',
              column3: vm.tableProductionsTotals.performanceCount,
              column4: vm.tableProductionsTotals.capacity,
              column5: vm.tableProductionsTotals.theaterCount,
              column6: vm.tableProductionsTotals.days[0].ticketCount,
              column7: vm.tableProductionsTotals.days[1].ticketCount,
              column8: vm.tableProductionsTotals.mutationNumber,
              column9:
                vm.tableProductionsTotals.mutationRate != null && vm.tableProductionsTotals.mutationRate != ''
                  ? parseFloat(vm.tableProductionsTotals.mutationRate.toFixed(2))
                  : '',
              column10:
                vm.tableProductionsTotals.occupancyRate != null && vm.tableProductionsTotals.occupancyRate != ''
                  ? parseFloat(vm.tableProductionsTotals.occupancyRate.toFixed(2))
                  : '',
              column11:
                vm.tableProductionsTotals.ticketPrice != null && vm.tableProductionsTotals.ticketPrice != ''
                  ? parseFloat(vm.tableProductionsTotals.ticketPrice.toFixed(2))
                  : '',
              column12:
                vm.tableProductionsTotals.revenueInVat != null && vm.tableProductionsTotals.revenueInVat != ''
                  ? parseFloat(vm.tableProductionsTotals.revenueInVat.toFixed(2))
                  : '',
            });
          }
        }
      }, 100);
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),

    ...mapGetters('storage', ['getFilter']),
  },
  beforeUnmount() {
    this.updateFilter({
      viewName: 'SalesMutationsOveral',
      filterObject: this.filter,
    });
  },
  watch: {
    tableProductionsFilter: {
      handler: 'filterProductions',
      deep: true,
    },
    filter: {
      handler: 'getData',
      deep: true,
    },
  },
};
</script>