<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row
          flex
          align="center"
          justify="space-between"
          class="px-3 mb-4"
        >
          <v-col class="py-3 add-row d-flex justify-sm-space-between align-center">
            <span class="d-none d-sm-flex">Meer cijfers?</span>
            <div>
              <v-btn
                v-if="!this.user.organisationTypeId == 2"
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsSummary' }"
              >
                samenvatting
                <v-icon
                  small
                  right
                  >mdi-chart-bar-stacked</v-icon
                >
              </v-btn>
              <v-btn
                v-else
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsProductions' }"
              >
                producties
                <v-icon
                  small
                  right
                  >mdi-chart-bar-stacked</v-icon
                >
              </v-btn>
              <v-btn
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsPerformances' }"
              >
                voorstellingen
                <v-icon
                  small
                  right
                  >mdi-chart-bar-stacked</v-icon
                >
              </v-btn>
              <v-btn
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsPerformances7days' }"
              >
                voorstellingen laatste 7 dagen
                <v-icon
                  small
                  right
                  >mdi-chart-bar-stacked</v-icon
                >
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="1126"></breadcrumbs-with-help>
        </v-row>

        <div v-if="!tablePerformancesLoading">
          <!-- Table -->
          <v-data-table
            :headers="tablePerformancesHeaders"
            :items="tablePerformances"
            :mobile-breakpoint="0"
            :loading="tablePerformancesLoading"
            :page="tablePerformancesPaging.page"
            :items-per-page="tablePerformancesPaging.itemsPerPage"
            hide-default-footer
            @update:current-items="tablePerformancesPaging.pageCount = Math.ceil(tablePerformances.length / tablePerformancesPaging.itemsPerPage)"
            @update:items-per-page="tablePerformancesPaging.pageCount = Math.ceil(tablePerformances.length / tablePerformancesPaging.itemsPerPage)"
            class="table-expandr"
            item-value="key"
            single-expand
            show-expand
            fixed-header
            :height="tablePerformances.length > 10 ? '55vh' : ''"
          >
            <template v-slot:[`item.startDate`]="{ item }">
              <span>{{ item.startDateDate_formatted }}</span>
            </template>

            <template v-slot:[`item.capacity`]="{ item }">
              <span>{{ formatThousandsWithDot(item.capacity) }}</span>
            </template>

            <template v-slot:expanded-row="{ columns, item }">
              <td
                :colspan="columns.length"
                class="pa-0"
              >
                <v-data-table
                  :headers="tablePerformancesDetailsHeaders"
                  :items="item.details"
                  :mobile-breakpoint="0"
                  hide-default-footer
                  :items-per-page="-1"
                  class="table-action"
                >
                  <template v-slot:[`item.type`]="{ item }">
                    <strong>{{ item.type }}</strong>
                  </template>
                </v-data-table>
              </td>
            </template>


          </v-data-table>

          <!-- Table settings -->
          <table-settings :items="tablePerformances" :paging="tablePerformancesPaging"
            :export-columns="downloadExportColumns" :file-name="'dip-verkoopmutaties-voorstellingen-laatste-10-weken'"
            :sheet-name="'voorstellingen laatste 10 weken'" :show-excel-button="true">
          </table-settings>
        </div>
        <div v-else>
          <div class="text-center progress-circular">
            <v-progress-circular
              :size="100"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <h4 class="text-primary mt-5">Een moment geduld aub, je gegevens worden berekend. Dit kan even duren.</h4>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import TableSettings from '../../components/TableSettings';
import functions from '../../services/functions.service';

export default {
  name: 'SalesMutationsPerformances10weeks',
  components: {
    BreadcrumbsWithHelp,
    TableSettings
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'verkoopmutaties',
          disabled: false,
          href: '/verkoopmutaties',
          bold: false,
        },
        {
          text: 'voorstellingen laatste 10 weken',
          disabled: true,
          href: '',
          bold: true,
        },
      ],
      timeStamp: '',
      tablePerformances: [],
      performances: [],
      tablePerformancesLoading: true,
      tablePerformancesHeaders: [
        { title: 'productienummer', key: 'productionNumber', width: '10%' },
        { title: 'voorstellingsnr', key: 'performanceNumber', width: '10%' },
        { title: 'speeldatum', key: 'startDate', width: '10%' },
        { title: 'productie', key: 'productionTitle', width: '15%' },
        { title: 'genre', key: 'genreName', width: '15%' },
        { title: 'capaciteit', key: 'capacity' },
        { title: '', key: 'data-table-expand', align: 'end' },
      ],
      tablePerformancesPaging: {
        page: 1,
        pageCount: 25,
        itemsPerPage: 25,
      },
      tablePerformancesDetailsHeaders: [
        { title: '', key: 'type' },
        { title: 'voorverkoop', key: 'preSale' },
        { title: 'week 1', key: 'week1' },
        { title: 'week 2', key: 'week2' },
        { title: 'week 3', key: 'week3' },
        { title: 'week 4', key: 'week4' },
        { title: 'week 5', key: 'week5' },
        { title: 'week 6', key: 'week6' },
        { title: 'week 7', key: 'week7' },
        { title: 'week 8', key: 'week8' },
        { title: 'week 9', key: 'week9' },
        { title: 'week 10', key: 'week10' },
      ],
      exportPerformances: [],
      exportPerformancesColumns: [],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('SalesMutations');
    this.getPerformances();
  },
  methods: {
    getEuroSign: function (value) {
      return functions.getEuroSignView(value);
    },
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getPercentageView: function (value) {
      return functions.getPercentageView(value);
    },
    calculateArraySum: function (items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    },
    getPerformances: function () {
      const vmLegacy = { $set: (o, k, v) => o[k] = v };
      this.tablePerformances = [];

      ApiService.get('/SalesMutations/GetPeformancesForLastTenWeeks')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          this.tablePerformances = response.data.result;

          for (var i = 0; i < this.tablePerformances.length; i++) {
            vmLegacy.$set(
              this.tablePerformances[i],
              'startDateDate_formatted',
              functions.getDateView(this.tablePerformances[i].startDate)
            );
            vmLegacy.$set(this.tablePerformances[i], 'key', i);

            var performanceDetails = [
              {
                type: 'aantal',
                preSale: this.tablePerformances[i].presale.ticketCount,
              },
              {
                type: 'recette',
                preSale: functions.getEuroSignView(this.tablePerformances[i].presale.revenueInVat),
              },
              {
                type: 'verschil',
              },
            ];
            for (var j = 0; j < this.tablePerformances[i].weeks.length; j++) {
              performanceDetails[0]['week' + (j + 1)] = this.formatThousandsWithDot(
                this.tablePerformances[i].weeks[j].ticketCount
              );
              performanceDetails[1]['week' + (j + 1)] = functions.getEuroSignView(
                this.tablePerformances[i].weeks[j].revenueInVat
              );
              performanceDetails[2]['week' + (j + 1)] = this.formatThousandsWithDot(
                this.tablePerformances[i].weeks[j].mutation
              );
              this.tablePerformancesDetailsHeaders[j + 2].text = 'week' + this.tablePerformances[i].weeks[j].number;
            }
            this.tablePerformances[i].details = performanceDetails;
          }
          this.tablePerformancesLoading = false;
          this.createExportData();
        })
        .catch((error) => {
          //eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    createExportData: function () {
      if (this.tablePerformances.length > 0) {
        this.exportPerformancesColumns = [];
        this.exportPerformances = [];

        // create export columns
        for (var i = 1; i < 45; i++) {
          this.exportPerformancesColumns.push({ label: '', field: 'column' + i });
        }

        // create export data
        let date = new Date();
        this.exportPerformances.push({
          column1: this.user.organisationName,
          column3:
            'Gegenereerd op: ' +
            date.getDate() +
            '-' +
            (date.getMonth() + 1) +
            '-' +
            date.getFullYear() +
            ' ' +
            date.getHours() +
            ':' +
            (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()),
        });
        this.exportPerformances.push({
          column9: 'voorverkoop t/m ' + functions.getDateView(this.tablePerformances[0].presale.date),
          column10: 'voorverkoop t/m ' + functions.getDateView(this.tablePerformances[0].presale.date),
        });

        this.exportPerformances.push({
          column1: 'productienummer',
          column2: 'voorstellingsnr',
          column3: 'datum',
          column4: 'productie',

          column5: 'impresariaat',
          column6: 'producent',

          column7: 'genre',
          column8: '# capaciteit',

          column9: 'aantal',
          column10: 'recette',
        });

        var weekColumsTotal = 10 * 3;
        var weekCount = 0;
        for (var y = 0; y < weekColumsTotal; y++) {
          var columnNummber = y + 11;
          var columnTitle = '';

          var weekPosition = (y + 1) % 3;
          if (weekPosition == 1) {
            columnTitle = 'aantal';
          } else if (weekPosition == 2) {
            columnTitle = 'recette';
          } else if (weekPosition == 0) {
            columnTitle = 'verschil';
          }
          this.exportPerformances[1]['column' + columnNummber] =
            'week ' + this.tablePerformances[0].weeks[weekCount].number;
          this.exportPerformances[2]['column' + columnNummber] = columnTitle;
          if (weekPosition == 0) {
            weekCount++;
          }
        }

        this.tablePerformances.forEach((item) => {
          this.exportPerformances.push({
            column1: item.productionNumber,
            column2: item.performanceNumber,
            column3: item.startDateDate_formatted,
            column4: item.productionTitle,

            column5: item.agencyName,
            column6: item.producerName,

            column7: item.genreName,

            column8: item.capacity,

            column9: item.presale.ticketCount,
            column10:
              item.presale.revenueInVat != null && item.presale.revenueInVat != ''
                ? parseFloat(item.presale.revenueInVat.toFixed(2))
                : '',

            column11: item.weeks[0].ticketCount,
            column12:
              item.weeks[0].revenueInVat != null && item.weeks[0].revenueInVat != ''
                ? parseFloat(item.weeks[0].revenueInVat.toFixed(2))
                : '',
            column13: item.weeks[0].mutation,

            column14: item.weeks[1].ticketCount,
            column15:
              item.weeks[1].revenueInVat != null && item.weeks[1].revenueInVat != ''
                ? parseFloat(item.weeks[1].revenueInVat.toFixed(2))
                : '',
            column16: item.weeks[1].mutation,

            column17: item.weeks[2].ticketCount,
            column18:
              item.weeks[2].revenueInVat != null && item.weeks[2].revenueInVat != ''
                ? parseFloat(item.weeks[2].revenueInVat.toFixed(2))
                : '',
            column19: item.weeks[2].mutation,

            column20: item.weeks[3].ticketCount,
            column21:
              item.weeks[3].revenueInVat != null && item.weeks[3].revenueInVat != ''
                ? parseFloat(item.weeks[3].revenueInVat.toFixed(2))
                : '',
            column22: item.weeks[3].mutation,

            column23: item.weeks[4].ticketCount,
            column24:
              item.weeks[4].revenueInVat != null && item.weeks[4].revenueInVat != ''
                ? parseFloat(item.weeks[4].revenueInVat.toFixed(2))
                : '',
            column25: item.weeks[4].mutation,

            column26: item.weeks[5].ticketCount,
            column27:
              item.weeks[5].revenueInVat != null && item.weeks[5].revenueInVat != ''
                ? parseFloat(item.weeks[5].revenueInVat.toFixed(2))
                : '',
            column28: item.weeks[5].mutation,

            column29: item.weeks[6].ticketCount,
            column30:
              item.weeks[6].revenueInVat != null && item.weeks[6].revenueInVat != ''
                ? parseFloat(item.weeks[6].revenueInVat.toFixed(2))
                : '',
            column31: item.weeks[6].mutation,

            column32: item.weeks[7].ticketCount,
            column33:
              item.weeks[7].revenueInVat != null && item.weeks[6].revenueInVat != ''
                ? parseFloat(item.weeks[6].revenueInVat.toFixed(2))
                : '',
            column34: item.weeks[7].mutation,

            column35: item.weeks[8].ticketCount,
            column36:
              item.weeks[8].revenueInVat != null && item.weeks[6].revenueInVat != ''
                ? parseFloat(item.weeks[6].revenueInVat.toFixed(2))
                : '',
            column37: item.weeks[8].mutation,

            column38: item.weeks[9].ticketCount,
            column39:
              item.weeks[9].revenueInVat != null && item.weeks[6].revenueInVat != ''
                ? parseFloat(item.weeks[6].revenueInVat.toFixed(2))
                : '',
            column40: item.weeks[9].mutation,
          });
        });
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
  },
  watch: {
    tablePerformancesFilter: {
      handler: 'filterProductions',
      deep: true,
    },
  },
};
</script>