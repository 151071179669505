<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="28"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- Page info first row -->
          <v-row class="flex align-center pb-2" no-gutters>
            <v-col cols="12">
              <v-col cols="12">
                <!-- Form -->
                <v-form @submit.prevent class="d-flex flex-column" ref="form">
                  <v-col cols="12">
                    <v-card title="gegevens kenmerk">
                      <v-divider></v-divider>
                      <v-card-text>
                        <v-row class="mt-0">
                          <v-col cols="12" class="py-1">
                            <v-text-field class="text-primary" label="naam" v-model="addCharacteristicForm.name"
                              :rules="formValidationRules.required"></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" class="py-1">
                            <v-text-field class="text-primary" label="code" v-model="addCharacteristicForm.code"
                              :rules="formValidationRules.required"></v-text-field>
                          </v-col>
                        </v-row>

                        <v-alert type="error" variant="tonal" v-if="formError != ''">{{ formError }}</v-alert>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <div>
                          <v-btn-save @click="submit" :loading="formLoading">Opslaan</v-btn-save>
                        </div>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-form>
              </v-col>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'AdminCharacteristicsAdd',
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      isEditing: false,
      formError: '',
      formLoading: false,
      addCharacteristicForm: {
        name: '',
        code: '',
      },
      formValidationRules: {},
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'kenmerken',
          disabled: false,
          href: '/admin/kenmerken',
          bold: false,
        },
        {
          text: 'toevoegen',
          disabled: false,
          href: '/admin/kenmerken/add',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    async submit() {
      this.formError = '';
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const form = this.addCharacteristicForm;
        ApiService.post('/Characteristics/Add', {
          name: form.name,
          code: form.code,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        await this.$refs.form.validate();
      }
    },
  },
  computed: {},
  watch: {
    addCharacteristicForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
