<template>
  <div>
    <h2 class="text-primary mb-2">wat houdt de financiële afspraak in?</h2>

    <!-- Form -->
    <v-form
      @submit.prevent
      v-model="formValid"
      class="d-flex flex-column"
      ref="form"
    >
      <v-card
        class="py-6 pb-0 px-3 mb-10"
        flat
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="py-1"
            >
              <v-text-field
                class="text-primary"
                label="auteursrechten"
                suffix="%"
                v-model="form.copyrightPercentage"
                :rules="formValidationRules.numberDecimalRequired"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
              class="py-1"
            >
              <v-text-field
                class="text-primary"
                label="uitkoopsom totaal (excl. BTW)"
                prefix="€"
                v-model="form.buyoutAmount"
                :rules="formValidationRules.valutaRequired"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import functions from '../../../../services/functions.service';

export default {
  props: ['form'],
  data() {
    return {
      formValid: false,
      formError: '',
      formValidationRules: {},
    };
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
  },
  methods: {
    validateForm() {
      this.$refs.form.validate();
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit('form', this.form);
      },
    },
    formValid: function () {
      this.$emit('valid', this.formValid);
    },
  },
};
</script>
