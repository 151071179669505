<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="9"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- Form -->
          <v-form @submit.prevent class="d-flex flex-column" ref="form">
            <v-col cols="12">
              <v-card title="gegevens theater">
                <v-divider></v-divider>
                <v-card-text>
                  <v-row class="mt-0">
                    <!-- Name -->
                    <v-col cols="12" class="py-1">
                      <v-text-field class="text-primary" label="naam" v-model="addTheaterForm.name"
                        :rules="formValidationRules.required"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <!-- Geactiveerd -->
                    <v-col cols="12" class="py-0">
                      <v-checkbox id="geactiveerd" v-model="addTheaterForm.isMember" label="geactiveerd"
                        class="checkbox text-primary ma-0"></v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row>
                    <!-- Selecteer datum -->
                    <v-col cols="12" class="py-1">
                      <date-picker :passed-date="addTheaterForm.isMemberSince"
                        @formatedDate="addTheaterForm.isMemberSince = $event" rules="dateRequired"></date-picker>
                    </v-col>
                  </v-row>

                  <!-- Contact e-mailadres -->
                  <v-row>
                    <v-col cols="12" class="py-1">
                      <v-text-field class="text-primary" label="contact e-mailadres" type="email"
                        v-model="addTheaterForm.contactEmailAddress"></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- Branchevereniging bijwerken -->
                  <v-row>
                    <v-col cols="12" class="py-1">
                      <v-autocomplete id="selecteer_branchevereniging" label="selecteer branchevereniging"
                        v-model="addTheaterForm.branchAssociationId" :items="branchAssociations"
                        clearable></v-autocomplete>
                    </v-col>
                  </v-row>

                  <!-- Ticketing systeem bijwerken -->
                  <v-row>
                    <v-col cols="12" class="py-1">
                      <v-autocomplete id="selecteer_ticketing_systeem" label="selecteer ticketing systeem"
                        v-model="addTheaterForm.ticketSystemId" :items="ticketSystems" clearable></v-autocomplete>
                    </v-col>
                  </v-row>

                  <!-- Kaartstanden bijwerken -->
                  <v-row>
                    <v-col cols="12" class="py-1">
                      <v-autocomplete id="kaartstanden_bijwerken" label="kaartstanden bijwerken"
                        v-model="addTheaterForm.ticketLinkType" :items="ticketLinkTypeOptions"
                        :rules="formValidationRules.required"></v-autocomplete>
                    </v-col>
                  </v-row>

                  <!-- Logo afbeelding -->
                  <v-row>
                    <v-col cols="12" class="py-1">
                      <v-file-input prepend-icon="" name="theater_logo" label="logo afbeelding uploaden" variant="outlined"
                        v-model="addTheaterForm.logoImage" accept="image/png, image/jpeg, image/bmp"
                        density="compact"></v-file-input>
                    </v-col>
                  </v-row>

                  <!-- Ticketmatic ClientName -->
                  <v-row v-if="addTheaterForm.ticketLinkType == 3">
                    <v-col cols="12" class="py-1">
                      <v-text-field class="text-primary" label="ticketmatic clientname"
                        v-model="addTheaterForm.ticketClientName" :rules="formValidationRules.required"></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- Voorwaarden DIP geaccepteerd -->
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-checkbox name="voorwaarden_geaccepteerd" label="voorwaarden DIP geaccepteerd"
                        v-model="addTheaterForm.agreedToTerms" class="checkbox text-primary ma-0"></v-checkbox>
                    </v-col>
                  </v-row>

                  <!-- Publieksmonitor tonen -->
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-checkbox name="toon_publieksmonitor" label="toon publieksmonitor"
                        v-model="addTheaterForm.hasPublicMonitorAccess" class="checkbox text-primary ma-0"></v-checkbox>
                    </v-col>
                  </v-row>

                  <!-- Benchmark tonen -->
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-checkbox name="toon_benchmark" label="toon benchmark" v-model="addTheaterForm.hasBenchmarkAccess"
                        class="checkbox text-primary ma-0"></v-checkbox>
                    </v-col>
                  </v-row>


                  <!-- Borderellen tonen -->
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-checkbox name="toon borderellen" label="toon borderellen"
                        v-model="addTheaterForm.hasBorderellenAccess" class="checkbox text-primary ma-0"></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card title="factuur instructies">
                <v-divider></v-divider>
                <v-card-text>
                  <v-row class="mt-0">
                    <v-col cols="12" class="py-0">
                      <v-textarea class="text-primary" label="factuur instructies" v-model="addTheaterForm.invoiceInstructions"></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card title="adres">
                <v-divider></v-divider>
                <v-card-text>
                  <v-row class="mt-0">
                    <!-- Straat -->
                    <v-col cols="8" class="py-1">
                      <v-text-field label="straat" v-model="addTheaterForm.street"
                        :rules="formValidationRules.required"></v-text-field>
                    </v-col>

                    <v-col cols="4" class="py-1">
                      <!-- Nummer -->
                      <v-text-field label="huisnummer" v-model="addTheaterForm.number"
                        :rules="formValidationRules.required"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" class="py-1">
                      <!-- Postcode -->
                      <v-text-field label="postcode" v-model="addTheaterForm.zipCode"
                        :rules="formValidationRules.zipCodeRequired"></v-text-field>
                    </v-col>
                    <v-col cols="8" class="py-1">
                      <!-- Plaats -->
                      <v-text-field label="plaats" v-model="addTheaterForm.city"
                        :rules="formValidationRules.required"></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- Provincie -->
                  <v-row>
                    <v-col cols="12" class="py-1">
                      <v-autocomplete id="provincie" label="provincie" v-model="addTheaterForm.province"
                        :items="provincieOptions" :rules="formValidationRules.required"></v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-alert type="error" variant="tonal" v-if="formError != ''">{{ formError }}</v-alert>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <div>
                    <v-btn-save type="submit" @click="submit" :loading="formLoading">Opslaan</v-btn-save>
                  </div>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp';
import DatePicker from '@/components/DatePicker';

export default {
  name: 'AdminTheatersAdd',
  components: {
    BreadcrumbsWithHelp,
    DatePicker,
  },
  data() {
    return {
      isEditing: false,
      formError: '',
      formLoading: false,
      addTheaterForm: {
        name: '',
        isMember: false,
        isMemberSince: '',
        branchAssociationId: null,
        ticketSystemId: null,
        ticketLinkType: null,
        ticketClientName: '',
        agreedToTerms: false,
        hasPublicMonitorAccess: false,
        hasBenchmarkAccess: false,
        street: '',
        number: '',
        zipCode: '',
        city: '',
        province: null,
        logoImage: null,
      },
      provincieOptions: [],
      branchAssociations: [],
      ticketSystems: [],
      ticketLinkTypeOptions: [
        { value: 1, title: 'handmatig' },
        { value: 2, title: 'api' },
        { value: 3, title: 'ticketmatic' },
      ],
      formValidationRules: {},
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'theaters',
          disabled: false,
          href: '/admin/theaters',
          bold: false,
        },
        {
          text: 'toevoegen',
          disabled: false,
          href: '/admin/theaters/add',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.getProvinces();
    this.getBranchAssociations();
    this.getTicketSystems();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getProvinces: function () {
      ApiService.get('/Provinces/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.provincieOptions.push({
              value: element.id,
              title: element.name,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    getBranchAssociations: function () {
      ApiService.get('/BranchAssociations/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.branchAssociations.push({
              value: element.id,
              title: element.name,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    getTicketSystems: function () {
      ApiService.get('/TicketSystems/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.ticketSystems.push({
              value: element.id,
              title: element.name,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    async submit() {
      this.formError = '';
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const form = this.addTheaterForm;

        await ApiService.post('/Theaters/Add', {
          name: form.name,
          street: form.street,
          number: form.number,
          zipCode: form.zipCode,
          city: form.city,
          provinceId: form.province,
          isMember: form.isMember,
          isMemberSince: form.isMemberSince == '' ? null : functions.getDateDatabase(form.isMemberSince),
          branchAssociationId: form.branchAssociationId != '' ? form.branchAssociationId : null,
          ticketSystemId: form.ticketSystemId != '' ? form.ticketSystemId : null,
          ticketLinkType: form.ticketLinkType,
          ticketClientName: form.ticketLinkType == 3 ? form.ticketClientName : '',
          agreedToTerms: form.agreedToTerms,
          hasPublicMonitorAccess: form.hasPublicMonitorAccess,
          hasBenchmarkAccess: form.hasBenchmarkAccess,
          hasBorderellenAccess: form.hasBorderellenAccess,
          invoiceInstructions: form.invoiceInstructions,
          contactEmailAddress: form.contactEmailAddress,
          logoImage: (form.logoImage) ? await functions.getBase64(form.logoImage) : null,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();

            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {},
  watch: {
    addTheaterForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}

.v-date-picker-table {
  height: 100%;
}
</style>
