<template>
  <div>
    <!-- Prices -->
    <v-card
      class="py-6 px-3 pb-4 mb-4"
      flat
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            class="py-0 mb-8"
          >
            <p
              :class="contract.isTicketPriceRejected ? 'error--text ' : 'text-primary '"
              class="font-weight-medium mb-0"
            >
              afrekenprijzen <br /><i>afrekenprijzen zijn exclusief theatertoeslag en exclusief toeslagen</i>
            </p>
          </v-col>

          <v-col
            v-for="ticketprice in ticketprices"
            :key="ticketprice.id"
            cols="12"
            sm="6"
            md="3"
            class="py-0 ma-0"
          >
            <v-col
              v-if="ticketprice.previousPrice && ticketprice.price != ticketprice.previousPrice"
              class="pa-0 ma-0 pb-3 mb-6 border-bottom"
            >
              <div class="d-flex align-center justify-space-between">
                <h3
                  :class="
                    contract.isTicketPriceRejected ? 'error--text ' : 'text-primary text-decoration-line-through '
                  "
                  class="mb-0"
                >
                  {{ ticketprice.previousPrice }}
                </h3>
                <p
                  :class="
                    contract.isTicketPriceRejected ? 'error--text ' : 'text-primary text-decoration-line-through '
                  "
                  class="font-weight-medium mb-0"
                >
                  {{ ticketprice.id.toString() }}
                </p>
              </div>
              <div class="contract-diff d-flex align-center justify-space-between">
                <h3 class="prev-approved--text mb-0">{{ ticketprice.price }}</h3>
                <p class="prev-approved--text font-weight-medium mb-0">{{ ticketprice.id.toString() }}</p>
              </div>
            </v-col>

            <v-col
              v-else
              class="pa-0 ma-0 pb-3 mb-6 d-inline-flex align-center justify-space-between border-bottom"
            >
              <h3
                :class="contract.isTicketPriceRejected ? 'error--text ' : 'text-primary '"
                class="mb-0"
              >
                {{ ticketprice.price }}
              </h3>
              <p
                :class="contract.isTicketPriceRejected ? 'error--text ' : 'text-primary '"
                class="font-weight-medium mb-0"
              >
                {{ ticketprice.id.toString() }}
              </p>
            </v-col>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import functions from '../../../../services/functions.service';

export default {
  props: ['contract'],
  methods: {
    getEuroSignView(value) {
      return functions.getEuroSignView(parseFloat(value));
    },
  },
  computed: {
    ...mapState('auth', ['user']),

    ticketprices() {
      let prices = [];

      for (var i = 1; i < 11; i++) {
        var priceCheck = this.contract['ticketPrice' + i];

        if (priceCheck != 0) {
          var price = parseFloat(functions.getEuroCurrencyDatabase(priceCheck));
          price = this.getEuroSignView(functions.getEuroCurrencyDatabase(price));

          if (this.contract.previousApproved != null) {
            var previousPriceCheck = this.getEuroSignView(this.contract.previousApproved['ticketPrice' + i]);
          }

          prices.push({
            id: i,
            price: price,
            previousPrice: previousPriceCheck != 0 ? previousPriceCheck : 0,
          });
        }
      }
      return prices;
    },
  },
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid #e1e7f2;
}
</style>
