<template>
  <v-col md="12">
    <v-card
      class="mb-18"
      flat
      height="100%"
    >
      <v-row
        class="py-5 px-3 pb-0"
        no-gutters
      >
        <v-col
          cols="12"
          md="6"
          class="py-0"
        >
          <p class="text-primary font-weight-medium mb-0">productienummer</p>
          <h3 class="text-primary border-bottom pb-0 mb-4">
            {{ production.id }}
          </h3>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="py-0"
        >
          <p class="text-primary font-weight-medium mb-0">genre</p>
          <h3 class="text-primary border-bottom pb-0 mb-4">
            {{ production.genre }}
          </h3>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="py-0"
        >
          <p class="text-primary font-weight-medium mb-0">productie naam</p>
          <h3 class="text-primary border-bottom pb-0 mb-4">
            {{ production.title }}
          </h3>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="py-0"
        >
          <p class="text-primary font-weight-medium mb-0">overlap</p>
          <h3 class="text-primary border-bottom pb-0 mb-4">
            {{ production.overlapCount }}
          </h3>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    production: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filterWidgets: {},
    };
  },
};
</script>
