<template>
  <v-col
    :cols="colsMobile"
    :sm="colsTablet"
    :md="colsDesktop"
    class="d-flex"
  >
    <v-card
      class="px-4 py-0 mb-1"
      flat
      width="100%"
      :loading="capacityLoading"
    >
      <v-container>
        <v-row class="block-head">
          <v-col
            cols="12"
            class="d-flex align-center justify-space-between"
          >
            <p class="text-primary font-weight-medium mb-0">capaciteit</p>
            <Help
              v-if="helpId"
              :helpId="helpId"
            />
          </v-col>
        </v-row>

        <v-row
          flex
          align="center"
          justify="center"
          class="py-2 flex-column flex-grow-1"
        >
          <template v-if="chart.loaded && capacity !== 0">
            <v-col cols="11">
              <h2 class="text-primary">totale capaciteit: {{ formatThousandsWithDot(capacity) }}</h2>
            </v-col>

            <v-col cols="12">
              <Doughnut
                v-if="chart.loaded"
                :chartData="chart.data"
                :chartOptions="chart.options"
              />
            </v-col>
          </template>

          <template v-else>
            <p class="text-primary font-weight-medium mb-0">geen data beschikbaar</p>
          </template>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import Help from '../Help.vue';
import { Doughnut } from 'vue-chartjs';

export default {
  props: {
    helpId: {
      type: [String, Number],
      required: true,
    },
    colsMobile: {
      type: Number,
      default: 12,
    },
    colsTablet: {
      type: Number,
      default: 6,
    },
    colsDesktop: {
      type: Number,
      default: 4,
    },
    filterPeriod: {
      type: Object,
      default: () => ({ from: '', to: '' }),
    },
  },
  components: {
    Help,
    Doughnut,
  },
  data() {
    return {
      capacity: 0,
      chart: {
        loaded: false,
        data: {},
        options: {},
      },
      capacityLoading: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    formatThousandsWithDot(value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getData() {
      this.chart.loaded = false;
      this.capacityLoading = true;

      ApiService.get('/Dashboard/GetTicketSales', {
          StartDate: this.filterPeriod.from,
          EndDate: this.filterPeriod.to,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          const data = response.data.result;

          this.capacity = data.capacity;

          const labels = data.tickets.map((e) => e.type);
          const dataCapacity = data.tickets.map((e) => e.count);
          const dataCapacityPercentage = data.tickets.map((e) => e.rate);

          // Set chart options
          this.chart.options = {
            responsive: true,
            maintainAspectRatio: true,
            layout: {
              padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
              },
            },
            cutout: '60%',
            textCenter: '',
            plugins: {
              datalabels: {
                color: '#fff',
              },
              legend: {
                display: true,
                position: 'top',
              },
              tooltip: {
                enabled: true,
                intersect: true,
                mode: 'index',
                position: 'nearest',
                callbacks: {
                  label: (tooltipItem) => {
                    const dataPercentage = tooltipItem.dataset.dataPercentages[tooltipItem.dataIndex];
                    const data = tooltipItem.dataset.data[tooltipItem.dataIndex];
                    return `${tooltipItem.label}: ${dataPercentage}% / ${data}`;
                  },
                },
              },
            },
          };

          // Set chart data
          this.chart.data = {
            labels: labels,
            datasets: [
              {
                data: dataCapacity,
                dataPercentages: dataCapacityPercentage,
                backgroundColor: ['#fff453', '#0abfb8', '#021e73', '#D92853', '#782562', '#FF8F2E', '#EAEAEA'],
                borderWidth: 0,
                weight: 1,
                datalabels: {
                  color: [
                    'transparent',
                    'transparent',
                    'transparent',
                    'transparent',
                    'transparent',
                    'transparent',
                    'transparent',
                  ],
                },
              },
            ],
          };

          // Load chart
          this.chart.loaded = true;
          this.capacityLoading = false;
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          this.capacityLoading = false;
        });
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    filterPeriod: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>