<template>
  <v-row class="text-center pt-2" align="center">
    <!-- Table totaal -->
    <v-col cols="12">
      <p class="font-weight-medium text-left caption text-primary ma-0 pa-0">
        totaal: {{ items.length }}
      </p>
    </v-col>

    <!-- Table buttons items per page -->
    <v-col cols="12" sm="4" class="d-flex align-center justify-center justify-sm-start">
      <v-btn v-if="items.length > 25" @click="paging.itemsPerPage = 25"
        :color="paging.itemsPerPage == 25 ? 'primary' : '#fff'" height="45" width="45" elevation="0" size="small"
        class="pa-0 mr-1 font-weight-bold body-2">25</v-btn>
      <v-btn v-if="items.length > 25" @click="paging.itemsPerPage = 50"
        :color="paging.itemsPerPage == 50 ? 'primary' : '#fff'" height="45" width="45" elevation="0" size="small"
        class="pa-0 mr-1 font-weight-bold body-2">50</v-btn>
      <v-btn v-if="items.length > 50" @click="paging.itemsPerPage = 100"
        :color="paging.itemsPerPage == 100 ? 'primary' : '#fff'" height="45" width="45" elevation="0" size="small"
        class="pa-0 mr-1 font-weight-bold body-2">100</v-btn>
    </v-col>

    <!-- Table pagination -->
    <v-col cols="12" sm="4">
      <v-pagination v-if="paging.pageCount > 1" v-model="paging.page" :length="paging.pageCount" :total-visible="7"
        :elevation="0" variant="elevated" active-color="primary" size="small"></v-pagination>
    </v-col>

    <!-- Table download -->
    <v-col cols="12" sm="4" class="d-flex justify-center justify-sm-end">
      <v-btn v-if="showExcelButton" color="primary" rounded depressed class="font-weight-bold text-lowercase">
        <vue-excel-xlsx class="v-btn font-weight-bold text-lowercase" 
          :data="exportSource" 
          :columns="exportColumns"
          :file-name="fileName" 
          :sheet-name="sheetName">
          exporteer selectie <v-icon right>mdi-file-move</v-icon>
        </vue-excel-xlsx>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TableSettings',
  props: {
    items: {
      type: Array,
      required: true,
    },
    paging: {
      type: Object,
      required: true,
    },
    exportData: {
      type: Array,
      required: false,
    },
    exportColumns: {
      type: Array,
      required: false,
    },
    fileName: {
      type: String,
      required: false,
    },
    sheetName: {
      type: String,
      required: false,
    },
    showExcelButton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    exportSource() {
      if (this.exportData) {
        return this.exportData;
      } else {
        return this.items;
      }
    }
  },
};
</script>

<style scoped>
/* Add any scoped styles here if necessary */
</style>