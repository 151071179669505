<template>
  <v-col
    :cols="colsMobile"
    :sm="colsTablet"
    :md="colsDesktop"
    class="d-flex"
  >
    <v-card
      class="px-4 py-0 mb-1"
      flat
      width="100%"
      :loading="performancesThisWeekLoading"
    >
      <v-container>
        <v-row class="block-head">
          <v-col
            cols="12"
            class="d-flex align-center justify-space-between"
          >
            <p class="text-primary font-weight-medium mb-0">voorstellingen deze week</p>
            <help
              v-if="helpId != ''"
              bg-color=""
              :help-id="helpId"
            ></help>
          </v-col>
        </v-row>

        <v-row
          flex
          justify="center"
          class="py-2 flex-grow-1"
        >
          <template v-if="chart.loaded">
            <v-col
              cols="12"
              class="pa-0"
            >
              <v-list
                flat
                v-if="performances.length"
              >
                <v-list-item
                  v-for="(item, i) in performances.slice(0, 5)"
                  class="tooltip-container"
                  :key="i"
                  link
                  :to="{
                    name: 'SalesMonitorPerformancesDetail',
                    params: {
                      productionNumber: item.productionNumber,
                      performanceId: item.id,
                    },
                  }"
                  dense
                >
                  <span class="tooltip">
                    <table>
                      <tbody>
                        <tr>
                          <th scope="row">%bezet</th>
                          <td>
                            {{
                              item.occupancyRate === 0
                                ? item.occupancyRate + '%'
                                : getPercentageView(item.occupancyRate)
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">%bezet landelijk</th>
                          <td>
                            {{
                              item.totalOccupancyRate === 0
                                ? item.totalOccupancyRate + '%'
                                : getPercentageView(item.totalOccupancyRate)
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </span>
                    <v-list-item-title class="text-primary">
                      {{ item.productionTitle }}
                    </v-list-item-title>
                    <div class="my-1 v-list-item-subtitle">
                      {{ item.startDate_formatted }}
                    </div>
                    <v-progress-linear
                      class="custom_progress my-2"
                      bg-color="grey-darken-2"
                      height="20"
                      width="200"
                      rounded="true"
                      :model-value="item.occupancyRate"
                      color="#0abfb8"
                    >
                      <div
                        class="progress-text"
                        v-if="item.occupancyRate != 0"
                      >
                        bezettingspercentage
                        {{ getPercentageView(item.occupancyRate) }}
                      </div>
                      <div
                        class="target"
                        :style="{
                          left: item.totalOccupancyRate + '%',
                        }"
                        v-if="item.totalOccupancyRate > 0"
                      ></div>
                      <div
                        class="progress-text"
                        v-else
                      >
                        bezettingspercentage 0%
                      </div>
                    </v-progress-linear>
                  <template v-slot:append>
                    <v-icon color="primary">mdi-chevron-right</v-icon>
                   </template>
                </v-list-item>
              </v-list>

              <v-list v-else>
                <v-list-item>
                  <!-- <v-list-item-content> -->
                    <div class="text-primary v-list-item-subtitle">
                      er zijn geen voorstellingen deze week
                    </div>
                  <!-- </v-list-item-content> -->
                </v-list-item>
              </v-list>

              <div
                class="col col-12 pb-0"
                v-if="performances.length > 5"
              >
                <v-btn
                  rounded
                  small
                  elevation="0"
                  :to="{ name: 'SalesMonitorPerformances' }"
                  color="primary"
                  class="text-lowercase mb-2"
                  >alle voorstellingen</v-btn
                >
              </div>
            </v-col>
          </template>

          <template v-else>
            <p class="text-primary font-weight-medium mb-0">geen data beschikbaar</p>
          </template>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import Help from '../Help.vue';
import functions from '@/services/functions.service';

export default {
  props: {
    helpId: {
      type: [String, Number],
      required: true,
    },
    colsMobile: {
      type: Number,
      default: 12,
    },
    colsTablet: {
      type: Number,
      default: 6,
    },
    colsDesktop: {
      type: Number,
      default: 4,
    },
  },
  components: {
    Help,
  },
  data() {
    return {
      chart: {
        loaded: false,
      },
      performances: [],
      performancesThisWeekLoading: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getPercentageView(value) {
      return functions.getPercentageViewWithoutDecimals(value);
    },
    getData() {
      this.chart.loaded = false;

      ApiService.get('/Dashboard/GetPerformancesForNextWeek')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.performances = response.data.result;

          this.performances.forEach((performance) => {
            performance.startDate_formatted = functions.getDateTimeView(performance.startDate);
          });

          // Load chart
          this.chart.loaded = true;
          this.performancesThisWeekLoading = false;
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          // Handle error case (e.g., show an error message)
        });
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
};
</script>

<style lang="scss" scoped>
.progress-text {
  font-size: 0.8rem;
}

.v-list-item__subtitle.my-1 {
  font-size: 13px;
  font-weight: 300;
}

.v-list-item-title {
  white-space: inherit;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.v-list-item-subtitle {
  font-size: 13px !important;
  font-weight: 300 !important;
}

.tooltip {
  left: 50%;
  transform: unset;

  table tbody tr th {
    font-size: 0.75rem;
  }
}
</style>