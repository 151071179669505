<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row class="d-flex" justify="center" align="center" no-gutters>
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="63"></breadcrumbs-with-help>

          <v-col cols="12">
            <v-card title="gegevens notificatie">
              <v-divider></v-divider>
              <v-card-text>

                <v-row no-gutters>
                  <v-col cols="12" class="py-0">
                    <p class="text-primary mb-0">
                      <small>titel:</small><br />
                      <strong>{{ notification.title }}</strong>
                    </p>
                  </v-col>
                </v-row>

                <hr class="accent-hr my-2" />
                <v-row no-gutters>
                  <v-col cols="12" class="py-0">
                    <p class="text-primary mb-0">
                      <small>datum:</small><br />
                      <strong>{{ notification.date }}</strong>
                    </p>
                  </v-col>
                </v-row>

                <hr class="accent-hr my-2" />
                <v-row no-gutters>
                  <v-col cols="12" class="py-0">
                    <p class="text-primary mb-0">
                      <small>tekst:</small><br />
                      <strong>{{ notification.text }}</strong>
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';

import BreadcrumbsWithHelp from '../../../components/BreadcrumbsWithHelp.vue';

export default {
  name: 'LibraryNotificationsDetail',
  props: ['notificationId'],
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      notification: {
        title: '',
        text: '',
        date: '',
      },
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'notificaties',
          disabled: false,
          href: '/beheer/notificaties',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryNotificationsDetail');
    this.getNotification();
  },
  methods: {
    getNotification: function () {
      ApiService.get('/Notifications/Get', {
        id: this.notificationId,
      })
        .then((response) => {
          if (response.data.success) {
            const notification = response.data.result;
            this.breadcrumbs[2].text = notification.title;
            this.notification.title = notification.title;
            this.notification.date = functions.getDateTimeView(notification.showDate);
            this.notification.text = notification.text;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
