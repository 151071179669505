<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :page.sync="tablePublicMonitorPage"
      :items-per-page="tableItemsPerPage"
      :filter-active="filterActive"
      :selected="selected"
      :total="total"
      :downloads="downloads"
      class="table-action row-is-clickable"
      :height="items.length > 10 ? '55vh' : ''"
      @update:current-items="tablePublicMonitorPageCount = Math.ceil(items.length / tableItemsPerPage)"
      @update:items-per-page="tablePublicMonitorPageCount = Math.ceil(items.length / tableItemsPerPage)"
      @click:row="(_, x) => goToDetail(x.item)"
      fixed-header
      hide-default-footer
    >
      <template v-slot:[`item.startDate`]="{ item }">
        {{ item.startDateView }}
      </template>

      <template v-slot:[`item.tickets`]="{ item }">
        {{ formatThousandsWithDot(item.tickets) }}
      </template>

      <template v-slot:[`item.performances`]="{ item }">
        {{ formatThousandsWithDot(item.performances) }}
      </template>

      <template v-slot:[`item.capacity`]="{ item }">
        {{ formatThousandsWithDot(item.capacity) }}
      </template>

      <template v-slot:[`item.purchases`]="{ item }">
        {{ formatThousandsWithDot(item.purchases) }}
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          v-if="item.number != null && item.number != 'no_export'"
          color="#f4f7fc"
          rounded="circle"
          density="default"
          size="x-small"
          icon="mdi-link-variant"
          class="ml-2"
          @click.stop="goToSalesDetail(item)">
          <v-icon color="primary">mdi-link-variant</v-icon>
        </v-btn>

        <v-btn
          v-if="!item.hasTooFewPurchases && detailPage == 'BenchmarkProductionsDetail'"
          color="#fff453"
          rounded="circle"
          density="default"
          size="x-small"
          icon="mdi-eye"
          class="ml-2"
          @click.stop="goToDetail(item)"
        >
          <v-icon color="primary">mdi-eye</v-icon>
        </v-btn>
        <v-btn
          v-if="!item.hasTooFewPurchases && detailPage == 'PublicMonitorProductionsDetail'"
          color="#fff453"
          rounded="circle"
          density="default"
          size="x-small"
          icon="mdi-eye"
          class="ml-2"
          @click.stop="goToDetail(item)">
          <v-icon color="primary">mdi-eye</v-icon>
        </v-btn>
      </template>

      <template
        v-slot:[`body.append`]
        v-if="!loading"
      >
        <tr class="table-selection-total" v-if="filterActive">
          <th class="title">selectie</th>
          <th class=""></th>
          <th class="text">
            {{ formatThousandsWithDot(selected.sold) }}
          </th>
          <th class="text">
            {{ formatThousandsWithDot(selected.performancesCount) }}
          </th>
          <th class="text">
            {{ formatThousandsWithDot(selected.capacity) }}
          </th>
          <th class="text">
            {{ formatThousandsWithDot(selected.purchases) }}
          </th>
          <th class="text"></th>
          <th class="text"></th>
        </tr>

        <tr class="table-total">
          <th class="title">totaal</th>
          <th></th>

          <th class="text">
            {{ formatThousandsWithDot(total.sold) }}
          </th>
          <th class="text">
            {{ formatThousandsWithDot(total.performancesCount) }}
          </th>
          <th class="text">
            {{ formatThousandsWithDot(total.capacity) }}
          </th>
          <th class="text">
            {{ formatThousandsWithDot(total.purchases) }}
          </th>
          <th class=""></th>
          <th class=""></th>
        </tr>
      </template>
    </v-data-table>

    <!-- Table settings -->
    <v-row
      class="text-center pt-2"
      align="center"
    >
      <!-- Table totaal -->
      <v-col cols="12">
        <p class="font-weight-medium text-left caption text-primary ma-0 pa-0">totaal: {{ this.items.length }}</p>
      </v-col>

      <!-- Table buttons items per page -->
      <v-col
        cols="12"
        sm="4"
        class="d-flex align-center justify-center justify-sm-start"
      >
        <v-btn
          v-if="items.length > 25"
          @click="tableItemsPerPage = 25"
          :color="tableItemsPerPage == 25 ? 'primary' : '#fff'"
          height="45"
          width="45"
          elevation="0"
          size="small"
          class="pa-0 mr-1 font-weight-bold body-2"
          >25</v-btn
        >
        <v-btn
          v-if="items.length > 25"
          @click="tableItemsPerPage = 50"
          :color="tableItemsPerPage == 50 ? 'primary' : '#fff'"
          height="45"
          width="45"
          elevation="0"
          size="small"
          class="pa-0 mr-1 font-weight-bold body-2"
          >50</v-btn
        >
        <v-btn
          v-if="items.length > 50"
          @click="tableItemsPerPage = 100"
          :color="tableItemsPerPage == 100 ? 'primary' : '#fff'"
          height="45"
          width="45"
          elevation="0"
          size="small"
          class="pa-0 mr-1 font-weight-bold body-2"
          >100</v-btn
        >
      </v-col>

      <!-- Table pagination -->
      <v-col
        cols="12"
        sm="4"
      >
        <v-pagination
          v-if="tablePublicMonitorPageCount > 1"
          v-model="tablePublicMonitorPage"
          :length="tablePublicMonitorPageCount"
          :total-visible="7"
          elevation="0"
          variant="elevated"
          active-color="primary"
          size="small"
        ></v-pagination>
      </v-col>

      <!-- Table download -->
      <download-excel
        :data="items"
        :columns="downloads"
        :filename="'dip-verkoop-en-publiek-producties-export'"
        :sheetname="'publieksmonitor producties'"
      >
      </download-excel>
    </v-row>
  </div>
</template>

<script>
import DownloadExcel from '../../../../components/ui/Misc/DownloadExcel.vue';

export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    filterActive: {
      type: Boolean,
      required: true,
    },
    selected: {
      type: Object,
      required: true,
    },
    total: {
      type: Object,
      required: true,
    },
    downloads: {
      type: Array,
      required: true,
    },
    detailPage: {
      type: String,
      required: false,
    },
  },
  components: {
    DownloadExcel,
  },
  data() {
    return {
      tablePublicMonitorPage: 1,
      tablePublicMonitorPageCount: 0,
      tableItemsPerPage: 25,
    };
  },
  methods: {
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    goToDetail(row) {
      if (this.detailPage == 'PublicMonitorProductionsDetail') {
        this.$router.push({
          name: 'PublicMonitorProductionsDetail',
          params: { productionNumber: row.id },
        });
      } else {
        this.$router.push({
          name: 'BenchmarkProductionsDetail',
          params: { productionNumber: row.number },
        });
      }
    },
    goToSalesDetail(row) {
      this.$router.push({
        name: 'SalesMonitorProductionsDetail',
        params: {
          productionNumber: row.number,
        },
      });
    },
  },
};
</script>

<style>
thead > tr > th {
  background-color: #e1e7f2 !important;
  color: #021e72 !important;
}
</style>