<template>
  <div>
    <v-row>
      <!-- Breadcrumbs and help -->
      <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="47"></breadcrumbs-with-help>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- Page info first row -->
          <v-row class="flex align-center py-5" no-gutters>
            <v-col cols="12">
              <!-- Form -->
              <v-form @submit.prevent class="d-flex flex-column" ref="form">
                <v-col cols="12">
                  <v-card title="Productiegegevens">
                    <v-divider></v-divider>
                    <v-card-text class="mt-5">
                      <!-- Producer -->
                      <v-row v-if="this.user.organisationTypeId == 1">
                        <v-col cols="12" class="py-1">
                          <v-autocomplete label="producent" v-model="addProductionForm.producerId"
                            :items="producerOptions" :rules="formValidationRules.required"
                            @update:modelValue="getSubventions()"></v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-row>
                        <!-- Title -->
                        <v-col cols="12" class="py-1">
                          <v-text-field class="text-primary" label="titel" v-model="addProductionForm.title"
                            :rules="formValidationRules.required"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <!-- New/Reprise -->
                        <v-col cols="12" class="py-1" v-if="this.user.organisationTypeId != 1">
                          <v-autocomplete label="nieuw / reprise" v-model="addProductionForm.isReprise"
                            :items="productiontypes"
                            @update:modelValue="getRepriseProductions(addProductionForm.isReprise)"></v-autocomplete>
                        </v-col>

                        <v-col cols="12" class="py-1"
                          v-else-if="this.user.organisationTypeId == 1 && addProductionForm.producerId">
                          <v-autocomplete label="nieuw / reprise" v-model="addProductionForm.isReprise"
                            :items="productiontypes"
                            @update:modelValue="getRepriseProductions(addProductionForm.isReprise)"></v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-row v-if="addProductionForm.isReprise == 'reprise'">
                        <!-- oorspronkelijke productie -->
                        <v-col cols="12" class="py-1">
                          <v-autocomplete label="oorspronkelijke productie" v-model="addProductionForm.repriseNumber"
                            :items="repriseProductions">
                          </v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-row>
                        <!-- Eigenaar -->
                        <v-col cols="12" class="py-1">
                          <v-autocomplete @update:modelValue="addProductionForm.coproduction = []" label="eigenaar"
                            v-model="addProductionForm.productionType" :items="ownerOptions" outlined
                            dense></v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-row v-if="addProductionForm.productionType == 'co_production'">
                        <!-- Coproducenten -->
                        <v-col cols="12" class="py-1">
                          <v-autocomplete multiple label="coproducenten" v-model="addProductionForm.coproduction"
                            :items="coProducerOptions"></v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-autocomplete multiple label="begeleid door"
                            v-model="addProductionForm.accompanyingProducers"
                            :items="accompanyingProducersOptions"></v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-row>
                        <!-- Musician count -->
                        <v-col cols="12" class="py-1">
                          <v-text-field class="text-primary" label="aantal muzikanten" type="number"
                            v-model="addProductionForm.musicianCount"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <!-- Synopsis -->
                        <v-col cols="12" class="py-1">
                          <v-textarea class="text-primary" label="synopsis"
                            v-model="addProductionForm.synopsis"></v-textarea>
                        </v-col>
                      </v-row>

                      <v-row>
                        <!-- Genre -->
                        <v-col cols="12" class="py-1">
                          <v-select label="genre" v-model="addProductionForm.genreId" :items="genreOptions"
                            :rules="formValidationRules.required"></v-select>
                        </v-col>
                      </v-row>

                      <template v-if="this.subventionRights.approve">
                        <v-row>
                          <!-- Subsidie -->
                          <v-col cols="12" class="py-1">
                            <v-autocomplete v-model="addProductionForm.subventions" label="subsidies"
                              :items="subventionOptions" multiple></v-autocomplete>
                          </v-col>
                        </v-row>

                        <v-row v-if="addProductionForm.subventions.length > 0">
                          <!-- data gebruik subsidie -->
                          <v-col cols="12" class="py-1">
                            <v-tooltip top max-width="500">
                              <template v-slot:activator="{ props: activatorProps }">
                                <v-btn color="info" width="24" height="24" fab x-small depressed light
                                  v-bind="activatorProps">
                                  <v-icon color="primary" x-small>mdi-help</v-icon>
                                </v-btn>
                              </template>
                              <span>Wil je gebruik maken van de koppeling met Fonds Podiumkunsten, geef dan toestemming
                                om
                                de
                                data uit DIP door te zetten naar de verantwoordingsmodule van het Fonds. Wij zetten dan
                                beschikbare gegevens voor je over, zodat je niet dubbel hoeft in te voeren. Overdracht
                                vindt plaats per kwartaal op 1 februari, 1 mei, 1 augustus en 1 november.<br />LET OP:
                                Het
                                definitief doorsturen van de informatie naar Fonds Podiumkunsten doe je vervolgens zelf
                                vanuit de verantwoordingsmodule Mijn Fonds.</span>
                            </v-tooltip>
                            <v-checkbox label="toestemming data gebruiken voor subsidieverantwoording"
                              v-model="addProductionForm.hasTransferPermission"
                              class="checkbox text-primary ma-0"></v-checkbox>
                          </v-col>
                        </v-row>
                      </template>

                      <v-row>
                        <!-- Start datum -->
                        <v-col cols="12" class="py-1">
                          <date-picker :passed-date="addProductionForm.startDateTime"
                            rules="dateRequired" @formatedDate="addProductionForm.startDateTime = $event"></date-picker>
                        </v-col>
                      </v-row>

                      <v-alert type="error" variant="tonal" v-if="formError != ''">{{ formError }}</v-alert>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-btn-save @click="submit" :loading="formLoading">
                        Opslaan
                      </v-btn-save>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import DatePicker from '@/components/DatePicker.vue';

export default {
  name: 'LibraryProductionsAdd',
  components: {
    BreadcrumbsWithHelp,
    DatePicker,
  },
  data() {
    return {
      isEditing: false,
      formError: '',
      formLoading: false,
      addProductionForm: {
        producerId: null,
        title: '',
        owner: '',
        coproduction: [],
        accompanyingProducers: [],
        subventions: [],
        hasTransferPermission: true,
        productionType: null,
        musicianCount: '0',
        synopsis: '',
        genreId: null,
        startDateTime: '',
        isReprise: '',
        repriseNumber: '',
      },
      genreOptions: [],
      producerOptions: [],
      coProducerOptions: [],
      accompanyingProducersOptions: [],
      subventionOptions: [],
      repriseProductions: [],
      ownerOptions: [
        { title: 'eigen productie', value: 'own_production' },
        { title: 'coproductie', value: 'co_production' },
      ],
      productiontypes: [
        { title: 'nieuwe productie', value: 'normal' },
        { title: 'reprise / herneming', value: 'reprise' },
      ],
      formValidationRules: {},
      showCoProducers: false,
      rights: {
        edit: false,
        approve: false,
      },
      subventionRights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producties',
          disabled: false,
          href: '/beheer/producties',
          bold: false,
        },
        {
          text: 'toevoegen',
          disabled: false,
          href: '/beheer/producties/add',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryProductionsAdd');
    this.subventionRights = this.getComponentRights('Subventions');
    this.checkRights();
    this.formValidationRules = functions.getFormValidationRules();
    this.check();
    this.getGenres();
    this.getProducers();
    this.getCoProducers();
    this.getAccompanyingProducers();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    checkRights() {
      if (!this.rights.edit) {
        router.back();
      }
    },
    check: function () {
      if (this.user.organisationTypeId == 3) {
        const vm = this;
        this.addProductionForm.producerId = this.user.organisationId;
        setTimeout(function () {
          vm.isEditing = false;
        }, 200);
        this.getSubventions();
      }
    },
    getGenres: function () {
      ApiService.get('/Genres/GetAll').then((response) => {
        const genres = response.data.result;
        for (let i = 0; i < genres.length; i++) {
          this.genreOptions.push({ value: genres[i].id, title: genres[i].name });
        }
      });
    },
    getProducers: function () {
      ApiService.get('/Productions/GetLinkedProducers').then((response) => {
        const producers = response.data.result;
        for (let i = 0; i < producers.length; i++) {
          this.producerOptions.push({
            value: producers[i].id,
            title: producers[i].name,
          });
        }
      });
    },
    getCoProducers: function () {
      ApiService.get('/Productions/GetProducers').then((response) => {
        const producers = response.data.result;
        for (let i = 0; i < producers.length; i++) {
          this.coProducerOptions.push({
            value: producers[i].id,
            title: producers[i].name,
          });
        }
      });
    },
    getAccompanyingProducers: function () {
      ApiService.get('/Producers/GetAllDropDown', {
        IsAccompanying: true,
      }).then((response) => {
        const accompanyingProducers = response.data.result;
        for (let i = 0; i < accompanyingProducers.length; i++) {
          this.accompanyingProducersOptions.push({
            value: accompanyingProducers[i].id,
            title: accompanyingProducers[i].name,
          });
        }
      });
    },
    getRepriseProductions: function () {
      const vm = this;

      ApiService.get('/Productions/GetPerProducer', {
        ProducerId: this.user.organisationTypeId == 1 ? vm.addProductionForm.producerId : this.user.organisationId,
        SeasonId: 0,
      }).then((response) => {
        let result = response.data.result;
        let sortedRepriseProductions = [];

        sortedRepriseProductions = result.map((element) => ({
          value: element.number,
          title: element.title + ' ' + element.number,
        }));

        sortedRepriseProductions.sort(function (a, b) {
          var textA = a.text ? a.text.toUpperCase() : '';
          var textB = b.text ? b.text.toUpperCase() : '';
          if (textA < textB) return -1;
          if (textA > textB) return 1;
          return 0;
        });

        this.repriseProductions = sortedRepriseProductions;
      });
    },
    getSubventions: function () {
      this.subventionOptions = [];
      this.addProductionForm.subventions = [];
      ApiService.get('/Productions/GetProducerSubventions', {
        ProducerId: this.addProductionForm.producerId,
      }).then((response) => {
        const subventions = response.data.result;
        const actualSubventions = [];
        const oldSubventions = [];

        for (let i = 0; i < subventions.length; i++) {
          if (subventions[i].isObsolete) {
            oldSubventions.push(subventions[i]);
          } else {
            actualSubventions.push(subventions[i]);
          }
        }

        if (actualSubventions.length > 0) {
          this.subventionOptions.push({ header: 'actueel' });
          for (let j = 0; j < actualSubventions.length; j++) {
            this.subventionOptions.push({
              value: actualSubventions[j].id,
              title:
                functions.getSubventionTypeView(actualSubventions[j].type) +
                ' ' +
                actualSubventions[j].organisation +
                ' (' +
                functions.getDateView(actualSubventions[j].startDate) +
                ' t/m ' +
                functions.getDateView(actualSubventions[j].endDate) +
                ')',
            });
          }
        }

        if (oldSubventions.length > 0) {
          this.subventionOptions.push({ header: 'ouder' });
          for (let k = 0; k < oldSubventions.length; k++) {
            this.subventionOptions.push({
              value: oldSubventions[k].id,
              title:
                functions.getSubventionTypeView(oldSubventions[k].type) +
                ' ' +
                oldSubventions[k].organisation +
                ' (' +
                functions.getDateView(oldSubventions[k].startDate) +
                ' t/m ' +
                functions.getDateView(oldSubventions[k].endDate) +
                ')',
            });
          }
        }
      });
    },
    async submit() {
      this.formError = '';
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const form = this.addProductionForm;

        if (form.startDateTime == '') {
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, '0');
          var mm = String(today.getMonth() + 1).padStart(2, '0');
          var yyyy = today.getFullYear();
          form.startDateTime = dd + '-' + mm + '-' + yyyy;
        }

        ApiService.post('/Productions/Add', {
          producerId: form.producerId,
          title: form.title,
          productionType: form.productionType,
          coProducerIds: form.coproduction,
          accompanyingProducerIds: form.accompanyingProducers,
          subventionIds: form.subventions,
          hasTransferPermission: form.hasTransferPermission,
          musicianCount: parseInt(form.musicianCount),
          synopsis: form.synopsis,
          genreId: form.genreId,
          startDateTime: functions.getDateDatabase(form.startDateTime),
          isReprise: form.isReprise,
          repriseNumber: form.repriseNumber,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
  },
  watch: {
    addProductionForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
