<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row align="center" class="px-3 mb-4">
          <v-col class="py-3 add-row d-flex justify-sm-space-between align-center">
            <span class="d-none d-sm-flex">Een nieuwe gebruiker aanmaken?</span>
            <v-btn color="primary" rounded depressed class="font-weight-bold text-lowercase"
              :to="{ name: 'AdminUsersAdd' }">
              Aanmaken nieuwe gebruiker
            </v-btn>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row flex justify="center" align="center" class="pb-2" no-gutters>
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="2"></breadcrumbs-with-help>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <v-text-field v-model="tableUsersFilter.name" label="op naam zoeken" hide-details variant="outlined"
                bg-color="#fff" class="ma-1 input-sm"></v-text-field>
              <v-text-field v-model="tableUsersFilter.email" label="op e-mail zoeken" hide-details variant="outlined"
                bg-color="#fff" class="ma-1 input-sm"></v-text-field>
              <v-autocomplete v-model="tableUsersFilter.status" :items="[
                { value: 1, title: 'ja' },
                { value: 6, title: 'nee' },
                { value: '', title: 'ja/nee' },
              ]" type="text" label="actief" variant="outlined" hide-details bg-color="#fff"
                class="ma-1 input-sm"></v-autocomplete>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog v-model="tableUsersFilterDialog" width="500" fullscreen>
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn class="font-weight-bold text-lowercase" color="success" rounded dark
                    v-bind="activatorProps">filter overzicht
                    <v-icon small right>mdi-filter</v-icon></v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline dark">
                    <h5 class="text-primary">filter overzicht</h5>
                  </v-card-title>

                  <v-card-text>
                    <v-text-field v-model="tableUsersFilter.name" label="op naam zoeken" hide-details dense outlined
                      background-color="#fff" class="mb-3"></v-text-field>
                    <v-text-field v-model="tableUsersFilter.email" label="op e-mail zoeken" hide-details dense outlined
                      background-color="#fff" class="mb-3"></v-text-field>
                    <v-autocomplete v-model="tableUsersFilter.status" :items="[
                      { value: 1, text: 'ja' },
                      { value: 6, text: 'nee' },
                      { value: '', text: 'ja/nee' },
                    ]" item-text="text" label="actief" hide-details dense outlined background-color="#fff"
                      class="mb-3"></v-autocomplete>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn class="font-weight-bold text-lowercase mb-5" color="success" depressed
                      @click="tableUsersFilterDialog = false">filter overzicht
                      <v-icon small right>mdi-filter</v-icon></v-btn>
                    <v-btn class="font-weight-bold text-lowercase" color="error" outlined dark
                      @click="clearFilterUsers()">reset filter
                      <v-icon small right>mdi-close</v-icon></v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table :headers="tableUsersHeaders" 
          :items="tableUsersFiltered" 
          :mobile-breakpoint="0"
          :loading="tableUsersLoading" 
          :page="tableUsersPaging.page" 
          :items-per-page="tableUsersPaging.itemsPerPage"
          hide-default-footer
          @update:items-per-page="tableUsersPaging.pageCount = Math.ceil(tableUsersFiltered.length / tableUsersPaging.itemsPerPage)"
          @update:current-items="tableUsersPaging.pageCount = Math.ceil(tableUsersFiltered.length / tableUsersPaging.itemsPerPage)"
          @click:row="(_, x) => goToDetail(x.item)" 
          class="table-action row-is-clickable" 
          fixed-header
          :height="tableUsersFiltered.length > 10 ? '55vh' : ''">
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ props: activatorProps }">
                <v-icon v-if="item.twoFactorEnabled" color="primary" v-bind="activatorProps">
                  mdi-two-factor-authentication
                </v-icon>
              </template>
              <span>Twee-factor ingeschakeld</span>
            </v-tooltip>
            <v-btn v-if="item.status == 1" rounded="circle" elevation="0" size="small" icon="mdi-eye" color="warning"
              class="ml-1" @click.stop="goToDetail(item)">
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
            <v-btn v-if="item.status == 1" rounded="circle" elevation="0" size="small" icon="mdi-square-edit-outline"
              color="info" class="ml-1" @click.stop="goToEdit(item)">
              <v-icon color="primary">mdi-square-edit-outline</v-icon>
            </v-btn>
            <delete-dialog v-if="user.userId != item.id && item.status == 1" :title="deleteDialogTitle(item)"
                    :text="deleteDialogText(item)" @confirm="(deleteItem = item), deleteItemUser();" />
            <v-btn v-if="item.status != 1" rounded="circle" elevation="0" size="small" icon="mdi-arrow-u-left-top"
              color="secondary" class="ml-1" @click.stop="(activateItem = item), (activateItemDialog = true);">
              <v-icon color="primary">mdi-arrow-u-left-top</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <!-- Table settings -->
        <table-settings :items="tableUsersFiltered" :paging="tableUsersPaging" :export-columns="downloadExportColumns"
          :file-name="'dip-gebruikers-export'" :sheet-name="'Gebruikers'" :show-excel-button="true"></table-settings>
      </v-col>
    </v-row>

    <!-- Activate dialog starts here -->
    <v-dialog v-model="activateItemDialog" persistent width="500">
      <v-card class="pa-6" flat>
        <v-card-title class="headline">{{ this.activateItem.fullName }} activeren?</v-card-title>
        <v-card-text>Weet je zeker dat je {{ this.activateItem.fullName }} wil activeren?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="(activateItemDialog = false), (activateItem = '');">Nee</v-btn>
          <v-btn color="primary" text @click="activateItemUser()">Ja</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ this.snackbarText }}
      <template v-slot:actions>
        <v-btn color="#fff" variant="text" @click="(snackbar = false), (snackbarText = '')">
          Sluiten
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';

import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp';
import TableSettings from '@/components/TableSettings';
import router from '../../../router/router';

export default {
  name: 'AdminUsers',
  components: {
    BreadcrumbsWithHelp,
    TableSettings,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'gebruikers',
          disabled: false,
          href: '/admin/gebruikers',
          bold: true,
        },
      ],
      users: [],
      tableUsersFiltered: [],
      tableUsersLoading: true,
      tableUsersHeaders: [
        { title: 'naam', key: 'fullName' },
        { title: 'e-mailadres', key: 'email' },
        { title: 'admin (medewerker DIP)', key: 'isSuperUser' },
        { title: 'api', key: 'api' },
        { title: 'actief', key: 'statusString' },
        { title: 'mails', key: 'mails' },
        { title: '', key: 'action', sortable: false, align: 'end', width: '170px' },
      ],
      tableUsersFilter: {
        name: '',
        email: '',
        status: 1,
      },
      tableUsersPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
      },
      tableUsersFilterDialog: false,
      downloadExportColumns: [
        { label: 'ID', field: 'id' },
        { label: 'voornaam', field: 'firstName' },
        { label: 'achternaam', field: 'lastName' },
        { label: 'e-mail', field: 'email' },
        { label: 'admin (medewerker DIP)', field: 'isSuperUser' },
        { label: 'type', field: 'organisationType' },
        { label: 'organisatie', field: 'organisationName' },
        { label: 'plaats', field: 'placeName' },
        { label: 'rol', field: 'userRole' },
        { label: 'actief', field: 'statusString' },
        { label: 'contracten', field: 'contractMails' },
        { label: 'subsidies', field: 'subventionMails' },
        { label: 'voorstellingen', field: 'performanceMails' },
        { label: 'producties', field: 'productionMails' },
      ],
      deleteItem: '',
      activateItemDialog: false,
      activateItem: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'secondary',
    };
  },
  mounted() {
    // Filter
    this.checkFilter({
      viewName: this.$options.name,
      filterObject: this.tableUsersFilter,
    });
    this.tableUsersFilter = this.getFilter(this.$options.name);

    this.getUsers();
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    goToDetail(row) {
      this.$router.push({
        name: 'AdminUsersDetail',
        params: { userId: row.id },
      });
    },
    goToEdit(row) {
      this.$router.push({
        name: 'AdminUsersEdit',
        params: { userId: row.id },
      });
    },
    getUsers: function () {
      ApiService.get('/Users/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.users = response.data.result.map(user => ({
            ...user,
            statusString: user.status == 1 ? 'Ja' : 'Nee',
            isSuperUser: user.isSuperUser ? 'Ja' : 'Nee',
            api: user.api ? 'Ja' : 'Nee',
            contractMails: user.receiveContractMails ? 'ja' : 'nee',
            performanceMails: user.receivePerformanceMails ? 'ja' : 'nee',
            productionMails: user.receiveProductionMails ? 'ja' : 'nee',
            subventionMails: user.receiveSubventionMails ? 'ja' : 'nee',
            mails: [
              user.receiveContractMails ? 'contracten' : '',
              user.receivePerformanceMails ? 'voorstellingen' : '',
              user.receiveProductionMails ? 'producties' : '',
              user.receiveSubventionMails ? 'subsidies' : ''
            ].filter(Boolean).join(', ')
          }));

          this.tableUsersFiltered = this.users;

          // Paging
          this.checkFilter({
            viewName: this.$options.name + '-paging',
            filterObject: this.tableUsersPaging,
          });

          this.tableUsersPaging = this.getFilter(this.$options.name + '-paging');

          this.filterUsers();

          this.tableUsersLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterUsers: function () {
      const vm = this;
      this.tableUsersFiltered = this.users.filter(function (user) {
        return (
          cleanWords(user.fullName).indexOf(cleanWords(vm.tableUsersFilter.name)) !== -1 &&
          cleanWords(user.email).indexOf(cleanWords(vm.tableUsersFilter.email)) !== -1 &&
          (user.status == vm.tableUsersFilter.status || vm.tableUsersFilter.status == '')
        );
      });
      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterUsers: function () {
      this.tableUsersFilter.name = '';
      this.tableUsersFilter.email = '';
      this.tableUsersFilter.status = 1;
      this.tableUsersFilterDialog = false;
    },
    deleteItemUser: function () {
      ApiService.delete('/Users/Delete', {
        id: this.deleteItem.id,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableUsersLoading = true;
            this.getUsers();

            this.snackbarText = this.deleteItem.fullName + ' is verwijderd';
            this.deleteItem = '';
            this.snackbarColor = 'secondary';
            this.snackbar = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    activateItemUser: function () {
      ApiService.put('/Users/Activate', {
        id: this.activateItem.id,
      })
        .then((response) => {
          if (response.data.success) {
            router.push({
              name: 'AdminUsersDetail',
              params: { userId: this.activateItem.id },
            });
            this.activateItem = '';
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    deleteDialogTitle(value) {
      return `${value.fullName} verwijderen?`;
    },
    deleteDialogText(value) {
      return `Weet je zeker dat je ${value.fullName} wil verwijderen? Het is daarna niet meer terug te vinden.`;
    },
  },
  computed: {
    ...mapState('auth', ['user']),

    ...mapGetters('storage', ['getFilter']),
  },
  beforeUnmount() {
    // Filter
    this.updateFilter({
      viewName: this.$options.name,
      filterObject: this.tableUsersFilter,
    });
    // Paging
    this.updateFilter({
      viewName: this.$options.name + '-paging',
      filterObject: this.tableUsersPaging,
    });
  },
  watch: {
    tableUsersFilter: {
      handler: 'filterUsers',
      deep: true,
    },
  },
};
</script>