<template>
  <div>
    <!-- Agreement Buyout -->
    <v-card
      class="py-6 px-3 pb-0 mb-4"
      flat
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <p
              :class="contract.isCopyrightRejected ? 'error--text ' : 'text-primary '"
              class="font-weight-medium mb-0"
            >
              auteursrechten
            </p>

            <div
              class="contract-diff mb-4"
              v-if="
                contract.previousApproved &&
                contract.previousApproved.copyrightPercentage != null &&
                contract.previousApproved.copyrightPercentage != contract.copyrightPercentage
              "
            >
              <h3
                :class="
                  contract.isCopyrightRejected
                    ? 'error--text no-dash'
                    : 'text-primary text-decoration-line-through mb-0 pr-2'
                "
              >
                {{ getPercentageView(contract.previousApproved.copyrightPercentage) }}
              </h3>
              <h3 class="prev-approved--text pb-4 border-bottom">
                {{ getPercentageView(contract.copyrightPercentage) }}
              </h3>
            </div>
            <h3
              v-else
              :class="contract.isCopyrightRejected ? 'error--text ' : 'text-primary '"
              class="border-bottom pb-4 mb-4"
            >
              {{ getPercentageView(contract.copyrightPercentage) }}
            </h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <p
              :class="contract.isBuyoutAmountRejected ? 'error--text ' : 'text-primary '"
              class="font-weight-medium mb-0"
            >
              uitkoopsom (excl. BTW)
            </p>
            <h3
              :class="contract.isBuyoutAmountRejected ? 'error--text ' : 'text-primary '"
              class="border-bottom pb-4 mb-4"
            >
              {{ getEuroSignView(contract.buyoutAmount) }}
            </h3>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import functions from '../../../../services/functions.service';

export default {
  props: ['contract'],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    getEuroSignView(value) {
      if (value != '') {
        return functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(value)));
      } else {
        return '-';
      }
    },
    getDecimalDatabase(value) {
      if (value != '') {
        return functions.getDecimalDatabase(value);
      } else {
        return '-';
      }
    },
    getPercentageView(value) {
      if (value != '') {
        return functions.getPercentageView(this.getDecimalDatabase(value));
      } else {
        return '-';
      }
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {},
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid #e1e7f2;
}
</style>
