<template>
  <v-col
    :cols="colsMobile"
    :sm="colsTablet"
    :md="colsDesktop"
    class="d-flex"
  >
    <v-card
      class="px-4 py-0 mb-1"
      flat
      width="100%"
    >
      <v-container>
        <v-row class="block-head">
          <v-col
            cols="12"
            class="d-flex align-center justify-space-between"
          >
            <p class="text-primary font-weight-medium mb-0">laatste synchronisatie PNL</p>
            <help
              v-if="helpId != ''"
              bg-color=""
              :help-id="helpId"
            ></help>
          </v-col>
        </v-row>

        <v-row
          flex
          justify="center"
          class="py-2 flex-grow-1"
        >
          <template v-if="dataLoaded">
            <v-col
              cols="12"
              class="pt-0"
            >
              <h2 class="text-primary mb-0">{{ lastSyncPKI }}</h2>
            </v-col>
          </template>

          <template v-else>
            <p class="text-primary font-weight-medium mb-0">geen data beschikbaar</p>
          </template>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import ApiService from '../../services/api.service';
import functions from '../../services/functions.service';
import Help from '../Help.vue';

export default {
  props: ['helpId', 'colsMobile', 'cols-Tablet', 'colsDesktop'],
  components: {
    Help,
  },
  data() {
    return {
      dataLoaded: false,
      lastSyncPKI: '',
    };
  },
  mounted() {
    this.getLastSync();
  },
  methods: {
    getLastSync: function () {
      ApiService.get('/Monitor/GetLastImportDate')
        .then((response) => {
          this.lastSyncPKI = response.data.result != null ? functions.getDateTimeView(response.data.result) : '-';
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
      this.dataLoaded = true;
    },
  },
  computed: {},
  watch: {},
};
</script>

<style scoped></style>
