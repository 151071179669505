<template>
  <div>
    <details-contract :contract="contract"></details-contract>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DetailsContract from '../DetailsContract.vue';

export default {
  props: ['contract'],
  components: {
    DetailsContract,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {},
};
</script>

<style scoped></style>
