<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="1136"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- Form -->
          <v-form @submit.prevent class="d-flex flex-column" ref="form">
            <v-col cols="12">
              <v-card title="gegevens gebruiker">
                <v-divider></v-divider>
                <v-card-text>
                  <v-col cols="12" class="py-1">
                    <v-text-field class="text-primary" label="naam" v-model="editThirdPartyForm.name"
                      :rules="formValidationRules.required"></v-text-field>
                  </v-col>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card title="api rechten">
                <v-divider></v-divider>
                <v-card-text>
                  <v-row class="mt-0">
                    <v-col cols="12" class="py-0">
                      <!-- data overdracht FPK -->
                      <v-checkbox label="data overdracht FPK" hide-details v-model="editThirdPartyForm.hasDataApi"
                        class="checkbox text-primary ma-0"></v-checkbox>
                      <!-- productiegegevens FPK -->
                      <v-checkbox label="productiegegevens FPK" hide-details v-model="editThirdPartyForm.hasProducerApi"
                        class="checkbox text-primary ma-0"></v-checkbox>
                    </v-col>
                  </v-row>

                  <v-alert type="error" variant="tonal" v-if="formError != ''">{{ formError }}</v-alert>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <div>
                    <p class="grey--text text--lighten-1 mb-2">
                      <small><i>{{ this.lastEditedBy }}</i></small>
                    </p>

                    <v-btn-save @click="submit" :loading="formLoading">Opslaan</v-btn-save>
                  </div>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'AdminthirdPartiesEdit',
  props: ['thirdPartyId'],
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      isEditing: false,
      formError: '',
      formLoading: false,
      editThirdPartyForm: {
        name: '',
        hasDataApi: false,
        hasProducerApi: false,
      },
      formValidationRules: {},
      lastEditedBy: '',
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'externe partijen',
          disabled: false,
          href: '/admin/externe-partijen',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.getThirdParties();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getThirdParties: function () {
      const vm = this;

      ApiService.get('/ThirdPartyUsers/Get', {
        id: this.thirdPartyId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const thirdParty = response.data.result;

            this.editThirdPartyForm = thirdParty;

            this.breadcrumbs[2].text = this.editThirdPartyForm.name;
            this.breadcrumbs[2].href = '/admin/externe-partijen/' + this.thirdPartyId;

            setTimeout(function () {
              vm.isEditing = false;
            }, 200);
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          router.back();
        });
    },
    async submit() {
      this.formError = '';
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const form = this.editThirdPartyForm;

        ApiService.put('/ThirdPartyUsers/Update', {
          id: this.thirdPartyId,
          name: form.name,
          hasDataApi: form.hasDataApi,
          hasProducerApi: form.hasProducerApi,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();

            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {},
  watch: {
    editThirdPartyForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
