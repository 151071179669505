<template>
  <v-data-table
    :headers="tablePublicMonitorHeadersFiltered"
    :items="tableProductionPerformancesFiltered"
    :mobile-breakpoint="0"
    :loading="loading"
    :items-per-page="-1"
    hide-default-footer
    @click:row="(_, x) => goToDetail(x.item)"
    class="table-action row-is-clickable mt-5"
    fixed-header
    :height="tableProductionPerformancesFiltered.length > 10 ? '55vh' : ''"
  >
    <template v-slot:[`item.startDate`]="{ item }">
      {{ item.startDateView }}
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <v-btn
        v-if="
          item.number != null && item.number != 'no_export' && item.dipId != null && item.hasTooFewPurchases == false
        "
        runded="circle" 
        elevation="0" 
        size="small" 
        color="info" 
        class="ml-1" 
        icon="mdi-link-variant"    
        @click.stop="goToSalesDetail(item)"
      >
        <v-icon color="primary">mdi-link-variant</v-icon>
      </v-btn>

      <v-btn
        v-if="item.id != null"
        runded="circle" 
        elevation="0" 
        size="small" 
        color="warning" 
        class="ml-1" 
        icon="mdi-eye"    
        @click.stop="goToDetail(item)"
      >
        <v-icon color="primary">mdi-eye</v-icon>
      </v-btn>
    </template>

  </v-data-table>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    productionNumber: {
      type: Number,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },

    tableProductionPerformancesFiltered: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      tablePublicMonitorHeaders: [
        { title: 'startdatum', key: 'startDate' },
        { title: 'verkocht', key: 'tickets' },
        { title: 'theater', key: 'theater.name', showFor: [1, 3] },
        { title: 'zaal', value: 'area' },
        { title: 'gem. tickets', value: 'averageTickets' },
        { title: 'gem. leeftijd', value: 'averageAge' },
        { title: '', value: 'action', sortable: false, align: 'end' },
      ],
    };
  },

  methods: {
    goToDetail(row) {
      this.$router.push({
        name: 'PublicMonitorPerformancesDetail',
        params: {
          productionNumber: row.number,
          performanceId: row.id,
        },
      });
    },
    goToSalesDetail(row) {
      this.$router.push({
        name: 'SalesMonitorPerformancesDetail',
        params: {
          productionNumber: row.production.number,
          performanceId: row.dipId,
        },
      });
    },
  },

  computed: {
    tablePublicMonitorHeadersFiltered() {
      return this.tablePublicMonitorHeaders.filter(
        (h) => !h.showFor || h.showFor.includes(this.user.organisationTypeId)
      );
    },
  },
};
</script>