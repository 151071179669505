<template>
  <div>
    <v-expansion-panels
      variant="accordion"
      flat
      multiple
      v-model="panel"
      v-if="contract.logs != null && contract.logs.length > 0"
    >
      <v-expansion-panel class="mb-3">
        <v-expansion-panel-title class="text-primary">log</v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-list nav>
            <v-list-item 
              v-for="log in contract.logs"
              :key="log.id"
              color="primary"
              link
              :to="{ name: 'ContractsDetail', params: { type: log.type, contractId: log.id } }"
              v-model="logActive"
            >
              <v-list-item-title>
                <div class="d-flex align-center">
                  <h5 class="ma-0 mr-4 text-primary font-weight-regular">
                    {{ getDateTimeView(log.modifiedDate) }}
                  </h5>
                  <h4 class="ma-0 text-primary" v-if="log.state === 'proposed'">
                    Voorgesteld <i class="font-weight-light">door {{ log.userFullName }}</i>
                  </h4>
                  <h4 class="ma-0 text-primary" v-else-if="log.state === 'rejected'">
                    Afgekeurd <i class="font-weight-light">door {{ log.userFullName }}</i>
                  </h4>
                  <h4 class="ma-0 text-primary" v-else-if="log.state === 'approved'">
                    Goedgekeurd <i class="font-weight-light">door {{ log.userFullName }}</i>
                  </h4>
                  <h4 class="ma-0 text-primary" v-else-if="log.state === 'revised'">
                    Aangepast voorstel <i class="font-weight-light">door {{ log.userFullName }}</i>
                  </h4>
                  <h4 class="ma-0 text-primary" v-else-if="log.state === 'concept'">
                    Concept <i class="font-weight-light">door {{ log.userFullName }}</i>
                  </h4>
                </div>
              </v-list-item-title>
              <template #append>
                <v-icon small>mdi-chevron-right-circle</v-icon>
              </template>
            </v-list-item>
          </v-list>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import functions from '../../../../services/functions.service';

export default {
  props: ['contract'],
  data() {
    return {
      panel: [0],
      logActive: null,
    };
  },
  mounted() {
    this.checkActiveLog();
  },
  methods: {
    checkActiveLog() {
      if (this.contract.logs && this.contract.logs.length > 0) {
        this.logActive = this.contract.logs.findIndex(log => log.id === this.contract.id);
      }
    },
    getDateTimeView(value) {
      return functions.getDateTimeView(value);
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {},
};
</script>

<style scoped></style>