<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row flex align="center" justify="space-between" class="pa-2">
          <v-col></v-col>
          <v-col class="d-flex align-center justify-end flex-grow-0">
            <v-btn color="primary" rounded depressed class="font-weight-bold text-lowercase ms-2">
              rapportages
            </v-btn>
            <v-btn color="primary" rounded variant="outlined" depressed class="font-weight-bold text-lowercase ms-2"
              :to="{ name: 'PublicMonitorProductions' }">
              producties
            </v-btn>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row flex justify="center" align="center" class="pb-2" no-gutters>
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="1147"></breadcrumbs-with-help>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex align-center">
              <v-text-field v-model="tablePublicMonitorFilter.reportName" label="zoek op rapportage" hide-details
                variant="outlined" density="compact" bg-color="#fff" class="ma-1 mr-2 input-sm"></v-text-field>
              <v-btn class="font-weight-bold text-lowercase" color="secondary" rounded
                :to="{ name: 'PublicMonitorReportsAdd' }">nieuwe rapportage maken
                <v-icon small right>mdi-arrow-right</v-icon>
              </v-btn>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog v-model="tablePublicMonitorFilterDialog" width="500" fullscreen>
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn class="font-weight-bold text-lowercase" color="success" rounded dark
                    v-bind="activatorProps">filter overzicht
                    <v-icon small right>mdi-filter</v-icon></v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline dark">
                    <h5 class="text-primary">filter overzicht</h5>
                  </v-card-title>

                  <v-card-text>
                    <v-text-field v-model="tablePublicMonitorFilter.reportName" label="zoek op rapportage" hide-details
                      dense outlined background-color="#fff" class="ma-1 input-sm"></v-text-field>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn class="font-weight-bold text-lowercase mb-5" color="success" depressed
                      @click="tablePublicMonitorFilterDialog = false">filter overzicht
                      <v-icon small right>mdi-filter</v-icon></v-btn>
                    <v-btn class="font-weight-bold text-lowercase" color="error" variant="outlined" dark
                      @click="clearFilterProductions()">reset filter
                      <v-icon small right>mdi-close</v-icon></v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table :headers="tablePublicMonitorHeadersFiltered" :items="tablePublicMonitorFiltered"
          :mobile-breakpoint="0" :loading="tablePublicMonitorLoading" :page.sync="tablePublicMonitorPaging.page"
          :items-per-page="tablePublicMonitorPaging.itemsPerPage" hide-default-footer
          @update:current-items="tablePublicMonitorPaging.pageCount = Math.ceil(tablePublicMonitorFiltered.length / tablePublicMonitorPaging.itemsPerPage)"
          @update:items-per-page="tablePublicMonitorPaging.pageCount = Math.ceil(tablePublicMonitorFiltered.length / tablePublicMonitorPaging.itemsPerPage)"
          @click:row="(_, x) => goToDetail(x.item)" class="table-action row-is-clickable" fixed-header
          :height="tablePublicMonitorFiltered.length > 10 ? '55vh' : ''">
          <template v-slot:[`item.startDate`]="{ item }">
            {{ item.startDateView }}
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-btn rounded="circle" elevation="0" size="small" icon="mdi-eye" color="warning" class="ml-1"
              @click.stop="goToDetail(item)">
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
            <v-btn rounded="circle" elevation="0" size="small" icon="mdi-square-edit-outline" color="info" class="ml-1"
              @click.stop="goToEdit(item)">
              <v-icon color="primary">mdi-square-edit-outline</v-icon>
            </v-btn>
            <v-btn rounded="circle" elevation="0" size="small" icon="mdi-delete" color="error" class="ml-1"
              @click.stop="(deleteItem = item), (deleteItemDialog = true);">
              <v-icon color="#fff">mdi-delete</v-icon>
            </v-btn>
          </template>

          <template v-slot:[`body.append`] v-if="!tablePublicMonitorLoading">
            <tr class="table-selection-total" v-if="tablePublicMonitorFilterActive">
              <th class="title">selectie</th>
              <th class="text">
                {{ formatThousandsWithDot(this.tablePublicMonitorSelected.performancesCount) }}
              </th>
              <th class=""></th>
              <th class="" v-for="header in tablePublicMonitorHeadersFiltered.slice(7)" :key="header.value"></th>
            </tr>

            <tr class="table-total">
              <th class="title">totaal</th>
              <th class="text">
                {{ formatThousandsWithDot(this.tablePublicMonitorTotal.performancesCount) }}
              </th>
              <th class=""></th>
              <th class="" v-for="header in tablePublicMonitorHeadersFiltered.slice(7)" :key="header.value"></th>
            </tr>
          </template>
        </v-data-table>

        <!-- Table settings -->
        <table-settings :items="tablePublicMonitorFiltered" :paging="tablePublicMonitorPaging"
          :export-columns="downloadExportColumns" :show-excel-button="false">
        </table-settings>

      </v-col>
    </v-row>

    <!-- Delete dialog starts here -->
    <v-dialog v-model="deleteItemDialog" persistent width="500">
      <v-card class="pa-6" flat>
        <v-card-title class="headline">{{ this.deleteItem.name }} verwijderen?</v-card-title>
        <v-card-text>Weet je zeker dat je rapport {{ this.deleteItem.name }} wil verwijderen? Het is daarna niet meer
          terug
          te
          vinden.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="(deleteItemDialog = false), (deleteItem = '');">Nee</v-btn>
          <v-btn color="primary" text @click="deleteItemReport()">Ja</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import TableSettings from '@/components/TableSettings.vue';
import functions from '../../services/functions.service';

export default {
  name: 'PublicMonitorReports',
  components: {
    BreadcrumbsWithHelp,
    TableSettings,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'publieksmonitor',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      reports: [],
      tablePublicMonitorSelected: {
        performancesCount: 0,
      },
      tablePublicMonitorTotal: {
        performancesCount: 0,
      },
      tablePublicMonitorFiltered: [],
      tablePublicMonitorLoading: true,
      tablePublicMonitorHeaders: [
        { title: 'naam', key: 'name' },
        { title: 'aantal vrst.', key: 'performances' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
      tablePublicMonitorFilterActive: false,
      tablePublicMonitorFilter: {
        reportName: '',
      },
      tablePublicMonitorPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
      },
      tablePublicMonitorFilterDialog: false,
      deleteItemDialog: false,
      deleteItem: '',
    };
  },
  mounted() {
    this.rights = this.getComponentRights('SalesMonitor');
    // Filter
    this.checkFilter({
      viewName: this.$options.name,
      filterObject: this.tablePublicMonitorFilter,
    });
    this.tablePublicMonitorFilter = this.getFilter(this.$options.name);

    this.getReports();
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    goToDetail(row) {
      this.$router.push({
        name: 'PublicMonitorReportsDetail',
        params: { reportNumber: row.id },
        query: { startDate: row.startDate, endDate: row.endDate }
      });
    },
    goToEdit(row) {
      this.$router.push({
        name: 'PublicMonitorReportsEdit',
        params: { reportNumber: row.id },
      });
    },
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getEuroSign: function (value) {
      return functions.getEuroSignView(value);
    },
    getPercentageView: function (value) {
      return functions.getPercentageViewWithoutDecimals(value);
    },
    calculateArraySum: function (items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    },
    getReports: function () {
      this.reports = [];
      this.tablePublicMonitorFiltered = [];
      this.tablePublicMonitorLoading = true;

      ApiService.get('PublicMonitor/GetReports')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.reports = response.data.result;

          this.tablePublicMonitorTotal.performancesCount = this.calculateArraySum(this.reports, 'performances');

          this.tablePublicMonitorFiltered = this.reports;

          // Paging
          this.checkFilter({
            viewName: this.$options.name + '-paging',
            filterObject: this.tablePublicMonitorPaging,
          });
          this.tablePublicMonitorPaging = this.getFilter(this.$options.name + '-paging');

          this.filterProductions();
          this.tablePublicMonitorLoading = false;
        })
        .catch((error) => {
          //eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterProductions: function () {
      const vm = this;

      this.tablePublicMonitorFiltered = this.reports.filter(function (report) {
        return cleanWords(report.name).indexOf(cleanWords(vm.tablePublicMonitorFilter.reportName)) !== -1;
      });

      this.tablePublicMonitorSelected.performancesCount = this.tablePublicMonitorSelected.performancesCount =
        this.calculateArraySum(this.tablePublicMonitorFiltered, 'performances');

      this.calculateArraySum(this.tablePublicMonitorFiltered, 'performances');

      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterProductions: function () {
      this.tablePublicMonitorFilterActive = false;
      this.tablePublicMonitorFilterDialog = false;
    },
    deleteItemReport: function () {
      ApiService.delete('/PublicMonitor/DeleteReport', {
        reportId: this.deleteItem.id,
      })
        .then((response) => {
          if (response.data.success) {
            this.getReports();
            this.deleteItemDialog = false;
            this.deleteItem = '';
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
    ...mapGetters('storage', ['getFilter']),

    tablePublicMonitorHeadersFiltered() {
      return this.tablePublicMonitorHeaders.filter(
        (h) => !h.showFor || h.showFor.includes(this.user.organisationTypeId)
      );
    },
  },
  beforeUnmount() {
    // Filter
    this.updateFilter({
      viewName: this.$options.name,
      filterObject: this.tablePublicMonitorFilter,
    });
    // Paging
    this.updateFilter({
      viewName: this.$options.name + '-paging',
      filterObject: this.tablePublicMonitorPaging,
    });
  },
  watch: {
    tablePublicMonitorFilter: {
      handler: 'filterProductions',
      deep: true,
    },
  },
};
</script>