<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="1148"></breadcrumbs-with-help>
          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <filter-period
                :includeOptions="['thisWeek', 'thisSeason', 'nextSeason', 'prevSeason']"
                selectedDefault="thisSeason"
                @changePeriod="filterPeriod = $event"
              ></filter-period>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog
                v-model="tableBenchmarkFilterDialog"
                width="500"
                fullscreen
              >
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn
                    class="font-weight-bold text-lowercase"
                    color="success"
                    rounded
                    dark
                    v-bind="activatorProps"
                    >filter overzicht
                    <v-icon
                      small
                      right
                      >mdi-filter</v-icon
                    ></v-btn
                  >
                </template>

                <v-card>
                  <v-card-title class="headline dark"><h5 class="text-primary">filter overzicht</h5></v-card-title>

                  <v-card-text>
                    <filter-period
                      :includeOptions="['thisWeek', 'thisSeason', 'nextSeason', 'prevSeason']"
                      selectedDefault="thisSeason"
                      @changePeriod="filterPeriod = $event"
                    ></filter-period>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Basis informatie -->
        <v-row class="d-flex mb-2">
          <benchmark-metadata :production="production"></benchmark-metadata>
        </v-row>
        <v-row class="d-flex mb-2 align-center">
          <!-- Submit button -->
          <v-col
            cols="12"
            sm="3"
          >
            <v-btn
              :disabled="selected.length < 3"
              rounded
              large
              elevation="0"
              color="secondary"
              class="text-lowercase"
              :to="{
                name: 'BenchmarkProductionsBenchmark',
                params: {
                  productionNumber: this.$route.params.productionNumber,
                },
                query: {
                  startDate: this.queryFrom,
                  endDate: this.queryTo,
                  theaterIds: this.selected.map((e) => e.id),
                },
              }"
              >start benchmark
              <v-icon
                small
                right
                >mdi-chart-bar</v-icon
              ></v-btn
            >
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <div v-if="selected.length < 3 && selected.length > 0">
              <v-alert
                type="info"
                class="my-0"
                color="secondary"
              >
                {{ `Selecteer minimaal 3 theaters (${selected.length} geselecteerd)` }}
              </v-alert>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table
          v-model="selected"
          :headers="tableBenchmarkHeadersFiltered"
          :items="tableBenchmarkFiltered"
          :mobile-breakpoint="0"
          :loading="tableBenchmarkLoading"
          :page.sync="tableBenchmarkPage"
          :items-per-page="tableBenchMarkItemsPerPage"
          :v-model:sort-by.sync="sortBy"
          hide-default-footer
          @update:current-items="tablePublicMonitorPageCount = Math.ceil(tableBenchmarkFiltered.length / tableBenchMarkItemsPerPage)"
          @update:items-per-page="tablePublicMonitorPageCount = Math.ceil(tableBenchmarkFiltered.length / tableBenchMarkItemsPerPage)"
          class="mt-5"
          @toggle-select-all="selectAllToggle"
          fixed-header
          :height="tableBenchmarkFiltered.length > 10 ? '45vh' : ''"
          show-select
          return-object
        >
          <template v-slot:[`item.firstStartDate`]="{ item }">
            {{ item.firstStartDateView }}
          </template>
          <template v-slot:[`item.lastStartDate`]="{ item }">
            {{ item.lastStartDateView }}
          </template>
        </v-data-table>

        <v-row class="pt-2">
          <v-col cols="12">
            <p class="font-weight-medium text-left caption text-primary ma-0 pa-0">
              totaal: {{ this.tableBenchmarkFiltered.length }}
            </p>
          </v-col>

          <v-col
            cols="12"
            sm="4"
            class="d-flex align-center justify-center justify-sm-start"
          >
            <v-btn
              v-if="tableBenchmarkFiltered.length > 25"
              @click="tableBenchMarkItemsPerPage = 25"
              :color="tableBenchMarkItemsPerPage == 25 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              size="small"
              class="pa-0 mr-1 font-weight-bold body-2"
              >25</v-btn
            >
            <v-btn
              v-if="tableBenchmarkFiltered.length > 25"
              @click="tableBenchMarkItemsPerPage = 50"
              :color="tableBenchMarkItemsPerPage == 50 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              size="small"
              class="pa-0 mr-1 font-weight-bold body-2"
              >50</v-btn
            >
            <v-btn
              v-if="tableBenchmarkFiltered.length > 50"
              @click="tableBenchMarkItemsPerPage = 100"
              :color="tableBenchMarkItemsPerPage == 100 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              size="small"
              class="pa-0 mr-1 font-weight-bold body-2"
              >100</v-btn
            >
          </v-col>

          <v-col
            cols="12"
            sm="4"
          >
            <v-pagination
              v-if="tablePublicMonitorPageCount > 1"
              v-model="tablePublicMonitorPage"
              :length="tablePublicMonitorPageCount"
              :total-visible="7"
              elevation="0"
              variant="elevated"
              active-color="primary"
              size="small"
            ></v-pagination>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <template v-if="selected.length > 0">
              <div class="pt-5 d-flex justify-start">
                <p class="text-primary font-weight-bold mb-0">huidige selectie</p>
              </div>
              <!-- Table -->
              <v-data-table
                :headers="tableBenchmarkSelectedTheaters"
                :items="selected"
                :mobile-breakpoint="0"
                :page.sync="tablePublicMonitorSelectedReportsPage"
                :items-per-page="tablePublicMonitorSelectedReportsItemsPerPage"
                hide-default-footer
                @page-count="tablePublicMonitorSelectedReportsPageCount = $event"
                :height="selected.length > 10 ? '45vh' : ''"
                class="table-action mt-5"
              >
                <template v-slot:[`item.firstStartDate`]="{ item }">
                  {{ item.firstStartDateView }}
                </template>
                <template v-slot:[`item.lastStartDate`]="{ item }">
                  {{ item.lastStartDateView }}
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <delete-dialog :title="deleteDialogTitle(item)"
                    :text="deleteDialogText(item)"
                    @confirm="(deleteItem = item), deleteItemSelected();" />
                </template>
              </v-data-table>

              <v-row class="pt-2">
                <v-col cols="12">
                  <p class="font-weight-medium text-left caption text-primary ma-0 pa-0">
                    totaal: {{ selected.length }}
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  class="d-flex align-center justify-center justify-sm-start"
                >
                  <v-btn
                    v-if="selected.length > 25"
                    @click="tablePublicMonitorSelectedReportsItemsPerPage = 25"
                    :color="tablePublicMonitorSelectedReportsItemsPerPage == 25 ? 'primary' : '#fff'"
                    height="45"
                    width="45"
                    elevation="0"
                    x-small
                    class="pa-0 mr-1 font-weight-bold body-2"
                    >25</v-btn
                  >
                  <v-btn
                    v-if="selected.length > 25"
                    @click="tablePublicMonitorSelectedReportsItemsPerPage = 50"
                    :color="tablePublicMonitorSelectedReportsItemsPerPage == 50 ? 'primary' : '#fff'"
                    height="45"
                    width="45"
                    elevation="0"
                    x-small
                    class="pa-0 mr-1 font-weight-bold body-2"
                    >50</v-btn
                  >
                  <v-btn
                    v-if="selected.length > 50"
                    @click="tablePublicMonitorSelectedReportsItemsPerPage = 100"
                    :color="tablePublicMonitorSelectedReportsItemsPerPage == 100 ? 'primary' : '#fff'"
                    height="45"
                    width="45"
                    elevation="0"
                    x-small
                    class="pa-0 mr-1 font-weight-bold body-2"
                    >100</v-btn
                  >
                </v-col>

                <!-- Table pagination -->
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-pagination
                    v-if="tablePublicMonitorSelectedReportsPageCount > 1"
                    v-model="tablePublicMonitorSelectedReportsPage"
                    :length="tablePublicMonitorSelectedReportsPageCount"
                    :total-visible="7"
                    :elevation="0"
                  ></v-pagination>
                </v-col>
              </v-row>
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import functions from '../../services/functions.service';
import FilterPeriod from '../../components/FilterPeriod';
import benchmarkMetadata from './components/Metadata';

export default {
  name: 'BenchmarkProductions',
  components: {
    BreadcrumbsWithHelp,
    FilterPeriod,
    benchmarkMetadata,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'benchmark producties',
          disabled: false,
          href: '/benchmark/producties',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      filterPeriod: {
        from: '',
        to: '',
      },
      production: {
        id: 0,
        title: '',
        productionNumber: '',
        genre: '',
        area: '',
        playDay: '',
      },
      selected: [],
      disabledCount: 0,
      productions: [],
      tableBenchmarkFiltered: [],
      tableBenchmarkLoading: true,
      sortBy: 'firstStartDate',
      tableBenchmarkHeaders: [
        { title: 'naam', key: 'name' },
        { title: 'plaats', key: 'city' },
        { title: 'aantal voorstellingen', key: 'performances' },
        { title: 'capaciteit', key: 'capacity' },
        { title: 'datum eerste voorstelling', key: 'firstStartDate' },
        { title: 'datum laatste voorstelling', key: 'lastStartDate' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
      tableBenchmarkPage: 1,
      tablePublicMonitorPage: 1,
      tablePublicMonitorPageCount: 0,
      tableBenchMarkItemsPerPage: 25,
      tablePublicMonitorSelectedReportsPage: 1,
      tablePublicMonitorSelectedReportsPageCount: 0,
      tablePublicMonitorSelectedReportsItemsPerPage: 25,
      reportSubmitLoading: false,
      deleteItem: '',
      tableBenchmarkFilterDialog: false,
      tableBenchmarkSelectedTheaters: [
        { title: 'naam', value: 'name' },
        { title: 'plaats', value: 'city' },
        { title: 'aantal voorstellingen', value: 'performances' },
        { title: 'capaciteit', value: 'capacity' },
        { title: 'datum eerste voorstelling', value: 'firstStartDate' },
        { title: 'datum laatste voorstelling', value: 'lastStartDate' },
        { title: '', value: 'action', sortable: false, align: 'end' },
      ],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('Benchmark');
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getEuroSign: function (value) {
      return functions.getEuroSignView(value);
    },
    getPercentageView: function (value) {
      return functions.getPercentageViewWithoutDecimals(value);
    },
    calculateArraySum: function (items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    },
    getDateTimeView(date) {
      return functions.getDateTimeView(date);
    },
    getProductionTheaters: function () {
       const vmLegacy = { $set: (o, k, v) => o[k] = v };
      this.productions = [];
      this.tableBenchmarkFiltered = [];
      this.tableBenchmarkLoading = true;

      ApiService.get('Benchmark/GetProduction', {
        productionNumber: this.$route.params.productionNumber,
        startDate: functions.jsDateToStringDate(this.filterPeriod.from),
        endDate: functions.jsDateToStringDate(this.filterPeriod.to),
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          const production = response.data.result;
          this.productions = response.data.result.theaters;

          this.production.id = production.id;
          this.production.title = production.title;
          this.production.productionNumber = production.number;
          this.production.genre = production.genre;
          this.production.firstStartDate = production.firstStartDate;
          this.production.lastStartDate = production.lastStartDate;
          this.production.area = production.area;
          this.production.playDay = production.playDay;
          this.breadcrumbs[1].text = production.title;

          for (var i = 0; i < this.productions.length; i++) {
            vmLegacy.$set(
              this.productions[i],
              'firstStartDateView',
              functions.getDateTimeView(this.productions[i].firstStartDate)
            );
            vmLegacy.$set(
              this.productions[i],
              'lastStartDateView',
              functions.getDateTimeView(this.productions[i].lastStartDate)
            );
          }

          this.tableBenchmarkFiltered = this.productions;
          this.tableBenchmarkLoading = false;
        })
        .catch((error) => {
          //eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    deleteItemSelected: function () {
      for (var i = 0; i < this.selected.length; i++) {
        if (this.selected[i].id === this.deleteItem.id) {
          this.selected.splice(i--, 1);
          this.deleteItem = '';
        }
      }
    },
    clearFilterProductions: function () {
      this.tableBenchmarkFilterActive = false;
    },
    selectAllToggle() {
      if (this.selected.length != this.tableBenchmarkFiltered.length - this.disabledCount) {
        this.selected = [];
        const self = this;
        this.tableBenchmarkFiltered.forEach((item) => {
          if (!item.disabled) {
            self.selected.push(item);
          }
        });
      } else {
        this.selected = [];
      }
    },
    deleteDialogTitle(value) {
      return `${value.name} verwijderen?`;
    },
    deleteDialogText(value) {
      return `Weet je zeker dat je ${value.name} met id ${value.id} wil verwijderen?`
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
    ...mapGetters('storage', ['getFilter']),

    tableBenchmarkHeadersFiltered() {
      return this.tableBenchmarkHeaders.filter((h) => !h.showFor || h.showFor.includes(this.user.organisationTypeId));
    },
    queryFrom() {
      return functions.jsDateToStringDate(new Date(this.filterPeriod.from));
    },
    queryTo() {
      return functions.jsDateToStringDate(new Date(this.filterPeriod.to));
    }
  },
  watch: {
    filterPeriod: function () {
      this.getProductionTheaters();
    },
  },
};
</script>