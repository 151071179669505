<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="3"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- Form -->
          <v-form @submit.prevent class="d-flex flex-column" ref="form">
            <v-col cols="12">
              <v-card title="gegevens gebruiker">
                <v-divider></v-divider>
                <v-card-text>
                  <v-row class="mt-0">
                    <!-- Voornaam -->
                    <v-col cols="6" class="py-1">
                      <v-text-field class="text-primary" label="voornaam" v-model="addUserForm.firstName"
                        :rules="formValidationRules.required"></v-text-field>
                    </v-col>

                    <!-- Achternaam -->
                    <v-col cols="6" class="py-1">
                      <v-text-field class="text-primary" label="achternaam" v-model="addUserForm.lastName"
                        :rules="formValidationRules.required"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <!-- Email -->
                    <v-col cols="12" class="py-1">
                      <v-text-field class="text-primary" label="e-mailadres" v-model="addUserForm.email"
                        :rules="formValidationRules.emailRequired"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <!-- Admin -->
                    <v-col cols="12" class="py-0">
                      <v-checkbox label="admin (medewerker DIP)" v-model="addUserForm.isSuperUser"
                        class="checkbox text-primary ma-0"></v-checkbox>
                    </v-col>
                  </v-row>

                  <template v-if="!addUserForm.isSuperUser">
                    <v-row>
                      <!-- Type organisatie -->
                      <v-col cols="12" class="py-1">
                        <v-select id="type_organisatie" label="type organisatie" 
                          v-model="addUserForm.organisationType" :items="organisationTypeOptions"
                          @update:modelValue="changeOrganisationTypeAndRoles"
                          :rules="formValidationRules.required"></v-select>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!-- Organisatie -->
                      <v-col cols="12" class="py-1">
                        <v-autocomplete label="organisatie" v-model="addUserForm.organisationId"
                          :items="organisationOptions" :rules="formValidationRules.required"></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!-- Userrol -->
                      <v-col cols="12" class="py-1">
                        <v-select id="gebruikersrol" label="gebruikersrol" v-model="addUserForm.roleId" :items="roleOptions"
                          :rules="formValidationRules.required"></v-select>
                      </v-col>
                    </v-row>
                  </template>

                  <v-alert type="error" variant="tonal" v-if="formError != ''">{{ formError }}</v-alert>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <div>
                    <v-btn-save @click="submit" :loading="formLoading">Opslaan</v-btn-save>
                  </div>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'AdminUsersAdd',
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      isEditing: false,
      formError: '',
      formLoading: false,
      addUserForm: {
        firstName: '',
        lastName: '',
        email: '',
        isSuperUser: false,
        organisationType: null,
        roleId: null,
        organisationId: null,
      },
      userRoleAgencieOptions: [],
      userRoleTheaterOptions: [],
      userRoleProducerOptions: [],
      organisationTypeOptions: [],
      organisationOptions: [],
      theaters: [],
      impresariaten: [],
      producenten: [],
      roleOptions: [],
      formValidationRules: {},
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'gebruikers',
          disabled: false,
          href: '/admin/gebruikers',
          bold: false,
        },
        {
          text: 'toevoegen',
          disabled: false,
          href: '/admin/gebruikers/add',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.getOrganisationTypes();
    this.getUserRoles();
    this.getOrganisations();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getUserRoles: function () {
      ApiService.get('/UserRoles/GetAll', {
        organisationTypeId: 1,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.userRoleAgencieOptions.push({ value: element.id, title: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
      ApiService.get('/UserRoles/GetAll', {
        organisationTypeId: 2,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.userRoleTheaterOptions.push({ value: element.id, title: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
      ApiService.get('/UserRoles/GetAll', {
        organisationTypeId: 3,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.userRoleProducerOptions.push({ value: element.id, title: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getOrganisations: function () {
      ApiService.get('/Theaters/GetAllDropDown')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.theaters.push({ value: element.id, title: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });

      ApiService.get('/Producers/GetAllDropDown')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.producenten.push({ value: element.id, title: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });

      ApiService.get('/Agencies/GetAllDropDown')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.impresariaten.push({ value: element.id, title: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    async submit() {
      this.formError = '';
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        if (this.addUserForm.isSuperUser) {
          this.addUserForm.organisationId = 0;
          this.addUserForm.organisationType = '';
          this.addUserForm.roleId = 0;
          this.addUserForm.userRoleId = 0;
        }

        const form = this.addUserForm;

        await ApiService.post('/Users/Add', {
          firstName: form.firstName,
          lastName: form.lastName,
          email: form.email,
          api: '',
          isSuperUser: form.isSuperUser,
          userRoleId: form.roleId,
          organisationId: form.organisationId,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();

            if (response.data.success) {
              if (response.data.result.userAlreadyExists) {
                this.formError = 'Deze gebruiker bestaat al.';
                this.formLoading = false;
              } else {
                this.isEditing = false;
                router.back();
              }
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
    getOrganisationTypes: function () {
      ApiService.get('/OrganisationTypes/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.organisationTypeOptions.push({
              value: element.organisationTypeId,
              title: element.organisationTypeName,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    changeOrganisationTypeAndRoles() {
      this.organisationOptions = [];
      this.roleOptions = [];

      this.addUserForm.organisationId = '';
      this.addUserForm.roleId = '';

      if (this.addUserForm.organisationType === 1) {
        this.organisationOptions = this.impresariaten;
        this.roleOptions = this.userRoleAgencieOptions;
      } else if (this.addUserForm.organisationType === 2) {
        this.organisationOptions = this.theaters;
        this.roleOptions = this.userRoleTheaterOptions;
      } else if (this.addUserForm.organisationType === 3) {
        this.organisationOptions = this.producenten;
        this.roleOptions = this.userRoleProducerOptions;
      }
    },
  },
  watch: {
    addUserForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
