<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="65"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- Page info first row -->
          <v-row class="flex align-center pb-2" no-gutters>
            <v-col cols="12" class="py-0">
              <!-- Form -->
              <v-form @submit.prevent class="d-flex flex-column" ref="form">
                <v-col cols="12">
                  <v-card title="voorstelling toevoegen">
                    <v-divider></v-divider>
                    <v-card-text class="mt-5">
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-autocomplete id="theater" label="theater" v-model="theaterId" :items="theaters"
                            :rules="formValidationRules.required"></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-autocomplete label="zalen" v-model="addProductionPerformanceForm.areaId" :items="areas"
                            :rules="formValidationRules.required"></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-checkbox label="In het kader van een festival?"
                            v-model="addProductionPerformanceForm.festival"></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-text-field label="Aantal voorstellingen"
                            v-model="addProductionPerformanceForm.performanceCount"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-alert type="error" variant="tonal" v-if="formError != ''">{{ formError }}</v-alert>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <div>
                        <v-btn-save @click="submit" :loading="formLoading">Opslaan</v-btn-save>
                      </div>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'LibraryProductionsPerformanceAddBulk',
  props: ['productionNumber'],
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      production: {
        name: '',
      },
      isEditing: false,
      formValid: false,
      formError: '',
      addProductionPerformanceForm: {
        theaterId: null,
        areaId: null,
        festival: false,
        performanceCount: '',
      },
      theaters: [],
      areas: [],
      performanceStatus: [
        { title: 'interesse', value: 'interest' },
        { title: 'optie', value: 'option' },
        { title: 'geaccepteerd', value: 'agreed' },
        { title: 'succesoptie', value: 'success' },
        { title: 'annulering', value: 'cancellation' },
      ],
      performanceType: [
        { title: 'normaal', value: 'normal' },
        { title: 'tryout', value: 'tryout' },
        { title: 'premiere', value: 'premiere' },
        { title: 'montage', value: 'montage' },
        { title: 'derniere', value: 'derniere' },
      ],
      formValidationRules: {},
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producties',
          disabled: false,
          href: '/beheer/producties',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'voorstelling toevoegen',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryProductionsPerformanceAddBulk');
    this.checkRights();
    this.formValidationRules = functions.getFormValidationRules();
    this.getProduction();
    this.getOrganisations();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    checkRights() {
      if (!this.rights.edit) {
        router.back();
      }
    },
    getProduction: function () {
      ApiService.get('/Productions/Get', {
        productionNumber: this.productionNumber,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const production = response.data.result;
            this.breadcrumbs[2].text = production.title;
            this.breadcrumbs[2].href = '/beheer/producties/' + this.productionNumber + '/';
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          //router.back();
        });
    },
    getOrganisations: function () {
      ApiService.get('/Theaters/GetAllDropDown')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.theaters.push({ value: element.id, title: `${element.name} (${element.city}, ${element.id})` });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getAreas: function () {
      this.areas = [];
      ApiService.get('/TheaterAreas/GetAll', {
        theaterId: this.addProductionPerformanceForm.theaterId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.areas.push({ value: element.id, title: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    async submit() {
      this.formError = '';
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const form = this.addProductionPerformanceForm;
        ApiService.post('/Performances/AddBulk', {
          productionNumber: this.productionNumber,
          theaterId: form.theaterId,
          theaterAreaId: form.areaId,
          isFestival: form.festival,
          performanceCount: parseInt(form.performanceCount),
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    theaterId: {
      get() {
        return this.addProductionPerformanceForm.theaterId;
      },
      set(value) {
        this.addProductionPerformanceForm.theaterId = value;
        this.getAreas();
      },
    },
  },
  watch: {
    addProductionPerformanceForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
