<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="34"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- Page info first row -->
          <v-row class="d-flex" justify="center" align="center" no-gutters>
            <v-col cols="12">
              <v-card title="gegevens castlid">
                <v-divider></v-divider>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <p class="text-primary mb-0">
                        <small>naam:</small> <br />
                        <strong>{{ creativeCast.name }}</strong>
                      </p>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <div>
                    <p class="grey--text text--lighten-1 mb-2">
                      <small><i>{{ this.creativeCast.lastEditedBy }}</i></small>
                    </p>

                    <!-- Bewerken -->
                    <v-btn-edit class="mr-5" :to="{ name: 'AdminCreativeCastEdit', params: { creativeCastId } }">Gegevens Bewerken</v-btn-edit>

                    <!-- Verwijderen -->
                    <delete-dialog buttonText="verwijderen" :title="deleteDialogTitle"
                    :text="deleteDialogText" @confirm="deleteItemCreativeCast()" />
                  </div>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Snackbar -->
      <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ this.snackbarText }}
        <template v-slot:actions>
          <v-btn color="#fff" variant="text" @click="(snackbar = false), (snackbarText = '')">
            Sluiten
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';

import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'AdminCreativeCastDetail',
  props: ['creativeCastId'],
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      creativeCast: {
        name: '',
        lastEditedBy: '',
      },
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'creatieve cast',
          disabled: false,
          href: '/admin/creatievecast',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
    };
  },
  mounted() {
    this.getCreativeCast();
  },
  methods: {
    getCreativeCast: function () {
      ApiService.get('/CreativeCasts/Get', {
        creativeCastId: this.creativeCastId,
      })
        .then((response) => {
          if (response.data.success) {
            const creativeCast = response.data.result;
            this.breadcrumbs[2].text = creativeCast.name;
            this.creativeCast.name = creativeCast.name;
            this.creativeCast.lastEditedBy = creativeCast.lastEditedBy;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    deleteItemCreativeCast: function () {
      ApiService.delete('/CreativeCasts/Delete', {
        creativeCastId: this.creativeCastId,
      })
        .then((response) => {
          if (response.data.success) {
            this.snackbarText = this.creativeCast.name + ' is verwijderd';
            this.snackbarColor = 'secondary';
            this.snackbar = true;
            setTimeout(function () {
              router.push({ path: '/admin/creatievecast' });
            }, 1500);
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {
    deleteDialogTitle() {
      return `${this.creativeCast.name} verwijderen?`;
    },
    deleteDialogText() {
      return `Weet je zeker dat je ${this.creativeCast.name} wil verwijderen? Het is daarna niet meer terug te vinden.`;
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
