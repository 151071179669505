<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="54"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">

          <v-col cols="12">
            <v-card title="gegevens zaal">
              <v-divider></v-divider>
              <v-card-text class="mt-5">
                <v-row>
                  <v-col cols="12" class="py-0">
                    <p class="text-primary mb-0">
                      <small>naam: </small><br /><strong> {{ area.name }}</strong>
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card title="adres">
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <p class="text-primary mb-0">
                      <small>straat en huisnummer:</small> <br />
                      <strong>{{ area.street + ' ' + area.number }}</strong>
                    </p>
                    <hr class="accent-hr my-2" />
                    <p class="text-primary mb-0">
                      <small>plaats:</small> <br />
                      <strong>{{ area.city }}</strong>
                    </p>
                    <hr class="accent-hr my-2" />
                    <p class="text-primary mb-0">
                      <small>postcode:</small> <br />
                      <strong>{{ area.zipCode }}</strong>
                    </p>
                    <hr class="accent-hr my-2" />
                    <p class="text-primary mb-0">
                      <small>provincie:</small> <br />
                      <strong>{{ area.province.name }}</strong>
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <div>
                  <p class="grey--text text--lighten-1 mb-2">
                    <small><i>{{ this.area.lastEditedBy }}</i></small>
                  </p>
                </div>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-row class="pa-0 ma-0 mb-2 d-flex flex-row align-center justify-space-between">
              <h4 class="text-primary font-weight-regular">toeslagen</h4>
              <v-btn v-if="rights.edit" rounded small elevation="0" color="primary" class="text-lowercase"
                :to="{ name: 'LibraryAreasSurchargeAdd', params: { theaterAreaId } }">toeslag toevoegen</v-btn>
            </v-row>

            <!-- Table -->
            <v-data-table :headers="tableTheaterAreasSurchargesHeaders" :items="tableTheaterAreasSurchargesFiltered"
              :mobile-breakpoint="0" :loading="tableTheaterAreasSurchargesLoading" :items-per-page="-1"
              hide-default-footer class="table-action">
              <template v-slot:[`item.action`]="{ item }">
                <v-btn v-if="rights.edit" rounded="circle" elevation="0" size="small" icon="mdi-square-edit-outline"
                  color="info" class="ml-1"
                  :to="{ name: 'LibraryAreasSurchargeEdit', params: { surchargeId: item.id } }">
                  <v-icon color="primary">mdi-square-edit-outline</v-icon>
                </v-btn>
                <delete-dialog v-if="rights.edit" title="Toeslag verwijderen?"
                    text="Weet je zeker dat je deze toeslag wil verwijderen? Het is daarna niet meer terug te vinden."
                    @confirm="(deleteItemLink = item), deleteAreaSurcharge();" />
              </template>
            </v-data-table>
          </v-col>
        </v-col>
      </v-row>

      <!-- Snackbar -->
      <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ this.snackbarText }}
        <template v-slot:actions>
          <v-btn color="#fff" variant="text" @click="(snackbar = false), (snackbarText = '')">
            Sluiten
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'LibraryAreasDetail',
  props: ['theaterAreaId'],
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      area: {
        name: '',
        street: '',
        number: '',
        zipCode: '',
        city: '',
        province: '',
        lastEditedBy: '',
      },
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'zalen',
          disabled: false,
          href: '/beheer/zalen',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      tableTheaterAreasSurchargesHeaders: [
        { title: 'startdatum', key: 'startDate' },
        { title: 'theatertoeslag', key: 'theaterFee', dataType: 'Currency' },
        { title: 'consumptietoeslag', key: 'consumptionFee' },
        { title: 'servicetoeslag', key: 'serviceFee' },
        { title: 'garderobetoeslag', key: 'wardrobeFee' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
      tableTheaterAreasSurchargesFiltered: [],
      tableTheaterAreasSurchargesLoading: true,
    };
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryAreasDetail');
    this.getArea();
    this.getTheaterAreaSurcharges();
  },
  methods: {
    getArea: function () {
      ApiService.get('/TheaterAreas/Get', {
        theaterAreaid: this.theaterAreaId,
      })
        .then((response) => {
          if (response.data.success) {
            const area = response.data.result;
            this.breadcrumbs[2].text = area.name;
            this.area.name = area.name;
            this.area.street = area.street;
            this.area.number = area.number;
            this.area.city = area.city;
            this.area.zipCode = area.zipCode;
            this.area.province = area.province;
            this.area.lastEditedBy = area.lastEditedBy;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getTheaterAreaSurcharges: function () {
      ApiService.get('/Surcharges/GetAllPerTheaterArea', {
        theaterAreaId: this.theaterAreaId,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableTheaterAreasSurchargesFiltered = response.data.result.map(surcharge => ({
              ...surcharge,
              theaterFee: functions.getEuroSignView(surcharge.theaterFee),
              consumptionFee: functions.getEuroSignView(surcharge.consumptionFee),
              serviceFee: functions.getEuroSignView(surcharge.serviceFee),
              wardrobeFee: functions.getEuroSignView(surcharge.wardrobeFee),
              startDate: functions.getDateView(surcharge.startDate),
            }));
            this.tableTheaterAreasSurchargesLoading = false;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    },
    deleteAreaSurcharge: function () {
      ApiService.delete('/Surcharges/Delete', {
        surchargeId: this.deleteItemLink.id,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.tableTheaterAreasSurchargesFiltered.map((item) => item.id).indexOf(this.deleteItemLink.id);
            this.tableTheaterAreasSurchargesFiltered.splice(i, 1);

            this.snackbarText = 'Toeslag is verwijderd';
            this.snackbarColor = 'secondary';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
