<template>
  <v-container class="d-flex align-center justify-center" style="min-height: 100vh">
    <v-container class="align-center justify-center pa-5">
      <div>
        <v-img src="/images/dip-logo.svg" contain aspect-ratio="1.6" width="115" max-width="100%"
          class="ma-5 mx-auto" />
      </div>
      <v-card class="d-flex" flat>
        <v-row class="align-center justify-center">
          <v-col cols="12" md="6" class="pa-6 pt-12">
            <h1 class="text-center text-primary mb-5">Wachtwoord vergeten</h1>
            <v-form @submit.prevent class="col col-12" ref="form">
              <v-text-field label="E-mailadres" v-model="email" :rules="formValidationRules.required" />

              <v-btn rounded x-large elevation="0" @click="submit" :loading="formLoading" color="secondary"
                class="text-lowercase font-weight-bold my-2 mr-5">
                verstuur
              </v-btn>

              <v-btn rounded x-large variant="outlined" elevation="0" color="primary"
                class="text-lowercase font-weight-bold my-2" :to="{ name: 'login' }">
                terug
              </v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import ApiService from '../../services/api.service';
import router from '../../router/router.js';
import functions from '../../services/functions.service';

export default {
  name: 'forgotPassword',
  data() {
    return {
      formLoading: false,
      email: '',
      formValidationRules: {},
    };
  },
  computed: {},
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
  },
  methods: {
    async submit() {
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const requestData = {
          method: 'post',
          url: '/Account/ForgotPassword',
          data: {
            emailAddress: this.email,
          },
        };

        ApiService.customRequest(requestData)
          .then((response) => {
            if (response.data.success) {
              router.push('/login');
            } else {
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch((error) => {
            this.formError = error;
            // eslint-disable-next-line
            console.log('error ' + error);
            this.errored = true;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
};
</script>

<style scoped></style>