<template>
  <v-container
    class="h-sceen flex items-center justify-center bg-gray-50"
    style="max-width: 1200px;"
  >
    <v-container class="align-center justify-center pa-5">
      <div>
        <v-img
          src="/images/dip-logo.svg"
          contain
          aspect-ratio="1.6"
          width="115"
          max-width="100%"
          class="ma-5 mx-auto"
        />
      </div>
      <v-card
        elevation="3"
        class="rounded-lg overflow-hidden w-full md:w-1/2 mx-auto">
        <v-row no-gutters>
          <v-col cols="12" md="6" class="hidden md:block relative" :style="{ backgroundImage: 'url(/images/img-login.png)',  backgroundSize: 'cover', 
          backgroundPosition: 'center' }">
        </v-col>
        <v-col cols="12" md="6" class="bg-white p-6 md:p-8">
          <v-container
            pa-6
            pt-8
            class="d-flex flex-column align-center"
          >
            <h1 class="text-center text-primary mb-2">Inloggen</h1>
            <v-col
              cols="12"
              class="pa-0 mb-2 text-right"
            >
              <v-btn
                color="#f4f7fc"
                rounded="circle"
                density="default"
                size="x-small"
                icon="mdi-help"
                @click="dialogHelp = true"
              >
                <v-icon color="primary">mdi-help</v-icon>
              </v-btn>

              <v-dialog
                v-model="dialogHelp"
                max-width="700px"
              >
                <v-card>
                  <v-card-title class="text-primary d-flex align-center">Inloggen
                    <v-spacer></v-spacer>
                    <v-btn
                      color="#fff"
                      rounded="circle"
                      size="small"
                      icon="mdi-close"
                      @click="dialogHelp = false">
                      <v-icon color="primary" x-small>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="text-primary caption pre-wrap">
                    <p>
                      Welkom bij DIP, via de DIP-applicatie kan je de verkoopoverzichten van jouw organisatie bekijken
                      in het verkoopmutaties overzicht. Momenteel zijn we bezig met een gefaseerde oplevering, dit
                      betekent dat het invoeren van producties en voorstellingen en het aanmaken van financiële
                      afspraken nu nog gedaan moet worden via
                      <a
                        href="https://applicatie.podiumkunst.info/login"
                        target="_blank"
                        >Podiumkunst.info</a
                      >.
                    </p>
                    <p>
                      Inloggen doe je door je gebruikersnaam en wachtwoord in te vullen en vervolgens op ‘inloggen’ te
                      klikken. Ben je je wachtwoord vergeten dan kan je deze opnieuw aanmaken door te klikken op
                      ‘wachtwoord vergeten?’ <br />Lukt het inloggen niet, neem dan een kijkje op:
                      <a
                        href="http://support.podiumkunst.info/support/solutions/articles/13000036073"
                        target="_blank"
                        >http://support.podiumkunst.info/support/solutions/articles/13000036073</a
                      >
                    </p>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
            <v-form
              @submit.prevent
              v-model="formValid"
              class="d-flex flex-column"
              ref="form"
            >
              <v-text-field
                label="e-mailadres"
                validate-on-blur
                v-model="email"
                :rules="formValidationRules.emailRequired"
                variant="outlined"
                bg-color="#fff"
                append-inner-icon="mdi-email-outline"
              />
              <v-text-field
                label="Wachtwoord"
                validate-on-blur
                v-model="password"
                min="8"
                password
                :rules="formValidationRules.required"
                type="password"
                required
                variant="outlined"
                bg-color="#fff"
                append-inner-icon="mdi-lock"
              />

              <v-alert
                color="error"
                v-if="authenticationError"
              >
                {{ authenticationError }}
              </v-alert>

              <v-col
                cols="12"
                class="d-flex align-center justify-end text-right mb-2"
              >
                <router-link :to="{ name: 'forgotPassword' }">Wachtwoord vergeten?</router-link>
              </v-col>

              <v-btn
                :disabled="!formValid"
                rounded
                block
                x-large
                elevation="0"
                @click="submit"
                :loading="authenticating"
                color="secondary"
                class="text-lowercase font-weight-bold mb-5"
              >
                inloggen
              </v-btn>

              <v-alert
                variant="elevated" 
                class="mt-2 mb-0"
              >
                <h4 class="font-weight-bold mb-1">Nog geen account?</h4>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfi8znPDPdIRhBssoy0kJOGbaDXj7UAlmjwfuSX5RRmcOHn3Q/viewform"
                  target="_blank"
                  >Meld je nu aan via het registratieformulier.</a
                >
                <br />
                Kijk op
                <a
                  href="https://www.dip.nl"
                  target="_blank"
                  >www.dip.nl</a
                >
                voor meer informatie en tarieven.
              </v-alert>
            </v-form>
            <v-row class="pt-6 d-flex nowrap">
              <v-col cols="12">
                <HelpButtons />
              </v-col>
            </v-row>
          </v-container>
          </v-col>
        </v-row>
      </v-card>
  </v-container>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import functions from '../../services/functions.service';
import HelpButtons from '@/components/HelpButtons';

export default {
  name: 'login',
  components: { HelpButtons },
  data() {
    return {
      formValid: false,
      formValidationRules: {},
      email: null,
      password: null,
      dialogHelp: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['authenticating', 'authenticationError', 'authenticationErrorCode']),
  },
  beforeMount() {
    this.formValidationRules = functions.getFormValidationRules();
  },
  mounted() {
    let self = this;
    window.addEventListener('keyup', function (event) {
      if (event.code === 'Enter') {
        self.submit();
      }
    });
  },
  methods: {
    ...mapActions('auth', ['login']),
    submit() {
      if (this.$refs.form.validate()) {
        this.login({ email: this.email, password: this.password });
      }
    },
  },
};
</script>

<style scoped></style>