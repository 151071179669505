<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="1121"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- Form -->
          <v-form @submit.prevent class="d-flex flex-column" ref="form">
            <v-card title="impresariaat koppeling wijzigen">
              <v-divider></v-divider>
              <v-card-text>
                <v-row class="mt-0">
                  <v-col cols="12" class="py-1">
                    <v-autocomplete label="impresariaat koppeling" v-model="editProductionLinkForm.agencyId"
                      :items="agencyOptions" clearable></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-1">
                    <v-text-field placeholder="DD-MM-JJJJ" v-model="editProductionLinkForm.date"
                      :rules="formValidationRules.dateMaxToday"></v-text-field>
                  </v-col>
                </v-row>

                <v-alert type="error" variant="tonal" v-if="formError != ''">{{ formError }}</v-alert>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <div>
                  <v-btn-save @click="checkChangeProductionAgencyLink" :loading="formLoading">Opslaan</v-btn-save>
                </div>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>

      <!-- Delete dialog agency link starts here -->
      <v-dialog v-model="changeProductionAgencyLinkDialog" persistent width="500">
        <v-card class="pa-6" flat>
          <v-alert v-if="changeProductionAgencyLinkDialogInfo.warning" type="warning" color="#fb8c00">Let op!</v-alert>
          <v-card-title class="headline">{{ changeProductionAgencyLinkDialogInfo.title }}</v-card-title>
          <v-card-text>
            {{ changeProductionAgencyLinkDialogInfo.text }}
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="
              changeProductionAgencyLinkDialog = false;
            formLoading = false;
            ">Nee</v-btn>
            <v-btn color="primary" text @click="changeProductionAgencyLink()">Ja</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Snackbar -->
      <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ this.snackbarText }}
        <template v-slot:actions>
          <v-btn color="#fff" variant="text" @click="(snackbar = false), (snackbarText = '')">
            Sluiten
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import { TokenService } from '../../../services/storage.service';
import functions from '../../../services/functions.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'AdminProducersProductionsAgencyLinkEdit',
  props: ['producerId', 'productionNumber'],
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      producer: {},
      production: {},
      isEditing: false,
      formError: '',
      formLoading: false,
      editProductionLinkForm: {
        agencyId: null,
        date: '',
      },
      formValidationRules: {},
      agencyOptions: [],
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producenten',
          disabled: false,
          href: '/admin/producenten',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producties',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'bewerken',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',

      changeProductionAgencyLinkDialog: false,
      changeProductionAgencyLinkDialogInfo: {
        title: '',
        text: '',
        warning: false,
      },
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.getProducer();
    this.getAgencies();
    this.getProduction();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getProducer: function () {
      ApiService.get('/Producers/Get', {
        producerId: this.producerId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const producer = response.data.result;
            this.producer = producer;
            this.breadcrumbs[2].text = producer.name;
            this.breadcrumbs[2].href = '/admin/producenten/' + this.producerId + '/';
            this.breadcrumbs[3].href = '/admin/producenten/' + this.producerId + '/producties';
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getAgencies: function () {
      ApiService.get('/AgencyProducerLinks/GetPerProducer', {
        producerId: this.producerId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.agencyOptions.push({ value: element.agencyId, title: element.agencyName });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getProduction: function () {
      const vm = this;

      ApiService.get('/Productions/Get', {
        productionNumber: this.productionNumber,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const production = response.data.result;
            this.breadcrumbs[4].text = production.title;
            this.production = production;
            this.editProductionLinkForm.agencyId = production.agency ? production.agency.id : null;
            setTimeout(function () {
              vm.isEditing = false;
            }, 200);
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    async checkChangeProductionAgencyLink() {
      this.formError = '';
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const form = this.editProductionLinkForm;

        ApiService.get('/Productions/CanChangeProductionAgency', {
          productionNumber: this.productionNumber,
          startDate: form.date != '' ? functions.getDateDatabase(form.date) : null,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              this.changeProductionAgencyLinkDialogInfo.title = response.data.result.title;
              this.changeProductionAgencyLinkDialogInfo.text = response.data.result.text;
              this.changeProductionAgencyLinkDialogInfo.warning = !response.data.result.canChange;
              this.changeProductionAgencyLinkDialog = true;
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
    async changeProductionAgencyLink() {
      this.formError = '';
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const form = this.editProductionLinkForm;

        ApiService.put('/Productions/ChangeProductionAgency', {
          productionNumber: this.productionNumber,
          agencyId: form.agencyId,
          startDate: form.date != '' ? functions.getDateDatabase(form.date) : null,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              this.snackbarText = 'koppeling is aangepast';
              this.changeProductionAgencyLinkDialog = false;
              this.snackbarColor = 'secondary';
              this.snackbar = true;
              this.formLoading = false;
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {},
  watch: {
    editProductionLinkForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
