import CryptoJS from 'crypto-js';
import deviceuuid from 'device-uuid/index';

const du = new deviceuuid.DeviceUUID().parse();
const dua = [
  du.language,
  du.platform,
  du.os,
  du.cpuCores,
  du.isAuthoritative,
  du.silkAccelerated,
  du.isKindleFire,
  du.isDesktop,
  du.isMobile,
  du.isTablet,
  du.isWindows,
  du.isLinux,
  du.isLinux64,
  du.isMac,
  du.isiPad,
  du.isiPhone,
  du.isiPod,
  du.isSmartTV,
  du.pixelDepth,
  du.isTouchScreen,
];
const uuid = du.hashMD5(dua.join(':'));

const TOKEN_KEY = 'access_token';
let canSetCookie = true;

function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/; SameSite=Strict;';
}

function getCookie(name) {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name) {
  document.cookie = name + '=; Max-Age=-99999999; path=/; SameSite=Strict;';
  return getCookie(name) === null;
}

// TokenService Definition
const TokenService = {
  getToken() {
    const token = getCookie(TOKEN_KEY);
    if (token == null) {
      return '';
    }
    return CryptoJS.AES.decrypt(token.toString(), uuid).toString(CryptoJS.enc.Utf8);
  },

  saveToken(accessToken) {
    if (canSetCookie) {
      const encryptedToken = CryptoJS.AES.encrypt(accessToken.toString(), uuid).toString();
      setCookie(TOKEN_KEY, encryptedToken, 1 / 24 / 2.2);
    }
  },

  removeToken() {
    canSetCookie = false;
    return eraseCookie(TOKEN_KEY);
  },

  changeCanSetCookie(value) {
    canSetCookie = value;
  },
};

export { TokenService };