<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="23"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12" class="py-0">
          <!-- Form -->
          <v-form @submit.prevent class="d-flex flex-column" ref="form">
            <v-col cols="12">
              <v-card title="gegevens impresariaat">
                <v-divider></v-divider>
                <v-card-text>
                  <v-row class="mt-0">
                    <!-- Name -->
                    <v-col cols="12" class="py-1">
                      <v-text-field class="text-primary" label="naam" v-model="editAgencyForm.name"
                        :rules="formValidationRules.required"></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- Branchevereniging bijwerken -->
                  <v-row>
                    <v-col cols="12" class="py-1">
                      <v-autocomplete label="selecteer branchevereniging" v-model="editAgencyForm.branchAssociationId"
                        :items="branchAssociations" clearable></v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" class="py-0">
                      <!-- Voorwaarden DIP geaccepteerd -->
                      <v-checkbox label="voorwaarden DIP geaccepteerd" hide-details
                        v-model="editAgencyForm.agreedToTerms" class="checkbox text-primary ma-0"></v-checkbox>
                      <!-- Publieksmonitor tonen -->
                      <v-checkbox label="toon publieksmonitor" hide-details
                        v-model="editAgencyForm.hasPublicMonitorAccess" class="checkbox text-primary ma-0"></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card title="adres">
                <v-divider></v-divider>
                <v-card-text>
                  <v-row class="mt-0">
                    <!-- Straat -->
                    <v-col cols="8" class="py-1">
                      <v-text-field label="straat" v-model="editAgencyForm.street"
                        :rules="formValidationRules.required"></v-text-field>
                    </v-col>

                    <v-col cols="4" class="py-1">
                      <!-- Nummer -->
                      <v-text-field label="huisnummer" v-model="editAgencyForm.number"
                        :rules="formValidationRules.required"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" class="py-1">
                      <!-- Postcode -->
                      <v-text-field label="postcode" v-model="editAgencyForm.zipCode"
                        :rules="formValidationRules.zipCodeRequired"></v-text-field>
                    </v-col>
                    <v-col cols="8" class="py-1">
                      <!-- Plaats -->
                      <v-text-field label="plaats" v-model="editAgencyForm.city"
                        :rules="formValidationRules.required"></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- Provincie -->
                  <v-row>
                    <v-col cols="12" class="py-1">
                      <v-autocomplete label="provincie" v-model="editAgencyForm.province" :items="provincieOptions"
                        :rules="formValidationRules.required"></v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-alert type="error" variant="tonal" v-if="formError != ''">{{ formError }}</v-alert>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <div>
                    <p class="grey--text text--lighten-1 mb-2">
                      <small><i>{{ this.lastEditedBy }}</i></small>
                    </p>

                    <v-btn-save @click="submit" :loading="formLoading">Opslaan</v-btn-save>
                  </div>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'AdminAgenciesEdit',
  props: ['agencyId'],
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      isEditing: false,
      formError: '',
      formLoading: false,
      editAgencyForm: {
        name: '',
        agreedToTerms: false,
        hasPublicMonitorAccess: false,
        street: '',
        number: '',
        zipCode: '',
        city: '',
        province: null,
        branchAssociationId: null,
      },
      lastEditedBy: '',
      provincieOptions: [],
      branchAssociations: [],
      formValidationRules: {},
      agencyname: '',
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: ' impresariaten',
          disabled: false,
          href: '/admin/impresariaten',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.getAgency();
    this.getProvinces();
    this.getBranchAssociations();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getAgency: function () {
      const vm = this;

      ApiService.get('/Agencies/Get', {
        agencyId: this.agencyId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const agency = response.data.result;
            this.breadcrumbs[2].text = agency.name;
            this.breadcrumbs[2].href = '/admin/impresariaten/' + agency.id;
            this.agencyname = agency.name;
            this.editAgencyForm.agreedToTerms = agency.agreedToTerms;
            this.editAgencyForm.hasPublicMonitorAccess = agency.hasPublicMonitorAccess;
            this.editAgencyForm.name = agency.name;
            this.editAgencyForm.street = agency.street;
            this.editAgencyForm.number = agency.number;
            this.editAgencyForm.zipCode = agency.zipCode;
            this.editAgencyForm.city = agency.city;
            this.editAgencyForm.province = agency.province.id;
            this.lastEditedBy = agency.lastEditedBy;

            if (agency.branchAssociation != null) {
              this.editAgencyForm.branchAssociationId = agency.branchAssociation.id;
            }

            setTimeout(function () {
              vm.isEditing = false;
            }, 200);
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getProvinces: function () {
      ApiService.get('/Provinces/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.provincieOptions.push({
              value: element.id,
              title: element.name,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    getBranchAssociations: function () {
      ApiService.get('/BranchAssociations/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.branchAssociations.push({
              value: element.id,
              title: element.name,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    submit() {
      this.formError = '';
      this.formLoading = true;

      const form = this.editAgencyForm;

      ApiService.put('/Agencies/Update', {
        id: this.agencyId,
        agreedToTerms: form.agreedToTerms,
        hasPublicMonitorAccess: form.hasPublicMonitorAccess,
        name: form.name,
        street: form.street,
        number: form.number,
        zipCode: form.zipCode,
        city: form.city,
        provinceId: form.province,
        branchAssociationId: form.branchAssociationId != '' ? form.branchAssociationId : null,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            this.isEditing = false;
            router.back();
          } else {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          }
        })
        // eslint-disable-next-line
        .catch(() => {
          this.formError =
            'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
          this.formLoading = false;
        });
    },
  },
  computed: {},
  watch: {
    editAgencyForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
