<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row flex align="center" justify="space-between" class="px-3 mb-4">
          <v-col class="py-3 add-row d-flex justify-sm-space-between align-center">
            <span class="d-none d-sm-flex">Meer cijfers?</span>
            <div>
              <v-btn v-if="this.user.organisationTypeId != 2" color="primary" rounded depressed
                class="font-weight-bold text-lowercase ma-1" :to="{ name: 'SalesMutationsSummary' }">
                samenvatting
                <v-icon small right>mdi-chart-bar-stacked</v-icon>
              </v-btn>
              <v-btn v-else color="primary" rounded depressed class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsProductions' }">
                producties
                <v-icon small right>mdi-chart-bar-stacked</v-icon>
              </v-btn>
              <v-btn color="primary" rounded depressed class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsPerformances' }">
                voorstellingen
                <v-icon small right>mdi-chart-bar-stacked</v-icon>
              </v-btn>
              <v-btn color="primary" rounded depressed class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsProductions10weeks' }">
                producties laatste 10 weken
                <v-icon small right>mdi-chart-bar-stacked</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row flex justify="center" align="center" class="pb-2" no-gutters>
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="1128"></breadcrumbs-with-help>
        </v-row>

        <div v-show="!tableProductionsLoading">
          <!-- Table -->
          <v-data-table 
            :headers="tableProductionsHeaders" 
            :items="tableProductions" 
            :mobile-breakpoint="0"
            :loading="tableProductionsLoading" 
            :page="tableProductionsPaging.page"
            :items-per-page="tableProductionsPaging.itemsPerPage" 
            @update:current-items="tableProductionsPaging.pageCount = Math.ceil(tableProductions.length / tableProductionsPaging.itemsPerPage)"
            @update:items-per-page="tableProductionsPaging.pageCount = Math.ceil(tableProductions.length / tableProductionsPaging.itemsPerPage)"
            class="table-action table-expand mt-2" 
            item-value="productionNumber"
            hide-default-footer
            single-expand 
            show-expand 
            fixed-header
            :height="tableProductions.length > 10 ? '55vh' : ''">
            <template v-slot:[`item.firstPerformanceDate`]="{ item }">
              {{ item.firstPerformanceDate_formatted }}
            </template>

            <template v-slot:[`item.capacity`]="{ item }">
              <span>{{ formatThousandsWithDot(item.capacity) }}</span>
            </template>

            <template v-slot:expanded-row="{ columns, item }">
              <td :colspan="columns.length" class="pa-0">
                <v-data-table 
                  :headers="tableProductionsDetailsHeaders" 
                  :items="item.details" 
                  :mobile-breakpoint="0"
                  hide-default-footer 
                  :items-per-page="-1" 
                  class="table-action">
                  <template v-slot:[`item.type`]="{ item }">
                    <strong class="text-primary">{{ item.type }}</strong>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>

          <!-- Table settings -->
          <table-settings 
            :items="tableProductions" 
            :paging="tableProductionsPaging"
            :export-columns="downloadExportColumns" 
            :file-name="'dip-verkoopmutaties-producties-laatste-7-dagen'"
            :sheet-name="'producties laatste 7 dagen'" 
            :show-excel-button="true">
          </table-settings>
        </div>
        <div v-show="tableProductionsLoading">
          <div class="text-center progress-circular">
            <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular>
            <h4 class="text-primary mt-5">Een moment geduld aub, je gegevens worden berekend. Dit kan even duren.</h4>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import TableSettings from '@/components/TableSettings';
import functions from '../../services/functions.service';

export default {
  name: 'SalesMutationsProductions7days',
  components: {
    BreadcrumbsWithHelp,
    TableSettings
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'verkoopmutaties',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producties laatste 7 dagen',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      inPast: false,
      tableProductions: [],
      tableProductionsLoading: true,
      tableProductionsHeaders: [
        { title: 'productienummer', key: 'productionNumber' },
        { title: 'eerste speeldatum', key: 'firstPerformanceDate' },
        { title: 'productie', key: 'productionTitle' },
        { title: 'genre', key: 'genreName' },
        { title: 'capaciteit', key: 'capacity' },
        { title: '', key: 'data-table-expand', align: 'end' },
      ],
      tableProductionsPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
      },
      tableProductionsDetailsHeaders: [
        { title: '', key: 'type' },
        { title: 'Zondag 25-10', key: 'dag1' },
        { title: 'Zaterdag 24-10', key: 'dag2' },
        { title: 'Vrijdag 23-10', key: 'dag3' },
        { title: 'Donderdg 22-10', key: 'dag4' },
        { title: 'Woensdag 21-10', key: 'dag5' },
        { title: 'Dinsdag 20-10', key: 'dag6' },
        { title: 'Maandag 20-10', key: 'dag7' },
      ],
      exportProductions: [],
      exportProductionsColumns: [],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('SalesMutations');
    this.getProductions();
  },
  methods: {
    getEuroSign: function (value) {
      return functions.getEuroSignView(value);
    },
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getPercentageView: function (value) {
      return functions.getPercentageView(value);
    },
    calculateArraySum: function (items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    },
    getProductions: function () {
      var result = [];

      ApiService.get('/SalesMutations/GetProductionsForLastSevenDays')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          result = response.data.result;

          result.forEach((production, index) => {
            production.firstPerformanceDate_formatted = functions.getDateView(production.firstPerformanceDate);
            production.key = index;

            const performanceDetails = [
              { type: 'aantal' },
              { type: 'recette' },
              { type: 'verschil' },
            ];

            production.days.forEach((day, dayIndex) => {
              performanceDetails[0]['dag' + (dayIndex + 1)] = this.formatThousandsWithDot(day.ticketCount);
              performanceDetails[1]['dag' + (dayIndex + 1)] = functions.getEuroSignView(day.revenueInVat);
              performanceDetails[2]['dag' + (dayIndex + 1)] = this.formatThousandsWithDot(day.mutation);
              if (this.tableProductionsDetailsHeaders[dayIndex + 1]) {
                this.tableProductionsDetailsHeaders[dayIndex + 1].text = functions.getDateView(day.date);
              }
            });

            production.details = performanceDetails;
          });

          this.tableProductions = result;
          this.tableProductionsLoading = false;
          this.createExportData();
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    },
    createExportData: function () {
      if (this.tableProductions.length > 0) {
        this.exportProductionsColumns = [];
        this.exportProductions = [];

        // create export columns
        for (var i = 1; i < 32; i++) {
          this.exportProductionsColumns.push({ label: '', field: 'column' + i });
        }

        // create export data
        let date = new Date();
        this.exportProductions.push({
          column1: this.user.organisationName,
          column3:
            'Gegenereerd op: ' +
            date.getDate() +
            '-' +
            (date.getMonth() + 1) +
            '-' +
            date.getFullYear() +
            ' ' +
            date.getHours() +
            ':' +
            (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()),
        });
        this.exportProductions.push({});

        this.exportProductions.push({
          column1: 'productienummer',
          column2: 'datum',
          column3: 'productie',
          column4: 'genre',
          column5: '# capaciteit',
        });

        var dayColumstotal = 7 * 3;
        var dayCount = 0;
        for (var y = 0; y < dayColumstotal; y++) {
          var columnNummber = y + 6;
          var columnTitle = '';

          var dayPosition = (y + 1) % 3;
          if (dayPosition == 1) {
            columnTitle = 'aantal';
          } else if (dayPosition == 2) {
            columnTitle = 'recette';
          } else if (dayPosition == 0) {
            columnTitle = 'verschil';
          }
          this.exportProductions[1]['column' + columnNummber] = functions.getDateView(
            this.tableProductions[0].days[dayCount].date
          );
          this.exportProductions[2]['column' + columnNummber] = columnTitle;
          if (dayPosition == 0) {
            dayCount++;
          }
        }

        this.tableProductions.forEach((item) => {
          this.exportProductions.push({
            column1: item.productionNumber,
            column2: item.firstPerformanceDate_formatted,
            column3: item.productionTitle,
            column4: item.genreName,
            column5: item.capacity,

            column6: item.days[0].ticketCount,
            column7:
              item.days[0].revenueInVat != null && item.days[0].revenueInVat != ''
                ? parseFloat(item.days[0].revenueInVat.toFixed(2))
                : '',
            column8: item.days[0].mutation,

            column9: item.days[1].ticketCount,
            column10:
              item.days[1].revenueInVat != null && item.days[1].revenueInVat != ''
                ? parseFloat(item.days[1].revenueInVat.toFixed(2))
                : '',
            column11: item.days[1].mutation,

            column12: item.days[2].ticketCount,
            column13:
              item.days[2].revenueInVat != null && item.days[2].revenueInVat != ''
                ? parseFloat(item.days[2].revenueInVat.toFixed(2))
                : '',
            column14: item.days[2].mutation,

            column15: item.days[3].ticketCount,
            column16:
              item.days[3].revenueInVat != null && item.days[3].revenueInVat != ''
                ? parseFloat(item.days[3].revenueInVat.toFixed(2))
                : '',
            column17: item.days[3].mutation,

            column18: item.days[4].ticketCount,
            column19:
              item.days[4].revenueInVat != null && item.days[4].revenueInVat != ''
                ? parseFloat(item.days[4].revenueInVat.toFixed(2))
                : '',
            column20: item.days[4].mutation,

            column21: item.days[5].ticketCount,
            column22:
              item.days[5].revenueInVat != null && item.days[5].revenueInVat != ''
                ? parseFloat(item.days[5].revenueInVat.toFixed(2))
                : '',
            column23: item.days[5].mutation,

            column24: item.days[6].ticketCount,
            column25:
              item.days[6].revenueInVat != null && item.days[6].revenueInVat != ''
                ? parseFloat(item.days[6].revenueInVat.toFixed(2))
                : '',
            column26: item.days[6].mutation,
          });
        });
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
  },
};
</script>