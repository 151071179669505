<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row flex justify="center" align="center" class="pb-2" no-gutters>
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="31"></breadcrumbs-with-help>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <v-text-field v-model="tableSeasonsFilter.name" label="op naam zoeken" hide-details variant="outlined"
                density="compact" bg-color="#fff" class="ma-1 input-sm"></v-text-field>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog v-model="tableSeasonsFilterDialog" width="500" fullscreen>
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn class="font-weight-bold text-lowercase" color="success" rounded dark
                    v-bind="activatorProps">filter overzicht
                    <v-icon small right>mdi-filter</v-icon></v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline dark">
                    <h5 class="text-primary">filter overzicht</h5>
                  </v-card-title>

                  <v-card-text>
                    <v-text-field v-model="tableSeasonsFilter.name" label="op naam zoeken" hide-details dense outlined
                      background-color="#fff" class="mb-3"></v-text-field>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn class="font-weight-bold text-lowercase mb-5" color="success" depressed
                      @click="tableSeasonsFilterDialog = false">filter overzicht
                      <v-icon small right>mdi-filter</v-icon></v-btn>
                    <v-btn class="font-weight-bold text-lowercase" color="error" outlined dark
                      @click="clearFilterSeasons()">reset
                      filter
                      <v-icon small right>mdi-close</v-icon></v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table :headers="tableSeasonsHeaders" :items="tableSeasonsFiltered" :mobile-breakpoint="0"
          :loading="tableSeasonsLoading" :page="tableSeasonsPaging.page"
          :items-per-page="tableSeasonsPaging.itemsPerPage" hide-default-footer
          @update:current-items="tableSeasonsPaging.pageCount = Math.ceil(tableSeasonsFiltered.length / tableSeasonsPaging.itemsPerPage)"
          @update:items-per-page="tableSeasonsPaging.pageCount = Math.ceil(tableSeasonsFiltered.length / tableSeasonsPaging.itemsPerPage)"
          class="table-action" fixed-header :height="tableSeasonsFiltered.length > 10 ? '60vh' : ''">
          <template v-slot:[`item.startDate`]="{ item }">
            <span>{{ item.startDate_formatted }}</span>
          </template>

          <template v-slot:[`item.endDate`]="{ item }">
            <span>{{ item.endDate_formatted }}</span>
          </template>
        </v-data-table>

        <!-- Table settings -->
        <table-settings :items="tableSeasonsFiltered" :paging="tableSeasonsPaging"
          :export-columns="downloadExportColumns" :file-name="'dip-seizoenen-export'" :sheet-name="'Seizoenen'"
          :show-excel-button="true">
        </table-settings>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';
import functions from '../../../services/functions.service';

import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import TableSettings from '@/components/TableSettings.vue';

export default {
  name: 'AdminSeasons',
  components: {
    BreadcrumbsWithHelp,
    TableSettings,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'seizoenen',
          disabled: false,
          href: '/admin/seizoenen',
          bold: true,
        },
      ],
      seasons: [],

      tableSeasonsFiltered: [],
      tableSeasonsLoading: true,
      tableSeasonsHeaders: [
        { title: 'naam', key: 'name' },
        { title: 'start datum', key: 'startDate' },
        { title: 'eind datum', key: 'endDate' },
      ],
      tableSeasonsFilter: {
        name: '',
      },
      tableSeasonsPaging: {
        page: 1,
        pageCount: 25,
        itemsPerPage: 25,
      },
      tableSeasonsFilterDialog: false,
      downloadExportColumns: [
        { label: 'ID', field: 'id' },
        { label: 'naam', field: 'name' },
        { label: 'start', field: 'startDate' },
        { label: 'eind', field: 'endDate' },
      ],
      deleteItemDialog: false,
      deleteItem: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'secondary',
    };
  },
  mounted() {
    // Filter
    this.checkFilter({ viewName: this.$options.name, filterObject: this.tableSeasonsFilter });
    this.tableSeasonsFilter = this.getFilter(this.$options.name);

    this.getSeasons();
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    getSeasons: function () {
      ApiService.get('/Seasons/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.seasons = response.data.result.map(season => ({
            ...season,
            startDate_formatted: functions.getDateView(season.startDate),
            endDate_formatted: functions.getDateView(season.endDate),
          }));

          this.tableSeasonsFiltered = this.seasons;

          // Paging
          this.checkFilter({ viewName: this.$options.name + '-paging', filterObject: this.tableSeasonsPaging });
          this.tableSeasonsPaging = this.getFilter(this.$options.name + '-paging');

          this.filterSeasons();

          this.tableSeasonsLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterSeasons: function () {
      const vm = this;
      this.tableSeasonsFiltered = this.seasons.filter(function (season) {
        return cleanWords(season.name).indexOf(cleanWords(vm.tableSeasonsFilter.name)) !== -1;
      });
      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z ]/, '')
          .toLowerCase();
      }
    },
    clearFilterSeasons: function () {
      this.tableSeasonsFilter.name = '';
      this.tableSeasonsFilterDialog = false;
    },
  },
  computed: {
    ...mapGetters('storage', ['getFilter']),
  },
  beforeUnmount() {
    // Filter
    this.updateFilter({ viewName: this.$options.name, filterObject: this.tableSeasonsFilter });
    // Paging
    this.updateFilter({ viewName: this.$options.name + '-paging', filterObject: this.tableSeasonsPaging });
  },
  watch: {
    tableSeasonsFilter: {
      handler: 'filterSeasons',
      deep: true,
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}
</style>
