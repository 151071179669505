<template>
  <div>
    <!-- Options -->
    <template v-if="!disapprove">
      <h3 class="text-primary mb-2">reageer op dit voorstel</h3>

      <div class="d-flex flex-column">
        <v-card
          class="pa-6 pb-4 mb-10"
          flat
        >
          <v-alert
            v-if="formError != ''"
            text
            color="error"
            >{{ formError }}</v-alert
          >

          <v-row>
            <v-col
              cols="12"
              class="py-1"
            >
              <v-btn
                v-if="rights.approve"
                rounded
                large
                elevation="0"
                :loading="formLoading"
                @click="submitApprove"
                color="secondary"
                class="text-lowercase mb-2 mr-2 align-self-start"
                >Voorstel accepteren
                <v-icon
                  small
                  right
                  >mdi-arrow-right</v-icon
                ></v-btn
              >
              <v-btn
                v-if="rights.approve || rights.edit"
                rounded
                large
                elevation="0"
                :loading="formLoading"
                @click="disapprove = true"
                color="error"
                class="text-lowercase mb-2 mr-2 align-self-start"
                >Voorstel afwijzen
                <v-icon
                  small
                  right
                  >mdi-close</v-icon
                ></v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </div>
    </template>

    <!-- Reject form -->
    <template v-else>
      <h3 class="text-primary mb-2">ik ga niet akkoord vanwege:</h3>

      <v-form
        @submit.prevent
        v-model="formValid"
        class="d-flex flex-column"
        ref="form"
      >
        <v-card
          class="pa-6 pb-0 mb-4"
          flat
        >
          <!-- Partage -->
          <v-row v-if="contract.type == 'Partage'">
            <v-col
              cols="12"
              md="10"
              class="py-0"
            >
              <v-checkbox
                label="aanvangstijd"
                v-model="form.isStartTimeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="theatertoeslag"
                v-model="form.isTheaterSurchargeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="auteursrechten"
                v-model="form.isCopyrightRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
              <v-checkbox
                label="percentageberekening auteursrecht"
                v-model="form.isCopyrightCalculationRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="partage aanbieder"
                v-model="form.isProviderPartageRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="garantie aanbieder"
                v-model="form.isProviderGuaranteeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
              <v-checkbox
                label="garantie afnemer"
                v-model="form.isConsumerGuaranteeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
              <v-checkbox
                label="garantieberekening"
                v-model="form.isGuaranteeCalculationRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="afrekenprijzen"
                v-model="form.isTicketPriceRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="consumptietoeslag"
                v-model="form.isConsumptionSurchargeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
              <v-checkbox
                label="servicetoeslag"
                v-model="form.isServiceSurchargeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
              <v-checkbox
                label="garderobetoeslag"
                v-model="form.isWardrobeSurchargeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="extra afspraken"
                v-model="form.isRemarkRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
            </v-col>
          </v-row>
          <!-- Huur -->
          <v-row v-else-if="contract.type == 'Huur'">
            <v-col
              cols="12"
              md="10"
              class="py-0"
            >
              <v-checkbox
                label="aanvangstijd"
                v-model="form.isStartTimeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="theatertoeslag"
                v-model="form.isTheaterSurchargeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
              <v-checkbox
                label="huur"
                v-model="form.isRentRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
              <v-checkbox
                label="overige kosten"
                v-model="form.isOtherCostsRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="afrekenprijzen"
                v-model="form.isTicketPriceRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="consumptietoeslag"
                v-model="form.isConsumptionSurchargeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
              <v-checkbox
                label="servicetoeslag"
                v-model="form.isServiceSurchargeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
              <v-checkbox
                label="garderobetoeslag"
                v-model="form.isWardrobeSurchargeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="extra afspraken"
                v-model="form.isRemarkRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
            </v-col>
          </v-row>
          <!-- Uitkoop -->
          <v-row v-else-if="contract.type == 'Uitkoop'">
            <v-col
              cols="12"
              md="10"
              class="py-0"
            >
              <v-checkbox
                label="aanvangstijd"
                v-model="form.isStartTimeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="auteursrechten"
                v-model="form.isCopyrightRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
              <v-checkbox
                label="uitkoopsom"
                v-model="form.isBuyoutAmountRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="afrekenprijzen"
                v-model="form.isTicketPriceRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="theatertoeslag"
                v-model="form.isTheaterSurchargeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="consumptietoeslag"
                v-model="form.isConsumptionSurchargeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
              <v-checkbox
                label="servicetoeslag"
                v-model="form.isServiceSurchargeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
              <v-checkbox
                label="garderobetoeslag"
                v-model="form.isWardrobeSurchargeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="extra afspraken"
                v-model="form.isRemarkRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
            </v-col>
          </v-row>
          <!-- Suppletie -->
          <v-row v-else-if="contract.type == 'Suppletie'">
            <v-col
              cols="12"
              md="10"
              class="py-0"
            >
              <v-checkbox
                label="aanvangstijd"
                v-model="form.isStartTimeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="theatertoeslag"
                v-model="form.isTheaterSurchargeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="auteursrechten"
                v-model="form.isCopyrightRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
              <v-checkbox
                label="percentageberekening auteursrecht"
                v-model="form.isCopyrightCalculationRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="partage aanbieder"
                v-model="form.isProviderPartageRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="aanvulling tot"
                v-model="form.isAmountRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
              <v-checkbox
                label="maximale aanvulling"
                v-model="form.isMaximumAmountRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="aanvullingsberekening"
                v-model="form.isGuaranteePerPerformanceRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="afrekenprijzen"
                v-model="form.isTicketPriceRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="consumptietoeslag"
                v-model="form.isConsumptionSurchargeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
              <v-checkbox
                label="servicetoeslag"
                v-model="form.isServiceSurchargeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
              <v-checkbox
                label="garderobetoeslag"
                v-model="form.isWardrobeSurchargeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="extra afspraken"
                v-model="form.isRemarkRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
            </v-col>
          </v-row>
          <!-- Staffel -->
          <v-row v-else-if="contract.type == 'Staffel'">
            <v-col
              cols="12"
              md="10"
              class="py-0"
            >
              <v-checkbox
                label="aanvangstijd"
                v-model="form.isStartTimeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="theatertoeslag"
                v-model="form.isTheaterSurchargeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="auteursrechten"
                v-model="form.isCopyrightRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
              <v-checkbox
                label="percentageberekening auteursrecht"
                v-model="form.isCopyrightCalculationRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="garantie aanbieder"
                v-model="form.isProviderGuaranteeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
              <v-checkbox
                label="garantie afnemer"
                v-model="form.isConsumerGuaranteeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="gezamenlijke post"
                v-model="form.isCombinedBudgetRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="staffel"
                v-model="form.isTrancheRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="afrekenprijzen"
                v-model="form.isTicketPriceRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="consumptietoeslag"
                v-model="form.isConsumptionSurchargeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
              <v-checkbox
                label="servicetoeslag"
                v-model="form.isServiceSurchargeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
              <v-checkbox
                label="garderobetoeslag"
                v-model="form.isWardrobeSurchargeRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>

              <v-checkbox
                label="extra afspraken"
                v-model="form.isRemarkRejected"
                class="checkbox text-primary ma-0 pa-0"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card>

        <v-card
          class="pa-6 pb-0 mb-10"
          flat
        >
          <v-row>
            <v-col
              cols="12"
              md="10"
              class="py-0"
            >
              <v-textarea
                class="text-primary"
                label="aanvullende opmerking"
                v-model="form.rejectReason"
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card>

        <v-alert
          v-if="formError != ''"
          text
          color="error"
          >{{ formError }}</v-alert
        >

        <v-row>
          <v-col
            cols="12"
            md="10"
            class="py-0"
          >
            <v-btn
              rounded
              large
              elevation="0"
              @click="submitReject"
              :loading="formLoading"
              color="secondary"
              class="text-lowercase mb-2 mr-2 align-self-start"
              >Versturen
              <v-icon
                small
                right
                >mdi-arrow-right</v-icon
              ></v-btn
            >
            <v-btn
              rounded
              large
              outlined
              elevation="0"
              @click="
                disapprove = false;
                isEditing = false;
              "
              color="primary"
              :loading="formLoading"
              class="text-lowercase mb-2 mr-2 align-self-start"
              >Annuleren
              <v-icon
                small
                right
                >mdi-close</v-icon
              ></v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </template>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      color="secondary"
      >je reactie is verstuurd</v-snackbar
    >
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';
import router from '../../../router/router';
import functions from '../../../services/functions.service';

export default {
  props: ['contract', 'rights'],
  data() {
    return {
      disapprove: false,
      formValid: false,
      formError: '',
      formLoading: false,
      formValidationRules: {},
      isEditing: false,
      snackbar: false,
      form: {
        isStartTimeRejected: false,
        isTheaterSurchargeRejected: false,
        isCopyrightCalculationRejected: false,
        isCopyrightRejected: false,
        isProviderGuaranteeRejected: false,
        isConsumerGuaranteeRejected: false,
        isGuaranteeCalculationRejected: false,
        isProviderPartageRejected: false,
        isTicketPriceRejected: false,
        isConsumptionSurchargeRejected: false,
        isServiceSurchargeRejected: false,
        isWardrobeSurchargeRejected: false,
        isRemarkRejected: false,
        isRentRejected: false,
        isOtherCostsRejected: false,
        isBuyoutAmountRejected: false,
        isSuppletionAmountRejected: false,
        isTrancheRejected: false,
        isCombinedBudgetRejected: false,
        isAmountRejected: false,
        isMaximumAmountRejected: false,
        isGuaranteePerPerformanceRejected: false,
        rejectReason: '',
      },
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    validateForm() {
      this.$refs.form.validate();
    },
    submitApprove() {
      this.formLoading = true;

      ApiService.post('/Contracts/Approve', {
        contractId: this.contract.id,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            this.isEditing = false;
            this.snackbar = true;
            setTimeout(function () {
              router.push({ name: 'Contracts' });
            }, 1000);
          } else {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          this.formError =
            'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
          this.formLoading = false;
          console.log(error);
        });
    },
    submitReject() {
      this.formLoading = true;

      ApiService.post('/Contracts/Reject', {
        contractId: this.contract.id,
        isStartTimeRejected: this.form.isStartTimeRejected,
        isTheaterSurchargeRejected: this.form.isTheaterSurchargeRejected,
        isCopyrightCalculationRejected: this.form.isCopyrightCalculationRejected,
        isCopyrightRejected: this.form.isCopyrightRejected,
        isProviderGuaranteeRejected: this.form.isProviderGuaranteeRejected,
        isConsumerGuaranteeRejected: this.form.isConsumerGuaranteeRejected,
        isGuaranteeCalculationRejected: this.form.isGuaranteeCalculationRejected,
        isProviderPartageRejected: this.form.isProviderPartageRejected,
        isTicketPriceRejected: this.form.isTicketPriceRejected,
        isConsumptionSurchargeRejected: this.form.isConsumptionSurchargeRejected,
        isServiceSurchargeRejected: this.form.isServiceSurchargeRejected,
        isWardrobeSurchargeRejected: this.form.isWardrobeSurchargeRejected,
        isRemarkRejected: this.form.isRemarkRejected,
        isRentRejected: this.form.isRentRejected,
        isOtherCostsRejected: this.form.isOtherCostsRejected,
        isBuyoutAmountRejected: this.form.isBuyoutAmountRejected,
        isSuppletionAmountRejected: this.form.isSuppletionAmountRejected,
        isTrancheRejected: this.form.isTrancheRejected,
        isCombinedBudgetRejected: this.form.isCombinedBudgetRejected,
        isAmountRejected: this.form.isAmountRejected,
        isMaximumAmountRejected: this.form.isMaximumAmountRejected,
        isGuaranteePerPerformanceRejected: this.form.isGuaranteePerPerformanceRejected,
        rejectReason: this.form.rejectReason,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            this.isEditing = false;
            this.snackbar = true;
            setTimeout(function () {
              router.push({ name: 'Contracts' });
            }, 1000);
          } else {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          this.formError =
            'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
          this.formLoading = false;
          console.log(error);
        });
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style scoped></style>
