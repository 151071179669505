<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="44"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- User details -->
          <v-card title="gegevens gebruiker">
            <v-divider></v-divider>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>naam:</small> <br />
                    <strong>{{ userInfo.firstName }} {{ userInfo.lastName }}</strong>
                  </p>
                </v-col>
              </v-row>
              <hr class="accent-hr my-2" />
              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>e-mailadres:</small> <br />
                    <strong>{{ userInfo.email }}</strong>
                  </p>
                </v-col>
              </v-row>
              <hr class="accent-hr my-2" />
              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>gebruikersrol:</small> <br />
                    <strong>{{ userInfo.roleName }}</strong>
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card title="rechten gebruiker">
            <v-divider></v-divider>
            <v-card-text>
              <!-- Table -->
              <v-data-table :headers="tableRightsHeaders" :items="userInfo.sitemap" :mobile-breakpoint="0"
                :loading="tableRightsLoading" :items-per-page="-1" hide-default-footer>
                <template v-slot:[`item.rights.view`]="{ item }">
                  <v-checkbox v-model="item.rights.view" v-if="item.rights.view != null" :input-value="item.rights.view"
                    :disabled="true"></v-checkbox>
                  <div v-else>-</div>
                </template>
                <template v-slot:[`item.rights.edit`]="{ item }">
                  <v-checkbox v-model="item.rights.edit" v-if="item.rights.edit != null" :input-value="item.rights.edit"
                    :disabled="true"></v-checkbox>
                  <div v-else>-</div>
                </template>
                <template v-slot:[`item.rights.approve`]="{ item }">
                  <v-checkbox v-model="item.rights.approve" v-if="item.rights.approve != null"
                    :input-value="item.rights.approve" :disabled="true"></v-checkbox>
                  <div v-else>-</div>
                </template>
              </v-data-table>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <div>
                <p class="grey--text text--lighten-1 mb-2">
                  <small><i>{{ this.userInfo.lastEditedBy }}</i></small>
                </p>

                <!-- Bewerken -->
                <v-btn-edit class="mr-5" :to="{ name: 'LibraryUsersEdit', params: { userId } }">gegevens Bewerken</v-btn-edit>
                <!-- Verwijderen -->
                <delete-dialog v-if="rights.edit && user.userId != this.userId" buttonText="verwijderen" :title="deleteDialogTitle"
                :text="deleteDialogText" @confirm="deleteItemUser()" />
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <!-- Snackbar -->
      <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ this.snackbarText }}
        <template v-slot:actions>
          <v-btn color="#fff" variant="text" @click="(snackbar = false), (snackbarText = '')">
            Sluiten
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import BreadcrumbsWithHelp from '../../../components/BreadcrumbsWithHelp.vue';

export default {
  name: 'LibraryUsersDetail',
  props: ['userId'],
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      userInfo: {
        firstName: '',
        lastName: '',
        email: '',
        roleName: '',
        linkId: '',
        lastEditedBy: '',
        sitemap: [],
      },
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'gebruikers',
          disabled: false,
          href: '/beheer/gebruikers',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      deleteItemLink: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      editUserDialog: false,

      tableRightsHeaders: [
        { title: 'module', key: 'name', sortable: false },
        { title: 'inzage', key: 'rights.view' },
        { title: 'wijzigen', key: 'rights.edit' },
        { title: 'accorderen', key: 'rights.approve' },
      ],
      tableRightsLoading: true,
    };
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryUsersDetail');
    this.getUser();
  },
  methods: {
    getUser: function () {
      ApiService.get('/Users/GetPerOrganisation', {
        userId: this.userId,
      })
        .then((response) => {
          if (response.data.success) {
            const userInfo = response.data.result;
            this.breadcrumbs[2].text = userInfo.firstName + ' ' + userInfo.lastName;
            this.userInfo.firstName = userInfo.firstName;
            this.userInfo.lastName = userInfo.lastName;
            this.userInfo.email = userInfo.email;
            this.userInfo.roleName = userInfo.roleName;
            this.userInfo.linkId = userInfo.linkId;
            this.userInfo.lastEditedBy = userInfo.lastEditedBy;
            this.userInfo.sitemap = userInfo.sitemap;

            this.tableRightsLoading = false;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    deleteItemUser: function () {
      ApiService.delete('/Users/DeletePerOrganisation', {
        linkId: this.userInfo.linkId,
      })
        .then((response) => {
          if (response.data.success) {
            this.snackbarText = this.userInfo.firstName + ' is verwijderd';
            this.snackbarColor = 'secondary';
            this.snackbar = true;
            setTimeout(function () {
              router.push({ path: '/beheer/gebruikers' });
            }, 1500);
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
    deleteDialogTitle() {
      return `${this.userInfo.firstName} ${this.userInfo.lastName} verwijderen?`;
    },
    deleteDialogText() {
      return `Weet je zeker dat je het account voor ${this.userInfo.firstName} ${this.userInfo.lastName} wil verwijderen? Het is daarna niet meer terug te vinden.`;
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
