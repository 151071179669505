<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="45"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-form @submit.prevent ref="form">
            <v-col cols="12">
              <v-card title="gegevens gebruiker">
                <v-divider></v-divider>
                <v-card-text class="mt-5">
                  <v-card class="pa-6 mb-1" flat>
                    <v-row>
                      <!-- Voornaam -->
                      <v-col cols="6" class="py-1">
                        <v-text-field class="text-primary" label="voornaam" v-model="editUserForm.firstName"
                          :rules="formValidationRules.required" :disabled="!userEditable"></v-text-field>
                      </v-col>

                      <!-- Achternaam -->
                      <v-col cols="6" class="py-1">
                        <v-text-field class="text-primary" label="achternaam" v-model="editUserForm.lastName"
                          :rules="formValidationRules.required" :disabled="!userEditable"></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!-- Email -->
                      <v-col cols="12" class="py-1">
                        <v-text-field class="text-primary" label="e-mailadres" v-model="editUserForm.email"
                          :rules="formValidationRules.emailRequired" :disabled="!userEditable"></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row v-if="!userEditable" class="mb-2">
                      <v-col cols="12" class="py-1">
                        <v-alert text color="primary">Deze gebruiker is gekoppeld aan meerdere organisaties. Voor het
                          bewerken van bovenstaande
                          gegevens neem contact op met DIP.</v-alert>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!-- Userrol -->
                      <v-col cols="12" class="py-1" v-if="user.userId != this.userId">
                        <v-autocomplete label="gebruikersrol" v-model="editUserForm.roleId" :items="userRoleOptions"
                          :rules="formValidationRules.required" @update:modelValue="getRights"></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card title="rechten gebruiker">
                <v-divider></v-divider>
                <v-card-text>
                  <template v-if="editUserForm.roleId != ''">
                    <!-- Table -->
                    <v-data-table :headers="tableRightsHeaders" :items="editUserForm.sitemap" :mobile-breakpoint="0"
                      :loading="tableRightsLoading" :items-per-page="-1" hide-default-footer>
                      <template v-slot:[`item.rights.view`]="{ item }">
                        <v-checkbox v-model="item.rights.view" v-if="item.rights.view != null"
                          :input-value="item.rights.view" @change="changeRight(item, item.rights.view, 'view')"
                          :disabled="editUserForm.roleIsAdmin"></v-checkbox>
                        <div v-else>-</div>
                      </template>
                      <template v-slot:[`item.rights.edit`]="{ item }">
                        <v-checkbox v-model="item.rights.edit" v-if="item.rights.edit != null"
                          :input-value="item.rights.edit" @change="changeRight(item, item.rights.edit, 'edit')"
                          :disabled="editUserForm.roleIsAdmin"></v-checkbox>
                        <div v-else>-</div>
                      </template>
                      <template v-slot:[`item.rights.approve`]="{ item }">
                        <v-checkbox v-model="item.rights.approve" v-if="item.rights.approve != null"
                          :input-value="item.rights.approve" @change="changeRight(item, item.rights.approve, 'approve')"
                          :disabled="editUserForm.roleIsAdmin"></v-checkbox>
                        <div v-else>-</div>
                      </template>
                    </v-data-table>
                  </template>


                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <div>
                    <p class="grey--text text--lighten-1 mb-2">
                      <small><i>{{ this.lastEditedBy }}</i></small>
                    </p>

                    <v-alert text type="error" v-if="formError != ''">{{ formError }}</v-alert>
                    <v-btn-save @click="submit" :loading="formLoading">
                      opslaan
                    </v-btn-save>
                  </div>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import BreadcrumbsWithHelp from '../../../components/BreadcrumbsWithHelp.vue';

export default {
  name: 'LibraryUsersEdit',
  props: ['userId'],
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      isEditing: false,
      formValid: false,
      formError: '',
      editUserForm: {
        firstName: '',
        lastName: '',
        email: '',
        roleId: '',
        linkId: '',
        sitemap: [],
        oldRoleIsAdmin: false,
        roleIsAdmin: false,
      },
      tableRightsHeaders: [
        { title: 'module', key: 'name', sortable: false },
        { title: 'inzage', key: 'rights.view' },
        { title: 'wijzigen', key: 'rights.edit' },
        { title: 'accorderen', key: 'rights.approve' },
      ],
      tableRightsLoading: true,
      lastEditedBy: '',
      userEditable: true,
      userRoleOptions: [],
      formValidationRules: {},
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'gebruikers',
          disabled: false,
          href: '/beheer/gebruikers',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryUsersEdit');
    this.checkRights();
    this.formValidationRules = functions.getFormValidationRules();
    this.getUser();
    this.getUserRoles();
    this.checkUserRole();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    checkRights() {
      if (!this.rights.edit) {
        router.back();
      }
    },
    getUser: function () {
      const vm = this;

      ApiService.get('/Users/GetPerOrganisation', {
        userId: this.userId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const user = response.data.result;

            this.breadcrumbs[2].text = user.firstName + ' ' + user.lastName;
            this.breadcrumbs[2].href = '/beheer/gebruikers/' + this.userId;
            this.editUserForm.firstName = user.firstName;
            this.editUserForm.lastName = user.lastName;
            this.editUserForm.email = user.email;
            this.editUserForm.roleId = user.roleId;
            this.editUserForm.linkId = user.linkId;
            this.editUserForm.sitemap = user.sitemap;
            this.editUserForm.oldRoleIsAdmin = user.roleIsAdmin;
            this.editUserForm.roleIsAdmin = user.roleIsAdmin;
            this.tableRightsLoading = false;
            this.lastEditedBy = user.lastEditedBy;
            this.userEditable = user.editable;

            setTimeout(function () {
              vm.isEditing = false;
            }, 200);
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getUserRoles: function () {
      ApiService.get('/UserRoles/GetAll', {
        organisationTypeId: this.user.organisationTypeId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.userRoleOptions.push({ value: element.id, title: element.name, isAdmin: element.isAdmin });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    checkUserRole: function () {
      const vm = this;
      const checkInterval = setInterval(function () {
        if (vm.editUserForm.roleId != '' && vm.userRoleOptions.length > 0) {
          clearInterval(checkInterval);
          var userRoleExist = vm.userRoleOptions.filter((role) => role['value'] === vm.editUserForm.roleId);
          if (userRoleExist.length === 0) {
            vm.editUserForm.roleId = '';
          }
        }
      }, 100);
    },
    getRights: function () {
      if (!this.tableRightsLoading) {
        ApiService.get('/Rights/GetPerOrganisation', {
          userRoleId: this.editUserForm.roleId,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();

            var roleIsAdmin = this.userRoleOptions.filter((role) => {
              if (role.value == this.editUserForm.roleId) {
                return role;
              }
            });
            this.editUserForm.roleIsAdmin = roleIsAdmin[0].isAdmin;

            this.editUserForm.sitemap = response.data.result.sitemap;
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.log(error);
          });
      }
    },
    changeRight(module, value, type) {
      if (type == 'view') {
        module.rights.edit = module.rights.edit == null ? null : false;
        module.rights.approve = module.rights.approve == null ? null : false;
      } else if (type == 'edit') {
        if (value) {
          module.rights.view = module.rights.view == null ? null : true;
          module.rights.approve = module.rights.approve == null ? null : false;
        } else {
          module.rights.approve = module.rights.approve == null ? null : false;
        }
      } else if (type == 'approve' && value) {
        module.rights.view = module.rights.view == null ? null : true;
        module.rights.edit = module.rights.edit == null ? null : true;
      }
    },
    async submit() {
      this.formError = '';
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const form = this.editUserForm;

        ApiService.put('/Users/UpdatePerOrganisation', {
          linkId: form.linkId,
          firstName: form.firstName,
          lastName: form.lastName,
          email: form.email,
          userRoleId: form.roleId,
          sitemap: form.roleIsAdmin ? [] : form.sitemap,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
  },
  watch: {
    editUserForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
