<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="18"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- Form -->
          <v-form @submit.prevent class="d-flex flex-column" ref="form">
            <v-card title="subsidie toevoegen">
              <v-divider></v-divider>
              <v-card-text>
                <v-row class="mt-0">
                  <!-- Subsidie -->
                  <v-col cols="12" class="py-1">
                    <v-autocomplete label="subsidieregeling" v-model="addSubventionForm.type" :items="subsidieOptions"
                      :rules="formValidationRules.required"></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <!-- Organisation -->
                  <v-col cols="12" class="py-1">
                    <v-autocomplete label="organisatie" v-model="addSubventionForm.organisation"
                      :items="organisationOptions" :rules="formValidationRules.required"></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <!-- Start datum -->
                  <v-col cols="12" class="py-1">
                    <v-text-field v-model="addSubventionForm.startDate" :rules="formValidationRules.dateRequired"
                      label="startdatum" placeholder="DD-MM-JJJJ"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <!-- End datum -->
                  <v-col cols="12" class="py-1">
                    <v-text-field v-model="addSubventionForm.endDate" :rules="formValidationRules.dateRequired"
                      label="einddatum" placeholder="DD-MM-JJJJ"></v-text-field>
                  </v-col>
                </v-row>

                <v-alert type="error" variant="tonal" v-if="formError != ''">{{ formError }}</v-alert>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <div>
                  <v-btn-save @click="submit" :loading="formLoading">Opslaan</v-btn-save>
                </div>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import { TokenService } from '../../../services/storage.service';
import functions from '../../../services/functions.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'AdminProducersSubventionAdd',
  props: ['producerId'],
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      producer: {
        name: '',
      },
      isEditing: false,
      formError: '',
      formLoading: false,
      addSubventionForm: {
        organisation: null,
        type: null,
        startDate: '',
        endDate: '',
      },
      formValidationRules: {},
      subsidieOptions: [
        { value: 'multiyear', title: 'meerjarige activiteitensubsidie (FPK)' },
        { value: 'basic', title: 'basisinfrastructuur (OCW)' },
        { value: 'project', title: 'projectsubsidie (FPK)' },
      ],
      organisationOptions: [
        { value: 'FPK', title: 'FPK' },
        { value: 'OC&W', title: 'OC&W' },
      ],
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producenten',
          disabled: false,
          href: '/admin/producenten',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'subsidie toevoegen',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.getProducer();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getProducer: function () {
      ApiService.get('/Producers/Get', {
        producerId: this.producerId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const producer = response.data.result;
            this.breadcrumbs[2].text = producer.name;
            this.breadcrumbs[2].href = '/admin/producenten/' + this.producerId + '/';
            this.producer.name = producer.name;
          } else {
            //router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    async submit() {
      this.formError = '';
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const form = this.addSubventionForm;

        ApiService.post('/Producers/AddSubvention', {
          producerId: parseInt(this.producerId),
          organisation: form.organisation,
          type: form.type,
          startDate: functions.getDateDatabase(form.startDate),
          endDate: functions.getDateDatabase(form.endDate),
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {},
  watch: {
    addSubventionForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>
