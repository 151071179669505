<template>
  <div>
    <!-- Provider contact -->
    <v-card
      class="py-6 px-3 pb-0 mb-4"
      flat
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0"
            v-if="contract.providerContact && contract.providerContact.fullName != ''"
          >
            <p class="text-primary font-weight-medium mb-0">contactpersoon aanbieder</p>

            <div
              class="contract-diff mb-4"
              v-if="
                contract.previousApproved &&
                contract.previousApproved.providerContact &&
                contract.previousApproved.providerContact.fullName != '' &&
                contract.previousApproved.providerContact.fullName != contract.providerContact.fullName
              "
            >
              <h3 class="text-primary mb-0 pr-2">{{ contract.previousApproved.providerContact.fullName }}</h3>
              <h3 class="error--text pb-4 border-bottom">{{ contract.providerContact.fullName }}</h3>
            </div>
            <h3
              v-else
              class="text-primary border-bottom pb-4 mb-4"
            >
              {{ contract.providerContact.fullName }}
            </h3>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="py-0 mb-8"
            v-if="contract.providerContact && contract.providerContact.email != ''"
          >
            <p class="text-primary font-weight-medium mb-0">e-mailadres aanbieder</p>

            <div
              class="contract-diff mb-4"
              v-if="
                contract.previousApproved &&
                contract.previousApproved.providerContact &&
                contract.previousApproved.providerContact.email != '' &&
                contract.previousApproved.providerContact.email != contract.providerContact.email
              "
            >
              <h3 class="text-primary mb-0 pr-2">{{ contract.previousApproved.providerContact.email }}</h3>
              <h3 class="error--text pb-4 border-bottom">{{ contract.providerContact.email }}</h3>
            </div>
            <h3
              v-else
              class="text-primary border-bottom pb-4 mb-0"
            >
              {{ contract.providerContact.email }}
            </h3>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="py-0 mb-8"
            v-else-if="contract.providerContactEmail != ''"
          >
            <p class="text-primary font-weight-medium mb-0">e-mailadres aanbieder</p>

            <div
              class="contract-diff mb-4"
              v-if="
                contract.previousApproved &&
                contract.previousApproved.providerContactEmail &&
                contract.previousApproved.providerContactEmail != '' &&
                contract.previousApproved.providerContactEmail != contract.providerContactEmail
              "
            >
              <h3 class="text-primary mb-0 pr-2">{{ contract.previousApproved.providerContactEmail }}</h3>
              <h3 class="error--text pb-4 border-bottom">{{ contract.providerContactEmail }}</h3>
            </div>
            <h3
              v-else
              class="text-primary border-bottom pb-4 mb-0"
            >
              {{ contract.providerContactEmail }}
            </h3>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: ['contract'],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {},
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid #e1e7f2;
}
</style>
