<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="22"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12" class="py-0">
          <v-card title="gegevens impresariaat">
            <v-divider></v-divider>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>naam:</small><br /><strong>{{ agency.name }}</strong>
                  </p>
                </v-col>
              </v-row>
              <hr class="accent-hr my-2" />
              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>voorwaarden DIP geaccepteerd:</small> <br />
                    <strong><span v-if="agency.agreedToTerms">Ja</span><span v-else>Nee</span></strong>
                  </p>
                </v-col>
              </v-row>
              <hr class="accent-hr my-2" />
              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>branchevereniging:</small><br /><strong>{{ agency.branchAssociation }}</strong>
                  </p>
                </v-col>
              </v-row>
              <hr class="accent-hr my-2" />
              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>toon publieksmonitor:</small> <br /><strong><span
                        v-if="agency.hasPublicMonitorAccess">Ja</span><span v-else>Nee</span></strong>
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card title="adres">
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>straat en huisnummer:</small> <br />
                    <strong>{{ agency.street + ' ' + agency.number }}</strong>
                  </p>
                  <hr class="accent-hr my-2" />
                  <p class="text-primary mb-0">
                    <small>postcode:</small> <br />
                    <strong>{{ agency.zipCode }}</strong>
                  </p>
                  <hr class="accent-hr my-2" />
                  <p class="text-primary mb-0">
                    <small>plaats:</small> <br />
                    <strong>{{ agency.city }}</strong>
                  </p>
                  <hr class="accent-hr my-2" />
                  <p class="text-primary mb-0">
                    <small>provincie:</small> <br />
                    <strong>{{ agency.province }}</strong>
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <div>
                <p class="grey--text text--lighten-1 mb-2">
                  <small><i>{{ this.agency.lastEditedBy }}</i></small>
                </p>
                <!-- Bewerken -->
                <v-btn-edit class="mr-5" :to="{ name: 'AdminAgenciesEdit', params: { agencyId } }">Gegevens Bewerken</v-btn-edit>
                <!-- Verwijderen -->
                <delete-dialog buttonText="verwijderen" :title="deleteDialogTitle"
                :text="deleteDialogText" @confirm="deleteItemAgency()" />
              </div>
            </v-card-actions>
          </v-card>
        </v-col>

        <!-- Active User overview -->
        <v-col cols="12">
          <v-row class="pa-0 ma-0 mb-2 d-flex flex-row align-center justify-space-between">
            <h4 class="text-primary font-weight-regular">gebruikers</h4>
            <v-btn rounded small elevation="0" color="primary" class="text-lowercase"
              :to="{ name: 'AdminAgenciesLinkAdd', params: { agencyId } }">gebruiker toevoegen</v-btn>
          </v-row>
          <!-- Table -->
          <v-data-table :headers="tableAgenciesUsersHeaders" :items="tableAgenciesUsersFiltered" :mobile-breakpoint="0"
            :loading="tableAgenciesUsersLoading" :items-per-page="-1" hide-default-footer class="table-action">
            <template v-slot:[`item.action`]="{ item }">
              <v-btn rounded="circle" elevation="0" size="small" icon="mdi-square-edit-outline" color="info"
                class="ml-1" :to="{
                  name: 'AdminAgenciesLinkEdit',
                  params: { linkId: item.linkId, agencyId: agencyId }
                }">
                <v-icon color="primary">mdi-square-edit-outline</v-icon>
              </v-btn>
              <delete-dialog title="Gebruikersrol verwijderen?"
                    text="Weet je zeker dat je dit token wil verwijderen? Het is daarna niet meer terug te vinden."
                    @confirm="(deleteItemLink = item), deleteItemLinkAgency();" />
            </template>
          </v-data-table>
        </v-col>

        <!-- Producer link overview -->
        <v-col cols="12">
          <v-row class="pa-0 ma-0 mb-2 d-flex flex-row align-center justify-space-between">
            <h4 class="text-primary font-weight-regular">gekoppelde producenten</h4>
            <v-btn rounded small elevation="0" color="primary" class="text-lowercase"
              :to="{ name: 'AdminAgenciesProducerLinkAdd', params: { agencyId } }">producer toevoegen</v-btn>
          </v-row>

          <!-- Table -->
          <v-data-table :headers="tableProducerLinkHeaders" :items="tableProducerLinkFiltered" :mobile-breakpoint="0"
            :loading="tableProducerLinkLoading" :items-per-page="-1" hide-default-footer class="table-action">
            <template v-slot:[`item.action`]="{ item }">
              <v-btn rounded="circle" elevation="0" size="small" icon="mdi-eye" color="warning" class="ml-1"
                :to="{ name: 'AdminProducersDetail', params: { producerId: item.producerId } }">
                <v-icon color="primary">mdi-eye</v-icon>
              </v-btn>
              <delete-dialog title="Koppeling producent verwijderen?"
                    text="Weet je zeker dat je dit token wil verwijderen? Het is daarna niet meer terug te vinden."
                    @confirm="(deleteProducerLinkItem = item), deleteProducerLink();" />
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- Snackbar -->
      <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ this.snackbarText }}
        <template v-slot:actions>
          <v-btn color="#fff" variant="text" @click="(snackbar = false), (snackbarText = '')">
            Sluiten
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';

import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'AdminAgenciesDetail',
  props: ['agencyId'],
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      agency: {
        name: '',
        agreedToTerms: false,
        hasPublicMonitorAccess: false,
        street: '',
        number: '',
        zipCode: '',
        city: '',
        province: '',
        branchAssociation: '',
        lastEditedBy: '',
      },
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'impresariaten',
          disabled: false,
          href: '/admin/impresariaten',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      tableAgenciesUsersFiltered: [],
      tableAgenciesUsersLoading: true,
      tableAgenciesUsersHeaders: [
        { title: 'naam', key: 'userFullName' },
        { title: 'rol', key: 'roleName' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
      deleteProducerDialog: false,
      tableProducerLinkFiltered: [],
      tableProducerLinkLoading: true,
      tableProducerLinkHeaders: [
        { title: 'id', key: 'producerId' },
        { title: 'naam', key: 'producerName' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
    };
  },
  mounted() {
    this.getAgency();
    this.getAgencyUsers();
    this.getProducerLinks();
  },
  methods: {
    getAgency: function () {
      ApiService.get('/Agencies/Get', {
        agencyId: this.agencyId,
      })
        .then((response) => {
          if (response.data.success) {
            const agency = response.data.result;
            this.breadcrumbs[2].text = agency.name;
            this.agency.agreedToTerms = agency.agreedToTerms;
            this.agency.hasPublicMonitorAccess = agency.hasPublicMonitorAccess;
            this.agency.name = agency.name;
            this.agency.street = agency.street;
            this.agency.number = agency.number;
            this.agency.zipCode = agency.zipCode;
            this.agency.city = agency.city;
            this.agency.province = agency.province.name;
            this.agency.lastEditedBy = agency.lastEditedBy;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getAgencyUsers: function () {
      ApiService.get('/Agencies/GetUsers', {
        agencyId: this.agencyId,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableAgenciesUsersFiltered = response.data.result;
            this.tableAgenciesUsersLoading = false;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          //router.back();
        });
    },
    getProducerLinks: function () {
      ApiService.get('/AgencyProducerLinks/GetPerAgency', {
        agencyId: this.agencyId,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableProducerLinkFiltered = response.data.result;
            this.tableProducerLinkLoading = false;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          //router.back();
        });
    },
    deleteItemAgency: function () {
      ApiService.delete('/Agencies/Delete', {
        agencyId: this.agencyId,
      })
        .then((response) => {
          if (response.data.success) {
            this.snackbarText = this.agency.name + ' is verwijderd';
            this.snackbarColor = 'secondary';
            this.snackbar = true;
            setTimeout(function () {
              router.push({ path: '/admin/impresariaten' });
            }, 1500);
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    deleteItemLinkAgency: function () {
      ApiService.delete('/UserRoles/Delete', {
        linkId: this.deleteItemLink.linkId,
        organisationTypeId: this.deleteItemLink.organisationTypeId,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.tableAgenciesUsersFiltered.map((item) => item.linkId).indexOf(this.deleteItemLink.linkId);
            this.tableAgenciesUsersFiltered.splice(i, 1);

            this.snackbarText = 'Gebruiker is verwijderd';
            this.snackbarColor = 'secondary';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    deleteProducerLink: function () {
      ApiService.delete('/AgencyProducerLinks/Delete', {
        linkId: this.deleteProducerLinkItem.linkId,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.tableProducerLinkFiltered
              .map((item) => item.linkId)
              .indexOf(this.deleteProducerLinkItem.linkId);
            this.tableProducerLinkFiltered.splice(i, 1);

            this.snackbarText = 'Koppeling producer is verwijderd';
            this.snackbarColor = 'secondary';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
  },
  computed: {
    deleteDialogTitle() {
      return `${this.agency.name} verwijderen?`;
    },
    deleteDialogText() {
      return `Weet je zeker dat je ${this.agency.name} wil verwijderen? Het is daarna niet meer terug te vinden.`;
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
