<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="59"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- Page info first row -->
          <v-row class="flex align-center pb-2" no-gutters>
            <v-col cols="12" class="py-0">
              <!-- Form -->
              <v-form @submit.prevent class="d-flex flex-column" ref="form">
                <v-col cols="12">
                  <v-card title="Zaalcapaciteit gegevens">
                    <v-divider></v-divider>
                    <v-card-text class="mt-5">
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-autocomplete label="zalen" v-model="addAreaCapacitieForm.areaId"
                            :items="areacapacitieAreaOptions" :rules="formValidationRules.required"></v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-text-field class="text-primary" label="Naam" v-model="addAreaCapacitieForm.name"
                            :rules="formValidationRules.required"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-text-field class="text-primary" label="totale capaciteit"
                            v-model="addAreaCapacitieForm.total" :rules="formValidationRules.number"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-alert type="error" variant="tonal" v-if="formError != ''">{{ formError }}</v-alert>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <div>
                        <v-btn-save @click="submit" :loading="formLoading">Opslaan</v-btn-save>
                      </div>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'LibraryAreaCapacitiesAdd',
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      isEditing: false,
      formError: '',
      formLoading: false,
      date: null,
      menu: false,
      addAreaCapacitieForm: {
        areaId: null,
        name: '',
        total: '0',
      },
      areacapacitieAreaOptions: [],
      formValidationRules: {},
      rights: {
        edit: false,
        approve: false,
      },
      areaname: '',
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'zaalcapaciteit',
          disabled: false,
          href: '/beheer/zaalcapaciteit',
          bold: false,
        },
        {
          text: 'toevoegen',
          disabled: false,
          href: '/beheer/zaalcapaciteit/add',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryAreaCapacitiesAdd');
    this.checkRights();
    this.formValidationRules = functions.getFormValidationRules();
    this.getAreas();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    checkRights() {
      if (!this.rights.edit) {
        router.back();
      }
    },
    getAreas: function () {
      ApiService.get('/TheaterAreas/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.areacapacitieAreaOptions.push({ value: element.id, title: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    async submit() {
      this.formError = '';
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const form = this.addAreaCapacitieForm;
        ApiService.post('/AreaCapacities/Add', {
          id: form.id,
          areaId: form.areaId,
          name: form.name,
          total: form.total,
          theaterId: form.theaterId,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
  },
  watch: {
    addAreaCapacitieForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
