<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row flex justify="center" align="center" class="pb-2" no-gutters>
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="1120"></breadcrumbs-with-help>


          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <v-text-field v-model="tableProductionsFilter.title" label="op naam zoeken" hide-details
                variant="outlined" density="compact" bg-color="#fff" class="ma-1 input-sm"></v-text-field>
              <v-autocomplete v-model="tableProductionsFilter.seasonId" :items="seasons" item-text="text"
                @update:modelValue="getProductions" label="seizoen selecteren" hide-details variant="outlined"
                density="compact" bg-color="#fff" class="ma-1 input-sm"></v-autocomplete>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog v-model="tableProductionsFilterDialog" width="500" fullscreen>
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn class="font-weight-bold text-lowercase" color="success" rounded dark
                    v-bind="activatorProps">filter
                    overzicht
                    <v-icon small right>mdi-filter</v-icon></v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline dark">
                    <h5 class="text-primary">filter overzicht</h5>
                  </v-card-title>

                  <v-card-text>
                    <v-text-field v-model="tableProductionsFilter.title" label="op naam zoeken" hide-details dense
                      outlined background-color="#fff" class="ma-3"></v-text-field>
                    <v-autocomplete v-model="tableProductionsFilter.seasonId" :items="seasons" item-text="text"
                      @update:modelValue="getProductions" label="seizoen selecteren" hide-details dense outlined
                      background-color="#fff" class="ma-3"></v-autocomplete>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn class="font-weight-bold text-lowercase mb-5" color="success" depressed
                      @click="tableProductionsFilterDialog = false">filter overzicht
                      <v-icon small right>mdi-filter</v-icon></v-btn>
                    <v-btn class="font-weight-bold text-lowercase" color="error" outlined dark
                      @click="clearFilterProductions()">reset
                      filter
                      <v-icon small right>mdi-close</v-icon></v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table :headers="tableProductionsHeaders" :items="tableProductionsFiltered" :mobile-breakpoint="0"
          :loading="tableProductionsLoading" :page="tableProductionsPaging.page"
          :items-per-page="tableProductionsPaging.itemsPerPage" hide-default-footer
          @update:current-items="tableProductionsPaging.pageCount = Math.ceil(tableProductionsFiltered.length / tableProductionsPaging.itemsPerPage)"
          @update:items-per-page="tableProductionsPaging.pageCount = Math.ceil(tableProductionsFiltered.length / tableProductionsPaging.itemsPerPage)"
          class="table-action" fixed-header :height="tableProductionsFiltered.length > 10 ? '55vh' : ''">

          <template v-slot:[`item.action`]="{ item }">
            <v-btn rounded="circle" elevation="0" size="small" icon="mdi-account-switch" color="info" class="ml-1"
              :to="{ name: 'AdminProducersProductionsAgencyLinkEdit', params: { productionNumber: item.number } }">
              <v-icon color="primary">mdi-account-switch</v-icon>
            </v-btn>
            <v-btn rounded="circle" elevation="0" size="small" icon="mdi-delete" color="error" class="ml-1"
              @click="(deleteItem = item), (deleteItemDialog = true);">
              <v-icon color="#fff">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <!-- Tabel settings -->
        <table-settings :items="tableProductionsFiltered" :paging="tableProductionsPaging"
          :export-columns="downloadExportColumns" :file-name="'dip-producties-export'" :sheet-name="'Producties'"
          :show-excel-button="true"></table-settings>
      </v-col>
    </v-row>

    <!-- Delete dialog starts here -->
    <v-dialog v-model="deleteItemDialog" persistent width="500">
      <v-card class="pa-6" flat>
        <v-card-title class="headline">{{ this.deleteItem.title }} verwijderen?</v-card-title>
        <v-card-text>Weet je zeker dat je {{ this.deleteItem.title }} wil verwijderen? Het is daarna niet meer terug te
          vinden.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="(deleteItemDialog = false), (deleteItem = '');">Nee</v-btn>
          <v-btn color="primary" text @click="deleteItemProduction()">Ja</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ this.snackbarText }}
      <template v-slot:actions>
        <v-btn color="#fff" variant="text" @click="(snackbar = false), (snackbarText = '')">
          Sluiten
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';
import router from '../../../router/router.js';

import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import TableSettings from '@/components/TableSettings.vue';

export default {
  name: 'AdminProducersProductions',
  props: ['producerId'],
  components: {
    BreadcrumbsWithHelp,
    TableSettings,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producenten',
          disabled: false,
          href: '/admin/producenten',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producties',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      seasonId: '',
      seasons: [],
      productions: [],
      tableProductionsFiltered: [],
      tableProductionsLoading: true,
      tableProductionsHeaders: [
        { title: 'productienr', key: 'number' },
        { title: 'naam', key: 'title' },
        { title: 'impresariaat koppeling', key: 'agency.name' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
      tableProductionsFilter: {
        title: '',
        seasonId: '',
      },
      tableProductionsPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
      },
      tableProductionsFilterDialog: false,
      deleteItemDialog: false,
      deleteItem: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'secondary',
      downloadExportColumns: [
        { label: 'productienr', field: 'number' },
        { label: 'naam', field: 'title' },
        { label: 'producent', field: 'producerName' },
      ],
    };
  },
  mounted() {
    // Filter
    this.checkFilter({ viewName: this.$options.name, filterObject: this.tableProductionsFilter });
    this.tableProductionsFilter = this.getFilter(this.$options.name);

    this.getProducer();
    this.getSeasons();
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    getProducer: function () {
      ApiService.get('/Producers/Get', {
        producerId: this.producerId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const producer = response.data.result;
            this.breadcrumbs[2].text = producer.name;
            this.breadcrumbs[2].href = '/admin/producenten/' + this.producerId + '/';
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getSeasons: function () {
      const vm = this;

      ApiService.get('/Seasons/GetAll')
        .then((response) => {
          response.data.result.forEach((element) => {
            this.seasons.push({ value: element.id, title: element.name });
          });

          var now = new Date();
          response.data.result.forEach((element) => {
            var startDate = new Date(element.startDate);
            var endDate = new Date(element.endDate);

            if (now > startDate && now < endDate) {
              vm.seasonId = element.id;
              vm.tableProductionsFilter.seasonId = vm.seasonId;
              this.getProductions();
            }
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    getProductions: function () {
      this.tableProductionsLoading = true;
      this.tableProductionsFiltered = [];
      this.productions = [];
      ApiService.get('/Productions/GetAllPerProducer', {
        SeasonId: this.tableProductionsFilter.seasonId,
        ProducerId: this.producerId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.productions = response.data.result;

          for (var i = 0; i < this.productions.length; i++) {
            this.productions[i].producerName = this.productions[i].producer.name;
          }

          this.tableProductionsFiltered = this.productions;

          // Paging
          this.checkFilter({ viewName: this.$options.name + '-paging', filterObject: this.tableProductionsPaging });
          this.tableProductionsPaging = this.getFilter(this.$options.name + '-paging');

          this.filterProductions();

          this.tableProductionsLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterProductions: function () {
      const vm = this;
      this.tableProductionsFiltered = this.productions.filter(function (production) {
        return cleanWords(production.title).indexOf(cleanWords(vm.tableProductionsFilter.title)) !== -1;
      });
      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterProductions: function () {
      this.tableProductionsFilter.title = '';
      this.tableProductionsFilter.seasonId = this.seasonId;
      this.tableProductionsFilterDialog = false;
      this.getProductions();
    },
    deleteItemProduction: function () {
      ApiService.delete('/Productions/Delete', {
        productionNumber: this.deleteItem.number,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.productions.map((item) => item.number).indexOf(this.deleteItem.number);
            this.productions.splice(i, 1);
            this.filterProductions();

            this.snackbarText = this.deleteItem.title + ' is verwijderd';
            this.deleteItemDialog = false;
            this.deleteItem = '';
            this.snackbarColor = 'secondary';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {
    ...mapState('auth', ['user']),

    ...mapGetters('storage', ['getFilter']),
  },
  beforeUnmount() {
    // Filter
    this.updateFilter({ viewName: this.$options.name, filterObject: this.tableProductionsFilter });
    // Paging
    this.updateFilter({ viewName: this.$options.name + '-paging', filterObject: this.tableProductionsPaging });
  },
  watch: {
    tableProductionsFilter: {
      handler: 'filterProductions',
      deep: true,
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}
</style>
