<template>
  <div>
    <!-- Default Agreement -->
    <v-card
      class="py-6 px-3 pb-0 mb-10"
      flat
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            class="py-0 mb-8"
          >
            <p class="text-primary font-weight-medium mb-0">overeenkomst</p>
            <h3
              v-if="contract.acceptTerms"
              class="text-primary"
            >
              door aanbieder en afnemer overeengekomen standaardovereenkomst is van toepassing
            </h3>
            <h3
              v-else
              class="text-primary"
            >
              door aanbieder en afnemer overeengekomen standaardovereenkomst is <u>niet</u> van toepassing
            </h3>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: ['contract'],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {},
};
</script>
