<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="11"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- Form -->
          <v-form @submit.prevent class="d-flex flex-column" ref="form">
            <v-col cols="12">
              <v-card title="gegevens theater">
                <v-divider></v-divider>
                <v-card-text>
                  <v-row class="mt-0">
                    <v-col cols="12" class="py-1">
                      <!-- Name -->
                      <v-text-field class="text-primary" label="naam" v-model="editTheaterForm.name"
                        :rules="formValidationRules.required"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" class="py-0">
                      <!-- Geactiveerd -->
                      <v-checkbox v-model="editTheaterForm.isMember" label="klant DIP"
                        class="checkbox text-primary ma-0"></v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" class="py-1">
                      <!-- Selecteer datum -->
                      <date-picker v-if="datePickerDataLoaded" :passedDate="editTheaterForm.isMemberSince"
                        @formatedDate="editTheaterForm.isMemberSince = $event" rules="date"></date-picker>
                    </v-col>
                  </v-row>

                  <!-- Contact e-mailadres -->
                  <v-row>
                    <v-col cols="12" class="py-1">
                      <v-text-field class="text-primary" label="contact e-mailadres" type="email"
                        v-model="editTheaterForm.contactEmailAddress"></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- Branchevereniging bijwerken -->
                  <v-row>
                    <v-col cols="12" class="py-1">
                      <v-autocomplete label="selecteer branchevereniging" v-model="editTheaterForm.branchAssociationId"
                        :items="branchAssociations" clearable></v-autocomplete>
                    </v-col>
                  </v-row>

                  <!-- Ticketing systeem bijwerken -->
                  <v-row>
                    <v-col cols="12" class="py-1">
                      <v-autocomplete label="selecteer ticketing systeem" v-model="editTheaterForm.ticketSystemId"
                        :items="ticketSystems" clearable></v-autocomplete>
                    </v-col>
                  </v-row>

                  <!-- Kaartstanden bijwerken -->
                  <v-row>
                    <v-col cols="12" class="py-1">
                      <v-autocomplete label="kaartstanden bijwerken" v-model="editTheaterForm.ticketLinkType"
                        :items="ticketLinkTypeOptions" :rules="formValidationRules.required"></v-autocomplete>
                    </v-col>
                  </v-row>

                  <!-- Logo afbeelding -->
                  <v-row>
                    <v-col cols="12" class="py-1">
                      <v-file-input prepend-icon="" name="theater_logo" label="logo afbeelding uploaden" variant="outlined"
                        v-model="editTheaterForm.logoImage" accept="image/png, image/jpeg, image/bmp"
                        density="compact"></v-file-input>
                    </v-col>
                  </v-row>

                  <!-- Ticketmatic ClientName -->
                  <v-row v-if="editTheaterForm.ticketLinkType == 3">
                    <v-col cols="12" class="py-1">
                      <v-text-field class="text-primary" label="ticketmatic clientname"
                        v-model="editTheaterForm.ticketClientName" :rules="formValidationRules.required"></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- Voorwaarden DIP geaccepteerd -->
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-checkbox name="voorwaarden_dip" label="voorwaarden DIP geaccepteerd"
                        v-model="editTheaterForm.agreedToTerms" class="checkbox text-primary ma-0"></v-checkbox>
                    </v-col>
                  </v-row>

                  <!-- Publieksmonitor tonen -->
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-checkbox name="toon publieksmonitor" label="toon publieksmonitor"
                        v-model="editTheaterForm.hasPublicMonitorAccess"
                        class="checkbox text-primary ma-0"></v-checkbox>
                    </v-col>
                  </v-row>

                  <!-- Benchmark tonen -->
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-checkbox name="toon benchmark" label="toon benchmark"
                        v-model="editTheaterForm.hasBenchmarkAccess" class="checkbox text-primary ma-0"></v-checkbox>
                    </v-col>
                  </v-row>

                  <!-- Borderellen tonen -->
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-checkbox name="toon borderellen" label="toon borderellen"
                        v-model="editTheaterForm.hasBorderellenAccess" class="checkbox text-primary ma-0"></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card title="factuur instructies">
                <v-divider></v-divider>
                <v-card-text>
                  <v-row class="mt-0">
                    <v-col cols="12" class="py-0">
                      <v-textarea class="text-primary" label="factuur instructies"
                        v-model="editTheaterForm.invoiceInstructions"></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card title="adres">
                <v-divider></v-divider>
                <v-card-text>
                  <v-row class="mt-0">
                    <v-col cols="8" class="py-1">
                      <!-- Straat -->
                      <v-text-field label="straat" v-model="editTheaterForm.street"
                        :rules="formValidationRules.required"></v-text-field>
                    </v-col>

                    <v-col cols="4" class="py-1">
                      <!-- Nummer -->
                      <v-text-field label="huisnummer" v-model="editTheaterForm.number"
                        :rules="formValidationRules.required"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" class="py-1">
                      <!-- Postcode -->
                      <v-text-field label="postcode" v-model="editTheaterForm.zipCode"
                        :rules="formValidationRules.zipCodeRequired"></v-text-field>
                    </v-col>
                    <v-col cols="8" class="py-1">
                      <!-- Plaats -->
                      <v-text-field label="plaats" v-model="editTheaterForm.city"
                        :rules="formValidationRules.required"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" class="py-1">
                      <!-- Provincie -->
                      <v-autocomplete label="provincie" v-model="editTheaterForm.province" :items="provincieOptions"
                        :rules="formValidationRules.required"></v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-alert type="error" variant="tonal" v-if="formError != ''">{{ formError }}</v-alert>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <div>
                    <p class="grey--text text--lighten-1 mb-2">
                      <small><i>{{ this.lastEditedBy }}</i></small>
                    </p>

                    <v-btn-save @click="submit" :loading="formLoading">Opslaan</v-btn-save>
                  </div>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp';
import DatePicker from '../../../components/DatePicker';

export default {
  name: 'AdminTheatersEdit',
  props: ['theaterId'],
  components: {
    BreadcrumbsWithHelp,
    DatePicker,
  },
  data() {
    return {
      isEditing: false,
      formValid: false,
      formError: '',
      date: null,
      menu: false,
      datePickerDataLoaded: false,
      editTheaterForm: {
        name: '',
        isMember: false,
        isMemberSince: new Date().toISOString().substring(0, 10),
        branchAssociationId: null,
        ticketSystemId: null,
        ticketLinkType: null,
        ticketClientName: '',
        agreedToTerms: false,
        hasPublicMonitorAccess: false,
        hasBenchmarkAccess: false,
        street: '',
        number: '',
        zipCode: '',
        city: '',
        province: null,
        logoImage: null,
      },
      lastEditedBy: '',
      provincieOptions: [],
      branchAssociations: [],
      ticketSystems: [],
      ticketLinkTypeOptions: [
        { value: 1, title: 'handmatig' },
        { value: 2, title: 'api' },
        { value: 3, title: 'ticketmatic' },
      ],
      formValidationRules: {},
      theatername: '',
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'theaters',
          disabled: false,
          href: '/admin/theaters',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.getTheater();
    this.getProvinces();
    this.getBranchAssociations();
    this.getTicketSystems();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getTheater: function () {
      const vm = this;

      ApiService.get('/Theaters/Get', {
        theaterId: this.theaterId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            this.datePickerDataLoaded = true;

            const theater = response.data.result;
            this.breadcrumbs[2].text = theater.name;
            this.breadcrumbs[2].href = '/admin/theaters/' + theater.id;
            this.theatername = theater.name;
            this.editTheaterForm.name = theater.name;
            this.editTheaterForm.isMember = theater.isMember;
            this.editTheaterForm.isMemberSince = functions.getDateView(theater.isMemberSince);

            if (theater.branchAssociation != null) {
              this.editTheaterForm.branchAssociationId = theater.branchAssociation.id;
            }

            if (theater.ticketSystem != null) {
              this.editTheaterForm.ticketSystemId = theater.ticketSystem.id;
            }

            this.editTheaterForm.ticketLinkType = theater.ticketLinkType;
            this.editTheaterForm.ticketClientName = theater.ticketClientName;
            this.editTheaterForm.agreedToTerms = theater.agreedToTerms;
            this.editTheaterForm.street = theater.street;
            this.editTheaterForm.number = theater.number;
            this.editTheaterForm.zipCode = theater.zipCode;
            this.editTheaterForm.city = theater.city;
            this.editTheaterForm.province = theater.province.id;
            this.editTheaterForm.hasPublicMonitorAccess = theater.hasPublicMonitorAccess;
            this.editTheaterForm.hasBenchmarkAccess = theater.hasBenchmarkAccess;
            this.editTheaterForm.hasBorderellenAccess = theater.hasBorderellenAccess;
            this.editTheaterForm.invoiceInstructions = theater.invoiceInstructions;
            this.editTheaterForm.contactEmailAddress = theater.contactEmailAddress;
            this.lastEditedBy = theater.lastEditedBy;

            setTimeout(function () {
              vm.isEditing = false;
            }, 200);
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getProvinces: function () {
      ApiService.get('/Provinces/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.provincieOptions.push({
              value: element.id,
              title: element.name,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    getBranchAssociations: function () {
      ApiService.get('/BranchAssociations/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.branchAssociations.push({
              value: element.id,
              title: element.name,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    getTicketSystems: function () {
      ApiService.get('/TicketSystems/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.ticketSystems.push({
              value: element.id,
              title: element.name,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    async submit() {
      this.formError = '';
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const form = this.editTheaterForm;

        ApiService.put('/Theaters/Update', {
          id: this.theaterId,
          name: form.name,
          street: form.street,
          number: form.number,
          zipCode: form.zipCode,
          city: form.city,
          provinceId: form.province,
          isMember: form.isMember,
          isMemberSince: form.isMemberSince == '' ? null : functions.getDateDatabase(form.isMemberSince),
          branchAssociationId: form.branchAssociationId != null ? form.branchAssociationId : null,
          ticketSystemId: form.ticketSystemId != null ? form.ticketSystemId : null,
          ticketLinkType: form.ticketLinkType,
          ticketClientName: form.ticketLinkType == 3 ? form.ticketClientName : '',
          agreedToTerms: form.agreedToTerms,
          hasPublicMonitorAccess: form.hasPublicMonitorAccess,
          hasBenchmarkAccess: form.hasBenchmarkAccess,
          hasBorderellenAccess: form.hasBorderellenAccess,
          invoiceInstructions: form.invoiceInstructions,
          contactEmailAddress: form.contactEmailAddress,
          logoImage: (form.logoImage) ? await functions.getBase64(form.logoImage) : null,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();

            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {},
  watch: {
    editTheaterForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
