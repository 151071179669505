<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="24"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12" class="py-0">
          <!-- Form -->
          <v-form @submit.prevent class="d-flex flex-column col col-6" ref="form">
            <v-card title="gebruiker toevoegen">
              <v-divider></v-divider>
              <v-card-text>
                <v-row class="mt-0">
                  <!-- Add user -->
                  <v-col cols="12" class="py-1">
                    <v-autocomplete label="gebruiker" v-model="addUserLinkForm.userId" :items="userOptions"
                      :rules="formValidationRules.required" required variant="outlined"></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <!-- Userrol -->
                  <v-col cols="12" class="py-1">
                    <v-autocomplete label="gebruikersrol" v-model="addUserLinkForm.roleId" :items="userRoleOptions"
                      :rules="formValidationRules.required" required variant="outlined"></v-autocomplete>
                  </v-col>
                </v-row>

                <v-alert type="error" variant="tonal" v-if="formError != ''">{{ formError }}</v-alert>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <div>
                  <v-btn-save @click="submit" :loading="formLoading">Opslaan</v-btn-save>
                </div>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'AdminTheatersLinkAdd',
  props: ['agencyId'],
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      agency: {
        name: '',
      },
      isEditing: false,
      formError: '',
      formLoading: false,
      addUserLinkForm: {
        roleId: null,
        userId: null,
      },
      userOptions: [],
      userRoleOptions: [],
      formValidationRules: {},
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'impresariaten',
          disabled: false,
          href: '/admin/impresariaten',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'gebruiker toevoegen',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.getAgency();
    this.getAgencyRoles();
    this.getUsers();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getAgency: function () {
      ApiService.get('/Agencies/Get', {
        agencyId: this.agencyId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const agency = response.data.result;
            this.breadcrumbs[2].text = agency.name;
            this.breadcrumbs[2].href = '/admin/impresariaten/' + this.agencyId + '/';
            this.breadcrumbs[3].href = '/admin/impresariaten/' + this.agencyId + '/link/add';
            this.agency.name = agency.name;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getAgencyRoles: function () {
      ApiService.get('/UserRoles/GetAll', {
        organisationTypeId: 1,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          response.data.result.forEach((element) => {
            this.userRoleOptions.push({ value: element.id, title: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getUsers: function () {
      ApiService.get('/Users/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          response.data.result.forEach((element) => {
            this.userOptions.push({ value: element.id, title: element.fullName + ' (' + element.email + ')' });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    async submit() {
      this.formError = '';
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const form = this.addUserLinkForm;

        ApiService.post('/UserRoles/Add', {
          organisationTypeId: 1,
          organisationId: parseInt(this.agencyId),
          roleId: form.roleId,
          userId: form.userId,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {},
  watch: {
    addUserLinkForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
