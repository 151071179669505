<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="1117"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- Page info first row -->
          <v-row class="flex align-center pb-2" no-gutters>
            <v-col cols="12" class="py-0">
              <!-- Form -->
              <v-form @submit.prevent class="d-flex flex-column" ref="form">
                <v-col cols="12">
                  <v-card title="capaciteit wijzigen">
                    <v-divider></v-divider>
                    <v-card-text class="mt-5">
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <p class="text-primary mb-10">
                            Pas hier de zaalcapaciteit voor de gekozen voorstelling aan. Dat kan op twee
                            manieren:<br /><br />
                            1) Selecteer een van je vaste opstellingen. Hierdoor wordt de bijbehorende zaalcapaciteit
                            automatisch opgehaald. Klik op Opslaan.<br />
                            2) Sla het bovenste invoerveld over en typ direct het juiste aantal kaarten in. Klik op
                            Opslaan.
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-autocomplete id="areacapacity" clearable
                            label="Kies de juiste opstelling en bijbehorende zaalcapaciteit"
                            v-model="editProductionPerformanceCapacityForm.areacapacity" :items="areacapacities"
                            @update:modelValue="changeAreaCapacity"></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-text-field id="totalcapacity" label="Totaal aantal beschikbare kaarten"
                            v-model="editProductionPerformanceCapacityForm.totalCapacity"
                            :disabled="totalDisabled"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-alert type="error" variant="tonal" v-if="formError != ''">{{ formError }}</v-alert>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <div>
                        <v-btn-save @click="submit" :loading="formLoading">Opslaan</v-btn-save>
                      </div>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'LibraryProductionsPerformanceCapacity',
  props: ['productionNumber', 'performanceId'],
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      production: {
        name: '',
      },
      isEditing: false,
      formError: '',
      formLoading: false,
      editProductionPerformanceCapacityForm: {
        areacapacity: null,
        areaId: null,
      },
      areacapacities: [],
      areas: [],
      performanceStatus: [
        { title: 'interesse', value: 'interest' },
        { title: 'optie', value: 'option' },
        { title: 'geaccepteerd', value: 'agreed' },
        { title: 'succesoptie', value: 'success' },
        { title: 'annulering', value: 'cancellation' },
      ],
      performanceType: [
        { title: 'normaal', value: 'normal' },
        { title: 'tryout', value: 'tryout' },
        { title: 'premiere', value: 'premiere' },
        { title: 'montage', value: 'montage' },
        { title: 'derniere', value: 'derniere' },
      ],
      formValidationRules: {},
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producties',
          disabled: false,
          href: '/beheer/producties',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'capaciteit bewerken',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryProductionsPerformanceCapacity');
    this.checkRights();
    this.formValidationRules = functions.getFormValidationRules();
    this.getProduction();
    this.getOrganisations();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    checkRights() {
      if (!this.rights.edit) {
        router.back();
      }
    },
    getProduction: function () {
      ApiService.get('/Productions/Get', {
        productionNumber: this.productionNumber,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const production = response.data.result;
            this.breadcrumbs[2].text = production.title;
            this.breadcrumbs[2].href = '/beheer/producties/' + this.productionNumber + '/';
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          //router.back();
        });
    },
    getOrganisations: function () {
      ApiService.get('/Performances/GetAreaCapacities', {
        performanceId: this.performanceId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.areacapacities.push({ value: element.total, title: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    async submit() {
      this.formError = '';
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const form = this.editProductionPerformanceCapacityForm;
        ApiService.put('/Performances/UpdateCapacity', {
          performanceId: this.performanceId,
          capacity: form.totalCapacity,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
    changeAreaCapacity() {
      this.editProductionPerformanceCapacityForm.totalCapacity = this.editProductionPerformanceCapacityForm.areacapacity;
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    totalDisabled() {
      return this.editProductionPerformanceCapacityForm.areacapacity !== null;
    },
  },
  watch: {
    editProductionPerformanceCapacityForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
