<template>
  <div>
      <!-- Extra Agreement -->
      <v-card class="py-6 px-3 pb-0 mb-4" flat>
        <v-container>
          <v-row>
            <v-col cols="12" class="py-0 mb-8">
              <p :class="contract.isRemarkRejected ? 'error--text ' : 'text-primary '" class="font-weight-medium mb-0">extra afspraken</p>
              <div class="contract-diff mb-4">
                <h3 :class="{
                  'text-primary' : !contract.isRemarkRejected,
                  'error--text' : contract.isRemarkRejected,
                  'text-decoration-line-through': remarkView.newValue != null,
                }" class="text-primary mb-0 pr-2" style="white-space: pre-line;">{{ remarkView.oldValue }}</h3>
                <h3 v-if="remarkView.newValue != null" class="prev-approved--text pb-4 border-bottom" style="white-space: pre-line;">
                  {{ remarkView.newValue }}
                </h3>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
  </div>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    props: ['contract'],
    data () {
      return {
      }
    },
    computed: {
    ...mapState('auth', ['user']),
    remarkView() {
      if (this.contract.previousApproved && this.contract.remark !== this.contract.previousApproved.remark) {
        return {
          oldValue: this.contract.previousApproved.remark != null && this.contract.previousApproved.remark != '' ? this.contract.previousApproved.remark : '-',
          newValue: this.contract.remark != null && this.contract.remark != '' ? this.contract.remark : '-',
        };
      }
      return {
        oldValue: this.contract.remark,
        newValue: null,
      };
    },
  },
  }
</script>

<style scoped>
  
</style>