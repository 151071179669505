<template>
  <v-container class="d-flex align-center justify-center" style="min-height: 100vh">
    <v-container class="align-center justify-center pa-5">
      <div>
        <v-img src="/images/dip-logo.svg" contain aspect-ratio="1.6" width="115" max-width="100%"
          class="ma-5 mx-auto" />
      </div>
      <v-card class="d-flex" flat>
        <v-row class="align-center justify-center">
          <v-col cols="12" md="6" class="pa-6 pt-12">
            <h1 class="text-center text-primary mb-5">Wachtwoord instellen</h1>
            <v-form @submit.prevent class="col col-12" ref="form">
              <v-text-field label="nieuw wachtwoord" class="mb-3" v-model="password" :rules="formValidationRules.password1" required
                outlined min="8" :type="showPassword1 ? 'text' : 'password'"
                :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword1 = !showPassword1" />

              <v-text-field label="nieuw wachtwoord bevestiging" v-model="passwordConfirm"
                :rules="formValidationRules.password2" required outlined min="8"
                :type="showPassword2 ? 'text' : 'password'" :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword2 = !showPassword2" />

              <v-btn rounded x-large elevation="0" @click="submit" :loading="formLoading" color="secondary"
                class="text-lowercase font-weight-bold my-3 mr-5">
                verstuur
              </v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import ApiService from '../../services/api.service';
import router from '../../router/router.js';

export default {
  name: 'resetPassword',
  props: ['token'],
  data() {
    return {
      formLoading: false,
      password: '',
      passwordConfirm: '',
      showPassword1: false,
      showPassword2: false,
      formValidationRules: {
        password1: [
          (v) => !!v || 'dit veld is verplicht',
          (v) =>
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/i.test(v) ||
            'minimaal 8 tekens met minstens één hoofdletter, één cijfer en één speciaal teken: !@#$%^&*',
        ],
        password2: [
          (v) => !!v || 'dit veld is verplicht',
          (v) =>
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/i.test(v) ||
            'minimaal 8 tekens met minstens één hoofdletter, één cijfer en één speciaal teken: !@#$%^&*',
          (v) => v == this.password || 'wachtwoorden komen niet overeen',
        ],
      },
    };
  },
  computed: {},
  mounted() {
    let self = this;
    window.addEventListener('keyup', function (event) {
      if (event.keyCode === 13) {
        self.submit();
      }
    });
  },
  methods: {
    async submit() {
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const requestData = {
          method: 'post',
          url: '/Account/ResetPassword',
          data: {
            forgotPasswordToken: this.token,
            newPassword: this.password,
          },
        };
        ApiService.customRequest(requestData)
          .then((response) => {
            if (response.data.success) {
              router.push('/login');
            } else {
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch((error) => {
            this.formError = error;
            // eslint-disable-next-line
            console.log('error ' + error);
            this.errored = true;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
};
</script>

<style scoped></style>