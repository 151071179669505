<template>
  <v-col
    :cols="colsMobile"
    :sm="colsTablet"
    :md="colsDesktop"
    class="d-flex fill-height"
  >
    <v-card
      class="px-4 py-0 mb-1"
      flat
      width="100%"
    >
      <v-container>
        <v-row class="block-head">
          <v-col
            cols="12"
            class="py-2 d-flex align-center justify-space-between"
          >
            <p class="text-primary font-weight-medium mb-0">kaarten per klant</p>
            <help
              v-if="helpId != ''"
              bg-color=""
              :help-id="helpId"
            ></help>
          </v-col>
        </v-row>

        <v-row
          flex
          align="center"
          justify="center"
          class="py-2 flex-column flex-grow-1"
        >
          <template v-if="chart.loaded">
            <v-col
              cols="12"
              class="d-flex flex-column"
            >
              <bar
                v-if="chart.loaded"
                :chart-data="chart.data"
                :chart-options="chart.options"
              ></bar>

              <div class="widget-average d-flex flex-column align-center justify-center my-4">
                <span class="text-primary font-weight-light mb-0">gemiddeld aantal kaarten per klant: {{ widgetData.average }}</span>
                <span class="text-primary font-weight-light mb-0" v-if="widgetData.averageComparison != null">
                  {{ `vergelijking: ${widgetData.averageComparison}` }}
                </span>
              </div>
            </v-col>
          </template>

          <template v-else>
            <p class="text-primary font-weight-light mb-0">geen data beschikbaar</p>
          </template>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import Help from '../../Help.vue';
import { Bar } from 'vue-chartjs';

export default {
  props: ['helpId', 'colsMobile', 'cols-Tablet', 'colsDesktop', 'widgetData'],
  components: {
    Help,
    Bar,
  },
  data() {
    return {
      chart: {
        loaded: false,
        data: {},
        options: {},
      },
    };
  },
  methods: {
    getData() {
      this.chart.loaded = false;

      const data = {
        ticketsPerCustomer: this.widgetData,
      };

      if (!data.ticketsPerCustomer.values.length > 0) {
        return;
      }

      var labels = data.ticketsPerCustomer.values.map(function (e) {
        return e.tickets === 999 ? '>8' : e.tickets;
      });

      var dataTickets = data.ticketsPerCustomer.values.map(function (e) {
        return e.frequency;
      });

      if (data.ticketsPerCustomer.comparison != null) {
        var dataFrequencyCompared = data.ticketsPerCustomer.comparison.map(function (e) {
          return e.frequency;
        });
      }

      // Set chart options
      this.chart.options = {
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 10,
            bottom: 0,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            position: 'bottom',
            color: '#021e72',
            font: {
              family: 'Axiforma',
              size: 14,
              weight: '500',
            },
            padding: 20,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'frequentie',
            },
          },
          x: {
            title: {
              display: true,
              text: 'aantal gekochte kaarten',
            },
          },
        },
        textOnTopOfBar: false,
      };

      // Set chart data
      this.chart.data = {
        labels: labels,
        datasets: [
          {
            data: dataTickets,
            backgroundColor: ['#fff453'],
            borderWidth: 3,
            weight: 3,
          },
          {
            data: dataFrequencyCompared,
            backgroundColor: ['#0abfb8'],
            borderWidth: 3,
            weight: 3,
          },
        ],
      };
      // Load chart
      this.chart.loaded = true;
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    widgetData() {
      this.getData();
    },
  },
};
</script>