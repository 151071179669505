<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="4"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- Page info first row -->
          <v-row class="d-flex" justify="center" align="center" no-gutters>
            <v-col cols="12">
              <v-card title="gegevens gebruiker">
                <v-divider></v-divider>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="12" class="py-0">
                      <p class="text-primary mb-0">
                        <small>naam:</small> <br />
                        <strong>{{ userInfo.firstName }} {{ userInfo.lastName }}</strong>
                      </p>
                    </v-col>
                  </v-row>

                  <hr class="accent-hr my-2" />
                  <v-row no-gutters>
                    <v-col cols="12" class="py-0">
                      <p class="text-primary mb-0">
                        <small>e-mailadres:</small> <br />
                        <strong>{{ userInfo.email }}</strong>
                      </p>
                    </v-col>
                  </v-row>
                  <hr class="accent-hr my-2" />
                  <v-row no-gutters>
                    <v-col cols="12" class="py-0">
                      <p class="text-primary mb-0">
                        <small>admin:</small> <br />
                        <strong><span v-if="userInfo.isSuperUser">Ja</span><span v-else>Nee</span></strong>
                      </p>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <div>
                    <p class="grey--text text--lighten-1 mb-2">
                      <small><i>{{ this.userInfo.lastEditedBy }}</i></small>
                    </p>

                    <!-- Bewerken -->
                    <v-btn-edit class="mr-5" :to="{ name: 'AdminUsersEdit', params: { userId } }">gegevens
                      Bewerken</v-btn-edit>

                    <!-- Verwijderen -->
                    <delete-dialog v-if="user.userId != this.userId" buttonText="verwijderen" :title="deleteDialogTitle"
                      :text="deleteDialogText" @confirm="deleteItemUser()" />
                  </div>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col cols="12 mt-5">
              <v-row class="pa-0 ma-0 mb-2 d-flex flex-row align-center justify-space-between">
                <h4 class="text-primary font-weight-regular">organisatiegegevens</h4>
                <v-btn rounded small elevation="0" color="primary" class="text-lowercase"
                  :to="{ name: 'AdminUsersLinkAdd', params: { userId } }">rechten toekennen</v-btn>
              </v-row>

              <!-- Table -->
              <v-data-table :headers="tableUsersOrganisationsHeaders" :items="tableUsersOrganisationsFiltered"
                :mobile-breakpoint="0" :loading="tableUsersOrganisationsLoading" :items-per-page="-1"
                @click:row="(_, x) => goToDetail(x.item)" hide-default-footer class="table-action">
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn rounded="circle" elevation="0" size="small" icon="mdi-square-edit-outline" color="info"
                    class="ml-1" @click.stop="goToDetail(item)">
                    <v-icon color="primary">mdi-square-edit-outline</v-icon>
                  </v-btn>
                  <delete-dialog title="Gebruikersrol verwijderen?"
                    text="Weet je zeker dat je deze gebruikersrol wil verwijderen? Het is daarna niet meer terug te vinden."
                    @confirm="(deleteItemLink = item), deleteItemLinkUser();" />
                </template>
              </v-data-table>
            </v-col>

            <v-col cols="12 mt-5">
              <v-row class="pa-0 ma-0 mb-2 d-flex flex-row align-center justify-space-between">
                <h4 class="text-primary font-weight-regular">tokens</h4>
                <v-btn rounded small elevation="0" color="primary" class="text-lowercase"
                  :loading="tableUsersTokensLoading" @click="addClientCredentials()">token toekennen</v-btn>
              </v-row>

              <!-- Table -->
              <v-data-table :headers="tableUsersTokensHeaders" :items="tableUsersTokensFiltered" :mobile-breakpoint="0"
                :loading="tableUsersTokensLoading" :items-per-page="-1" hide-default-footer class="table-action">
                <template v-slot:[`item.action`]="{ item }">
                  <delete-dialog title="Token verwijderen?"
                    text="Weet je zeker dat je dit token wil verwijderen? Het is daarna niet meer terug te vinden."
                    @confirm="(deleteItemToken = item), deleteItemTokenUser();" />
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Snackbar -->
      <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ this.snackbarText }}
        <template v-slot:actions>
          <v-btn color="#fff" variant="text" @click="(snackbar = false), (snackbarText = '')">
            Sluiten
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';

import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'AdminUsersDetail',
  props: ['userId'],
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      userInfo: {
        firstName: '',
        lastName: '',
        email: '',
        isSuperUser: false,
        lastEditedBy: '',
      },
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'gebruikers',
          disabled: false,
          href: '/admin/gebruikers',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      deleteItemLink: '',
      deleteItemToken: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      tableUsersOrganisationsFiltered: [],
      tableUsersOrganisationsLoading: true,
      tableUsersOrganisationsHeaders: [
        { title: 'organisatie', key: 'organisationName' },
        { title: 'rol', key: 'roleName' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
      tableUsersTokensFiltered: [],
      tableUsersTokensLoading: true,
      tableUsersTokensHeaders: [
        { title: 'ClientId', key: 'clientId' },
        { title: 'Secret', key: 'clientSecret' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
    };
  },
  mounted() {
    this.getUser();
    this.getUserOrganisations();
    this.getUserTokens();
  },
  methods: {
    getUser: function () {
      ApiService.get('/Users/Get', {
        userId: this.userId,
      })
        .then((response) => {
          if (response.data.success) {
            const userInfo = response.data.result;

            this.userInfo.firstName = userInfo.firstName != null ? userInfo.firstName : '';
            this.userInfo.lastName = userInfo.lastName != null ? userInfo.lastName : '';
            this.userInfo.email = userInfo.email;
            this.userInfo.isSuperUser = userInfo.isSuperUser;
            this.userInfo.lastEditedBy = userInfo.lastEditedBy;

            this.breadcrumbs[2].text = this.userInfo.firstName + ' ' + this.userInfo.lastName;
            this.breadcrumbs[2].href = '/admin/gebruikers/' + userInfo.id;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getUserOrganisations: function () {
      ApiService.get('/Users/GetOrganisationRoles', {
        userId: this.userId,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableUsersOrganisationsFiltered = response.data.result;
            this.tableUsersOrganisationsLoading = false;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    getUserTokens: function () {
      ApiService.get('/Users/GetClientCredentials', {
        userId: this.userId,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableUsersTokensFiltered = response.data.result;
            this.tableUsersTokensLoading = false;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    addClientCredentials: function () {
      this.tableUsersTokensLoading = true;
      ApiService.post('/Users/AddClientCredentials', {
        userId: this.userId,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableUsersTokensLoading = false;
            this.getUserTokens();
          } else {
            this.tableUsersTokensLoading = false;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.tableUsersTokensLoading = false;
        });
    },
    goToDetail(row) {
      console.log(row, row.organisationTypeId);
      this.$router.push({
        name: 'AdminUsersLinkEdit',
        params: { userId: this.userId, linkId: row.linkId, organisationTypeId: row.organisationTypeId },
      });
    },
    deleteItemUser: function () {
      ApiService.delete('/Users/Delete', {
        id: this.userId,
      })
        .then((response) => {
          if (response.data.success) {
            this.snackbarText = this.userInfo.firstName + ' is verwijderd';
            this.snackbarColor = 'secondary';
            this.snackbar = true;
            setTimeout(function () {
              router.push({ path: '/admin/gebruikers' });
            }, 1500);
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    deleteItemLinkUser: function () {
      ApiService.delete('/UserRoles/Delete', {
        linkId: this.deleteItemLink.linkId,
        organisationTypeId: this.deleteItemLink.organisationTypeId,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.tableUsersOrganisationsFiltered.map((item) => item.linkId).indexOf(this.deleteItemLink.linkId);
            this.tableUsersOrganisationsFiltered.splice(i, 1);

            this.snackbarText = 'Gebruikersrol is verwijderd';
            this.snackbarColor = 'secondary';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    deleteItemTokenUser: function () {
      ApiService.delete('/Users/DeleteClientCredentials', {
        tokenId: this.deleteItemToken.id,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.tableUsersTokensFiltered.map((item) => item.id).indexOf(this.deleteItemToken.id);
            this.tableUsersTokensFiltered.splice(i, 1);

            this.snackbarText = 'Token is verwijderd';
            this.snackbarColor = 'secondary';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    deleteDialogTitle() {
      return `${this.userInfo.firstName} ${this.userInfo.lastName} verwijderen?`;
    },
    deleteDialogText() {
      return `Weet je zeker dat je ${this.userInfo.firstName} ${this.userInfo.lastName} wil verwijderen? Het is daarna niet meer terug te vinden.`;
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
