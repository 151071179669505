<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="57"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- Page info first row -->
          <v-row flex justify="center" align="center" class="pb-2" no-gutters>
            <v-col cols="12" class="py-0">
              <!-- Form -->
              <v-form @submit.prevent class="d-flex flex-column col col-6" ref="form">
                <v-col cols="12">
                  <v-card title="toeslag toevoegen">
                    <v-divider></v-divider>
                    <v-card-text class="mt-5">
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <!-- <v-text-field label="Start datum" placeholder="DD-MM-JJJJ" v-model="editAreaSurchargeForm.startDate" :rules="formValidationRules.dateRequired"></v-text-field> -->
                            <date-picker v-if="datePickerDataLoaded" :passedDate="editAreaSurchargeForm.startDate" @formatedDate="editAreaSurchargeForm.startDate = $event"
                            rules="dateRequired"></date-picker>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-text-field label="theatertoeslag" prefix="€" v-model="editAreaSurchargeForm.theaterFee"
                            :rules="formValidationRules.valutaRequired"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-text-field label="consumptietoeslag" prefix="€"
                            v-model="editAreaSurchargeForm.consumptionFee"
                            :rules="formValidationRules.valutaRequired"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-text-field label="servicetoeslag" prefix="€" v-model="editAreaSurchargeForm.serviceFee"
                            :rules="formValidationRules.valutaRequired"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-text-field label="kosten garderobe" prefix="€" v-model="editAreaSurchargeForm.wardrobeFee"
                            :rules="formValidationRules.valutaRequired"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <div>
                        <p class="grey--text text--lighten-1 mb-2">
                          <small><i>{{ this.lastEditedBy }}</i></small>
                        </p>
                        <v-btn-save @click="submit" :loading="formLoading">Opslaan</v-btn-save>
                      </div>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import DatePicker from '../../../components/DatePicker';

import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'LibraryAreasSurchargeEdit',
  props: ['theaterAreaId', 'surchargeId'],
  components: {
    BreadcrumbsWithHelp,
    DatePicker,
  },
  data() {
    return {
      surchargeName: '',
      isEditing: false,
      formError: '',
      formLoading: false,
      datePickerDataLoaded: false,
      editAreaSurchargeForm: {
        startDate: new Date().toISOString().substring(0, 10),
        theaterFee: '',
        consumptionFee: '',
        serviceFee: '',
        wardrobeFee: '',
      },
      lastEditedBy: '',
      userOptions: [],
      userRoleOptions: [],
      formValidationRules: {},
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'zalen',
          disabled: false,
          href: '/beheer/zalen',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'toeslag bewerken',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryAreasSurchargeEdit');
    this.checkRights();
    this.formValidationRules = functions.getFormValidationRules();
    this.getArea();
    this.getSurcharge();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    checkRights() {
      if (!this.rights.edit) {
        router.back();
      }
    },
    getArea: function () {
      ApiService.get('/TheaterAreas/Get', {
        theaterAreaId: this.theaterAreaId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const area = response.data.result;
            this.breadcrumbs[2].text = area.name;
            this.breadcrumbs[2].href = '/beheer/zalen/' + this.theaterAreaId;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getSurcharge: function () {
      const vm = this;

      ApiService.get('/Surcharges/Get', {
        surchargeId: this.surchargeId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            this.datePickerDataLoaded = true;

            const link = response.data.result;
            this.editAreaSurchargeForm.startDate = functions.getDateView(link.startDate);
            this.editAreaSurchargeForm.theaterFee = functions.getEuroCurrencyView(link.theaterFee);
            this.editAreaSurchargeForm.consumptionFee = functions.getEuroCurrencyView(link.consumptionFee);
            this.editAreaSurchargeForm.serviceFee = functions.getEuroCurrencyView(link.serviceFee);
            this.editAreaSurchargeForm.wardrobeFee = functions.getEuroCurrencyView(link.wardrobeFee);
            this.lastEditedBy = link.lastEditedBy;

            setTimeout(function () {
              vm.isEditing = false;
            }, 200);
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    async submit() {
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const form = this.editAreaSurchargeForm;

        ApiService.put('/Surcharges/Update', {
          id: this.surchargeId,
          startDate: functions.getDateDatabase(form.startDate),
          theaterFee: functions.getEuroCurrencyDatabase(form.theaterFee),
          consumptionFee: functions.getEuroCurrencyDatabase(form.consumptionFee),
          serviceFee: functions.getEuroCurrencyDatabase(form.serviceFee),
          wardrobeFee: functions.getEuroCurrencyDatabase(form.wardrobeFee),
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formLoading = false;
            }
          })
          .catch((error) => {
            this.formError = error;
            // eslint-disable-next-line
            console.log('error ' + error);
            this.errored = true;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
  },
  watch: {
    editAreaSurchargeForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
