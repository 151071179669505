<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="15"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- Form -->
          <v-form @submit.prevent class="d-flex flex-column" ref="form">
            <v-col cols="12">
              <v-card title="gegevens producent">
                <v-divider></v-divider>
                <v-card-text>
                  <!-- Name -->
                  <v-row class="mt-0">
                    <v-col cols="12" class="py-1">
                      <v-text-field class="text-primary" label="naam" v-model="addProducerForm.name"
                        :rules="formValidationRules.required"></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- Type -->
                  <v-row>
                    <v-col cols="12" class="py-1">
                      <v-autocomplete label="type" v-model="addProducerForm.type" :items="producerTypeOptions"
                        :rules="formValidationRules.required"></v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" class="py-1">
                      <!-- Branchevereniging bijwerken -->
                      <v-autocomplete label="selecteer branchevereniging" hide-details
                        v-model="addProducerForm.branchAssociationId" :items="branchAssociations"
                        clearable></v-autocomplete>
                    </v-col>
                  </v-row>

                  <!-- Publieksmonitor tonen -->
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <!-- Voorwaarden DIP geaccepteerd -->
                      <v-checkbox label="voorwaarden DIP geaccepteerd" hide-details
                        v-model="addProducerForm.agreedToTerms" class="checkbox text-primary ma-0"></v-checkbox>
                      <v-checkbox label="toon publieksmonitor" v-model="addProducerForm.hasPublicMonitorAccess"
                        class="checkbox text-primary ma-0"></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card title="adres">
                <v-divider></v-divider>
                <v-card-text>
                  <v-row class="mt-0">
                    <!-- Straat -->
                    <v-col cols="8" class="py-1">
                      <v-text-field label="straat" v-model="addProducerForm.street"></v-text-field>
                    </v-col>

                    <v-col cols="4" class="py-1">
                      <!-- Nummer -->
                      <v-text-field label="huisnummer" v-model="addProducerForm.number"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" class="py-1">
                      <!-- Postcode -->
                      <v-text-field label="postcode" v-model="addProducerForm.zipCode"
                        :rules="formValidationRules.zipCode"></v-text-field>
                    </v-col>
                    <v-col cols="8" class="py-1">
                      <!-- Plaats -->
                      <v-text-field label="plaats" v-model="addProducerForm.city"></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- Provincie -->
                  <v-row>
                    <v-col cols="12" class="py-1">
                      <v-autocomplete label="provincie" v-model="addProducerForm.province" :items="provincieOptions"
                        :rules="formValidationRules.required"></v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-alert type="error" variant="tonal" v-if="formError != ''">{{ formError }}</v-alert>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <div>
                    <v-btn-save @click="submit" :loading="formLoading">Opslaan</v-btn-save>
                  </div>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'AdminProducersAdd',
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      isEditing: false,
      formError: '',
      formLoading: false,
      addProducerForm: {
        name: '',
        agreedToTerms: false,
        hasPublicMonitorAccess: false,
        street: '',
        number: '',
        zipCode: '',
        city: '',
        province: null,
        type: null,
        branchAssociationId: null,
      },
      provincieOptions: [],
      branchAssociations: [],
      producerTypeOptions: [],
      formValidationRules: {},
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producenten',
          disabled: false,
          href: '/admin/producenten',
          bold: false,
        },
        {
          text: 'toevoegen',
          disabled: false,
          href: '/admin/producenten/add',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.getproducerTypeOptions();
    this.formValidationRules = functions.getFormValidationRules();
    this.getProvinces();
    this.getBranchAssociations();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getproducerTypeOptions: function () {
      ApiService.get('/ProducerTypes/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.producerTypeOptions.push({ value: element.id, title: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    getProvinces: function () {
      ApiService.get('/Provinces/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.provincieOptions.push({ value: element.id, title: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    getBranchAssociations: function () {
      ApiService.get('/BranchAssociations/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.branchAssociations.push({
              value: element.id,
              title: element.name,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    async submit() {
      this.formError = '';
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const form = this.addProducerForm;

        ApiService.post('/Producers/Add', {
          name: form.name,
          agreedToTerms: form.agreedToTerms,
          hasPublicMonitorAccess: form.hasPublicMonitorAccess,
          street: form.street,
          number: form.number,
          zipCode: form.zipCode,
          city: form.city,
          provinceId: form.province,
          typeId: form.type,
          branchAssociationId: form.branchAssociationId != '' ? form.branchAssociationId : null,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {},
  watch: {
    addProducerForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
