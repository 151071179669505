<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row flex align="center" justify="space-between" class="pa-2">
          <v-col></v-col>
          <v-col class="d-flex align-center justify-end flex-grow-0">
            <v-btn color="primary" variant="outlined" rounded depressed class="font-weight-bold text-lowercase ms-2"
              :to="{ name: 'SalesMonitorProductions' }">
              Per productie
            </v-btn>
            <v-btn color="primary" rounded depressed class="font-weight-bold text-lowercase ms-2">
              Per voorstelling
            </v-btn>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row flex justify="center" align="center" class="pb-2" no-gutters>
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="76"></breadcrumbs-with-help>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <filter-period :includeOptions="['thisWeek', 'thisSeason', 'nextSeason', 'prevSeason']"
                selectedDefault="thisSeason" @changePeriod="filterPeriod = $event"></filter-period>
              <v-autocomplete v-if="user.organisationTypeId !== 3" v-model="tableSalesMonitorFilter.producerId"
                :items="producers" label="producent selecteren" clearable hide-details density="compact" bg-color="#fff"
                class="ma-1 input-sm"></v-autocomplete>
              <v-autocomplete v-if="user.organisationTypeId == 2" v-model="tableSalesMonitorFilter.agencyId"
                :items="agencies" item-text="text" label="impresariaat selecteren" clearable hide-details
                density="compact" bg-color="#fff" class="ma-1 input-sm"></v-autocomplete>
              <v-autocomplete v-if="user.organisationTypeId == 2" v-model="tableSalesMonitorFilter.areaId"
                :items="areaNames" item-text="text" label="zaal selecteren" clearable hide-details density="compact"
                bg-color="#fff" class="ma-1 input-sm"></v-autocomplete>
              <v-autocomplete v-model="tableSalesMonitorFilter.isSoldOut" :items="[
                { value: true, title: 'uitverkocht' },
                { value: false, title: 'niet uitverkocht' },
                { value: 1, title: 'geen verkoop' },
                { value: '', title: 'uitverkocht/niet uitverkocht' },
              ]" item-text="text" label="uitverkocht/niet uitverkocht" clearable hide-details density="compact"
                bg-color="#fff" class="ma-1 input-sm"></v-autocomplete>
              <v-text-field v-model="tableSalesMonitorFilter.productionTitle" label="productie zoeken" hide-details
                density="compact" bg-color="#fff" class="ma-1 input-sm"></v-text-field>
              <v-autocomplete v-if="user.organisationTypeId !== 2" v-model="tableSalesMonitorFilter.genreId"
                :items="genres" label="genre selecteren" clearable hide-details density="compact" bg-color="#fff"
                class="ma-1 input-sm"></v-autocomplete>
              <v-autocomplete v-if="user.organisationTypeId !== 2" v-model="tableSalesMonitorFilter.theaterId"
                :items="theaters" label="selecteer een theater" clearable hide-details density="compact" bg-color="#fff"
                class="ma-1 input-sm"></v-autocomplete>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog v-model="tableSalesMonitorFilterDialog" width="500" fullscreen>
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn class="font-weight-bold text-lowercase" color="success" rounded dark
                    v-bind="activatorProps">filter overzicht
                    <v-icon small right>mdi-filter</v-icon></v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline dark">
                    <h5 class="text-primary">filter overzicht</h5>
                  </v-card-title>

                  <v-card-text>
                    <filter-period :includeOptions="['thisWeek', 'thisSeason', 'nextSeason', 'prevSeason']"
                      selectedDefault="thisSeason" @changePeriod="filterPeriod = $event"></filter-period>
                    <v-autocomplete v-if="user.organisationTypeId !== 3" v-model="tableSalesMonitorFilter.producerId"
                      :items="producers" label="producent selecteren" clearable hide-details density="compact"
                      bg-color="#fff" class="ma-1 input-sm"></v-autocomplete>
                    <v-autocomplete v-if="user.organisationTypeId == 2" v-model="tableSalesMonitorFilter.agencyId"
                      :items="agencies" item-text="text" label="impresariaat selecteren" clearable hide-details
                      density="compact" bg-color="#fff" class="ma-1 input-sm"></v-autocomplete>
                    <v-autocomplete v-if="user.organisationTypeId == 2" v-model="tableSalesMonitorFilter.areaId"
                      :items="areaNames" item-text="text" label="zaal selecteren" clearable hide-details
                      density="compact" bg-color="#fff" class="ma-1 input-sm"></v-autocomplete>
                    <v-autocomplete v-model="tableSalesMonitorFilter.isSoldOut" :items="[
                      { value: true, title: 'uitverkocht' },
                      { value: false, title: 'niet uitverkocht' },
                      { value: null, title: 'geen verkoop' },
                      { value: '', title: 'uitverkocht/niet uitverkocht' },
                    ]" item-text="text" label="uitverkocht/niet uitverkocht" clearable hide-details density="compact"
                      bg-color="#fff" class="ma-1 input-sm"></v-autocomplete>
                    <v-text-field v-model="tableSalesMonitorFilter.productionTitle" label="productie zoeken"
                      hide-details dense outlined background-color="#fff" class="ma-1 input-sm"></v-text-field>
                    <v-autocomplete v-if="user.organisationTypeId !== 2" v-model="tableSalesMonitorFilter.genreId"
                      :items="genres" label="genre selecteren" clearable hide-details density="compact" bg-color="#fff"
                      class="ma-1 input-sm"></v-autocomplete>
                    <v-autocomplete v-if="user.organisationTypeId !== 2" v-model="tableSalesMonitorFilter.theaterId"
                      :items="theaters" label="selecteer een theater" hide-details density="compact" bg-color="#fff"
                      class="ma-1 input-sm"></v-autocomplete>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn class="font-weight-bold text-lowercase mb-5" color="success" depressed
                      @click="tableSalesMonitorFilterDialog = false">filter overzicht
                      <v-icon small right>mdi-filter</v-icon></v-btn>
                    <v-btn class="font-weight-bold text-lowercase" color="error" variant="outlined" dark
                      @click="clearfilterPerformances()">reset
                      filter
                      <v-icon small right>mdi-close</v-icon></v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table :headers="tableSalesMonitorPerformancesHeadersFiltered"
          :items="tableSalesMonitorPerformancesFiltered" :mobile-breakpoint="0"
          :loading="tableSalesMonitorPerformancesLoading" :page.sync="tableSalesMonitorPublicPaging.page"
          :items-per-page="tableSalesMonitorPublicPaging.itemsPerPage" hide-default-footer
          @update:current-items="tableSalesMonitorPublicPaging.pageCount = Math.ceil(tableSalesMonitorPerformancesFiltered.length / tableSalesMonitorPublicPaging.itemsPerPage)"
          @update:items-per-page="tableSalesMonitorPublicPaging.pageCount = Math.ceil(tableSalesMonitorPerformancesFiltered.length / tableSalesMonitorPublicPaging.itemsPerPage)"
          @click:row="(_, x) => goToDetail(x.item)" class="table-action row-is-clickable" fixed-header
          :height="tableSalesMonitorPerformancesFiltered.length > 10 ? '55vh' : ''">
          <template v-slot:[`item.productionTitle`]="{ item }">
            <td>
              <span>{{ item.productionTitle }}</span>
            </td>
          </template>
          <template v-if="user.organisationTypeId !== 2" v-slot:[`item.occupancyRate`]="{ item }">
            <td class="tooltip-container">
              <span class="tooltip">
                <table>
                  <tbody>
                    <tr>
                      <th scope="row">capaciteit</th>
                      <td>{{ item.capacity }}</td>
                    </tr>
                    <tr>
                      <th scope="row">%bezet</th>
                      <td>
                        {{
                          item.occupancyRate === 0 ? item.occupancyRate + '%' : getPercentageView(item.occupancyRate)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">%bezet landelijk</th>
                      <td>
                        {{
                          item.nationalOccupancyRate === 0
                            ? item.nationalOccupancyRate + '%'
                            : getPercentageView(item.nationalOccupancyRate)
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </span>
              <v-progress-linear class="custom_progress" bg-color="grey-darken-2" height="20" width="200" rounded="true"
                :model-value="item.occupancyRate" color="#0abfb8">
                <div class="target" :style="{ left: item.nationalOccupancyRate + '%' }"
                  v-if="item.nationalOccupancyRate > 0"></div>
              </v-progress-linear>
            </td>
          </template>

          <template v-else v-slot:[`item.occupancyRate`]="{ item }">
            <td class="tooltip-container">
              <span class="tooltip">
                <table>
                  <tbody>
                    <tr>
                      <th scope="row">capaciteit</th>
                      <td>{{ item.capacity }}</td>
                    </tr>
                    <tr>
                      <th scope="row">%bezet</th>
                      <td>
                        {{
                          item.occupancyRate === 0 ? item.occupancyRate + '%' : getPercentageView(item.occupancyRate)
                        }}
                      </td>
                    </tr>
                    <tr v-if="user.organisationTypeId == 2">
                      <th scope="row">%bezet landelijk</th>
                      <td>
                        {{
                          item.nationalOccupancyRate === 0
                            ? item.nationalOccupancyRate + '%'
                            : getPercentageView(item.nationalOccupancyRate)
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </span>
              <v-progress-linear class="custom_progress" bg-color="grey-darken-2" height="20" width="200" rounded="true"
                :model-value="item.occupancyRate" color="#0abfb8">
                <div class="target" :style="{ left: item.nationalOccupancyRate + '%' }"
                  v-if="item.nationalOccupancyRate > 0"></div>
              </v-progress-linear>
            </td>
          </template>

          <template v-slot:[`item.totalTickets`]="{ item }">
            <v-tooltip v-if="item.averageTotalTickets != null"
              :text="'landelijk gemiddelde: ' + formatThousandsWithDot(item.averageTotalTickets)"
              content-class="bg-primary">
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ formatThousandsWithDot(item.totalTickets) }}</span>
              </template>
            </v-tooltip>
            <div v-else>
              <span>{{ formatThousandsWithDot(item.totalTickets) }}</span>
            </div>
          </template>

          <template v-slot:[`item.totalRevenueInVat`]="{ item }">
            <span>{{ getEuroSign(item.totalRevenueInVat) }}</span>
          </template>

          <template v-slot:[`item.reservedTickets`]="{ item }">
            <span>{{ formatThousandsWithDot(item.reservedTickets) }}</span>
          </template>

          <template v-slot:[`item.startDate`]="{ item }">{{ item.startDateView }}</template>

          <template v-slot:[`item.averageTicketPrice`]="{ item }">
            <span>{{ getEuroSign(item.averageTicketPrice) }}</span>
          </template>

          <template v-slot:[`item.blockedTickets`]="{ item }">
            <span>{{ formatThousandsWithDot(item.blockedTickets) }}</span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-btn v-if="item.productionPnlId != null && item.pnlId != null" rounded="circle" elevation="0" size="small"
              color="info" class="ml-1" icon="mdi-link-variant" @click.stop="goToPerformanceDetail(item)">
              <v-icon color="primary">mdi-link-variant</v-icon>
            </v-btn>

            <v-btn rounded="circle" elevation="0" size="small" icon="mdi-eye" color="warning" class="ml-1"
              @click.stop="goToDetail(item)">
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
          </template>

          <template v-slot:[`body.append`] v-if="!tableSalesMonitorPerformancesLoading">
            <tr class="table-selection-total" v-if="tableSalesMonitorFilterActive">
              <th class="title">selectie</th>
              <th class=""></th>
              <th class="" v-if="user.organisationTypeId !== 2"></th>
              <th class="" v-if="user.organisationTypeId !== 2"></th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorSelected.totalTickets) }}
              </th>
              <th class="text">
                {{ getEuroSign(this.tableSalesMonitorSelected.totalRevenueInVat) }}
              </th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorSelected.reservedTickets) }}
              </th>
              <th class="text">
              </th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorSelected.blockedTickets) }}
              </th>
              <th class="" v-for="header in tableSalesMonitorPerformancesHeadersFiltered.slice(
                user.organisationTypeId === 2 ? 7 : 9
              )" :key="header.value"></th>
            </tr>
            <tr class="table-total">
              <th class="title">totaal</th>
              <th class=""></th>
              <th class="" v-if="user.organisationTypeId !== 2"></th>
              <th class="" v-if="user.organisationTypeId !== 2"></th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorTotal.totalTickets) }}
              </th>
              <th class="text">
                {{ getEuroSign(this.tableSalesMonitorTotal.totalRevenueInVat) }}
              </th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorTotal.reservedTickets) }}
              </th>
              <th class="text">
              </th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorTotal.blockedTickets) }}
              </th>
              <th class="" v-for="header in tableSalesMonitorPerformancesHeadersFiltered.slice(
                user.organisationTypeId === 2 ? 7 : 9
              )" :key="header.value"></th>
            </tr>
          </template>
        </v-data-table>

        <!-- Table settings -->
        <table-settings :items="tableSalesMonitorPerformancesFiltered" :paging="tableSalesMonitorPublicPaging"
          :export-columns="downloadExportColumns" :file-name="'dip-salesmonitor-voorstellingen-export'"
          :sheet-name="'Voorstellingen'" :show-excel-button="true">
        </table-settings>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import BreadcrumbsWithHelp from '../../components/BreadcrumbsWithHelp.vue';
import TableSettings from '../../components/TableSettings';
import functions from '../../services/functions.service';
import FilterPeriod from '../../components/FilterPeriod';

export default {
  name: 'SalesMonitorPerformances',
  components: {
    BreadcrumbsWithHelp,
    TableSettings,
    FilterPeriod,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'verkoopmonitor',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      filterPeriod: {
        from: '',
        to: '',
        previousText: '',
      },
      performances: [],
      tableSalesMonitorSelected: {
        amount: 0,
        sold: 0,
        reserved: 0,
        blocked: 0,
        averageTicketPrice: 0,
        totalRevenueInVat: 0,
      },
      tableSalesMonitorTotal: {
        amount: 0,
        sold: 0,
        reserved: 0,
        blocked: 0,
        averageTicketPrice: 0,
        totalRevenueInVat: 0,
      },
      tableSalesMonitorPerformancesFiltered: [],
      selectAgencyId: '',
      theaters: [],
      agencies: [],
      producers: [],
      genres: [],
      areaNames: [],
      tableSalesMonitorPerformancesLoading: true,
      tableSalesMonitorPerformancesHeaders: [
        { title: 'naam', key: 'productionTitle' },
        { title: 'datum en tijd', key: 'startDate' },
        { title: 'theater', key: 'theaterName', showFor: [1, 3] },
        { title: 'plaats', value: 'areaCity', showFor: [1, 3] },
        { title: 'verkocht', value: 'totalTickets' },
        { title: 'recette', value: 'totalRevenueInVat' },
        { title: 'gereserv.', value: 'reservedTickets' },
        { title: 'gem. ticketprijs', value: 'averageTicketPrice' },
        { title: 'geblok.', value: 'blockedTickets' },
        {
          title: '% bezet',
          value: 'occupancyRate',
          sortable: false,
          width: '125px',
          showFor: [1, 3],
        },
        {
          title: '% bezet',
          value: 'occupancyRate',
          sortable: false,
          width: '125px',
          showFor: [2],
        },
        { title: 'type', value: 'type' },
        { title: 'status', value: 'state' },
        { title: 'zaal', value: 'areaName', showFor: [2] },
        { title: 'producent', value: 'producerName' },
        { title: 'co-productie', value: 'isCoProduction', showFor: [1, 3] },
        { title: 'impresariaat', value: 'agencyName', showFor: [2, 3] },
        { title: '', value: 'action', sortable: false, align: 'end' },
      ],
      tableSalesMonitorFilterActive: false,
      tableSalesMonitorFilter: {
        productionTitle: '',
        agencyId: null,
        theaterId: null,
        producerId: null,
        genreId: null,
        areaId: null,
        isSoldOut: null,
      },
      tableSalesMonitorPublicPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
      },
      tableSalesMonitorFilterDialog: false,
      downloadExportColumns: [
        { label: 'naam', field: 'productionTitle' },
        { label: 'nummer productie', field: 'productionNumber' },
        { label: 'nummer voorstelling', field: 'number' },
        { label: 'data', field: 'startDate' },
        { label: 'theater', field: 'theaterName' },
        { label: 'zaal', field: 'areaName' },
        { label: 'plaats', field: 'areaCity' },
        { label: 'aantal vrijkaarten', field: 'freeTickets' },
        { label: 'verkocht normaal', field: 'normalTickets' },
        { label: 'recette normaal', field: 'normalRevenueInVat' },
        { label: 'verkocht korting', field: 'discountTickets' },
        { label: 'recette korting', field: 'discountRevenueInVat' },
        { label: 'verkocht overig', field: 'otherTickets' },
        { label: 'recette overig', field: 'otherRevenueInVat' },
        { label: 'verkocht totaal', field: 'totalTickets' },
        {
          label: 'verkocht landelijk gemiddelde',
          field: 'averageTotalTickets',
        },
        { label: 'recette totaal', field: 'totalRevenueInVat' },
        { label: 'gereserveerd', field: 'reservedTickets' },
        { label: 'recette reserveringen', field: 'reservedRevenueInVat' },
        { label: 'gemiddelde kaartprijs', field: 'averageTicketPrice' },
        { label: 'geblokkeerd', field: 'blockedTickets' },
        { label: 'capaciteit', field: 'capacity' },
        { label: '%bezet', field: 'occupancyRate' },
        { label: '%bezet buiten blokkade', field: 'exBlockedOccupancyRate' },
        { label: '%bezet verwacht', field: 'expectedOccupancyRate' },
        { label: '%bezet landelijk', field: 'nationalOccupancyRate' },
        { label: 'voorstellingen hier', field: 'localPerformances' },
        { label: 'voorstellingen landelijk', field: 'nationalPerformances' },
        { label: 'type', field: 'type' },
        { label: 'status', field: 'state' },
        { label: 'producent', field: 'producerName' },
        { label: 'co-productie', field: 'isCoProduction' },
        { label: 'co-producenten', field: 'coProducers' },
        { label: 'muziekale begeleiding', field: 'accompanyingProducers' },
        { label: 'impresariaat', field: 'agencyName' },
        { label: 'genre', field: 'genreName' },
        { label: 'geen verkoop', field: 'noSales' },
      ],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('SalesMonitor');

    // Filter
    this.checkFilter({
      viewName: this.$options.name,
      filterObject: this.tableSalesMonitorFilter,
    });
    this.tableSalesMonitorFilter = this.getFilter(this.$options.name);
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),
    ...mapMutations('storage', ['getStoredSalesMonitorPerformances', 'getStoredSalesMonitorPerformancesFilterPeriod']),

    goToDetail(row) {
      this.$router.push({
        name: 'SalesMonitorPerformancesDetail',
        params: {
          productionNumber: row.productionNumber,
          performanceId: row.id,
        },
      });
    },
    goToPerformanceDetail(row) {
      this.$router.push({
        name: 'PublicMonitorPerformancesDetail',
        params: {
          productionNumber: row.productionPnlId,
          performanceId: row.pnlId,
        },
      });
    },
    getEuroSign: function (value) {
      return functions.getEuroSignView(value);
    },
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    calculateArraySum: function (items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    },
    calculateArrayAvg: function (items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop] / items.length;
      }, 0);
    },
    getPercentageView: function (value) {
      return functions.getPercentageViewWithoutDecimals(value);
    },
    getPerformances: function () {
      this.performances = [];
      this.tableSalesMonitorPerformancesFiltered = [];
      this.tableSalesMonitorPerformancesLoading = true;

      if (
        this.$store.state.storage.storedSalesMonitorPerformances != undefined &&
        this.$store.state.storage.storedSalesMonitorPerformances.length > 0 &&
        this.$store.state.storage.storedFilterPeriod != null &&
        this.filterPeriod.from.toString() ==
        this.$store.state.storage.storedSalesMonitorPerformancesFilterPeriod.from.toString() &&
        this.filterPeriod.to.toString() ==
        this.$store.state.storage.storedSalesMonitorPerformancesFilterPeriod.to.toString()
      ) {
        this.performancesDataHandler(this.$store.state.storage.storedSalesMonitorPerformances, true);

        this.filterPerformances();
        this.tableSalesMonitorPerformancesLoading = false;
      } else {
        ApiService.get('/SalesMonitor/GetPerformances', {
          startDate: functions.jsDateToStringDate(this.filterPeriod.from),
          endDate: functions.jsDateToStringDate(this.filterPeriod.to),
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            this.performances = response.data.result;

            this.performancesDataHandler(this.performances, false);

            this.filterPerformances();
            this.getStoredSalesMonitorPerformances(this.performances);
            this.getStoredSalesMonitorPerformancesFilterPeriod(this.filterPeriod);

            this.tableSalesMonitorPerformancesLoading = false;
          })
          .catch((error) => {
            //eslint-disable-next-line
            console.log(error);
            this.errored = true;
          });
      }
    },
    performancesDataHandler: function (response, cachedPerformances) {
      const vmLegacy = { $set: (o, k, v) => o[k] = v };
      this.performances = response;

      for (var i = 0; i < this.performances.length; i++) {
        if (!cachedPerformances) {
          vmLegacy.$set(this.performances[i], 'startDateView', functions.getDateTimeView(this.performances[i].startDate));

          vmLegacy.$set(this.performances[i], 'state', functions.changeState(this.performances[i].state));

          vmLegacy.$set(this.performances[i], 'type', functions.changeType(this.performances[i].type));

          vmLegacy.$set(
            this.performances[i],
            'soldoutString',
            this.performances[i].isSoldOut == true ? 'Uitverkocht' : 'Niet uitverkocht'
          );

          if (this.performances[i].isCoProduction == true) {
            this.performances[i].isCoProduction = 'Ja';
          } else {
            this.performances[i].isCoProduction = 'Nee';
          }

          vmLegacy.$set(
            this.performances[i],
            'noSales',
            this.performances[i].totalTickets === 0 && this.performances[i].reservedTickets === 0
              ? `${this.performances[i].productionNumber} | ${this.performances[i].number}`
              : ''
          );
        }
      }

      this.producers = functions.uniqueSorted(this.performances.map(p => ({
        value: p.producerId,
        title: p.producerName,
      })), p => p.title);

      this.theaters = functions.uniqueSorted(this.performances.map(p => ({
        value: p.theaterId,
        title: `${p.theaterName} (${p.theaterCity})`,
      })), t => t.title);

      this.agencies = functions.uniqueSorted(this.performances.filter(p => p.agencyId).map(p => ({
        value: p.agencyId,
        title: p.agencyName,
      })), a => a.title);

      this.genres = functions.uniqueSorted(this.performances.map(p => ({
        value: p.genreId,
        title: p.genreName,
      })), g => g.title);

      this.areaNames = functions.uniqueSorted(this.performances.map(p => ({
        value: p.areaId,
        title: p.areaName,
      })), a => a.title);

      this.tableSalesMonitorTotal.totalTickets = this.calculateArraySum(this.performances, 'totalTickets');
      this.tableSalesMonitorTotal.reservedTickets = this.calculateArraySum(this.performances, 'reservedTickets');
      this.tableSalesMonitorTotal.blockedTickets = this.calculateArraySum(this.performances, 'blockedTickets');
      this.tableSalesMonitorTotal.totalRevenueInVat = this.calculateArraySum(this.performances, 'totalRevenueInVat');

      this.tableSalesMonitorPerformancesFiltered = this.performances;

      // Paging
      this.checkFilter({
        viewName: this.$options.name + '-paging',
        filterObject: this.tableSalesMonitorPublicPaging,
      });
      this.tableSalesMonitorPublicPaging = this.getFilter(this.$options.name + '-paging');
    },
    filterPerformances: function () {
      const vm = this;
      if (
        this.tableSalesMonitorFilter.productionTitle == '' &&
        (this.tableSalesMonitorFilter.agencyId == '' || this.tableSalesMonitorFilter.agencyId == undefined || this.tableSalesMonitorFilter.agencyId == null) &&
        (this.tableSalesMonitorFilter.theaterId == '' || this.tableSalesMonitorFilter.theaterId == undefined) &&
        (this.tableSalesMonitorFilter.producerId == '' || this.tableSalesMonitorFilter.producerId == undefined || this.tableSalesMonitorFilter.producerId == null) &&
        (this.tableSalesMonitorFilter.genreId == '' || this.tableSalesMonitorFilter.genreId == undefined) &&
        (this.tableSalesMonitorFilter.areaId == '' || this.tableSalesMonitorFilter.areaId == undefined || this.tableSalesMonitorFilter.areaId == null)
      ) {
        this.tableSalesMonitorFilterActive = false;
      } else {
        this.tableSalesMonitorFilterActive = true;
      }

      this.tableSalesMonitorPerformancesFiltered = this.performances.filter(function (performance) {
        return (
          cleanWords(performance.productionTitle).indexOf(cleanWords(vm.tableSalesMonitorFilter.productionTitle)) !==
          -1 &&
          (performance.agencyId == vm.tableSalesMonitorFilter.agencyId ||
            vm.tableSalesMonitorFilter.agencyId == '' ||
            vm.tableSalesMonitorFilter.agencyId == undefined ||
            vm.tableSalesMonitorFilter.agencyId == null) &&
          (performance.theaterId == vm.tableSalesMonitorFilter.theaterId ||
            vm.tableSalesMonitorFilter.theaterId == '' ||
            vm.tableSalesMonitorFilter.theaterId == undefined) &&
          (performance.producerId == vm.tableSalesMonitorFilter.producerId ||
            vm.tableSalesMonitorFilter.producerId == '' ||
            vm.tableSalesMonitorFilter.producerId == undefined ||
            vm.tableSalesMonitorFilter.producerId == null) &&
          (performance.genreId == vm.tableSalesMonitorFilter.genreId ||
            vm.tableSalesMonitorFilter.genreId == '' ||
            vm.tableSalesMonitorFilter.genreId == undefined) &&
          (performance.areaId == vm.tableSalesMonitorFilter.areaId ||
            vm.tableSalesMonitorFilter.areaId == '' ||
            vm.tableSalesMonitorFilter.areaId == undefined ||
            vm.tableSalesMonitorFilter.areaId == null) &&
          (performance.isSoldOut === vm.tableSalesMonitorFilter.isSoldOut ||
            vm.tableSalesMonitorFilter.isSoldOut === '' ||
            vm.tableSalesMonitorFilter.isSoldOut === null ||
            vm.tableSalesMonitorFilter.isSoldOut === undefined)
        );
      });

      this.tableSalesMonitorSelected.totalTickets = this.calculateArraySum(
        this.tableSalesMonitorPerformancesFiltered,
        'totalTickets'
      );
      this.tableSalesMonitorSelected.reservedTickets = this.calculateArraySum(
        this.tableSalesMonitorPerformancesFiltered,
        'reservedTickets'
      );
      this.tableSalesMonitorSelected.blockedTickets = this.calculateArraySum(
        this.tableSalesMonitorPerformancesFiltered,
        'blockedTickets'
      );
      this.tableSalesMonitorSelected.averageTicketPrice = this.calculateArrayAvg(
        this.tableSalesMonitorPerformancesFiltered,
        'averageTicketPrice'
      );
      this.tableSalesMonitorSelected.totalRevenueInVat = this.calculateArraySum(
        this.tableSalesMonitorPerformancesFiltered,
        'totalRevenueInVat'
      );

      if (this.tableSalesMonitorFilter.isSoldOut === 1) {
        const noSalesPerformances = this.performances.filter((p) => p.totalTickets === 0 && p.reservedTickets === 0);
        this.tableSalesMonitorPerformancesFiltered = noSalesPerformances;
      }

      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearfilterPerformances: function () {
      this.tableSalesMonitorFilter.productionTitle = '';
      this.tableSalesMonitorFilter.agencyId = '';
      this.tableSalesMonitorFilter.theaterId = '';
      this.tableSalesMonitorFilter.producerId = '';
      this.tableSalesMonitorFilter.genreId = '';
      this.tableSalesMonitorFilter.areaId = '';
      this.tableSalesMonitorFilter.isSoldOut = '';
      this.tableSalesMonitorFilterActive = false;
      this.tableSalesMonitorFilterDialog = false;
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user', 'navigationStatus']),
    ...mapGetters('storage', ['getFilter']),

    tableSalesMonitorPerformancesHeadersFiltered() {
      return this.tableSalesMonitorPerformancesHeaders.filter(
        (h) => !h.showFor || h.showFor.includes(this.user.organisationTypeId)
      );
    },
  },
  beforeUnmount() {
    // Filter
    this.updateFilter({
      viewName: this.$options.name,
      filterObject: this.tableSalesMonitorFilter,
    });
    // Paging
    this.updateFilter({
      viewName: this.$options.name + '-paging',
      filterObject: this.tableSalesMonitorPublicPaging,
    });
  },
  watch: {
    tableSalesMonitorFilter: {
      handler: 'filterPerformances',
      deep: true,
    },
    filterPeriod: function () {
      this.getPerformances();
    },
  },
};
</script>

<style lang="scss" scoped>
.target {
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px;
  max-width: 2px;
  background-color: #021e72;
}
</style>