<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="56"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- Page info first row -->
          <v-row flex justify="center" align="center" class="pb-2" no-gutters>
            <v-col cols="12" class="py-0">
              <!-- Form -->
              <v-form @submit.prevent class="d-flex flex-column col col-6" ref="form">
                <v-col cols="12">
                  <v-card title="toeslag toevoegen">
                    <v-divider></v-divider>
                    <v-card-text class="mt-5">
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <date-picker :passed-date="addAreaSurchargeForm.startDate" @formatedDate="addAreaSurchargeForm.startDate = $event" rules="dateRequired"></date-picker>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-text-field label="theatertoeslag" prefix="€" v-model="addAreaSurchargeForm.theaterFee"
                            :rules="formValidationRules.valutaRequired"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-text-field label="consumptietoeslag" prefix="€"
                            v-model="addAreaSurchargeForm.consumptionFee"
                            :rules="formValidationRules.valutaRequired"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-text-field label="servicetoeslag" prefix="€" v-model="addAreaSurchargeForm.serviceFee"
                            :rules="formValidationRules.valutaRequired"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-text-field label="kosten garderobe" prefix="€" v-model="addAreaSurchargeForm.wardrobeFee"
                            :rules="formValidationRules.valutaRequired"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-btn-save @click="submit" :loading="formLoading">Opslaan</v-btn-save>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import DatePicker from '../../../components/DatePicker';

import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'LibraryAreasSurchargeAdd',
  props: ['theaterAreaId'],
  components: {
    BreadcrumbsWithHelp,
    DatePicker,
  },
  data() {
    return {
      area: {
        name: '',
      },
      isEditing: false,
      formError: '',
      formLoading: false,
      addAreaSurchargeForm: {
        startDate: '',
        theaterFee: '0,00',
        consumptionFee: '0,00',
        serviceFee: '0,00',
        wardrobeFee: '0,00',
      },
      formValidationRules: {},
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'zalen',
          disabled: false,
          href: '/beheer/zalen',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'toeslag toevoegen',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryAreasSurchargeAdd');
    this.checkRights();
    this.formValidationRules = functions.getFormValidationRules();
    this.getArea();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    checkRights() {
      if (!this.rights.edit) {
        router.back();
      }
    },
    getArea: function () {
      ApiService.get('/TheaterAreas/Get', {
        theaterAreaId: this.theaterAreaId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          if (response.data.success) {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            const area = response.data.result;
            this.breadcrumbs[2].text = area.name;
            this.breadcrumbs[2].href = '/beheer/zalen/' + this.theaterAreaId + '/';
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    async submit() {
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const form = this.addAreaSurchargeForm;

        ApiService.post('/Surcharges/Add', {
          areaId: this.theaterAreaId,
          startDate: functions.getDateDatabase(form.startDate),
          theaterFee: functions.getEuroCurrencyDatabase(form.theaterFee),
          consumptionFee: functions.getEuroCurrencyDatabase(form.consumptionFee),
          serviceFee: functions.getEuroCurrencyDatabase(form.serviceFee),
          wardrobeFee: functions.getEuroCurrencyDatabase(form.wardrobeFee),
        })
          .then((response) => {
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formLoading = false;
            }
          })
          .catch((error) => {
            this.formError = error;
            // eslint-disable-next-line
            console.log('error ' + error);
            this.errored = true;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
  },
  watch: {
    addAreaSurchargeForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
