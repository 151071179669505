<template>
  <v-col
      cols="12"
      md="6"
      class="py-0"
  >
    <p
        :class="isRejected ? 'error--text ' : 'text-primary '"
        class="font-weight-medium mb-0"
    >
      {{label}}
    </p>

    <div
        class="contract-diff mb-4"
        v-if="
              contract.previousApproved &&
              getter(contract.previousApproved).theaterFee &&
              getter(contract.previousApproved) !== getter(contract)
            "
    >
      <h3
          :class="
                isRejected
                  ? 'error--text no-dash'
                  : 'text-primary text-decoration-line-through mb-0 pr-2'
              "
      >
        {{ renderOldValue }}
      </h3>
      <h3 class="prev-approved--text pb-4 border-bottom">{{ renderValue(contract) }}</h3>
    </div>
    <h3
        v-else
        :class="isRejected ? 'error--text ' : 'text-primary '"
        class="border-bottom pb-4 mb-4"
    >
      {{ renderValue }}
    </h3>
  </v-col>
</template>

<script>
export default {
  props: ['label', 'contract', 'getter', 'isRejected', 'format', 'colClass'],
  computed: {
    renderValue() {
      const obj = this.contract;
      return this.format ? this.format(this.getter(obj)) : this.getter(obj);
    },
    renderOldValue() {
      const obj = this.contract.previousApproved;
      return this.format ? this.format(this.getter(obj)) : this.getter(obj);
    }
  }
}
</script>

<style scoped>

</style>