<template>
  <div>
    <!-- Proposed -->
    <v-alert
      v-if="contract.state == 'proposed'"
      text
      color="primary"
      variant="outlined"
      class="mt-5"
      >Deze financiële afspraak is ingediend op {{ messageDateTime() }}</v-alert
    >

    <!-- Revised -->
    <v-alert
      v-else-if="contract.state == 'revised'"
      text
      color="primary"
      variant="outlined"
      class="mt-5"
      >Deze financiële afspraak is herzien op {{ messageDateTime() }}</v-alert
    >

    <!-- Approved -->
    <v-alert
      v-else-if="contract.state == 'approved'"
      text
      color="success"
      variant="outlined"
      class="mt-5"
    >
      Deze financiële afspraak <template v-if="contract.isLatestApproved">is rechtsgeldig per</template>
      <template v-else>was rechtsgeldig van</template> {{ messageDateTime() }}
      <template v-if="!contract.isLatestApproved">tot {{ nextApprovedDateTime() }}</template>
    </v-alert>

    <!-- Rejected -->
    <template v-else-if="contract.state == 'rejected'">
      <v-alert
        text
        color="error"
        variant="outlined"
        >Deze financiële afspraak is afgekeurd op {{ messageDateTime() }}</v-alert
      >
      <v-alert
        text
        color="error"
        variant="outlined"
        class="my-5"
      >
        <template v-if="contract.isStartTimeRejected"><strong>- aanvangstijd</strong><br /></template>
        <template v-if="contract.isTheaterSurchargeRejected"><strong>- theatertoeslag</strong><br /></template>
        <template v-if="contract.isCopyrightCalculationRejected"
          ><strong>- percentageberekening auteursrecht</strong><br
        /></template>
        <template v-if="contract.isAmountRejected"><strong>- aanvulling tot</strong><br /></template>
        <template v-if="contract.isMaximumAmountRejected"><strong>- maximale aanvulling</strong><br /></template>
        <template v-if="contract.isGuaranteePerPerformanceRejected"
          ><strong>- aanvullingsberekening</strong><br
        /></template>
        <template v-if="contract.isCopyrightRejected"><strong>- auteursrechten</strong><br /></template>
        <template v-if="contract.isProviderGuaranteeRejected"><strong>- garantie aan aanbieder</strong><br /></template>
        <template v-if="contract.isConsumerGuaranteeRejected"><strong>- garantie aan afnemer</strong><br /></template>
        <template v-if="contract.isGuaranteeCalculationRejected"><strong>- garantieberekening</strong><br /></template>
        <template v-if="contract.isProviderPartageRejected"><strong>- partage aanbieder</strong><br /></template>
        <template v-if="contract.isTicketPriceRejected"><strong>- afrekenprijzen</strong><br /></template>
        <template v-if="contract.isConsumptionSurchargeRejected"><strong>- consumptietoeslag</strong><br /></template>
        <template v-if="contract.isServiceSurchargeRejected"><strong>- servicetoeslag</strong><br /></template>
        <template v-if="contract.isWardrobeSurchargeRejected"><strong>- garderobetoeslag</strong><br /></template>
        <template v-if="contract.isRemarkRejected"><strong>- extra afspraken</strong><br /></template>
        <template v-if="contract.isRentRejected"><strong>- huur</strong><br /></template>
        <template v-if="contract.isOtherCostsRejected"><strong>- overige kosten</strong><br /></template>
        <template v-if="contract.isBuyoutAmountRejected"><strong>- uitkoopsom</strong><br /></template>
        <template v-if="contract.isSuppletionAmountRejected"><strong>- suppletie</strong><br /></template>
        <template v-if="contract.isTrancheRejected"><strong>- staffel</strong><br /></template>
        <template v-if="contract.isCombinedBudgetRejected"><strong>- gezamelijke post</strong><br /></template>
        <template v-if="contract.rejectReason != ''">
          <br /><strong>Extra opmerking:</strong>
          <div
            v-for="item in contract.rejectReason.split('\n')"
            style="min-height: 15px"
            :key="item"
          >
            {{ item }}
          </div>
        </template>
      </v-alert>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import functions from '../../../../services/functions.service';

export default {
  props: ['contract'],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    messageDateTime() {
      return functions.getDateTimeView(this.contract.modifiedDate);
    },
    nextApprovedDateTime() {
      return functions.getDateTimeView(
        this.contract.logs.filter((l) => l.modifiedDate > this.contract.modifiedDate && l.state == 'approved')[0]
          ?.modifiedDate
      );
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {},
};
</script>

<style scoped></style>
