<template>
  <v-col
    :cols="colsMobile"
    :sm="colsTablet"
    :md="colsDesktop"
    class="d-flex"
  >
    <v-card
      class="px-4 py-0 mb-1"
      flat
      width="100%"
      :loading="areaOccupationLoading"
    >
      <v-container>
        <v-row class="block-head">
          <v-col
            cols="12"
            class="d-flex align-center justify-space-between"
          >
            <p class="text-primary font-weight-medium mb-0">zaalbezetting</p>
            <help
              v-if="helpId != ''"
              bg-color=""
              :help-id="helpId"
            ></help>
          </v-col>
        </v-row>

        <v-row
          flex
          align="center"
          justify="center"
          class="py-2 flex-column flex-grow-1"
        >
          <template v-if="chart.loaded">
            <v-col cols="12">
              <Bar
                v-if="chart.loaded"
                :chart-data="chart.data"
                :chart-options="chart.options"
              />
            </v-col>
          </template>

          <template v-else>
            <p class="text-primary font-weight-medium mb-0">geen data beschikbaar</p>
          </template>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import Help from '../Help.vue';
import { Bar } from 'vue-chartjs';

export default {
  props: {
    helpId: {
      type: [String, Number],
      required: false,
    },
    colsMobile: {
      type: Number,
      default: 12,
    },
    colsTablet: {
      type: Number,
      default: 6,
    },
    colsDesktop: {
      type: Number,
      default: 4,
    },
    filterPeriod: {
      type: Object,
      default: () => ({ from: '', to: '' }),
    },
  },
  components: {
    Help,
    Bar,
  },
  data() {
    return {
      chart: {
        loaded: false,
        data: {},
        options: {},
      },
      chartData: [],
      areaOccupationLoading: true,
    };
  },
  methods: {
    async getData() {
      this.chart.loaded = false;
      this.areaOccupationLoading = true;

      try {
        const response = await ApiService.get('/Dashboard/GetOccupancyRatePerGenre', {
            StartDate: this.filterPeriod.from,
            EndDate: this.filterPeriod.to,
        });

        TokenService.saveToken(response.data.token);
        ApiService.setHeader();

        this.chartData = response.data.result;

        const labels = this.chartData.map(e => e.genreName);
        const dataGenrelocalOccupancyRate = this.chartData.map(e => e.localOccupancyRate);
        const dataGenrenationalOccupancyRate = this.chartData.map(e => e.nationalOccupancyRate);

        // Set chart options
        this.chart.options = {
          onHover: (e, chartEl) => {
            e.native.target.style.cursor = chartEl[0] ? 'pointer' : 'default';
          },
          onClick: (e, chart) => {
            const res = chart.getElementsAtEventForMode(e.native, 'nearest', { intersect: true }, true);

            if (res.length === 0) return;

            const selectedBarLabel = chart.data.labels[res[0].index];

            this.$router.push({
              name: 'SalesMonitorProductions',
              query: { genre: selectedBarLabel },
            });
          },
          responsive: true,
          maintainAspectRatio: this.user.organisationTypeId !== 3,
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 20,
              bottom: 0,
            },
          },
          indexAxis: 'y',
          scales: {
            x: {
              barThickness: 0.4,
            },
            y: {
              barThickness: 50,
              barPercentage: 1,
            },
          },
          plugins: {
            datalabels: {
              align: 'end',
              anchor: 'end',
              color: '#021e73',
              formatter: (value) => value > 5 ? value : '',
            },
            legend: {
              display: true,
              position: 'top',
            },
            tooltip: {
              enabled: true,
              intersect: true,
              mode: 'index',
              position: 'nearest',
              callbacks: {
                label: (tooltipItem) => {
                  let label = tooltipItem.dataset.label || '';
                  if (label) label += ': ';
                  label += `${tooltipItem.formattedValue}%`;
                  return label;
                },
              },
            },
          },
        };

        // Set chart data
        this.chart.data = {
          labels: labels,
          datasets: [
            {
              label: 'eigen',
              backgroundColor: '#021e73',
              data: dataGenrelocalOccupancyRate,
            },
            {
              label: 'landelijk',
              backgroundColor: '#e8eff9',
              data: dataGenrenationalOccupancyRate,
            },
          ],
        };

        this.chart.loaded = true;
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.areaOccupationLoading = false;
      }
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    filterPeriod: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped></style>