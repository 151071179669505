<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row flex justify="center" align="center" class="pb-2" no-gutters>
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="1137"></breadcrumbs-with-help>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-lg-flex">
              <filter-date-from-to :defaultFrom="this.filter.filterDateOne" :defaultTo="this.filter.filterDateTwo"
                @changePeriod="filter.filterPeriod = $event"></filter-date-from-to>
              <!-- <v-text-field v-model="filter.dateFrom" label="datum vanaf" :rules="formValidationRules.dateRequired" hide-details dense outlined background-color="#fff" class="ma-1 input-sm" ></v-text-field> -->
            </div>

            <!-- Filter dialog -->
            <div class="d-lg-none">
              <v-dialog v-model="tableContactFilterDialog" width="500" fullscreen>
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn class="font-weight-bold text-lowercase" color="success" rounded dark
                    v-bind="activatorProps">filter overzicht
                    <v-icon small right>mdi-filter</v-icon></v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline dark">
                    <h5 class="text-primary">filter overzicht</h5>
                  </v-card-title>

                  <v-card-text>
                    <filter-date-from-to :defaultFrom="this.filter.filterDateOne" :defaultTo="this.filter.filterDateTwo"
                      @changePeriod="filter.filterPeriod = $event"></filter-date-from-to>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn class="font-weight-bold text-lowercase mb-5" color="success" depressed
                      @click="tableContactFilterDialog = false">filter overzicht
                      <v-icon small right>mdi-filter</v-icon></v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <template v-if="filter.filterPeriod.from != '' && filter.filterPeriod.to != ''">
          <!-- Table -->
          <v-data-table :headers="tableContactHeaders" 
            :items="tableContactFiltered" 
            :mobile-breakpoint="0"
            :loading="tableContactLoading" 
            :page.sync="tableContactPaging.page"
            :items-per-page="tableContactPaging.itemsPerPage" 
            hide-default-footer
            @update:current-items="tableContactPaging.pageCount = Math.ceil(tableContactFiltered.length / tableContactPaging.itemsPerPage)"
            @update:items-per-page="tableContactPaging.pageCount = Math.ceil(tableContactFiltered.length / tableContactPaging.itemsPerPage)"
            class="table-action" :sort-desc="false">
          </v-data-table>

          <!-- Table settings -->
          <table-settings 
          :items="tableContactFiltered" 
          :paging="tableContactPaging" 
          :export-columns="downloadExportColumns"
          :file-name="'dip-monitor-contactpersonen-gesubsidieerde-producenten'" 
          :sheet-name="'contactpersonen gesubsidieerde producenten'" 
          :show-excel-button="true"></table-settings>
        </template>
        <template v-else>
          <v-row flex justify="center" align="center">
            <v-col>
              <v-alert variant="outlined" text type="warning" color="error" dark>Selecteer eerst een van/tot datum om
                het overzicht te laden</v-alert>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ApiService from '@/services/api.service';
import { TokenService } from '@/services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import functions from '@/services/functions.service';
import FilterDateFromTo from '@/components/FilterDateFromTo';
import TableSettings from '@/components/TableSettings';

export default {
  name: 'MonitorContactPersonsSubsidizedProducers',
  components: {
    BreadcrumbsWithHelp,
    FilterDateFromTo,
    TableSettings,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'monitor',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'contactpersonen gesubsidieerde producenten',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      tableContactFiltered: [],
      tableContactLoading: true,
      tableContactHeaders: [
        { title: 'producent', key: 'producer.name' },
        { title: 'aantal', key: 'count' },
        { title: 'contactpersonen', key: 'usersString' },
      ],
      filter: {
        filterDateOne: '',
        filterDateTwo: '',
        filterPeriod: {
          from: '',
          to: '',
        },
      },
      tableContactPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      tableContactFilterDialog: false,
      downloadExportColumns: [
        { label: 'producent', field: 'producer.name' },
        { label: 'aantal', field: 'count' },
        { label: 'contactpersonen', field: 'usersString' },
      ],
      formValidationRules: {},
    };
  },
  beforeMount() {
    this.filterDefaultDate();
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    filterDefaultDate: function () {
      // Filter
      this.checkFilter({ viewName: this.$options.name, filterObject: this.filter });
      this.filter = this.getFilter(this.$options.name);

      if (this.filter.filterDateOne == '') {
        var now = new Date();
        var previousWeek = new Date();
        previousWeek = new Date(previousWeek.setDate(previousWeek.getDate() - 7));
        this.filter.filterDateOne = functions.getDateView(functions.jsDateToStringDate(previousWeek));
        this.filter.filterDateTwo = functions.getDateView(functions.jsDateToStringDate(now));
      } else {
        this.filter.filterDateOne = functions.getDateView(
          functions.jsDateToStringDate(new Date(this.filter.filterPeriod.from))
        );
        this.filter.filterDateTwo = functions.getDateView(
          functions.jsDateToStringDate(new Date(this.filter.filterPeriod.to))
        );
      }
    },
    getContacts: function () {
      if (this.filter.filterPeriod.from !== '' && this.filter.filterPeriod.to !== '') {
        this.tableContactFiltered = [];
        this.tableContactLoading = true;

        ApiService.get('/Monitor/GetSubventionProducers', {
          startDate: this.filter.filterPeriod.from,
          endDate: this.filter.filterPeriod.to,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            this.tableContactFiltered = response.data.result;

            this.tableContactFiltered.forEach(contact => {
              contact.count = contact.users.length;

              contact.usersString = contact.users.map(user => `${user.fullName} (${user.email})`).join(', ');
            });

            // Paging
            this.checkFilter({ viewName: this.$options.name + '-paging', filterObject: this.tableContactPaging });
            this.tableContactPaging = this.getFilter(this.$options.name + '-paging');

            this.tableContactLoading = false;
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.log(error);
            this.errored = true;
          });
      }
    },
  },
  computed: {
    ...mapGetters('storage', ['getFilter']),
  },
  beforeUnmount() {
    // Filter
    this.updateFilter({ viewName: this.$options.name, filterObject: this.filter });
    // Paging
    this.updateFilter({ viewName: this.$options.name + '-paging', filterObject: this.tableContactPaging });
  },
  watch: {
    filter: {
      handler: 'getContacts',
      deep: true,
    },
  },
};
</script>