import ApiService from './api.service';
import { TokenService } from './storage.service';
import router, { resetRouter } from '../router/router.js';
import { viewService } from './view.service';
import store from '../store';

const UserService = {
  async login(email, password) {
    const requestData = {
      method: 'post',
      url: '/Account/Login',
      data: {
        email: email,
        password: password,
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);

      if (!response.data.success) {
        const error = { errorCode: response.data.statusCode, errorMessage: response.data.result.message };
        return error;
      } else {
        TokenService.saveToken(response.data.result.token);
        ApiService.setHeader();
        ApiService.mount401Interceptor();

        if (!response.data.result.twoFactorEnabled) {
          await UserService.setNavigation();
        } else {
          store.dispatch('auth/fetchUser', { twoFactorEnabled: true });
        }
      }

      return response.data.result.token;
    } catch (error) {
      return {
        errorCode: '500',
        errorMessage:
          'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.',
      };
    }
  },

  logout() {
    ApiService.removeHeader();
    ApiService.unmount401Interceptor();
    UserService.clearNavigation();

    return TokenService.removeToken();
  },

  async twoFactorAuth(authCode) {
    const requestData = {
      method: 'post',
      url: '/Account/ValidateTwoFactorAuthentication',
      data: {
        CustomerKey: 'two-factor',
        ValidationKey: authCode,
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);

      if (!response.data.success) {
        const error = { errorCode: response.data.statusCode, errorMessage: response.data.result.message };
        return error;
      } else {
        TokenService.saveToken(response.data.result.token);
        ApiService.setHeader();
        ApiService.mount401Interceptor();
        await UserService.setNavigation();
      }

      return response.data.result.token;
    } catch (error) {
      return {
        errorCode: '500',
        errorMessage:
          'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.',
      };
    }
  },

  async switchRole(userId, userRoleId, organisationId, organisationTypeId) {
    try {
      const response = await ApiService.post('/Account/SetRole', {
        userId: userId,
        userRoleId: userRoleId,
        organisationId: organisationId,
        organisationTypeId: organisationTypeId,
      });

      TokenService.saveToken(response.data.token);
      ApiService.setHeader();
      ApiService.mount401Interceptor();

      UserService.clearNavigation();
      UserService.setNavigation();

      const redirect = setInterval(() => {
        if (store.state.auth.user.agreedToTerms !== undefined) {
          clearInterval(redirect);

          if (!store.state.auth.user.agreedToTerms) {
            router.push('/accepteer-voorwaarden');
          } else if (userRoleId === 0 && organisationId === 0 && organisationTypeId === 0) {
            router.push('/');
          } else {
            router.push('/dashboard/');
          }

          store.dispatch('auth/fetchAuthenticating');
        }
      }, 100);
    } catch (error) {
      console.error(error);
      router.back();
    }
  },

  async setNavigation() {
    try {
      // Fetch the current navigation status
      await store.dispatch('auth/fetchNavigationStatus');
      const navigationStatus = store.getters['auth/getNavigationStatus'];

      // Fetch navigation data from API
      const response = await ApiService.get('/Account/GetNavigation', { status: navigationStatus });
      const { navigation, user } = response.data.result;

      // Fetch helper functions
      await this.fetchHelperFunctions();

      // Add routes dynamically based on navigation data
      addRoutes(navigation);

      // Store navigation and user data in the state
      store.dispatch('auth/fetchNavigation', navigation);
      store.dispatch('auth/fetchUser', user);

      // Redirect to terms acceptance if user hasn't agreed to terms
      if (!user.agreedToTerms) {
        addSingleRoute({
          path: '/accepteer-voorwaarden',
          component: viewService.getView('AcceptTerms'),
          name: 'AcceptTerms',
          redirect: true,
        });
      }

      // Add a catch-all route for handling 404s
      addSingleRoute({
        path: '/:catchAll(.*)',
        component: viewService.getView('PageNotFound'),
        name: 'PageNotFound',
      });

      // Refresh the current route to ensure new routes are in effect
      router.replace(router.currentRoute.value.fullPath);
    } catch (error) {
      console.error('Failed to set navigation:', error);
      store.dispatch('auth/logout');
    }
  },

  async fetchHelperFunctions() {
    try {
      const response = await ApiService.get('/HelpFunctions/GetAll');
      const functions = response.data.result;
  
      store.dispatch('auth/fetchHelperFunctions', functions);
    } catch (error) {
      console.error('Failed to fetch helper functions:', error);
    }
  },

  clearNavigation() {
    resetRouter();
  },
};

// Helper function to add multiple routes
function addRoutes(routes) {
  routes.forEach((route) => {
    const newRoute = mapRoute(route);
    if (newRoute) router.addRoute(newRoute);

    if (route.children) addRoutes(route.children);
  });
}

// Helper function to add a single route with an optional redirect
function addSingleRoute({ path, component, name, redirect = false }) {
  const route = { path, component, name };
  router.addRoute(route);
  if (redirect) router.push(path);
}

// Map route data to a Vue Router-compatible route object
function mapRoute(route) {
  if (!route.router || !route.router.component) return null;
  return {
    path: route.router.path,
    component: viewService.getView(route.router.component),
    name: route.router.name,
    props: route.router.props || false,
  };
}

export default UserService;
