<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row flex justify="center" align="center" class="pb-2" no-gutters>
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="40"></breadcrumbs-with-help>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <v-text-field v-model="tableRightsFilter.name" label="op naam zoeken" hide-details density="compact"
                variant="outlined" bg-color="#fff" class="ma-1 input-sm" />
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog v-model="tableRightsFilterDialog" width="500" fullscreen>
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn class="font-weight-bold text-lowercase" color="success" rounded dark v-bind="activatorProps">
                    filter overzicht
                    <v-icon small right>mdi-filter</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline dark">
                    <h5 class="text-primary">filter overzicht</h5>
                  </v-card-title>

                  <v-card-text>
                    <v-text-field v-model="tableRightsFilter.name" label="op naam zoeken" hide-details dense outlined
                      background-color="#fff" class="mb-3" />
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn class="font-weight-bold text-lowercase mb-5" color="success" depressed
                      @click="tableRightsFilterDialog = false">
                      filter overzicht
                      <v-icon small right>mdi-filter</v-icon>
                    </v-btn>
                    <v-btn class="font-weight-bold text-lowercase" color="error" outlined dark
                      @click="clearFilterUsers">
                      reset filter
                      <v-icon small right>mdi-close</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table 
          :headers="tableRightsHeaders" 
          :items="tableRightsFiltered" 
          :mobile-breakpoint="0"
          :loading="tableRightsLoading" 
          :page="tableRightsPaging.page" 
          :items-per-page="tableRightsPaging.itemsPerPage"
          @update:current-items="tableRightsPaging.pageCount = Math.ceil(tableRightsFiltered.length / tableRightsPaging.itemsPerPage)"
          @update:items-per-page="tableRightsPaging.pageCount = Math.ceil(tableRightsFiltered.length / tableRightsPaging.itemsPerPage)"
          class="table-action table-expand mt-2" 
          hide-default-footer 
          fixed-header 
          single-expand 
          show-expand
          :height="tableRightsFiltered.length > 10 ? '60vh' : ''">
          <template v-slot:expanded-row="{ columns, item }">
            <td :colspan="columns.length" class="pa-0">
              <v-data-table :headers="tableRightsDetailHeaders" :items="item.sitemap" :mobile-breakpoint="0"
                :items-per-page="-1" hide-default-footer class="table-action">
                <template v-slot:[`item.rights.view`]="{ item }">
                  <v-checkbox v-if="item.rights.view != null" :input-value="item.rights.view" :disabled="true" />
                  <div v-else>-</div>
                </template>
                <template v-slot:[`item.rights.edit`]="{ item }">
                  <v-checkbox v-if="item.rights.edit != null" :input-value="item.rights.edit" :disabled="true" />
                  <div v-else>-</div>
                </template>
                <template v-slot:[`item.rights.approve`]="{ item }">
                  <v-checkbox v-if="item.rights.approve != null" :input-value="item.rights.approve" :disabled="true" />
                  <div v-else>-</div>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>

        <!-- Table settings -->
        <table-settings :items="tableRightsFiltered" :paging="tableRightsPaging"
          :show-excel-button="false"></table-settings>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';

import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import TableSettings from '@/components/TableSettings.vue';

export default {
  name: 'AdminRights',
  components: {
    BreadcrumbsWithHelp,
    TableSettings,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'rechten',
          disabled: false,
          href: '/admin/rechten',
          bold: true,
        },
      ],
      userRoles: [],
      tableRightsFiltered: [],
      tableRightsLoading: true,
      tableRightsHeaders: [
        { title: 'naam', key: 'name' },
        { title: 'organisatie type', key: 'organisationTypeName' },
        { title: '', key: 'data-table-expand', align: 'end' },
      ],
      tableRightsFilter: {
        roleName: '',
      },
      tableRightsPaging: {
        page: 1,
        pageCount: 25,
        itemsPerPage: 25,
      },
      tableRightsFilterDialog: false,
      tableRightsDetailHeaders: [
        { title: 'module', key: 'name', sortable: false },
        { title: 'inzage', key: 'rights.view' },
        { title: 'beheer', key: 'rights.edit' },
        { title: 'accorderen', key: 'rights.approve' },
      ],
      adminCanEdit: false,
    };
  },
  mounted() {
    // Filter
    this.checkFilter({ viewName: this.$options.name, filterObject: this.tableRightsFilter });
    this.tableRightsFilter = this.getFilter(this.$options.name);

    this.canEdit();

    this.getUsersroles();
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    canEdit: function () {
      const usersCanEdit = [
        1043, // Marlène Colbers (DIP)
        877, // Roel Cuppen (Cre8ion)
        878, // Sven Vermeulen (Cre8ion)
        879, // Sander van de Rijt (Cre8ion)
        880, // Joris van Loon (Cre8ion)
        1308, // Bas van Hout (Cre8ion)
      ];

      if (usersCanEdit.includes(this.user.userId)) {
        this.adminCanEdit = true;
      }
    },

    getUsersroles: function () {
      ApiService.get('/Rights/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.userRoles = response.data.result.userRoles;
          this.tableRightsFiltered = this.userRoles;

          // Paging
          this.checkFilter({ viewName: this.$options.name + '-paging', filterObject: this.tableRightsPaging });
          this.tableRightsPaging = this.getFilter(this.$options.name + '-paging');

          this.filterUsers();

          this.tableRightsLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterUsers: function () {
      const vm = this;
      this.tableRightsFiltered = this.userRoles.filter(function (userrole) {
        return cleanWords(userrole.name).indexOf(cleanWords(vm.tableRightsFilter.name)) !== -1;
      });
      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterUsers: function () {
      this.tableRightsFilter.roleName = '';
      this.tableRightsFilterDialog = false;
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('storage', ['getFilter']),
  },
  beforeUnmount() {
    // Filter
    this.updateFilter({ viewName: this.$options.name, filterObject: this.tableRightsFilter });
    // Paging
    this.updateFilter({ viewName: this.$options.name + '-paging', filterObject: this.tableRightsPaging });
  },
  watch: {
    tableRightsFilter: {
      handler: 'filterUsers',
      deep: true,
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}
</style>
